import { Formik, Form } from "formik";
import "./editOustandingAmountModal.scss";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import { oustandingSchema } from "../../validations/MembershipValidation";
import { useMutation } from "@apollo/client";
import { UPDATE_OUTSTANDING_AMOUNT } from "../../gqloperations/mutations";
import { toast } from "react-toastify";

const EditOustandingAmountModal = (props) => {
  const [updateOustandingAmount, { loading: updateOustandingAmountLoading }] =
    useMutation(UPDATE_OUTSTANDING_AMOUNT);

  const onSubmit = async (values, OnSubmitProps) => {
    await updateOustandingAmount({
      variables: {
        memberId: props.memberId,
        amount: values.oustandingAmount,
        reason: values.reason,
        operationName: "updateOutstanding",
      },
      onCompleted: (data) => {
        toast.success("Updated outstanding amount successfully");
        props.showModalPayment(true);
        props.showModal("");
        props.setOutstandingAmount(values.oustandingAmount);
      },
      onError: (err) => {
        toast.error(`${err}`);
      },
    });
  };
  return (
    <Formik
      initialValues={{
        oustandingAmount: Number(props.outstandingAmount).toFixed(2),
        reason: "",
      }}
      validationSchema={oustandingSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="edit-oustanding-container pd-32">
            <div className="entry-title">
              <h3 className="fs-35 item-centered pb-24">
                Edit Oustanding Amount
              </h3>
            </div>
            <Form>
              <div className="member-details">
                <Input
                  type="number"
                  label="Oustanding amount"
                  name="oustandingAmount"
                  id="oustandingAmount"
                />
              </div>
              <div>
                <p className="reason-title fs-10 text-gray">Reason</p>
                <textarea
                  className="reason-box fs-12"
                  id="reason"
                  name="reason"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.reason}
                ></textarea>
                <div className="fs-12 error">
                  {formik.errors.reason &&
                    formik.touched.reason &&
                    formik.errors.reason}
                </div>
              </div>
              <div className="entry-btn-group item-centered">
                <Button
                  loading={updateOustandingAmountLoading}
                  name={"Update"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm fs-12"
                  disabled={updateOustandingAmountLoading}
                />
                <Button
                  name="cancel"
                  btntype="button"
                  btnname="cancel"
                  click={() => {
                    props.showModalPayment(true);
                    props.showModal("");
                  }}
                  className="btn btn-deny btn-small fs-12"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default EditOustandingAmountModal;
