import { useEffect, useState } from "react";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { GET_SMS_INCOMING_BY_DESTINATION } from "../../../../gqloperations/queries";
import { useTableStateProps } from "../../../../hooks/useTableStateprops";
import { Table, Modal } from "../../../../components";
import { BsCardText } from "react-icons/bs";
import { dateFormatForColumn } from "../../../../utils/dateFormatForColumn";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { AiOutlineSearch } from "react-icons/ai";
import { format } from "date-fns";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";

const IncomingSMS = () => {
  const client = useApolloClient();
  const { state } = useLocationContext();
  const { createLog } = useMemberContext();
  const [originNumber, setOriginNumber] = useState(
    state?.location?.getLocation?.originNumber ?? null
  );
  const [tableData, setTableData] = useState();
  const [nextToken, setNextToken] = useState(null);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [detailsData, setDetailsData] = useState("");
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();

  useEffect(() => {
    setOriginNumber(state?.location?.getLocation?.originNumber ?? null);
  }, [state]);

  const getIncomingSMS = async (nextToken = null) => {
    try {
      const result = await client.query({
        query: GET_SMS_INCOMING_BY_DESTINATION,
        fetchPolicy: "no-cache",
        variables: {
          to: originNumber,
          sortDirection: "DESC",
          nextToken,
        },
      });
      if (result && nextToken) {
        setTableData([
          ...tableData,
          ...result.data.getSmsIncomingByDestination.items,
        ]);
      } else {
        setTableData(result.data.getSmsIncomingByDestination.items);
      }

      setNextToken(result.data.getSmsIncomingByDestination.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    getIncomingSMS();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleNextToken = () => {
    if (nextToken) {
      getIncomingSMS(nextToken);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const viewMessage = (data) => {
    setDetailsData(data);
  };

  const columns = [
    {
      accessorFn: (row) => {
        return `${row?.memberDetails?.givenName}, ${row?.memberDetails?.surname}`;
      },
      header: "MEMBER NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "memberName",
    },
    {
      accessorFn: (row) => {
        return `${row?.memberDetails?.email}`;
      },
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      accessorFn: (row) => {
        return dateFormatForColumn(row.dateTime);
      },
      header: "RECEIVE DATE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "sentDateTime",
    },

    {
      id: "row-actions-sms",
      cell: (props) => (
        <BsCardText
          onClick={() => {
            viewMessage(props.row.original);
            createLog(
              `Clicked view message icon for memberID: ${props.row.original.memberId}`
            );
          }}
        />
      ),
    },
  ];

  return (
    <>
      <div className="">
        <div className="tabs ">
          {tableData && originNumber && (
            <>
              <div className="actions-row ">
                <div className="left-elements ">
                  <div className="search-and-button-row">
                    <div className="search-container">
                      <form onSubmit={onSearchBarSubmit}>
                        <div className="search-bar">
                          <div className="search-bar-container">
                            <input
                              className="fs-10"
                              type="text"
                              name="search"
                              id="search"
                              value={searchBarInputVal}
                              onChange={handleChange}
                              placeholder="Member name"
                            />
                            <button type="submit">
                              <AiOutlineSearch />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>

              {tableData && originNumber ? (
                <div className="landing-table location-sms">
                  <Table
                    data={tableData}
                    globalFilter={globalFilter}
                    setGlobalFilter={setGlobalFilter}
                    sorting={sorting}
                    setSorting={setSorting}
                    columns={columns}
                    rowsPerPage={10}
                    handleNextToken={handleNextToken}
                    nextToken={nextToken}
                    columnFilters={columnFilters}
                    setColumnFilters={setColumnFilters}
                  />
                </div>
              ) : (
                <div className="no-data">
                  <h3 className="norecords-text fs-12 uppercase">
                    No Incoming SMS for this location
                  </h3>
                </div>
              )}
            </>
          )}
        </div>

        {detailsData && (
          <Modal>
            <div className="member-changes-modal">
              <h2 className="modal-title fs-35">SMS</h2>
              <div className="details-container">
                <div className="detail-entire-row">
                  <div className="detail">
                    <h3 className="fs-10">Date</h3>
                    <p className="fs-12">
                      {format(new Date(detailsData?.dateTime), "E d/LL/yy")}
                    </p>
                  </div>
                </div>
                <div className="detail">
                  <h3 className="fs-10">Sent from</h3>
                  <p className="fs-12">
                    {detailsData ? detailsData?.from : ""}
                  </p>
                </div>

                <div className="msg-container">
                  <h3 className="fs-10">Message</h3>

                  <textarea
                    className="msg-container fs-12"
                    defaultValue={detailsData?.message}
                  ></textarea>
                </div>
              </div>
              <button
                className="btn btn-small btn-confirm"
                onClick={() => setDetailsData("")}
              >
                CLOSE
              </button>
            </div>
          </Modal>
        )}
      </div>
    </>
  );
};

export default IncomingSMS;
