import "./app.scss";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import icon_chat from "./assets/images/icon_chat.svg";
import icon_chat_open from "./assets/images/icons/icon_chat_open.svg";
import {
  EditStaff,
  StaffGroup,
  Dashboard,
  AddClassTemplate,
  Member,
  Classes,
  AddClass,
  ClassTemplates,
  DashboardDetails,
  Report,
  SpecificReport,
  Login,
  ConfirmSignup,
  ForgotPassword,
  AddLocation,
  SpecificLocation,
  AddStaff,
  Staff,
  AddMember,
  Membership,
  AddMembership,
  EditMembershipType,
  AddMembershipToMember,
  ViewMembershipType,
  ApplyMembershipToLocation,
  ChangeMembershipPrice,
  RolesAndPermissions,
  Location,
  Profile,
  MemberDetails,
  ChangePassword,
  EditPinCode,
  GuestEntryLogs,
  Instructors,
  AddPinCode,
  AddInstructor,
  EditClassTemplate,
  ClassBookings,
  EditClass,
  PageNotFound,
  BulkSms,
  EditInstructor,
  ProtectedRoutes,
  PendingApproval,
  Groups,
  StaffToGroup,
  AddGroup,
  EditGroup,
  ViewGroup,
  GroupLocations,
  AccessLogs,
  MemberAccessLogs,
  Financial,
  UpgradeDowngrade,
  HealthQuestionnaire,
  AddHealthQuestionnaire,
  EditHealthQuestionNaire,
  ViewHealthQuestionnaire,
  ViewRejection,
  MedicalSuspension,
  ViewMedicalSuspension,
  AddVoucher,
  ViewVoucher,
  AddVoucherToLocation,
  AddVoucherToMember,
  EditVoucher,
  Allusersetting,
} from "./pages/index";

import {
  ChatWindow,
  Suspension,
  AddSuspension,
  ViewSuspension,
  IdleTimerContainer,
  AddDoorAccess,
  AddStudio,
  EditStudio,
  StaffReport,
  SiteLink,
} from "./components/index";
import {
  AddMemberContext,
  AddMemberContextProvider,
} from "./contexts/AddMemberContext";
import useUpdateSW from "./hooks/useUpdateSW";
import DebtCollection from "./pages/debtCollection/debtCollection";
import BankAccountRefund from "./pages/bankAccountRefund/BankAccountRefund";
import { useAuthContext } from "./contexts/AuthContext/AuthProvider";

function App() {
  const [show, setShow] = useState(false);
  const { loggedUser } = useAuthContext();

  const handleShowChat = () => {
    setShow(!show);
  };
  useUpdateSW();
  return (
    <>
      <Router>
        <div className="App">
          <IdleTimerContainer />
          <Routes>
            <Route path="/" element={<Login />}></Route>
            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
            <Route path="/signup" element={<ConfirmSignup />}></Route>
            <Route
              path="/dashboard"
              element={
                <ProtectedRoutes permission="CanViewDashboard">
                  <Dashboard />
                </ProtectedRoutes>
              }
            >
              <Route index element={<DashboardDetails />}></Route>
              <Route path="profile" element={<Profile />}></Route>
              <Route
                path="user_setting/all_setting"
                element={<Allusersetting />}
              ></Route>
              <Route
                path="sitelink/:searchValue"
                element={<SiteLink />}
              ></Route>
              <Route path="changepassword" element={<ChangePassword />}></Route>
              <Route
                path="member"
                element={
                  <ProtectedRoutes permission="CanViewMember">
                    <Member />
                  </ProtectedRoutes>
                }
              ></Route>
              {/* <Route path="member/addmember" element={<AddMember />}></Route> */}
              <Route
                path="member/addmember"
                element={
                  <AddMemberContextProvider>
                    <AddMember />
                  </AddMemberContextProvider>
                }
              ></Route>
              <Route
                path="membership"
                element={
                  <ProtectedRoutes permission="CanViewAllMembership">
                    <Membership />
                  </ProtectedRoutes>
                }
              ></Route>
              {/* <Route path="membership/addvoucher" element={<AddVoucher />}></Route>
              <Route
                path="membership/viewvoucher/:idVoucher"
                element={<ViewVoucher />} */}
              <Route
                path="membership/addvoucher"
                element={<AddVoucher />}
              ></Route>

              <Route
                path="membership/viewvoucher/:idVoucher"
                element={
                  <ProtectedRoutes permission="CanViewVoucher">
                    <ViewVoucher />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="membership/editvoucher/:idVoucher"
                element={<EditVoucher />}
              ></Route>
              <Route
                path="membership/addvouchertolocation/:voucherId"
                element={<AddVoucherToLocation />}
              ></Route>
              <Route
                path="membership/addvouchertomember/:voucherId"
                element={<AddVoucherToMember />}
              ></Route>
              <Route
                path="member/addsuspension/:contractId"
                element={<AddSuspension />}
              ></Route>

              <Route
                path="member/viewsuspension/:suspensionId"
                element={<ViewSuspension />}
              ></Route>
              <Route
                path="suspensions/:contractId"
                element={
                  <ProtectedRoutes permission="CanSuspendMembership">
                    <Suspension />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="member/upgradedowngrade/:contractId"
                element={<UpgradeDowngrade />}
              ></Route>
              <Route
                path="membership/addmembership"
                element={
                  <ProtectedRoutes permission="CanAddMembership">
                    <AddMembership />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="member/addMembershipToMember"
                element={
                  <ProtectedRoutes permission="CanAddMembershipToMember">
                    <AddMembershipToMember />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="addMembershipToMember/:contractId"
                element={
                  <ProtectedRoutes permission="CanAddMembershipToMember">
                    <AddMembershipToMember />
                  </ProtectedRoutes>
                }
              ></Route>

              <Route
                path="membership/editmembershiptype/:id"
                element={
                  <ProtectedRoutes permission="CanEditMembership">
                    <EditMembershipType />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="membership/viewmembershiptype/:id"
                element={
                  <ProtectedRoutes permission="CanViewMembership">
                    <ViewMembershipType />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="membership/changemembershipprice/:id"
                element={
                  <ProtectedRoutes permission="CanEditMembershipPrice">
                    <ChangeMembershipPrice />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="membership/applymembershipto/:id"
                element={
                  <ProtectedRoutes permission="CanApplyMembershipToLocation">
                    <ApplyMembershipToLocation />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="member/:id"
                viewMember
                element={
                  <ProtectedRoutes permission="CanViewMember">
                    <MemberDetails />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="classes"
                element={
                  <ProtectedRoutes permission="CanViewClassSchedule">
                    <Classes />
                  </ProtectedRoutes>
                }
              ></Route>
              {/* <Route path="classes/addClass" element={<AddClass />}></Route>
              <Route path="classes/editclass/:id" element={<EditClass />}></Route> */}
              <Route
                path="classes/addClass"
                element={
                  <ProtectedRoutes permission="CanAddClass">
                    <AddClass />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="classes/editclass/:id"
                element={
                  <ProtectedRoutes permission="CanEditClass">
                    <EditClass />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="classTemplates"
                element={
                  <ProtectedRoutes permission="CanViewClassTemplate">
                    <ClassTemplates />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                //   path="classTemplates/addClassTemplate"
                //   element={<AddClassTemplate />}
                // ></Route>
                // <Route
                //   path="classTemplates/editClassTemplate/:id"
                //   element={<EditClassTemplate />}
                path="classTemplates/addClassTemplate"
                element={
                  <ProtectedRoutes permission="CanAddClassTemplate">
                    <AddClassTemplate />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="classTemplates/editClassTemplate/:id"
                element={
                  <ProtectedRoutes permission="CanEditClassTemplate">
                    <EditClassTemplate />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="classes/bookingclass/:id"
                element={<ClassBookings />}
              ></Route>
              <Route path="accesslogs" element={<AccessLogs />}></Route>
              <Route
                path="accesslogs/guestentrylogs"
                element={<GuestEntryLogs />}
              ></Route>
              <Route
                path="accesslogs/memberaccesslogs"
                element={<MemberAccessLogs />}
              ></Route>
              <Route path="instructors" element={<Instructors />}></Route>
              {/* // <Route path="instructors/addInstructor" element={<AddInstructor />}></Route>
              // <Route path="instructors/editInstructor/:id" element={<EditInstructor />} /> */}
              <Route
                path="instructors/addInstructor"
                element={
                  <ProtectedRoutes permission="CanAddInstructor">
                    <AddInstructor />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="instructors/editInstructor/:id"
                element={
                  <ProtectedRoutes permission="CanEditInstructor">
                    <EditInstructor />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="rolesandpermissions"
                element={
                  <ProtectedRoutes permission="CanViewRoleAndPermission">
                    <RolesAndPermissions />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="financial"
                element={
                  <ProtectedRoutes permission="CanViewFinancial">
                    <Financial />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="financial/generateAba/:id"
                element={<ViewRejection />}
              ></Route>
              <Route path="bulkSms" element={<BulkSms />}></Route>
              <Route path="report" element={<Report />}></Route>

              <Route path="report/:id" element={<SpecificReport />}></Route>

              <Route
                path="staff"
                element={
                  <ProtectedRoutes permission="CanViewStaff">
                    <Staff />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="staff/addstaff"
                element={
                  <ProtectedRoutes role="L5" permission="CanAddStaff">
                    <AddStaff />
                  </ProtectedRoutes>
                }
              ></Route>

              <Route
                path="staff/editstaff/:id"
                element={
                  <ProtectedRoutes permission="CanEditStaff">
                    <EditStaff />
                  </ProtectedRoutes>
                }
              ></Route>

              {/* // <Route path="staff/staffreport/:id" element={<StaffReport />}></Route>
              // <Route path="staff/staffgroup/:id" element={<StaffGroup />}></Route> */}
              <Route
                path="staff/staffreport/:id"
                element={
                  <ProtectedRoutes permission="CanViewStaffAuditReport">
                    <StaffReport />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="staff/staffgroup/:id"
                element={
                  <ProtectedRoutes permission="CanAddAndRemoveStaffToGroup">
                    <StaffGroup />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route path="groups" element={<Groups />}></Route>
              <Route
                path="groups/addgroup"
                element={
                  <ProtectedRoutes permission="CanCreateGroup">
                    <AddGroup />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route path="editgroup/:id" element={<EditGroup />}></Route>
              <Route
                path="groups/viewgroup/:id"
                element={<ViewGroup />}
              ></Route>
              <Route
                path="groups/grouplocations/:id"
                element={<GroupLocations />}
              ></Route>
              <Route path="groups/:id" element={<StaffToGroup />}></Route>
              {/* Commented beacause business requirement change we will only assign locations to staff from group */}
              {/* <Route
                path="stafflocations/:id"
                element={<StaffLocations />}
              ></Route> */}
              {/* End-staff */}
              {/* start-location */}
              <Route
                path="location"
                element={
                  <ProtectedRoutes permission="CanViewAllLocations">
                    <Location />
                  </ProtectedRoutes>
                }
              ></Route>

              {/* // <Route path="location/addlocation" element={<AddLocation />}></Route>
              // <Route path="location/addPinCode" element={<AddPinCode />}></Route>
              // <Route path="location/addStudio" element={<AddStudio />}></Route>
              // <Route path="location/addDoorAccess" element={<AddDoorAccess />}></Route> */}
              <Route
                path="location/addlocation"
                element={
                  <ProtectedRoutes permission="CanCreateLocation">
                    <AddLocation />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="location/addPinCode"
                element={<AddPinCode />}
              ></Route>
              <Route path="location/addStudio" element={<AddStudio />}></Route>
              <Route
                path="location/addDoorAccess"
                element={<AddDoorAccess />}
              ></Route>
              <Route
                path="location/specificLocation/:id"
                element={<SpecificLocation />}
              ></Route>

              <Route
                path="location/editpincode/:id"
                element={<EditPinCode />}
              ></Route>
              <Route
                path="location/editstudio/:id"
                element={<EditStudio />}
              ></Route>

              <Route
                path="healthquestions"
                element={
                  // <ProtectedRoutes permission="CanViewHealthQuestion">
                  <HealthQuestionnaire />
                  // </ProtectedRoutes>
                }
              />

              <Route
                path="debtcollection"
                element={
                  <ProtectedRoutes permission="CanViewDebtCollection">
                    <DebtCollection />
                  </ProtectedRoutes>
                }
              />
              <Route
                path="bankaccountrefund"
                element={
                  <ProtectedRoutes permission="CanViewBankAccountRefund">
                    <BankAccountRefund />
                  </ProtectedRoutes>
                }
              />

              <Route path="pendingapproval" element={<PendingApproval />} />
              <Route
                path="medicalsuspensions"
                element={<MedicalSuspension />}
              />
              <Route
                path="medicalsuspensions/viewmedicalsuspension/:id"
                element={<ViewMedicalSuspension />}
              />
              <Route
                path="healthquestions/addquestionnaire"
                element={
                  // <ProtectedRoutes permission="CanAddHealthQuestion">
                  <AddHealthQuestionnaire />
                  // </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="healthquestions/viewhealthquestion/:id"
                element={
                  <ProtectedRoutes permission="CanViewHealthQuestion">
                    <ViewHealthQuestionnaire />
                  </ProtectedRoutes>
                }
              ></Route>
              <Route
                path="healthquestions/edithealthquestion/:id"
                element={
                  <ProtectedRoutes permission="CanEditHealthQuestion">
                    <EditHealthQuestionNaire />
                  </ProtectedRoutes>
                }
              ></Route>
            </Route>
            <Route path="*" element={<PageNotFound />}></Route>
          </Routes>
          <div className="chat_icon">
            {show && (
              <>
                <ChatWindow className="chat_window"></ChatWindow>
              </>
            )}

            {show ? (
              <img src={icon_chat_open} alt="chat" onClick={handleShowChat} />
            ) : (
              <img src={icon_chat} onClick={handleShowChat} alt="" />
            )}
          </div>
        </div>
      </Router>

      <ToastContainer className="fs-14" />
    </>
  );
}

export default App;
