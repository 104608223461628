import { createContext, useReducer, useContext } from "react";
import { useMemberContext } from "../contexts/MemberContext";
import {
  GET_CLASS_TEMPLATE_BY_ID,
  GET_STUDIO_BY_LOCATION_ID,
  GET_ALL_INSTRUCTOR,
  GET_CLASS_SCHEDULE_BY_ID,
  GET_CLASS_TEMPLATE_BY_BRANDID,
} from "../gqloperations/queries";
import { useLazyQuery } from "@apollo/client";
import reducer from "../reducers/classes_reducer";
import { toast } from "react-toastify";
import { useApolloClient } from "@apollo/client";

import {
  GET_CLASS_TEMPLATE_DATA_BY_ID,
  SET_CLASS_TEMPLATE_ID,
  LIST_STUDIO_DATA_BY_LOCATION_ID,
  LIST_INSTRUCTOR,
  CLASSTEMPLATE_BY_BRAND_ID,
  CLEAR_CLASS_TEMPLATE_DATA,
} from "../actions";

export const ClassContext = createContext();

const initialState = {
  classTemplateId: null,
  classTemplateDataById: "",
  id: "",
  listClassTemplates: [],
  studioData: "",
  allInstructor: "",
  classScheduleId: "",
  editTableData: "",
};

export const ClassContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { sidebarBrandId, sidebarLocationId: locationId } = useMemberContext();
  const client = useApolloClient();

  const getTemplateById = (id) => {
    dispatch({
      type: SET_CLASS_TEMPLATE_ID,
      payload: id,
    });
  };

  // useEffect(() => {
  //   if (state.classTemplateId) {
  //     getClassTemplateById();
  //   }
  // }, [state.classTemplateId]);

  const clearClassTemplateData = () => {
    dispatch({
      type: CLEAR_CLASS_TEMPLATE_DATA,
    });
  };

  const getClassTemplateById = async () => {
    let variable = { id: state.classTemplateId };
    try {
      const result = await client.query({
        query: GET_CLASS_TEMPLATE_BY_ID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) {
        dispatch({
          type: GET_CLASS_TEMPLATE_DATA_BY_ID,
          payload: result.data,
        });
      }
    } catch (error) {
      toast.error("Error: Cannot access class template");
      console.log(error);
    }
  };

  // const [getClassTemplateById, { data: classTemplateByIdData }] = useLazyQuery(
  //   GET_CLASS_TEMPLATE_BY_ID,
  //   {
  //     fetchPolicy: "no-cache",
  //     variables: { id: `${state.classTemplateId}` },
  //     onCompleted: (data) => {
  //       if (data) {
  //         dispatch({
  //           type: GET_CLASS_TEMPLATE_DATA_BY_ID,
  //           payload: data,
  //         });
  //       }
  //     },
  //     onError: (error) => {
  //       toast.error("Error: Cannot access class template");
  //       console.log(`${error}`);
  //     },
  //   }
  // );

  const [
    listStudioByLocationId,
    {
      loading: listStudioLoading,
      error: listStudioError,
      data: listStudioData,
    },
  ] = useLazyQuery(GET_STUDIO_BY_LOCATION_ID, {
    fetchPolicy: "no-cache",
    variables: { locationId: `${locationId}` },
    onCompleted: (data) => {
      dispatch({
        type: LIST_STUDIO_DATA_BY_LOCATION_ID,
        payload: data,
      });
    },
  });

  const [
    listInstructor,
    {
      data: listInstructorData,
      loading: listInstructorLoading,
      error: listInstructorError,
    },
  ] = useLazyQuery(GET_ALL_INSTRUCTOR, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const fetchMoreData = async (nextToken, allData) => {
        let finalData = {
          listClassInstructors: {
            items: [...allData],
          },
        };
        while (nextToken) {
          const result = await client.query({
            query: GET_ALL_INSTRUCTOR,
            variables: {
              limit: 1000,
              nextToken: nextToken,
            },
            fetchPolicy: "network-only",
          });

          const newData = result.data.listClassInstructors.items;
          finalData.listClassInstructors.items =
            finalData.listClassInstructors.items.concat(newData);
          nextToken = result.data.listClassInstructors.nextToken;
        }
        dispatch({
          type: LIST_INSTRUCTOR,
          payload: finalData,
        });
      };

      const initialData = data.listClassInstructors.items;
      const initialNextToken = data.listClassInstructors.nextToken;
      fetchMoreData(initialNextToken, initialData);
    },
    variables: {
      limit: 1000,
      nextToken: null,
    },
  });

  const [classScheduleById, { data: classScheduledata }] = useLazyQuery(
    GET_CLASS_SCHEDULE_BY_ID,
    {
      fetchPolicy: "network-only",
      variables: {
        id: `${state.classScheduleId}`,
      },
      onCompleted: (data) => {},
    }
  );

  const [classTemplateByBrandId] = useLazyQuery(GET_CLASS_TEMPLATE_BY_BRANDID, {
    fetchPolicy: "network-only",
    onCompleted: (data) => {
      const fetchMoreData = async (nextToken, initialData) => {
        let allData = initialData;

        while (nextToken) {
          const result = await client.query({
            query: GET_CLASS_TEMPLATE_BY_BRANDID,
            variables: {
              brandId: `${sidebarBrandId}`,
              sortDirection: "DESC",
              nextToken: nextToken,
              filter: null,
              limit: 1000,
            },
            fetchPolicy: "network-only",
          });

          const filteredData =
            result.data.getClassTemplateByBrandId.items.filter(
              (item) => item.isDeleted !== true && item.isActive === true
            );
          allData = allData.concat(filteredData);
          nextToken = result.data.getClassTemplateByBrandId.nextToken;
        }

        dispatch({
          type: CLASSTEMPLATE_BY_BRAND_ID,
          payload: allData,
        });
      };

      const initialFilteredData = data.getClassTemplateByBrandId.items.filter(
        (item) => item.isDeleted !== true && item.isActive === true
      );
      fetchMoreData(
        data.getClassTemplateByBrandId.nextToken,
        initialFilteredData
      );
    },
    variables: {
      brandId: `${sidebarBrandId}`,
      sortDirection: "DESC",
      nextToken: null,
      filter: null,
      limit: 1000,
    },
  });

  return (
    <ClassContext.Provider
      value={{
        ...state,
        dispatch,
        getClassTemplateById,
        getTemplateById,
        clearClassTemplateData,
        classScheduledata,
        classTemplateByBrandId,
        classScheduleById,
        // classTemplateByIdData,
        listInstructor,
        listStudioByLocationId,
        listInstructorLoading,
        listInstructorError,
        listInstructorData,
        listStudioLoading,
        listStudioError,
        listStudioData,
      }}
    >
      {children}
    </ClassContext.Provider>
  );
};

export const useClassContext = () => {
  return useContext(ClassContext);
};
