import { createContext, useReducer, useContext, useState } from "react";
import {
  SET_SIDEBAR_BRAND_LOCATION_ID,
  SET_SIDEBAR_LOCATION_ID,
  OPEN_MODAL,
  CLOSE_MODAL,
  SET_SIDEBAR_BRAND_ID,
  SET_LOCATION_DATA_BY_BRAND_ID,
  SET_IMG_URL,
  SET_MEMBER,
  SET_INDEX,
  SET_MEMBERSHIPS_BY_LOCATION_ID,
  SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID,
  SET_SELECTED_MEMBERSHIP,
  SET_IPADDRESS,
  SET_MEMBER_INTERACTION,
  SET_VOUCHER_TYPE,
  SET_STATS_DATA,
  SET_USER_SETTINGS,
} from "../actions";
import {
  GET_LOCATION_BY_BRANDID,
  GET_MEMBERSHIPS_BY_LOCATION_ID,
  LIST_BRAND,
  GET_MEMBER_BY_ID,
  LIST_MEMBER_CONTRACT,
  LIST_STATISTICS,
  GET_LOCTAION_BY_ID,
} from "../gqloperations/queries";
import { AUDIT_OPERATION, AUDIT_VISIT_LOG } from "../gqloperations/mutations";
import { useLazyQuery, useMutation } from "@apollo/client";
import reducer from "../reducers/member_reducer";
import { useEffect } from "react";
import { useAuthContext } from "./AuthContext/AuthProvider";
import { API, Storage, graphqlOperation } from "aws-amplify";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { publicIpv4 } from "public-ip";
import { MEMBER_ACCESS_LOGS_SUBSCRIPTION } from "../gqloperations/subscriptions";

export const MemberContext = createContext();

const initialState = {
  memberId: "",
  modal: false,
  memberInteraction: "",
  index: 0,
  sidebarBrandId: "",
  sidebarBrandName: "",
  sidebarLocationId: "",
  sidebarLocationName: "",
  sidebarLocationData: [],
  statsData: "",
  role: "",
  member: "",
  oldValues: {},
  imgUrl: "",
  ipAddress: "",
  getMembershipByLocationData: "",
  memberContractByMembershipId: {},
  selectedMembershipId: null,
  voucherType: "FIXED",
  userSettings: null,
};

export const MemberContextProvider = ({ children }) => {
  const client = useApolloClient();
  // const [ipAddress, setIpAddress] = useState();
  const [state, dispatch] = useReducer(reducer, initialState);

  const { loggedUser, locationAccess, isHomeLocationAccess } = useAuthContext();

  const openModal = () => {
    dispatch({
      type: OPEN_MODAL,
    });
  };
  const setSelectedMembership = (id) => {
    dispatch({
      type: SET_SELECTED_MEMBERSHIP,
      payload: id,
    });
  };

  const setMemberInteraction = (interaction) => {
    dispatch({
      type: SET_MEMBER_INTERACTION,
      payload: interaction,
    });
  };

  const closeModal = () => {
    dispatch({
      type: CLOSE_MODAL,
    });
  };

  const setIndex = (index) => {
    dispatch({
      type: SET_INDEX,
      payload: {
        index: index,
      },
    });
  };
  const setVoucherType = (type) => {
    dispatch({
      type: SET_VOUCHER_TYPE,
      payload: {
        voucherType: type,
      },
    });
  };
  //get member by id
  // const [
  //   getMemberById,
  //   { loading: memberloading, error: membererror, data: memberdata },
  // ] = useLazyQuery(GET_MEMBER_BY_ID, {
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     memberId: `${state.memberId}`,
  //   },
  //   onCompleted: (data) => {
  //     dispatch({ type: SET_MEMBER, payload: data });
  //   },
  //   onError: (error) => {
  //     toast.error("Cannot access the member");
  //   },
  // });
  const [getMemberById] = useLazyQuery(GET_MEMBER_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      memberId: `${state.memberId}`,
    },
    onCompleted: (data) => {
      dispatch({ type: SET_MEMBER, payload: data });
    },
    onError: (error) => {
      toast.error("Cannot access the member");
    },
  });

  //get member contracts by membership id
  // const [getContracts] = useLazyQuery(LIST_MEMBER_CONTRACT, {
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     id: state.selectedMembershipId,
  //   },
  //   onCompleted: (data) => {
  //     dispatch({ type: SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID, payload: data });

  //     setSelectedMembership(data?.listMemberContracts?.items[0]?.id);
  //     // setContractId(
  //     //   data?.listMemberContracts?.items[0]?.billings?.items[0]
  //     //     ?.memberContractId
  //     // );
  //   },
  //   onError: (error) => {
  //     toast.error("Cannot access the member");
  //   },
  // });

  const getStats = async () => {
    try {
      const result = await client.query({
        query: LIST_STATISTICS,
        fetchPolicy: "no-cache",
        variables: {
          filter: {
            locationId: {
              eq: `${state.sidebarLocationId}`,
            },
          },
        },
      });
      if (result) {
        dispatch({
          type: SET_STATS_DATA,
          payload: result.data,
        });
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getContracts = async () => {
    try {
      const result = await client.query({
        query: LIST_MEMBER_CONTRACT,
        fetchPolicy: "no-cache",
        variables: {
          id: `${state.selectedMembershipId}`,
        },
      });
      if (result) {
        dispatch({
          type: SET_MEMBERCONTRACT_BY_MEMBERSHIP_ID,
          payload: result.data,
        });
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  // get Brand
  const [getAllBrands, { data: brandData }] = useLazyQuery(LIST_BRAND, {
    onCompleted: (data) => {},
  });
  const setBrandID = (brandId, brandName) => {
    dispatch({
      type: SET_SIDEBAR_BRAND_ID,
      payload: {
        sidebarBrandId: brandId,
        sidebarBrandName: brandName,
      },
    });
  };

  const getSidebarLocationId = (locationId, locationName) => {
    dispatch({
      type: SET_SIDEBAR_LOCATION_ID,
      payload: {
        sidebarLocationId: locationId,
        sidebarLocationName: locationName,
      },
    });
  };
  const [createAuditLogApi] = useMutation(AUDIT_OPERATION);
  const setIpAddress = (ip) => {
    dispatch({ type: SET_IPADDRESS, payload: ip });
  };
  const createAuditLog = async (newData, oldData, id, action, endPoint) => {
    try {
      let userIp = state.ipAddress;
      if (!userIp) {
        userIp = await publicIpv4();
        setIpAddress(userIp);
      }
      const payload = {
        input: {
          action: action,
          ipAddress: userIp,
          newAttributeName: Object.keys(newData),
          newAttributeValue: Object.values(newData),
          oldAttributeName: Object.keys(oldData),
          oldAttributeValue: Object.values(oldData),
          memberId: id,
          updatedById: loggedUser?.getMember?.memberId,
          endpoint: endPoint,
        },
      };
      createAuditLogApi({ variables: payload });
    } catch (e) {}
    // createAuditOperation(input: {action: READ, ipAddress: "", newAttributeName: "", newAttributeValue: "", oldAttributeName: "", oldAttributeValue: "", targetId: "", updatedBy: "", endpoint: ""})
  };

  const [
    getLocationByBrandId,
    { loading: locationLoading, error: locationError, data: locationData },
  ] = useLazyQuery(GET_LOCATION_BY_BRANDID, {
    fetchPolicy: "no-cache",
    variables: { brandId: `${state.sidebarBrandId}` },
    onCompleted: (data) => {},
  });

  const getLocationsByBrandId = () => {
    const locationDataByBrand = locationAccess?.brands.filter((brand) => {
      return brand.brandId === state.sidebarBrandId;
    });
    const initialLocationValues = locationDataByBrand?.map((item) => {
      if (
        isHomeLocationAccess &&
        locationDataByBrand[0].brandId === loggedUser.getMember.brandId
      ) {
        return {
          sidebarLocationId: loggedUser?.getMember?.homeLocationDetails.id,
          sidebarLocationName: loggedUser?.getMember?.homeLocationDetails?.name,
        };
      } else {
        return {
          sidebarLocationId: item?.locations[0]?.locationId,
          sidebarLocationName: item?.locations[0]?.locationName,
        };
      }
    });
    if (locationDataByBrand) {
      dispatch({
        type: SET_LOCATION_DATA_BY_BRAND_ID,
        payload: {
          sidebarLocationId: initialLocationValues[0]?.sidebarLocationId,
          sidebarLocationName: initialLocationValues[0]?.sidebarLocationName,
          sidebarLocationData: locationDataByBrand[0]?.locations,
        },
      });
    }
  };

  // const [
  //   getMembershipByLocationId,
  //   {
  //     loading: getMembershipByLocationLoading,
  //     error: getMembershipByLocationError,
  //     data: getMembershipByLocationData,
  //   },
  // ] = useLazyQuery(GET_MEMBERSHIPS_BY_LOCATION_ID, {
  //   fetchPolicy: "no-cache",
  //   variables: {
  //     locationId: state.sidebarLocationId,
  //     memberId: state.memberId,
  //     operationName: "getMembershipByLocationId",
  //   },
  //   // variables: { id: `${state.sidebarLocationId}` },
  //   onCompleted: (data) => {},
  //   onError: (error) => {
  //     toast.error(`${error}`);
  //   },
  // });

  const getMembershipByLocationId = async (locationId) => {
    try {
      const result = await client.query({
        query: GET_MEMBERSHIPS_BY_LOCATION_ID,
        fetchPolicy: "no-cache",
        variables: {
          locationId: locationId ?? state.sidebarLocationId,
          memberId: state.memberId,
          operationName: "getMembershipByLocationId",
        },
      });
      if (result) {
        dispatch({
          type: SET_MEMBERSHIPS_BY_LOCATION_ID,
          payload: result.data.getMembershipByLocationId,
        });
      }
      return result.data;
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  // audit visit logs
  const createLog = (whatWasClicked, clicked, id) => {
    let auditLog = {
      whatwasDone: whatWasClicked,
      clickedBy: `${loggedUser?.getMember?.memberId}`,
    };
    if (clicked === "member") {
      auditLog = {
        memberId: id,
        ...auditLog,
      };
    }
    if (clicked === "location") {
      auditLog = {
        locationId: id,
        ...auditLog,
      };
    }
    // if (clicked === "sidebar") {
    //   auditLog = {
    //     whatwasDone: whatWasClicked,
    //     clickedBy: `${loggedUser?.getMember?.memberId}`,
    //   };
    // } else if (clicked === "member") {
    //   auditLog = {
    //     whatwasDone: whatWasClicked,
    //     memberId: id,
    //     clickedBy: `${loggedUser?.getMember?.memberId}`,
    //   };
    // }
    auditvisitlog({
      variables: {
        input: auditLog,
      },
      onCompleted: () => {},
      // refetchQueries: [
      //   {
      //     query: GET_MEMBER_ACTIVITY_BY_MEMBERID,
      //     variables: {
      //       memberId: state?.member?.getMember?.memberId,
      //     },
      //   },
      // ],
    });
  };

  //calling a mutation
  const [
    auditvisitlog,
    {
      data: auditvisitlogdata,
      loading: auditvisitlogloading,
      error: auditvisitlogerror,
    },
  ] = useMutation(AUDIT_VISIT_LOG);

  useEffect(() => {
    if (state.sidebarBrandId) {
      getLocationsByBrandId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.sidebarBrandId]);

  useEffect(() => {
    if (isHomeLocationAccess) {
      dispatch({
        type: SET_SIDEBAR_BRAND_LOCATION_ID,
        payload: {
          sidebarBrandId: loggedUser?.getMember?.brandId,
          sidebarBrandName: loggedUser?.getMember?.brandDetails?.name,

          role: loggedUser?.getMember?.role,
        },
      });
    } else {
      dispatch({
        type: SET_SIDEBAR_BRAND_LOCATION_ID,
        payload: {
          sidebarBrandId: locationAccess?.brands[0]?.brandId,
          sidebarBrandName: locationAccess?.brands[0]?.brandName,

          role: loggedUser?.getMember?.role,
        },
      });
    }
  }, [loggedUser?.getMember, locationAccess, isHomeLocationAccess]);

  useEffect(() => {
    if (state.sidebarLocationId) {
      getStats();
    }
  }, [state.sidebarLocationId]);

  const encodeAndUploadImageToAws = async (file, filePath) => {
    return await Storage.put(filePath, file, {
      contentType: file?.type,
      contentEncoding: "base64",
    });
  };

  const downloadAndDecode = async (awsImgPath) => {
    await Storage.get(awsImgPath, {
      // contentType: "image/jpeg",
      download: true,
    })
      .then((data) => {
        const reader = new FileReader();
        reader.readAsDataURL(data.Body);
        reader.onloadend = () => {
          const decodedImage = reader.result;

          dispatch({
            type: SET_IMG_URL,
            payload: {
              imgUrl: decodedImage,
            },
          });
        };
        // reader.readAsDataURL(data.Body);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const setImgUrl = (url) => {
    dispatch({
      type: SET_IMG_URL,
      payload: {
        imgUrl: url,
      },
    });
  };
  useEffect(() => {
    const userSettings = localStorage.getItem("userSettingAll");
    if (userSettings) {
      dispatch({
        type: SET_USER_SETTINGS,
        payload: {
          userSettings: JSON.parse(userSettings),
        },
      });
    }
  }, []);
  useEffect(() => {
    let subscription;
    const tableSettings = state.userSettings?.filter(
      (setting) => setting.id === "setting1"
    )[0];
    if (
      state.sidebarLocationId !== 0 &&
      state.userSettings &&
      tableSettings.value
    ) {
      subscription = API.graphql(
        graphqlOperation(MEMBER_ACCESS_LOGS_SUBSCRIPTION, {
          filter: { locationId: { eq: state.sidebarLocationId } },
        })
      ).subscribe({
        next: (eventData) => {
          toast(
            <div style={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M9.27738 23.6676V2.06763L18.2442 3.90084V21.7946L9.27738 23.6676ZM10.7121 13.4654C11.0525 13.4654 11.3285 13.1894 11.3285 12.849C11.3285 12.5085 11.0525 12.2325 10.7121 12.2325C10.3716 12.2325 10.0956 12.5085 10.0956 12.849C10.0956 13.1894 10.3716 13.4654 10.7121 13.4654Z"
                  fill="#60A5FA"
                />
                <path
                  d="M5.75586 3.84648H8.75929V5.07106H6.98044V20.4649H8.75929V21.6895H5.75586V3.84648Z"
                  fill="#60A5FA"
                />
              </svg>{" "}
              Hello{" "}
              <span style={{ fontWeight: 600 }}>
                {eventData.value.data.onCreateDoorAccessLog.memberName}
              </span>
            </div>,
            {
              position: "bottom-right",
              autoClose: 3000,
              className: "toast-member-enter",
            }
          );
        },
        error: (error) => {
          console.error("sSubscription error:", error);
        },
      });
    }
    if (tableSettings && !tableSettings.value) {
      subscription && subscription.unsubscribe();
    }
    return () => subscription && subscription.unsubscribe();
  }, [state.sidebarLocationId, state.userSettings]);
  return (
    <MemberContext.Provider
      value={{
        ...state,
        dispatch,
        locationData,
        getLocationsByBrandId,
        getSidebarLocationId,
        openModal,
        closeModal,
        getAllBrands,
        brandData,
        setBrandID,
        createLog,
        getMembershipByLocationId,
        downloadAndDecode,
        setImgUrl,
        encodeAndUploadImageToAws,
        getMemberById,
        getContracts,
        setIndex,
        createAuditLog,
        setSelectedMembership,
        setMemberInteraction,
        setVoucherType,
      }}
    >
      {children}
    </MemberContext.Provider>
  );
};
// custom hooks in order to use the context-> what makes other component to be able to use
export const useMemberContext = () => {
  return useContext(MemberContext);
};
