import "./editclass.scss";
import { useEffect } from "react";
import { useParams} from "react-router-dom";
import { useClassContext } from "../../contexts/ClassContext";
import { UPDATE_CLASS_SCHEDULE } from "../../gqloperations/mutations";
import { GET_CLASS_SCHEDULE_BY_ID} from "../../gqloperations/queries";
import { useMutation} from "@apollo/client";
import { toast } from "react-toastify";
import { CLASS_SCHEDULE_BY_ID } from "../../actions";
import {
  ClassScheduleTemplate,
  ClassScheduleMessage,
  EditClassLocationSchedule,
  Header,
} from "../../components";

const EditClass = (props) => {
  let { id } = useParams();
  const [updateClassSchedule] = useMutation(UPDATE_CLASS_SCHEDULE);
  const {
    dispatch,
    classScheduledata,
    classScheduleId,
    classScheduleById,
    listInstructor,
    // listStudioByLocationId,
  } = useClassContext();
  
  // const { sidebarLocationId: locationId } =
  //   useMemberContext();

  useEffect(() => {
    dispatch({
      type: CLASS_SCHEDULE_BY_ID,
      payload: id,
    });
  }, [dispatch, id]);

  useEffect(() => {
    if (classScheduleId) {
      classScheduleById();
    }
  }, [classScheduleId, classScheduleById]);

  useEffect(() => {
    listInstructor();
  }, [listInstructor]);

  // useEffect(() => {
  //   if (locationId) {
  //     listStudioByLocationId();
  //   }
  // }, [locationId, listStudioByLocationId]);

  const onSubmit = async (values, OnSubmitProps) => {
    try {
      await updateClassSchedule({
        variables: {
          input: values,
        },

        onCompleted: () => {
          toast.success("Update successfully");
        },
        refetchQueries: [
          {
            query: GET_CLASS_SCHEDULE_BY_ID,
            variables: {
              id: `${id}`,
            },
          },
        ],
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  return (
    <>
      {classScheduledata && (
        <>
          <Header pageTitle="Edit Classes" />
          <div className="pb-32">
            <ClassScheduleTemplate
              classScheduledata={classScheduledata}
              id={id}
            />
            <ClassScheduleMessage
              classScheduledata={classScheduledata}
              id={id}
              onSubmit={onSubmit}
            />
            <EditClassLocationSchedule
              classScheduledata={classScheduledata}
              id={id}
              // onSubmit={onSubmit}
            />
          </div>
        </>
      )}
    </>
  );
};

export default EditClass;
