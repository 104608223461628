import "./addMembership.scss";
import "./editMembershipType.scss";
import { useState } from "react";
import { useMutation, useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { Formik, Form } from "formik";
import { AiOutlineEdit } from "react-icons/ai";
import { GET_MEMBERSHIP_TYPE_BY_ID } from "../../gqloperations/queries";
import {
  UPDATE_MEMBERSHIP_ACTIVATION_DATE,
  DELETE_MEMBERSHIP_ACTIVATION_DATE,
  UPDATE_MEMBERSHIP_DESCRIPTION,
} from "../../gqloperations/mutations";
import { MdOutlineDelete } from "react-icons/md";
import { format } from "date-fns";
import {
  Button,
  Header,
  Modal,
  AddMembershipActivationDate,
  StatusModal,
  Toggle,
} from "../../components";

const EditMembershipType = () => {
  let { id } = useParams();
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [isActive, setIsActive] = useState(true);
  const [membershipData, setMembershipData] = useState([]);
  const { loading, error, data, refetch } = useQuery(
    GET_MEMBERSHIP_TYPE_BY_ID,
    {
      variables: {
        id,
      },
      onCompleted: (data) => {
        setMembershipData(data);
      },
    }
  );

  const [updateDescription] = useMutation(UPDATE_MEMBERSHIP_DESCRIPTION);

  const [updateActivationDate] = useMutation(UPDATE_MEMBERSHIP_ACTIVATION_DATE);
  const [deleteActivationDate] = useMutation(DELETE_MEMBERSHIP_ACTIVATION_DATE);

  const handleClick = () => {
    setIsActive(false);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("editEditMembershipDescriptionForm").reset();
    setIsActive(true);
  };

  const onSubmit = (values, OnSubmitProps) => {
    let input = { ...values, id: `${data?.getMembership?.id}` };
    updateDescription({
      variables: {
        input,
      },
      onCompleted: () => {
        OnSubmitProps.resetForm();
        toast.success("Update successfully");
        setIsActive(true);
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
      refetchQueries: [
        {
          query: GET_MEMBERSHIP_TYPE_BY_ID,
          variables: {
            id: `${membershipData?.getMembership?.id}`,
          },
        },
      ],
    });
  };

  if (loading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (error) {
    return (
      <div>
        <div>Problem in getting membership activation date</div>
      </div>
    );
  }
  const activationDates = data.getMembership.activationDates.items;

  const existDatePeriods = activationDates.map((i) => {
    const datePeriod = (({ startDateTime, endDateTime }) => ({
      startDateTime,
      endDateTime,
    }))(i);
    const datePeriodTimeStamp = {
      ...datePeriod,
      startDateTime: new Date(i.startDateTime).getTime(),
      endDateTime: new Date(i.endDateTime).getTime(),
    };
    return datePeriodTimeStamp;
  });
  const sortedData = activationDates.slice().sort((a, b) => {
    return new Date(a.startDateTime) - new Date(b.startDateTime);
  });
  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };
  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const handleDeleteClick = async (rowObj) => {
    try {
      await deleteActivationDate({
        variables: {
          input: {
            id: rowObj.id,
          },
        },
        onCompleted: () => {
          toast.success("delete successfully");
          refetch();
          modalClose();
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const handleStatusClick = async (rowObj) => {
    try {
      await updateActivationDate({
        variables: {
          input: {
            id: rowObj.id,
            isActive: !rowObj.isActive,
          },
        },
        onCompleted: () => {
          toast.success("Updated successfully");
          refetch();
          modalClose();
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  return (
    <>
      {!loading && !error && (
        <>
          <Header pageTitle="Edit Membership" />
          <>
            <div className="info-card mt-32">
              <Formik
                enableReinitialize
                initialValues={{
                  description: membershipData?.getMembership?.description,
                }}
                onSubmit={(values, OnSubmitProps) => {
                  onSubmit(values, OnSubmitProps);
                }}
              >
                {(formik) => {
                  return (
                    <Form id="editEditMembershipDescriptionForm">
                      {isActive ? (
                        <>
                          <div className="title-bar uppercase">
                            <h3 className="fs-14">Description *</h3>
                            <span
                              className="icon-container edit"
                              onClick={handleClick}
                            >
                              <AiOutlineEdit />
                            </span>
                          </div>
                        </>
                      ) : (
                        <div className="title-bar uppercase">
                          <h3 className="fs-14">Description *</h3>
                          <span className="icon-container ">
                            <div className="sub-containers">
                              <div className="sub-container save">
                                <Button
                                  iconname="BiSave"
                                  btntype="submit"
                                  btnname="submit"
                                  className="btn btn-edit save btn-lg fs-12 "
                                />
                              </div>
                              <div className="sub-container cancel">
                                <Button
                                  iconname="AiOutlineCloseCircle"
                                  btntype="reset"
                                  btnname="reset"
                                  className="btn  btn-edit cancel btn-lg fs-12"
                                  click={handleCancel}
                                />
                              </div>
                            </div>
                          </span>
                        </div>
                      )}

                      <div className="message-content">
                        <textarea
                          className="reason-box fs-12"
                          id="description"
                          name="description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                          disabled={isActive}
                        ></textarea>
                        {formik?.errors?.description &&
                        formik?.touched?.description ? (
                          <div className="error fs-12">
                            {formik.errors.description}
                          </div>
                        ) : null}
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </>
          <AddMembershipActivationDate
            refetch={refetch}
            membershipId={id}
            membershipName={data.getMembership.membershipName}
            existDatePeriods={existDatePeriods}
          />
          <div className="add-membership-page active-date-status mt-12 pd-12">
            <h3 className="fs-14 uppercase pb-12">
              Edit Activation Date Status
            </h3>
            <table>
              <thead>
                <tr className="fs-10">
                  <th className="fs-10">Start Date</th>
                  <th className="fs-10">End Date</th>
                  <th className="fs-10 active-status-title">Active</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {sortedData.map((dateItem) => {
                  return (
                    <tr className="fs-12 activation-dates" key={dateItem.id}>
                      <td className="cell-data">
                        {format(new Date(dateItem.startDateTime), "dd/MM/yyyy")}{" "}
                      </td>
                      <td className="cell-data">
                        {dateItem.endDateTime
                          ? format(new Date(dateItem.endDateTime), "dd/MM/yyyy")
                          : ""}
                      </td>
                      <td className="cell-data">
                        <Toggle
                          checked={dateItem.isActive}
                          value={dateItem.isActive}
                          onChange={() =>
                            handleComponent("status-confirm", {
                              id: dateItem.id,
                              isActive: dateItem.isActive,
                            })
                          }
                        />
                      </td>
                      <td className="cell-data">
                        <div className="btn-cell pr-12">
                          <MdOutlineDelete
                            onClick={() =>
                              handleComponent("delete-template", {
                                id: dateItem.id,
                              })
                            }
                          />
                        </div>
                      </td>
                      <td></td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>

          {componentName && (
            <Modal>
              {componentName === "status-confirm" && (
                <StatusModal
                  curRow={curRow}
                  modalHeaderTxt={"Edit activation Status"}
                  modalParaText={`Are you sure you want to ${
                    curRow.isActive ? "deactivate" : "activate"
                  } these dates?`}
                  handleStatusClick={handleStatusClick}
                  modalClose={modalClose}
                />
              )}
              {componentName === "delete-template" && (
                <StatusModal
                  curRow={curRow}
                  modalHeaderTxt={"DELETE ACTIVATION DATE"}
                  modalParaText={"Are you sure you want to delete these dates?"}
                  handleStatusClick={handleDeleteClick}
                  modalClose={modalClose}
                />
              )}
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export default EditMembershipType;
