import "./sms.scss";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { SMS_SINGLE_SMS } from "../../gqloperations/mutations";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import { smsSchema } from "../../validations/MembershipValidation";
import { useMemberContext } from "../../contexts/MemberContext";

const Sms = (props) => {
  const { member, sidebarBrandName } = useMemberContext();

  //calling a mutations to SMS
  const [sendSms] = useMutation(SMS_SINGLE_SMS);

  const onSubmit = (values, OnSubmitProps) => {
    // const originNumber =
    //   sidebarBrandName === "Plus Fitness" ? "PFitness" : "Lime";
    delete values.memberName;

    sendSms({
      variables: {
        type: "SINGLE",
        // originNumber: originNumber,
        destinationNumber: values.to,
        message: values.message + " Reply STOP to Opt-Out",
        locationId: values.locationId,
        memberId: values.memberId,
        reason: "CUSTOMER_SERVICE",
      },

      onCompleted: (data) => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Message send successfully");
        props.onClick();
      },
      onError: (error) => {
        OnSubmitProps.setSubmitting(false);
        toast.error(`${error}`);
      },
    });
  };

  return (
    <Formik
      initialValues={{
        from: `${sidebarBrandName}`,
        identityName: `${sidebarBrandName}`,
        message: "",
        to: member?.getMember?.mobileNumber,
        memberId: member?.getMember?.memberId,
        memberName: `${member?.getMember?.givenName} ${member?.getMember?.surname}`,
        locationId: member?.getMember?.homeLocationId,
      }}
      validationSchema={smsSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="send-sms-container pd-32">
            <div className="entry-title">
              <h3 className="fs-35 item-centered pb-24">SMS</h3>
            </div>
            <Form>
              <div className="member-details">
                <Input
                  type="text"
                  label="Member"
                  name="memberName"
                  id="memberName"
                  disabled
                />
                <Input
                  type="text"
                  label="Mobile Number"
                  name="to"
                  id="to"
                  disabled
                />
              </div>
              <div>
                <p className="reason-title fs-10 text-gray">Message</p>
                <textarea
                  className="reason-box fs-12"
                  id="message"
                  name="message"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                ></textarea>
                <div className="fs-12 error">
                  {formik.errors.message &&
                    formik.touched.message &&
                    formik.errors.message}
                </div>
                <div className="note">
                  Note: 'Reply STOP to Opt-Out' will be sent with the message.
                </div>
              </div>
              <div className="entry-btn-group item-centered">
                {/* {formik.isSubmitting && (
                  <Button
                    name={<BeatLoader color="white" />}
                    btntype="submit"
                    btnname="submit"
                    disabled={true}
                    className="btn btn-confirm fs-12"
                  />
                )}
                {!formik.isSubmitting && (
                  <Button
                    name="send"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm fs-12"
                  />
                )} */}
                <Button
                  loading={formik.isSubmitting}
                  name={"Send"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="cancel"
                  btntype="button"
                  btnname="cancel"
                  click={props.onClick}
                  className="btn btn-deny btn-small fs-12"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default Sms;
