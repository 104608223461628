import "./suspension.scss";
import { useQuery, useMutation } from "@apollo/client";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import actions from "../../../assets/images/icons/action_menu.png";
import {
  GET_MEMBER_CONTRACT_BY_CONTRACTID,
  GET_MEMBER_BY_ID,
  LIST_MEMBER_CONTRACT,
  LIST_TRANSACTION_BY_MEMBERID,
} from "../../../gqloperations/queries";
import { useParams, useNavigate } from "react-router-dom";
import Table from "../../table/Table";
import Header from "../../header/Header";
import Button from "../../formUiElements/Button";
import { isAfter, isBefore, endOfToday, addDays, format } from "date-fns";
import { useMemberContext } from "../../../contexts/MemberContext";
import EditSuspensionModal from "../memberships/suspension/EditSuspensionModal";
import Modal from "../../modal/Modal";
import StatusModal from "../../modal/StatusModal";
import isSameDay from "date-fns/isSameDay";
import {
  SUSPEND_MEMBERSHIP,
  SUSPEND_MEMBERSHIP_MEDICAL,
  UPFRONT_SUSPENSION,
  UPFRONT_SUSPENSION_MEDICAL,
  MEDICAL_SUSPENSION_OPERATION,
} from "../../../gqloperations/mutations";
import { sortAsc } from "../../../utils/sort";
import MakePaymentModal from "../../modal/MakePaymentModal";
import { BeatLoader } from "react-spinners";
import { utcToZonedTime } from "date-fns-tz";

const Suspension = () => {
  const ref = useRef();
  const navigate = useNavigate();
  const { contractId } = useParams();
  const { createLog, member, createAuditLog } = useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [tableData, setTableData] = useState();
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [componentName, setComponentName] = useState("");
  const [nextDebitDate, setNextDebitDate] = useState();
  const [paymentFrequencyType, setPaymentFrequencyType] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [suspensionDetails, setSuspensionDetails] = useState({});
  const [upfrontPrice, setUpFrontPrice] = useState(0);
  const [auditValues, setAuditValues] = useState();
  const [paymentModal, setPaymentModal] = useState(false);
  const [paymentOperation, setPaymentOperation] = useState("");
  const [payloadArgument, setPayLoadArgument] = useState();
  const [showActions, setShowActions] = useState({});
  const [memberContractDetails, setMemberContractDetails] = useState({});
  const [medicalSuspensionOperation, { loading: suspensionOperationLoading }] =
    useMutation(MEDICAL_SUSPENSION_OPERATION);
  const [upfront, { loading: upfrontSuspensionLoading }] =
    useMutation(UPFRONT_SUSPENSION);
  const [upfrontMedical, { loading: upfrontMedicalSuspensionLoading }] =
    useMutation(UPFRONT_SUSPENSION_MEDICAL);
  const [suspendMembership, { loading: standardSuspensionLoading }] =
    useMutation(SUSPEND_MEMBERSHIP);
  const [suspendMembershipMedical, { loading: medicalSuspensionLoading }] =
    useMutation(SUSPEND_MEMBERSHIP_MEDICAL);
  const columns = [
    {
      accessorKey: "suspensionStartDateTime",
      header: "SUSPENSION START DATE",
      cell: (info) =>
        format(
          utcToZonedTime(
            info.getValue(),
            member?.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney"
          ),
          "dd/MM/yy"
        ),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionStartDate",
    },
    {
      accessorKey: "suspensionEndDateTime",
      header: "SUSPENSION END DATE",
      cell: (info) =>
        format(
          utcToZonedTime(
            info.getValue(),
            member?.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney"
          ),
          "dd/MM/yy"
        ),

      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionEndDateTime",
    },
    {
      accessorKey: "suspensionType",
      header: "TYPE",
      cell: (info) => info.getValue() ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionType",
    },
    {
      accessorKey: "suspensionStatus",
      header: "STATUS",
      cell: (info) =>
        info.getValue().toLowerCase() === "pending"
          ? "AWAITING VERIFICATION"
          : info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionStatus",
    },

    {
      id: "row-actions",
      header: "ACTIONS",
      cell: (props) => {
        const { isSuspensionActiveToday, isSuspensionCancelledAlready } =
          shouldBtnShow(props.row.original);

        const suspensionId = props.row.original.id;
        const { id, suspensionStatus } = props?.row?.original;
        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions({
                  // Comment this out to allow more than one row open at a time
                  // ...showActions,
                  [id]: true,
                });
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() => {
                      handleViewClick(suspensionId);
                    }}
                  >
                    <Button
                      name="view"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (
                        suspensionStatus !== "REJECTED" &&
                        !isSuspensionCancelledAlready
                      ) {
                        handleEditClick();
                        setSuspensionDetails({
                          suspensionStartDate:
                            props.row.original.suspensionStartDateTime,
                          suspensionEndDate:
                            props.row.original.suspensionEndDateTime,
                          suspensionType: props.row.original.suspensionType,
                          suspensionReason: props.row.original.suspensionReason,
                          suspensionId,
                          suspensionStatus: props.row.original.suspensionStatus,
                        });
                      }
                    }}
                  >
                    <Button
                      name="edit"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        suspensionStatus !== "REJECTED" &&
                        !isSuspensionCancelledAlready
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>
                  <li
                    onClick={() => {
                      if (
                        (!isSuspensionActiveToday ||
                          !isSuspensionCancelledAlready) &&
                        suspensionStatus !== "REJECTED"
                      ) {
                        setComponentName("delete-suspension");
                        setSuspensionDetails({
                          suspensionStartDate:
                            props.row.original.suspensionStartDate,
                          suspensionEndDate:
                            props.row.original.suspensionStartDate,
                          suspensionType: props.row.original.suspensionType,
                          suspensionReason: props.row.original.suspensionReason,
                          suspensionId,
                          suspensionStatus: props.row.original.suspensionStatus,
                        });
                      }
                    }}
                  >
                    <Button
                      name="delete"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        (!isSuspensionActiveToday ||
                          !isSuspensionCancelledAlready) &&
                        suspensionStatus !== "REJECTED"
                          ? "btn-transparent btn-small"
                          : "disabled"
                      } `}
                    />
                  </li>

                  <li
                    onClick={() => {
                      if (suspensionStatus !== "APPROVED") {
                        setComponentName("approve-confirm");
                        setSuspensionDetails({
                          id: id,
                          suspensionInfo: props?.row?.original,
                        });
                      }
                    }}
                  >
                    <Button
                      name="approve"
                      btntype="submit"
                      btnname="submit"
                      className={`btn ${
                        suspensionStatus === "APPROVED"
                          ? "disabled"
                          : "btn-transparent btn-small"
                      } `}
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const isAfterToday = (date) => isAfter(date, endOfToday());
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const { data: getmembercontract } = useQuery(
    GET_MEMBER_CONTRACT_BY_CONTRACTID,
    {
      onCompleted: (data) => {
        const { items } = data?.getMemberContract?.billings;
        setMemberContractDetails(data?.getMemberContract);
        setPaymentFrequencyType(
          data?.getMemberContract?.paymentFrequency?.type
        );
        const unprocessedNextDebits = items.filter(
          (cur) => !cur.isProcessed && isAfterToday(new Date(cur?.debitDate))
        );
        if (unprocessedNextDebits.length <= 0) return;

        const sortedDebits = sortAsc(unprocessedNextDebits, "debitDate");

        const { debitDate: upcomingDebitDate } = sortedDebits?.[0];
        setNextDebitDate(
          format(addDays(new Date(upcomingDebitDate), 1), "yyyy-MM-dd")
        );
      },
      variables: {
        id: contractId,
      },
    }
  );
  // const [memberContracts, setMemberCon/]

  const {
    data: memberContract,
    loading: suspensionloading,
    error: suspensionerror,
    refetch,
  } = useQuery(GET_MEMBER_CONTRACT_BY_CONTRACTID, {
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      const { getMemberContract } = data;

      if (!getMemberContract) {
        return;
      }
      const { suspensions } = data?.getMemberContract;
      if (suspensions) {
        const { items } = suspensions;

        const filteredTable = items.filter((cur) => !cur.cancelledDateTime);
        setTableData(filteredTable);
      }
    },
    variables: { id: contractId },
  });

  const handleClick = (id) => {
    if (!member?.getMember?.outstandingBalance) {
      if (nextDebitDate || !memberContractDetails.recurring) {
        navigate(`/dashboard/member/addsuspension/${contractId}`);
      } else {
        setModalOpen(true);
      }
    } else {
      setComponentName("outstanding-modal");
    }
  };

  const handleViewClick = (id) => {
    navigate(`/dashboard/member/viewsuspension/${id}`);
  };

  const handleEditClick = () => {
    if (!member?.getMember?.outstandingBalance) {
      setComponentName("update-suspension");
    } else {
      setComponentName("outstanding-modal");
    }
  };

  const modalClose = () => {
    setComponentName("");
    createLog(
      `Click CANCEL button for edit suspension`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const shouldBtnShow = (data) => {
    let nextSuspension;
    let suspensionEndDate;
    let upcomingSuspensions = [];
    let isSuspensionActiveToday = false;
    let isSuspensionCancelledAlready = false;

    const hasSuspension = isAfter(
      new Date(data.suspensionStartDateTime),
      new Date()
    );

    if (hasSuspension) {
      const isAfterOrSameAsStartDate =
        isSameDay(new Date(), new Date(data.suspensionEndDateTime)) ||
        isAfter(new Date(), new Date(data.suspensionEndDateTime));
      const isBeforeOrSameAsEndDate =
        isSameDay(new Date(), new Date(data.suspensionStartDateTime)) ||
        isBefore(new Date(data.suspensionStartDateTime), new Date());
      isSuspensionActiveToday =
        isAfterOrSameAsStartDate && isBeforeOrSameAsEndDate;
    }

    if (data.cancelledDateTime) {
      isSuspensionCancelledAlready = true;
    }
    return {
      hasSuspension,
      hasUpcomingSuspensions: upcomingSuspensions?.length > 0,
      nextSuspension,
      suspensionEndDate,
      isSuspensionCancelledAlready,
      isSuspensionActiveToday,
    };
  };

  const handleEndDate = (v) => {
    const isMedical = suspensionDetails.suspensionType === "MEDICAL";
    if (!isMedical)
      upfront({
        variables: {
          suspensionId: suspensionDetails.suspensionId,
          operation: "UPFRONT_EDIT_SUSPENSION",
          suspensionEndDate: v,
        },
      }).then((res) => {
        // setUpFrontPrice(res.data.suspendMembership.data.amount.toFixed(2));
        setUpFrontPrice(0);
      });
    else if (suspensionDetails.suspensionStatus !== "PENDING")
      upfrontMedical({
        variables: {
          suspensionId: suspensionDetails.suspensionId,
          operation: "UPFRONT_EDIT_SUSPENSION",
          suspensionEndDate: v,
        },
      }).then((res) => {
        // setUpFrontPrice(
        //   res.data.suspendMedicalMembership.data.amount.toFixed(2)
        // );
        setUpFrontPrice(0);
      });
  };

  const handleUpfrontCancel = () => {
    const isMedical = suspensionDetails.suspensionType === "MEDICAL";
    if (!isMedical)
      upfront({
        variables: {
          suspensionId: suspensionDetails.suspensionId,
          operation: "UPFRONT_CANCEL_SUSPENSION",
        },
      }).then((res) => {
        // setUpFrontPrice(res.data.suspendMembership.data.amount.toFixed(2));
        setUpFrontPrice(0);
      });
    else if (suspensionDetails.suspensionStatus !== "PENDING")
      upfrontMedical({
        variables: {
          suspensionId: suspensionDetails.suspensionId,
          operation: "UPFRONT_CANCEL_SUSPENSION",
        },
      }).then((res) => {
        // setUpFrontPrice(
        //   res.data.suspendMedicalMembership.data.amount.toFixed(2)
        // );
        setUpFrontPrice(0);
      });
  };

  const handleSubmit = (values, OnSubmitProps) => {
    const isMedical = suspensionDetails.suspensionType === "MEDICAL";
    const { suspensionEndDate, suspensionReason, suspensionId } = values;
    const oldValue = {
      suspensionId: suspensionId,
      suspensionReason: suspensionDetails.suspensionReason,
      suspensionEndDate: suspensionDetails.suspensionEndDate
        .split("T")[0]
        .split("-")
        .reverse()
        .join("/"),
    };

    const newValue = {
      suspensionId: suspensionId,
      suspensionReason: suspensionReason,
      suspensionEndDate: suspensionEndDate.split("-").reverse().join("/"),
    };
    const auditpayload = { oldValue, newValue };
    setAuditValues(auditpayload);
    // UPFRONT_SUSPENSION
    const graphQlPayload = {
      variables: {
        operation: "EDIT_SUSPENSION",
        suspensionId,
        suspensionReason,
        suspensionEndDate,
      },
      onCompleted: () => {
        onCompleteEdit(auditpayload);
      },
      onError: (error) => {
        toast.error(`${error}`);
        OnSubmitProps.setSubmitting(false);
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: member?.getMember?.memberId,
          },
        },
        {
          query: LIST_MEMBER_CONTRACT,
          variables: {
            id: contractId,
          },
        },
      ],
    };
    if (upfrontPrice > 0) {
      if (isMedical) setPaymentOperation("MCU");
      else setPaymentOperation("SSU");
      const suspensionArguments = {
        suspensionArguments: {
          operation: "EDIT_SUSPENSION",
          suspensionId,
          suspensionReason,
          suspensionEndDate,
        },
      };
      setPayLoadArgument(suspensionArguments);
      setPaymentModal(true);
    } else if (isMedical) {
      suspendMembershipMedical(graphQlPayload);
    } else suspendMembership(graphQlPayload);
  };

  const onCompleteEdit = (auditValues) => {
    const isMedical = suspensionDetails.suspensionType === "MEDICAL";
    createAuditLog(
      auditValues.newValue,
      auditValues.oldValue,
      member?.getMember?.memberId,
      "UPDATE",
      isMedical ? "suspendMedicalMembership" : "suspendMembership"
    );
    toast.success("Update successfully");
    refetch();
    modalClose();
    setPaymentModal(false);
  };

  const handleDeleteClick = () => {
    const graphqlPayload = {
      variables: {
        operation: "CANCEL_SUSPENSION",
        suspensionId: suspensionDetails?.suspensionId,
      },
      onCompleted: () => {
        onCompleteCancel();
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
      // refetchQueries: ,
    };
    const isMedical = suspensionDetails.suspensionType === "MEDICAL";
    if (upfrontPrice > 0) {
      if (isMedical) setPaymentOperation("MCU");
      else setPaymentOperation("SSU");
      const suspensionArguments = {
        suspensionArguments: {
          operation: "CANCEL_SUSPENSION",
          suspensionId: suspensionDetails?.suspensionId,
        },
      };
      setPayLoadArgument(suspensionArguments);
      setPaymentModal(true);
    } else {
      if (isMedical) suspendMembershipMedical(graphqlPayload);
      else suspendMembership(graphqlPayload);
    }
  };

  const onCompleteCancel = () => {
    const isMedical = suspensionDetails.suspensionType === "MEDICAL";
    createAuditLog(
      {
        operation: "CANCEL_SUSPENSION",
        suspensionId: suspensionDetails?.suspensionId,
      },
      { suspensionId: suspensionDetails?.suspensionId },
      member?.getMember?.memberId,
      "DELETE",
      isMedical ? "suspendMedicalMembership" : "suspendMembership"
    );
    toast.success("Update successfully");
    refetch();
    modalClose();
    setPaymentModal(false);
  };
  const handleApproveClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
    };
    try {
      await medicalSuspensionOperation({
        variables: {
          operation: "REVIEW_SUSPENSION",
          suspensionId: values.id,
          suspensionStatus: "APPROVED",
        },
        onCompleted: () => {
          createAuditLog(
            {
              suspensionId: values.id,
              operation: "REVIEW_SUSPENSION",
              suspensionStatus: "APPROVED",
            },
            suspensionDetails.suspensionInfo.memberId,
            "UPDATE",
            "suspendMedicalMembership"
          );
          refetch();
          toast.success("Suspension approve Successfully ");
          modalClose();
        },
        onError: (error) => {
          toast.error(`${error}`);
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  useEffect(() => {
    createLog(
      `Visited suspension landing page for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (componentName === "") {
      setUpFrontPrice(0);
    }
    if (componentName === "delete-suspension") {
      handleUpfrontCancel();
    }
  }, [componentName]);

  return (
    <>
      <div className="suspension">
        <Header pageTitle="Suspension" />
        <p className="mt-32 fs-35">{`${member.getMember?.givenName} ${member.getMember?.surname}`}</p>
        <div className="suspension-container mt-24">
          <div>
            <div className="row2 mt-24 mb-12">
              <div className="right-btn">
                <Button
                  name="add Suspension"
                  btntype="submit"
                  btnname="submit"
                  // className="btn-primary fs-12"
                  className={`btn-primary fs-12 ${
                    getmembercontract?.getMemberContract?.expiryDate &&
                    "btn-disabled"
                  }`}
                  disabled={getmembercontract?.getMemberContract?.expiryDate}
                  onClick={handleClick}
                />
              </div>
            </div>
          </div>
        </div>
        {componentName && (
          <Modal>
            {paymentModal ? (
              <MakePaymentModal
                amount={upfrontPrice}
                setShowModalPayment={setPaymentModal}
                operation={paymentOperation}
                locationId={getmembercontract.getMemberContract.locationId}
                modalClose={() => setPaymentModal(false)}
                paymentType={
                  paymentOperation === "SSC"
                    ? "edit membership suspension"
                    : "cancel membership suspension "
                }
                payloadData={payloadArgument}
                onApiError={(e) => toast.error(e)}
                onApiCompleted={() => {
                  setPaymentModal(false);
                  if (componentName === "delete-suspension") onCompleteCancel();
                  if (componentName === "update-suspension")
                    onCompleteEdit(auditValues);
                }}
                apiRefetchQueries={[
                  {
                    query: GET_MEMBER_BY_ID,
                    variables: {
                      memberId: member?.getMember?.memberId,
                    },
                  },
                  {
                    query: LIST_MEMBER_CONTRACT,
                    variables: {
                      id: contractId,
                    },
                  },
                  {
                    query: LIST_TRANSACTION_BY_MEMBERID,
                    variables: { memberId: member?.getMember?.memberId },
                  },
                ]}
              />
            ) : (
              <>
                {componentName === "update-suspension" && (
                  <EditSuspensionModal
                    cancelFn={modalClose}
                    contract={memberContract}
                    suspensionDetails={{
                      ...suspensionDetails,
                      upfrontAmount: upfrontPrice,
                    }}
                    handleSubmit={handleSubmit}
                    handleEndDateChange={handleEndDate}
                    disabled={
                      upfrontMedicalSuspensionLoading ||
                      upfrontSuspensionLoading
                    }
                  />
                )}
                {componentName === "outstanding-modal" && (
                  <StatusModal
                    modalHeaderTxt={"Outstanding Balance"}
                    modalParaText={`You have $${member?.getMember?.outstandingBalance} outstanding balance`}
                    modalClose={() => setComponentName("")}
                    showOneBtn={true}
                  />
                )}
                {componentName === "delete-suspension" && (
                  <StatusModal
                    loading={
                      standardSuspensionLoading || medicalSuspensionLoading
                    }
                    modalHeaderTxt={"Delete Suspension"}
                    modalParaText={
                      <>
                        Are you sure you want to delete this suspension?
                        <br />
                        {upfrontPrice > 0 ? (
                          <>Upfront cost: ${upfrontPrice}</>
                        ) : (
                          <>
                            {upfrontMedicalSuspensionLoading ||
                            upfrontSuspensionLoading ? (
                              <BeatLoader color="white" size="8" />
                            ) : (
                              ""
                            )}
                          </>
                        )}
                      </>
                    }
                    handleStatusClick={handleDeleteClick}
                    modalClose={modalClose}
                    disabled={
                      upfrontMedicalSuspensionLoading ||
                      upfrontSuspensionLoading
                    }
                  />
                )}
              </>
            )}
            {componentName === "approve-confirm" && (
              <StatusModal
                loading={suspensionOperationLoading}
                curRow={suspensionDetails}
                modalHeaderTxt={"APPROVE MEDICAL SUSPENSION"}
                modalParaText={
                  "ARE YOU SURE YOU WANT TO APPROVE THIS MEMBERSHIP SUSPENSION?"
                }
                handleStatusClick={handleApproveClick}
                modalClose={modalClose}
              />
            )}
          </Modal>
        )}
        <div className="suspension-table">
          {!suspensionloading && !suspensionerror && tableData && (
            <Table
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
            />
          )}
        </div>
      </div>
      {modalOpen && (
        <Modal>
          <div className="member-changes-modal">
            <p className="modal-title fs-12">
              For weekly payment frequency type, no next debit date, you can not
              suspend the membership!
            </p>
            <button
              className="btn btn-small btn-confirm"
              onClick={() => setModalOpen(false)}
            >
              CLOSE
            </button>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Suspension;
