import React, { useState, useEffect, useContext } from "react";
import { toast } from "react-toastify";
import { Auth, Amplify } from "aws-amplify";
import awsconfig from "../../aws-exports";
import AuthContext from "./auth";
import { GET_LOGGEDUSER_BY_MEMBERID } from "../../gqloperations/queries";
import { useApolloClient } from "@apollo/client";
Amplify.configure(awsconfig);
Auth.configure({ storage: sessionStorage });

const AuthProvider = ({ children }) => {
  const client = useApolloClient();
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [token, setToken] = useState("");
  const [user, setUser] = useState(null);
  const [memberId, setMemberId] = useState(null);
  const [loggedUser, setLoggedUser] = useState(null);
  const [locationAccess, setLocationAccess] = useState(null);
  const [isHomeLocationAccess, setIsHomeLocationAccess] = useState(false);
  const [permissions, setPermissions] = useState([]);
  const [session, setSession] = useState();
  const [otpAttempt, setOtpAttempt] = useState(0);
  const [loginUserLoading, setLoginUserLoading] = useState(true);

  const checkRoles = (roleData) => {
    const filteredRole =
      loggedUser?.getMember?.roleDetails?.items[0]?.haspermissions.items.map(
        (permission) => permission.permissionsList.items[0].permissionName
      );

    setPermissions([...filteredRole, "CanViewDashboard"]);
  };
  const checkToken = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      const accessToken = (await Auth.currentSession())
        .getIdToken()
        .getJwtToken();
      setToken(accessToken);

      setUser(user);
      setMemberId(user && user.attributes["custom:memberId"]);
      return;
    } catch (e) {
      setUser(null);
      setLoginUserLoading(false);
    }
  };

  const getLoggedUser = async () => {
    try {
      const result = await client.query({
        query: GET_LOGGEDUSER_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: { memberId },
      });
      setLoggedUser(result.data);
      setLoginUserLoading(false);
    } catch (error) {
      toast.error(`${error}`);
      setLoginUserLoading(false);
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  useEffect(() => {
    if (user) {
      getLoggedUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const getGroupByStaffId = async () => {
    const groups = loggedUser?.getMember?.groups?.items;
    const result = [];
    const stateResult = [];
    groups.forEach((group) => {
      const locations = group?.groupDetails?.groupLocation?.items.filter(
        (item) =>
          item.locationDetails !== null &&
          item.locationDetails.status === "ACTIVE"
      );

      locations.forEach((location) => {
        const brandId = location?.locationDetails?.brandDetails?.id;
        const brandName = location?.locationDetails?.brandDetails?.name;
        const locationId = location?.locationDetails?.id;
        const locationName = location?.locationDetails?.name;
        const state = location?.locationDetails?.state;

        let brandArray = result.find((obj) => obj.brandName === brandName);
        if (!brandArray) {
          brandArray = {
            brandId: brandId,
            brandName: brandName,
            locations: [],
          };
          result.push(brandArray);
        }

        if (
          !brandArray.locations.some((loc) => loc.locationId === locationId)
        ) {
          brandArray.locations.push({
            locationId: locationId,
            locationName: locationName,
          });
        }

        let stateArray = stateResult.find(
          (locState) => locState.state === state
        );
        if (!stateArray) {
          stateArray = {
            state: state,
            locations: [],
          };
          stateResult.push(stateArray);
        }
        if (
          !stateArray.locations.some((loc) => loc.locationId === locationId)
        ) {
          stateArray.locations.push({
            locationId: locationId,
            locationName: locationName,
          });
        }
      });
    });

    const locationAll = result.reduce((acc, brand) => {
      brand.locations.forEach((location) => {
        acc.push(location.locationId);
      });
      return acc;
    }, []);

    const finalResult = {
      brands: result,
      locationAll: locationAll,
      state: stateResult,
    };

    const homeGroup = finalResult?.locationAll.includes(
      loggedUser?.getMember?.homeLocationId
    );
    if (homeGroup) {
      setIsHomeLocationAccess(true);
    }
    setLocationAccess(finalResult);
  };

  useEffect(() => {
    if (loggedUser) {
      getGroupByStaffId();
      checkRoles();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loggedUser]);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      setUser(null);
      setLoggedUser(null);
      setMemberId("");
      setLoginUserLoading(false);
      toast.success(`Logout Successful`);
    } catch (err) {
      toast.error("Error singing out", err);
    }
  };

  const handleSignUp = async (
    enteredEmail,
    enteredPassword,
    inviteToken,
    brandId,
    memberId,
    mobileNumber
  ) => {
    try {
      await Auth.signUp({
        username: enteredEmail,
        password: enteredPassword,
        attributes: {
          email: enteredEmail,
          "custom:inviteToken": inviteToken,
          "custom:brandId": brandId,
          "custom:memberId": memberId,
          "custom:mobileNumber": mobileNumber,
        },
      });
      return {
        error: "",
        signup: true,
      };
    } catch (err) {
      throw new Error(err);
      // return {
      //   error: err,
      //   signup: false,
      // };
    }
  };

  const handleConfirmPassword = async (oldPassword, newPassword) => {
    setLoading(true);
    setError(false);
    await Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, oldPassword, newPassword);
      })
      .then((data) => {
        if (data === "SUCCESS") {
          return (
            toast.success("Password Changed Successfully"), setLoading(false)
          );
        }
      })
      .catch((err) => {
        if (err.message) {
          setLoading(false);
          return toast.error(`Error: ${err.message}`);
        }
      });
  };

  const handleForgotPassword = async (
    email,
    OnSubmitProps,
    setStage,
    setForgotEmail
  ) => {
    setLoading(true);
    setError(false);
    // Send confirmation code to user's email
    Auth.forgotPassword(email)
      .then(() => {
        setLoading(false);
        setStage(2);
        setForgotEmail(email);
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("We have send you a code. Please check your Email");
      })
      .catch((err) => {
        toast.error(`Error: ${err.message}`);
        setLoading(false);
      });
  };

  const handleForgotPasswordSubmit = async (username, code, new_password) => {
    setLoading(true);
    setError(false);
    try {
      setLoading(false);
      const forgotPassword = await Auth.forgotPasswordSubmit(
        username,
        code,
        new_password
      );
      if (forgotPassword) {
        toast.success("Password Updated Successfully");
      }
    } catch (err) {
      setLoading(false);
      setError(true);
      toast.error(`Error: ${err.message}`);
    }
  };
  const handleSignIn = async (email, OnSubmitProps) => {
    setLoggedUser(null);
    setLoginUserLoading(false);
    setMemberId("");
    try {
      const session = await Auth.signIn(email);
      setSession(session);
      OnSubmitProps.setValues({ ...OnSubmitProps.values, step: 1 });
    } catch (err) {
      toast.error(err.message);
      // setError(
      //   JSON.stringify(err).includes("InvalidParameterException")
      //     ? toast.error("Incorrect username or password")
      //     : toast.error(`Error: ${err}`)
      // );
    }
    OnSubmitProps.setSubmitting(false);
  };
  const handleResendOtp = async (type) => {
    try {
      // const session = await Auth.signIn(email);
      await Auth.sendCustomChallengeAnswer(session, "0", {
        loginType: type,
      });
    } catch (e) {
      throw new Error(e);
    }
  };
  const verifyOtp = async (otp, errCallback, onWrongOtp) => {
    try {
      await Auth.sendCustomChallengeAnswer(session, otp);
      const user = await Auth.currentAuthenticatedUser();
      setMemberId(user && user.attributes["custom:memberId"]);
      setUser(user);
    } catch (err) {
      if (otpAttempt === 2) {
        setSession(null);
        setOtpAttempt(0);
        toast.error(
          "You have entered invalid OTP many time please try again with your email "
        );
        typeof errCallback === "function" && errCallback();
      } else {
        toast.error("INVALID OTP!");
        typeof onWrongOtp === "function" && onWrongOtp();
        setOtpAttempt(otpAttempt + 1);
      }
      // toast.error("WRONG OTP!");
    }
  };
  return (
    <AuthContext.Provider
      value={{
        handleSignIn,
        handleSignOut,
        handleConfirmPassword,
        handleForgotPassword,
        handleForgotPasswordSubmit,
        error,
        setError,
        loading,
        loginUserLoading,
        token,
        setToken,
        handleSignUp,
        user,
        loggedUser,
        permissions,
        getLoggedUser,
        locationAccess,
        isHomeLocationAccess,
        memberId,
        verifyOtp,
        // accessToken,
        handleResendOtp,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;

export const useAuthContext = () => {
  return useContext(AuthContext);
};
