import visa from "../../assets/images/visa.png";
import db from "../../assets/images/directDebit.png";
import "./paymentInformation.scss";
import * as yup from "yup";
import Input from "../formUiElements/input/Input";
import { Form, Formik } from "formik";
import { AiOutlineEdit } from "react-icons/ai";
import { useEffect, useState } from "react";
import Button from "../formUiElements/Button";
import { toast } from "react-toastify";
import { UPDATE_PAYMENT_INFO } from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { useMemberContext } from "../../contexts/MemberContext";
import Modal from "../modal/Modal";
import InputWithoutFormik from "../formUiElements/input/InputWithoutFormik";
import { format } from "date-fns";
import { BeatLoader } from "react-spinners";
const bankvalidationSchema = yup.object().shape({
  accountName: yup.string().required("holder name is required").nullable(),
  accountNumber: yup
    .string()
    .min(5, "minimum 5 digits")
    .max(9, "maximum 9 digits")
    .nullable(),
  bsb: yup.string().min(6).max(6, "Only 6 digits allowed").nullable(),
});
const PaymentInformation = ({ paymentDetails }) => {
  const { sidebarLocationId } = useMemberContext();
  const [updatePaymentInfo, { loading }] = useMutation(UPDATE_PAYMENT_INFO);
  const [isEdit, setIsEdit] = useState(false);
  const [paymentInformation, setPaymentInformation] = useState("");
  const {
    getContracts,
    getMemberById,
    memberId,
    member: userInfo,
  } = useMemberContext();

  const [modalVivaPayIsOpen, setIsOpenVivaPay] = useState(false);
  const [cardRequestId, setCardRequestId] = useState("");
  const [index, setIndex] = useState(0);
  const openVivaPayModal = () => {
    // setModalAddPayment(true);
    openModalVivaPay();
  };
  const [modalAddPaymentInfo, setModalAddPayment] = useState(false);
  const iframeSrc = `${process.env.REACT_APP_VIVAPAY_IFRAME}/${cardRequestId}`;

  const [initialData, setInit] = useState({
    accountName: "",
    accountNumber: "",
    bsb: "",
    cardNumber: "",
    cardExpiryDate: "",
    cardCvv: "",
    cardHolderName: "",
  });

  const loadAllCard = async (update) => {
    let data = {};
    let sortedCard = {};
    const userPayment = paymentDetails.filter(
      (i) =>
        i.primary &&
        i?.vivaPaymentInformationId &&
        i?.vivaPaymentInformationId !== ""
    );
    if (userPayment?.length) {
      if (userPayment[0].paymentType === "CREDIT_CARD") {
        !update && setIndex(1);
        sortedCard = paymentDetails
          .filter(
            (i) =>
              i.paymentType !== "CREDIT_CARD" &&
              i?.vivaPaymentInformationId &&
              i?.vivaPaymentInformationId !== ""
          )
          .sort(
            (a, b) =>
              new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
          )
          .reverse()[0];
        sortedCard = {
          bsb: sortedCard?.bsb ?? "",
          accountName: sortedCard?.accountName ?? "",
          accountNumber: sortedCard?.accountNumber ?? "",
        };
      } else {
        !update && setIndex(0);
        // setPrimary(0);
        sortedCard = paymentDetails
          .filter(
            (i) =>
              i.paymentType === "CREDIT_CARD" &&
              i?.vivaPaymentInformationId &&
              i?.vivaPaymentInformationId !== ""
          )
          .sort(
            (a, b) =>
              new Date(a.createdAt).valueOf() - new Date(b.createdAt).valueOf()
          )
          .reverse()[0];

        sortedCard = {
          cardNumber: sortedCard?.cardNumber ?? "",
          cardExpiryDate: sortedCard?.cardExpiryDate ?? "",
          cardCvv: sortedCard?.cardCvv ?? "",
          cardHolderName: sortedCard?.cardHolderName ?? "",
        };
      }
    }
    data = { ...userPayment[0], ...sortedCard };
    setInit(data);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("member-payment-Form").reset();
    setIsEdit(false);
  };
  const saveCard = async (values, OnSubmitProps) => {
    try {
      let variables = {
        action: "ADD_CARD",
        memberId: memberId,
        bankAccount: {},
      };
      variables.action = "ADD_BANK";
      variables.bankAccount = {
        accountName: values?.accountName,
        accountNumber: values?.accountNumber,
        bsb: values?.bsb,
      };
      if (
        [
          "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
          "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
        ].includes(userInfo.getMember.homeLocationId)
      ) {
        variables.bankAccount.bsb = "000000";
      }
      await updatePaymentInfo({
        variables: {
          ...variables,
        },
        onCompleted: async (response) => {
          if (response.updatePaymentInformation.body) {
            toast.success("Update successfully!");
            setIsEdit(false);
            await getMemberById();
            await getContracts();
            await loadAllCard(true);
          } else {
            toast.error(
              response.updatePaymentInformation.message ??
                "Error on update payment method"
            );
          }
        },
      });
    } catch (e) {
      toast.error(e.message);
    }
  };

  async function closeModalVivaPay() {
    setIsOpenVivaPay(false);
    try {
      let variables = {
        action: "ADD_CARD",
        creditCard: { cardRequestId: cardRequestId },
        memberId: memberId,
        bankAccount: null,
      };
      const data = await updatePaymentInfo({
        variables: {
          ...variables,
        },
      });
      setPaymentInformation(data.data?.updatePaymentInformation.body);
      if (
        data.data?.updatePaymentInformation.body?.accountNumber ||
        data.data?.updatePaymentInformation.body?.cardNumber
      ) {
        toast.success("Save card successfully");
        setCardRequestId("");
        await getMemberById();
        loadAllCard(true);
      } else {
        toast.error("Save card fail");
        setCardRequestId("");
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setModalAddPayment(false);
      setIsEdit("");
    }
  }

  async function openModalVivaPay() {
    setIsOpenVivaPay(true);
    setModalAddPayment(false);
    try {
      const data = await updatePaymentInfo({
        variables: {
          memberId: memberId,
          action: "CREATE_CARD_TOKEN",
        },
      });
      if (data?.data.updatePaymentInformation.cardRequestId) {
        setCardRequestId(data.data?.updatePaymentInformation.cardRequestId);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
      setIsEdit("");
    }
  }

  useEffect(() => {
    loadAllCard(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    loadAllCard(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentDetails]);
  const hideAccountNumber = (accountNumber) => {
    if (!accountNumber || accountNumber.length < 4) return "";
    const visibleDigits = accountNumber.slice(-4);
    const hiddenDigits = "*".repeat(accountNumber?.length - 4);
    return hiddenDigits + visibleDigits;
  };
  return (
    <div className="paymentInformation-container">
      {modalVivaPayIsOpen && (
        <Modal>
          <div className="status-modal make-a-payment-modal pd-32">
            <iframe
              id="pay-with-vivapay"
              title="pay with vivapay"
              src={iframeSrc}
              className="iframe-vivapay"
              loading="lazy"
            />
            <Button
              name="Continue"
              btntype="button"
              btnname="cancel"
              className="btn btn-confirm btn-small"
              click={() => closeModalVivaPay()}
            />
          </div>
        </Modal>
      )}
      {modalAddPaymentInfo && (
        <Modal>
          <div className="status-modal make-a-payment-modal pd-32">
            <div className="make-payment-title ">
              <h3 className="fs-35 pb-24 uppercase">Update Payment</h3>
            </div>{" "}
            <div>
              <div className="member-details-modal-container">
                <h3>Member Details</h3>

                <div className="member-details-inputs pb-24">
                  <InputWithoutFormik
                    type="text"
                    label="member No:"
                    name="memberNo"
                    autoComplete="off"
                    id="memberNo"
                    className="fs-12"
                    value={userInfo?.getMember?.aliasMemberId || ""}
                    readOnly
                  />
                  <InputWithoutFormik
                    type="text"
                    label="member Name:"
                    name="memberName"
                    autoComplete="off"
                    id="memberName"
                    className="fs-12"
                    value={userInfo?.getMember?.givenName || ""}
                    readOnly
                  />
                  <InputWithoutFormik
                    type="text"
                    label="Date of Birth:"
                    name="dob"
                    autoComplete="off"
                    id="dob"
                    className="fs-12"
                    value={
                      format(
                        new Date(userInfo?.getMember?.dob),
                        "dd/MM/yyyy"
                      ) || ""
                    }
                    readOnly
                  />
                </div>
              </div>
            </div>
            <p>Add Payments Information</p>
            <div
              className=" payment-options-container pb-18 pt-18 fs-12"
              onClick={() => {
                openModalVivaPay();
              }}
            >
              <Button
                name={"Update payment"}
                btntype="submit"
                btnname="submit"
                className="btn btn-primary btn-lg fs-12 "
              />
            </div>
            {paymentInformation?.cardNumber && (
              <div className="payment-details-modal-container">
                <InputWithoutFormik
                  value={
                    paymentInformation?.cardType
                      ? paymentInformation?.cardType
                      : paymentInformation?.accountName
                  }
                  label={"Type:"}
                  readOnly
                />

                <InputWithoutFormik
                  value={paymentInformation?.cardNumber}
                  label={"Card:"}
                  readOnly
                />

                <InputWithoutFormik
                  value={
                    paymentInformation?.cardExpiryDate
                      ? paymentInformation?.cardExpiryDate
                      : paymentInformation?.accountNumber
                  }
                  label={"Expiry:"}
                  readOnly
                />
              </div>
            )}
            <div className="make-payment-btn-container ">
              <Button
                name="CANCEL"
                btntype="button"
                btnname="cancel"
                click={() => {
                  setModalAddPayment(false);
                }}
                className="btn btn-deny btn-lg fs-12"
              />
            </div>
          </div>
        </Modal>
      )}
      <div className="tabs">
        <div className="tabs-list">
          <div
            className={
              index !== 0
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setIndex(0);
            }}
          >
            {/* {primary === 0 && <span className="primarylabel">primary</span>} */}
            {![
              "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
              "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
            ].includes(userInfo.getMember.homeLocationId)
              ? "BANK ACCOUNT"
              : "BANK CODE"}
          </div>
          <div
            className={
              index !== 1
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setIndex(1);
            }}
          >
            {/* {primary === 1 && <span className="primarylabel">primary</span>} */}
            CREDIT CARD
          </div>
        </div>
        <>
          <div className="tabContent" hidden={index !== 1}>
            <Formik
              enableReinitialize
              initialValues={{
                ...initialData,
                cardType: "CREDIT CARD",
                type2: "DIRECT DEBIT",
              }}
              onSubmit={(values, OnSubmitProps) => {
                saveCard(values, OnSubmitProps);
              }}
            >
              {(formik) => (
                <Form id="">
                  <div className="member-payment-title">
                    <h3 className="fs-14 uppercase">Payment Method</h3>

                    {!loading && (
                      <>
                        {isEdit ? (
                          <div className="action-container">
                            <div className="sub-container save">
                              <Button
                                iconname="BiSave"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12 "
                              />
                            </div>
                            <div className="sub-container cancel">
                              <Button
                                iconname="AiOutlineCloseCircle"
                                btntype="reset"
                                btnname="reset"
                                className="btn  btn-edit cancel btn-lg fs-12"
                                click={() => handleCancel()}
                              />
                            </div>
                          </div>
                        ) : (
                          <span
                            className="icon-container edit"
                            onClick={() => openVivaPayModal()}
                          >
                            <AiOutlineEdit />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {loading ? (
                    <BeatLoader className="loading" color="white" size={10} />
                  ) : (
                    <div className="member-payment-display">
                      <div className="first-row credit">
                        <span className="pay-type">
                          <img src={visa} alt="visa icon" />
                          <div>
                            <Input
                              type="text"
                              label="Type"
                              name="cardType"
                              id="cardType"
                              autoComplete="off"
                              disabled={true}
                            />
                          </div>
                        </span>
                        <div className="names">
                          <Input
                            type="text"
                            label="Name On Card"
                            name="cardHolderName"
                            id="cardHolderName"
                            autoComplete="off"
                            disabled={true}
                          />
                        </div>
                        <div className="number">
                          <Input
                            type="text"
                            label="Card Number"
                            name="cardNumber"
                            id="cardNumber"
                            autoComplete="off"
                            disabled={true}
                          />
                        </div>
                        <div className="expiry">
                          <Input
                            type="text"
                            label="Expiry Date"
                            name="cardExpiryDate"
                            id="cardExpiryDate"
                            autoComplete="off"
                            disabled={true}
                          />
                        </div>
                        {/* <div className="cvv">
                          {" "}
                          <Input
                            type="text"
                            label="CVV"
                            name="cardCvv"
                            id="cardCvv"
                            autoComplete="off"
                            disabled={true}
                            className={"blue"}
                          />
                        </div> */}
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
          <div className="tabContent" hidden={index !== 0}>
            <Formik
              enableReinitialize
              initialValues={{
                ...initialData,
                cardType: "CREDIT CARD",
                type2: "DIRECT DEBIT",
              }}
              onSubmit={(values, OnSubmitProps) =>
                !loading && saveCard(values, OnSubmitProps)
              }
              validationSchema={
                ![
                  "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
                  "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
                ].includes(userInfo.getMember.homeLocationId)
                  ? bankvalidationSchema
                  : null
              }
            >
              {(formik) => (
                <Form id="member-payment-Form">
                  <div className="member-payment-title">
                    {/* <form id='member-payment-form' ></form> */}
                    <h3 className="fs-14 uppercase pb-24">Payment Method</h3>

                    {!loading && (
                      <>
                        {isEdit ? (
                          <div className="action-container">
                            <div className="sub-container save">
                              {/* <IoIosSave /> */}
                              <Button
                                iconname="BiSave"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12 "
                              />
                            </div>
                            <div className="sub-container cancel">
                              <Button
                                iconname="AiOutlineCloseCircle"
                                btntype="reset"
                                btnname="reset"
                                className="btn  btn-edit cancel btn-lg fs-12"
                                click={() => handleCancel()}
                              />
                            </div>
                          </div>
                        ) : (
                          <span
                            className="icon-container edit"
                            onClick={() => setIsEdit(true)}
                          >
                            <AiOutlineEdit />
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  {loading ? (
                    <BeatLoader className="loading" color="white" size={10} />
                  ) : (
                    <div className="member-payment-display">
                      <div className="first-row debit">
                        <span className="pay-type">
                          <img src={db} alt="Direct Debit icon" />
                          {![
                            "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
                            "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
                          ].includes(userInfo.getMember.homeLocationId) ? (
                            <div className="pay-details">
                              <Input
                                type="text"
                                label="Type"
                                name="type2"
                                id="type2"
                                autoComplete="off"
                                disabled={!isEdit}
                              />
                            </div>
                          ) : (
                            <div className="pay-details">
                              <InputWithoutFormik
                                label="Type"
                                value={"Bank Code"}
                                disabled
                              />
                            </div>
                          )}
                        </span>
                        <div className="names">
                          <Input
                            type="text"
                            label={
                              ![
                                "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
                                "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
                              ].includes(userInfo.getMember.homeLocationId)
                                ? "Account holder name"
                                : "Account holder name"
                            }
                            name="accountName"
                            id="accountName"
                            autoComplete="off"
                            disabled={!isEdit}
                          />
                        </div>
                        <div className="number">
                          <Input
                            type="text"
                            label={
                              ![
                                "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
                                "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
                              ].includes(userInfo.getMember.homeLocationId)
                                ? "Account Number"
                                : "Bank Code"
                            }
                            name="accountNumber"
                            id="accountNumber"
                            value={
                              !isEdit
                                ? hideAccountNumber(formik.values.accountNumber)
                                : formik.values.accountNumber ?? ""
                            }
                            // value={formik.values.accountNumber}
                            onChange={(e) => {
                              formik.handleChange(e);
                              const inputValue = e.target.value.replace(
                                /\D/g,
                                ""
                              );
                              formik.setFieldValue("accountNumber", inputValue);
                            }}
                            autoComplete="off"
                            disabled={!isEdit}
                          />
                        </div>
                        {![
                          "bdd5ebe3-322e-542f-8b5d-b1ae3bc5aab0",
                          "7ef74bb7-7f9c-526c-8cca-9b828b4c3ebd",
                        ].includes(userInfo.getMember.homeLocationId) && (
                          <div className="expiry">
                            <Input
                              type="text"
                              label="BSB"
                              name="bsb"
                              id="bsb"
                              value={formik.values.bsb}
                              onChange={(e) => {
                                formik.handleChange(e);
                                const inputValue = e.target.value.replace(
                                  /\D/g,
                                  ""
                                );
                                formik.setFieldValue("bsb", inputValue);
                              }}
                              autoComplete="off"
                              disabled={!isEdit}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </>
      </div>
    </div>
  );
};
export default PaymentInformation;
