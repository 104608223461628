import "./addMembership.scss";
import "./viewMembershipType.scss";
import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import { format } from "date-fns";
import { BeatLoader } from "react-spinners";
import getDurationString from "../../utils/getDurationString";
import { GET_MEMBERSHIP_TYPE_BY_ID } from "../../gqloperations/queries";
import { Header } from "../../components";

const ViewMembershipType = () => {
  const { id } = useParams();
  const { loading, error, data } = useQuery(GET_MEMBERSHIP_TYPE_BY_ID, {
    variables: {
      id,
    },
  });

  if (loading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (error) {
    return (
      <div>
        <div>Problem in getting membership activation date</div>
      </div>
    );
  }
  const sortedData = data?.getMembership?.activationDates?.items
    .slice()
    .sort((a, b) => {
      return new Date(a.startDateTime) - new Date(b.startDateTime);
    });
  return (
    <>
      {!loading && !error && (
        <>
          <Header pageTitle="Membership Details" />
          <div className="membership-view-page">
            <div className="cards-container mt-32">
              <div className="detail-card first pd-12">
                <div className="row-data flex-wrap">
                  <div>
                    <h6 className="fs-12">Membership Name </h6>
                    <p className="fs-12">
                      {data?.getMembership?.membershipName}
                    </p>
                  </div>
                  <div>
                    <h6 className="fs-12">Cost Price</h6>
                    <p className="fs-12">${data?.getMembership?.costPrice}</p>
                  </div>
                  <div>
                    <h6 className="fs-12">Membership Active</h6>
                    <p className="fs-12">
                      {data?.getMembership?.isActive ? "YES" : "NO"}
                    </p>
                  </div>
                  <div className="website-live">
                    <h6 className="fs-12">Live On Website</h6>
                    <p className="fs-12">
                      {data.getMembership.isLiveOnWebsite ? "YES" : "NO"}
                    </p>
                  </div>
                  <div>
                    <h6 className="fs-12">Peak hours</h6>
                    <p className="fs-12">
                      {data.getMembership.isPeak ? "YES" : "NO"}
                    </p>
                  </div>
                </div>
                <div className="row-data">
                  <div className="website-live">
                    <h6 className="fs-12">Membership Type</h6>
                    <p className="fs-12">{data.getMembership.membershipType}</p>
                  </div>
                  <div className="website-live">
                    <h6 className="fs-12">Membership Level</h6>
                    <p className="fs-12">
                      {data.getMembership.membershipLevel}
                    </p>
                  </div>
                </div>
                <div className="desc-row">
                  <h3 className="fs-12">Description *</h3>
                  <textarea
                    className="reason-box fs-12"
                    id="description"
                    name="membershipInput.description"
                    value={data.getMembership.description}
                    disabled
                  ></textarea>
                </div>
              </div>
              <div className="detail-card sec pd-12">
                <h3 className="fs-14 uppercase">Payment Details</h3>
                <div className="details-grid">
                  <div className="freq">
                    <h6 className="fs-12">pay frequency</h6>
                    <p className="fs-12">
                      {data.getMembership.paymentFrequency
                        ? getDurationString(data.getMembership.paymentFrequency)
                        : ""}
                    </p>
                  </div>
                  <div className="delay">
                    <h6 className="fs-12">Payment Delay</h6>
                    <p className="fs-12">
                      {data.getMembership.paymentDelay
                        ? getDurationString(data.getMembership.paymentDelay)
                        : ""}
                    </p>
                  </div>
                  <div className="length">
                    <h6 className="fs-12">Contract Length</h6>
                    <p className="fs-12">
                      {data.getMembership.contractLength
                        ? getDurationString(data.getMembership.contractLength)
                        : ""}
                    </p>
                  </div>
                  {/* Need to add the proper data to this section below */}
                  <div className="notice-period">
                    <h6 className="fs-12">Notice Period</h6>
                    <p className="fs-12">
                      {data.getMembership.noticePeriod
                        ? getDurationString(data.getMembership.noticePeriod)
                        : ""}
                    </p>
                  </div>
                  <div className="fee-1">
                    <h6 className="fs-12">Joining Fee</h6>
                    <p className="fs-12">${data.getMembership.joiningFee}</p>
                  </div>
                  <div className="fee-2">
                    <h6 className="fs-12">Activation Fee</h6>
                    <p className="fs-12">${data.getMembership.joiningFee2}</p>
                  </div>
                  <div className="fee-3">
                    <h6 className="fs-12">Fob/Pass Fee</h6>
                    <p className="fs-12">${data.getMembership.joiningFee3}</p>
                  </div>
                  <div className="type">
                    <h6 className="fs-12">Payment Type</h6>
                    {data.getMembership.paymentType.items.find(
                      (item) => item.name === "DEBIT"
                    )?.isActive && <p className="fs-12">Direct Debit</p>}
                    {data.getMembership.paymentType.items.find(
                      (item) => item.name === "CREDIT"
                    )?.isActive && <p className="fs-12">Credit Card</p>}
                    {data.getMembership.paymentType.items.find(
                      (item) => item.name === "CASH"
                    )?.isActive && <p className="fs-12">Cash</p>}
                  </div>
                </div>
              </div>
              <div className="detail-card third pd-12">
                <h3 className="fs-14 uppercase">activation dates</h3>
                <table>
                  <thead>
                    <tr className="fs-12">
                      <th>Start Date</th>
                      <th>End Date</th>
                      <th>Dates Active</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sortedData.map((dateItem) => {
                      return (
                        <tr className="fs-12" key={dateItem.id}>
                          <td>
                            {format(
                              new Date(dateItem.startDateTime),
                              "dd/MM/yyyy"
                            )}
                          </td>
                          <td>
                            {!dateItem.endDateTime
                              ? "-/-/-"
                              : format(
                                  new Date(dateItem.endDateTime),
                                  "dd/MM/yyyy"
                                )}
                          </td>
                          <td>{dateItem.isActive ? "YES" : "NO"}</td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default ViewMembershipType;
