import { createContext, useEffect, useReducer, useState } from "react";
import { useContext } from "react";
import { sortDesc } from "../utils/sort";
import { isAfter, isSameDay } from "date-fns";
import { useLazyQuery, useMutation } from "@apollo/client";
import { API, graphqlOperation } from "aws-amplify";
import { CREATE_NOTIFICATION_SUBSCRIPTION } from "../gqloperations/subscriptions";
import { useMemberContext } from "./MemberContext";
import { useAuthContext } from "./AuthContext/AuthProvider";
import { toast } from "react-toastify";
import { NOTIFICATION_ACTION } from "../gqloperations/mutations";
import {
  GET_LOGGEDUSER_BY_MEMBERID,
  GET_NOTIFCATION_BY_ID,
} from "../gqloperations/queries";

export const NotificationContext = createContext();

export const NotificationReducer = (state, action) => {
  if (action.type === "SET_NOTIFICATION") {
    return sortDesc(action.payload, "createdAt");
  }
  if (action.type === "ADD_NOTIFICATION") {
    return sortDesc([...state, ...action.payload], "createdAt");
  }
  if (action.type === "REMOVE_NOTIFICATION") {
    return state.filter((item) => item.id !== action.payload);
  }
  if (action.type === "REMOVE_ALL_NOTIFICATION") {
    return [];
  }
};

export const NotificationContextProvider = ({ children }) => {
  const [notification, dispatch] = useReducer(NotificationReducer, []);
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [initialFilter, setInitialFilter] = useState([]);
  const { locationAccess, memberId } = useAuthContext();
  const [notificationAction] = useMutation(NOTIFICATION_ACTION);
  const [getNotificationById] = useLazyQuery(GET_NOTIFCATION_BY_ID);

  useEffect(() => {
    dispatch({
      type: "REMOVE_ALL_NOTIFICATION",
    });
    let memberLocationFilter = [];
    if (sidebarLocationId) {
      memberLocationFilter.push({ locationId: { eq: sidebarLocationId } });
    } else if (sidebarLocationId === 0) {
      const brandLocations = locationAccess.brands.find(
        (brand) => brand.brandId === sidebarBrandId
      )?.locations;
      brandLocations?.map((location) => {
        memberLocationFilter.push({
          locationId: { eq: location.locationId },
        });

        return null;
      });
    } else {
      memberLocationFilter.push({ locationId: { eq: "" } });
    }
    setInitialFilter(memberLocationFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);

  const getNotifications = async () => {
    let payload = {
      action: "list_notifications",
      brandId: sidebarBrandId,
    };
    if (sidebarLocationId !== 0) {
      payload.locationId = sidebarLocationId;
    }
    try {
      notificationAction({
        variables: {
          input: payload,
        },
        onCompleted: (data) => {
          const listNotifications = JSON.parse(
            data.notificationActions.body
          )?.listNotifications;
          const validNotification = listNotifications.filter((noti) => {
            return (
              isAfter(new Date(noti.displayUntil), new Date()) ||
              isSameDay(new Date(noti.displayUntil), new Date())
            );
          });
          dispatch({
            type: "SET_NOTIFICATION",
            payload: validNotification,
          });
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const chunkArray = (array, size) => {
    const chunkedArr = [];
    for (let i = 0; i < array.length; i += size) {
      chunkedArr.push(array.slice(i, i + size));
    }
    return chunkedArr;
  };
  const chunkedInitialFilter = chunkArray(initialFilter, 10);

  useEffect(() => {
    if (initialFilter[0]?.locationId?.eq && memberId) {
      getNotifications();
      const subscriptions = chunkedInitialFilter.map((chunk) =>
        API.graphql(
          graphqlOperation(CREATE_NOTIFICATION_SUBSCRIPTION, {
            filter: { or: chunk },
          })
        ).subscribe({
          next: (eventData) => {
            if (
              !eventData.value.data.onCreateNotification
                .actionByMemberDetails ||
              !eventData.value.data.onCreateNotification.locationDetails
            ) {
              getNotificationById({
                fetchPolicy: "no-cache",
                variables: {
                  id: eventData.value.data.onCreateNotification.id,
                },
                onCompleted: (data) => {
                  dispatch({
                    type: "ADD_NOTIFICATION",
                    payload: [data.getNotification],
                  });
                },
              });
            } else {
              dispatch({
                type: "ADD_NOTIFICATION",
                payload: [eventData.value.data.onCreateNotification],
              });
            }
          },
          error: (error) => {
            console.error("Subscription error:", error);
          },
        })
      );

      return () => {
        subscriptions.forEach((subscription) => subscription.unsubscribe());
      };
    }
  }, [initialFilter]);

  return (
    <NotificationContext.Provider value={{ notification, dispatch }}>
      {children}
    </NotificationContext.Provider>
  );
};
export const useNotificationContext = () => {
  return useContext(NotificationContext);
};
