import { Form, Formik, useFormikContext } from "formik";
import React from "react";
import {
  Button,
  Input,
  InternationalPhoneInput,
  Select,
} from "../../../../components";
import * as yup from "yup";
import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import "./userDetails.css";
import { NavigateButtonGroup } from "../index";
import { gender } from "../../../../constants/commonConstant";
import { isValidPhoneNumber } from "libphonenumber-js";
import { differenceInYears } from "date-fns";
import { useLazyQuery } from "@apollo/client";
import { SEARCH_BY_EMAIL } from "../../../../gqloperations/queries";
import { toast } from "react-toastify";
function UserDetails() {
  const {
    handleMoveStepNext,
    setMemberInformation,
    memberInformation,
    memberData,
    setMemberData,
  } = useAddMemberContext();
  const userDetailsSchema = yup.object().shape({
    givenName: yup.string().required("First name is required"),
    surname: yup.string().required("Last name is required"),
    mobileNumber: yup
      .string()
      .required("Mobile number is required")
      .test("is-valid-number", "Invalid Number", (mobileNumber) => {
        return mobileNumber ? isValidPhoneNumber(mobileNumber) : true;
      }),
    dob: yup.string().required("DOB is Required"),
    gender: yup.string().required("Gender is Required"),
    postCode: yup
      .string()
      .matches(/^\d+$/, "Post code must contain only numbers")
      .required("Post code is Required"),
    email: yup
      .string()
      .email("Invalid email address")
      .required("Email is required"),

    emergencyContactName: yup.string().when("dob", {
      is: (dob) => {
        return differenceInYears(new Date(), new Date(dob)) < 18;
      }, //just an e.g. you can return a function
      then: yup.string().required("Emergency name is required"),
    }),
    emergencyContactEmail: yup.string().when("dob", {
      is: (dob) => {
        return differenceInYears(new Date(), new Date(dob)) < 18;
      }, //just an e.g. you can return a function
      then: yup
        .string()
        .email("Invalid email address")
        .required("Email is required"),
    }),
    emergencyContactRelation: yup.string().when("dob", {
      is: (dob) => {
        return differenceInYears(new Date(), new Date(dob)) < 18;
      }, //just an e.g. you can return a function
      then: yup.string().required("Emergency relation is Required"),
    }),
    //  yup
    //   .string()
    //   .required("Emergency relation is Required"),

    emergencyContactNumber: yup.string().when("dob", {
      is: (dob) => {
        return differenceInYears(new Date(), new Date(dob)) < 18;
      }, //just an e.g. you can return a function
      then: yup
        .string()
        .test("is-valid-number", "Invalid Number", (mobileNumber) => {
          return mobileNumber ? isValidPhoneNumber(mobileNumber) : false;
        }),
      // .required("Emergency relation is Required"),
    }),
  });
  const [getMemberByEmail] = useLazyQuery(SEARCH_BY_EMAIL);
  const submitUserData = async (value, OnSubmitProps) => {
    const isExist = await getMemberByEmail({
      variables: { email: value.email.toUpperCase() },
    });
    if (isExist?.data?.getMemberByEmail?.items?.length > 0) {
      toast.error("this email is already been used by another user!");
      return;
    }

    const age = differenceInYears(new Date(), new Date(value.dob));
    let membershipLevel;
    if (age >= 16) {
      //more than 16 is adult membership
      membershipLevel = "ADULT";
    } else if (age > 13 && age < 16) {
      //less than 16 and more than 13 is youth membership
      membershipLevel = "YOUTH";
    } else if (age <= 13) {
      //less than 13 is junior membership
      membershipLevel = "JUNIOR";
    }
    setMemberData({
      ...memberData,
      memberDetails: {
        ...memberData.memberDetails,
        mobileNumber: value.mobileNumber,
        givenName: value.givenName,
        surname: value.surname,
        email: value.email,
        gender: value.gender,
        postCode: value.postCode,
        dob: value.dob,
        emergencyContactName: value.emergencyContactName,
        emergencyContactRelation: value.emergencyContactRelation,
        emergencyContactEmail: value.emergencyContactEmail,
        emergencyContactNumber: value.emergencyContactNumber,
      },
    });
    setMemberInformation({ ...memberInformation, age, membershipLevel });
    handleMoveStepNext();
  };

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          givenName: "",
          surname: "",
          mobileNumber: "",
          email: "",
          dob: "",
          gender: "",
          postCode: "",
          emergencyContactEmail: "",
          emergencyContactName: "",
          emergencyContactRelation: "",
          emergencyContactNumber: "",
        }}
        validationSchema={() => {
          return userDetailsSchema;
        }}
        onSubmit={async (values, OnSubmitProps) => {
          submitUserData(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form id="user-details-form">
              <div className="user-details-form">
                <p>CONTACT DETAILS</p>
                <div className="user-details-form-inputs">
                  <Input
                    type="name"
                    label="First name*"
                    name="givenName"
                    id="firstName"
                    autoComplete="off"
                  />
                  <Input
                    type="name"
                    label="Last name*"
                    name="surname"
                    id="lastName"
                    autoComplete="off"
                  />
                  <Input
                    type="name"
                    label="Email*"
                    name="email"
                    id="email"
                    autoComplete="off"
                  />
                  <InternationalPhoneInput
                    title="Mobile Number*"
                    name="mobileNumber"
                    id="mobileNumber"
                    editable={true}
                  />
                  <Input
                    type="date"
                    label="D.O.B*"
                    name="dob"
                    id="dob"
                    autoComplete="off"
                    // className="blue"
                  />
                  <Select
                    label="Gender*"
                    optionvalues={gender}
                    selecttype="array"
                    name="gender"
                    id="gender"
                    autoComplete="off"
                    className="select-element-value blue"
                  />
                  <Input
                    maxLength={4}
                    type="name"
                    label="Postcode*"
                    name="postCode"
                    id="postCode"
                    autoComplete="off"
                  />
                </div>
              </div>
              {differenceInYears(new Date(), new Date(formik.values.dob)) <
                18 && (
                <div className="user-details-form form-2">
                  <p>EMERGENCY CONTACT</p>
                  <div className="user-details-form-inputs">
                    <Input
                      type="name"
                      label="Emergency name*"
                      name="emergencyContactName"
                      id="emergencyContactName"
                      autoComplete="off"
                    />
                    <Input
                      type="name"
                      label="Emergency relationship*"
                      name="emergencyContactRelation"
                      id="emergencyContactRelation"
                      autoComplete="off"
                    />
                    <Input
                      type="name"
                      label="Emergency email*"
                      name="emergencyContactEmail"
                      id="emergencyContactEmail"
                      autoComplete="off"
                    />{" "}
                    <InternationalPhoneInput
                      title="Emergency Number*"
                      name="emergencyContactNumber"
                      id="emergencyContactNumber"
                      editable={true}
                    />
                  </div>
                </div>
              )}
              <NavigateButtonGroup />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UserDetails;
