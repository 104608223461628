import "./changeMembershipPrice.scss";
import { React, useState, useEffect } from "react";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import { brandTypes } from "../../constants/commonConstant";
import getTypeIcon from "../../utils/getTypeIcon";
import { AiOutlineSearch } from "react-icons/ai";
import { useParams } from "react-router-dom";
import { GET_LOCATION_BY_BRANDID } from "../../gqloperations/queries";
import {
  UPDATE_MEMBERSHIP_LOCATION_STATUS_PRICE,
  UPDATE_PRICE_FOR_MEMBERSHIP_LOCATION,
  UPDATE_ISACTIVE_FOR_MEMBERSHIP_LOCATION,
} from "../../gqloperations/mutations";
import { useMutation, useLazyQuery } from "@apollo/client";
import { Header, Modal, Table, Checkbox, BulkPriceStatusModal} from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
const ChangeMembershipPrice = () => {
  const { id: membershipId } = useParams();
  const { locationAccess } = useAuthContext();
  const { sidebarBrandId } = useMemberContext();
  const [details, setDetailsId] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const [rowSelection, setRowSelection] = useState({});
  const [membershipName, setMembershipName] = useState("");

  const [updateMembershipLocationDetails, { loading: bulkPriceStatusLoading }] =
    useMutation(UPDATE_MEMBERSHIP_LOCATION_STATUS_PRICE);
  const [updateMembershipLocationPrice, { loading: bulkPriceLoading }] =
    useMutation(UPDATE_PRICE_FOR_MEMBERSHIP_LOCATION);
  const [updateMembershipLocationStatus, { loading: bulkStatusLoading }] =
    useMutation(UPDATE_ISACTIVE_FOR_MEMBERSHIP_LOCATION);
  const [isPaginating, setPaginating] = useState(false);
  const [
    getLocationByBrandId,
    { loading: locationLoading, error: locationError },
  ] = useLazyQuery(GET_LOCATION_BY_BRANDID, {
    fetchPolicy: "no-cache",
    variables: {
      brandId: sidebarBrandId,
      membershipFilter: { membershipId: { eq: membershipId } },
    },
    onCompleted: (data) => {
      const eligibleLocations = data?.getLocationByBrandId?.items.filter(
        (cur) => {
          const { memberships } = cur;
          return memberships?.items?.length > 0;
        }
      );
      const matchingLocations = locationAccess?.locationAll.flatMap(
        (curLocationId) => {
          return eligibleLocations.filter((cur) => cur.id === curLocationId);
        }
      );
      const membershipName =
        eligibleLocations[0]?.memberships?.items[0]?.membershipDetails
          ?.membershipName;

      if (isPaginating && tableData)
        setTableData([...tableData, ...matchingLocations]);
      else setTableData([...matchingLocations]);
      setMembershipName(membershipName);

      setNextToken(data?.getLocationByBrandId?.nextToken);
    },
  });

  const [nextToken, setNextToken] = useState();
  const handleNextToken = () => {
    setPaginating(true);
    getLocationByBrandId({
      variables: {
        brandId: sidebarBrandId,
        membershipFilter: { membershipId: { eq: membershipId } },
        nextToken: nextToken,
      },
    });
  };

  useEffect(() => {
    if (sidebarBrandId) {
      setPaginating(false);
      getLocationByBrandId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId]);

  if (locationLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (locationError) {
    return (
      <div>
        <div>Problem in getting memberships</div>
      </div>
    );
  }
  const columns = [
    {
      id: "select",
      header: ({ table }) => {
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              <Checkbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          <Checkbox
            {...{
              checked: row.getIsSelected(),
              indeterminate: row.getIsSomeSelected(),
              onChange: row.getToggleSelectedHandler(),
            }}
          />
        </div>
      ),
    },
    {
      accessorFn: (row) => row?.brandDetails?.name ?? "",
      header: "BRAND",
      cell: (info) => {
        const brandName = info.getValue();
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
    },
    {
      accessorFn: (row) => row?.name ?? "",
      header: "LOCATION NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "locationName",
    },
    {
      accessorFn: (row) => {
        return row?.memberships?.items[0]?.isActive;
      },
      header: "ACTIVE",
      cell: (info) => {
        return info.getValue() ? (
          <span className="text-green">YES</span>
        ) : (
          <span className="red">NO</span>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "actionStatus",
    },

    {
      accessorFn: (row) => {
        return row?.memberships?.items[0]?.costPrice;
      },
      header: "COST PRICE",
      cell: (info) => `$${info.getValue()}`,
      enableSorting: false,
      enableGlobalFilter: false,
      id: "price",
    },
    {
      header: "",
      cell: ``,
      enableSorting: false,
      enableGlobalFilter: false,
      id: "empty-space",
    },
  ];

  const modalClose = () => {
    setDetailsId("");
  };

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const handleClick = () => {
    setDetailsId("bulk-price-update");
  };
  const handleChangeAllPrice = async (values, OnSubmitProps) => {
    const { statusCheckbox, statusToggle, priceCheckbox, priceInput } = values;

    const locationIds = Object.keys(rowSelection).map(
      (cur) => tableData[cur].id
    );
    const variables = { membershipId, locationIds };
    const handleComplete = (message) => {
      toast.success(message);
      setPaginating(false);
      getLocationByBrandId();
      modalClose();
    };
    switch (true) {
      case statusCheckbox && priceCheckbox:
        try {
          await updateMembershipLocationDetails({
            variables: {
              ...variables,
              costPrice: priceInput,
              isActive: statusToggle,
            },
            onCompleted: () => {
              handleComplete("Updated price and status successfully!");
              setPaginating(false);
              getLocationByBrandId();
            },
          });
        } catch (error) {
          toast.error(`${error}`);
        }
        break;
      case priceCheckbox:
        try {
          await updateMembershipLocationPrice({
            variables: {
              ...variables,
              costPrice: priceInput,
            },
            onCompleted: () => {
              setPaginating(false);
              setNextToken(null);
              handleComplete("Updated price successfully!");
              setPaginating(false);
              getLocationByBrandId();
            },
          });
        } catch (error) {
          toast.error(`${error}`);
        }
        break;
      case statusCheckbox:
        try {
          await updateMembershipLocationStatus({
            variables: {
              ...variables,
              isActive: statusToggle,
            },
            onCompleted: () => {
              setPaginating(false);
              setNextToken(null);
              handleComplete("Updated status successfully!");
              getLocationByBrandId();
            },
          });
        } catch (error) {
          toast.error(`${error}`);
        }
        break;
      default:
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  return (
    <>
      <div className="apply-membership-container">
        <Header pageTitle="Change Price/Status" />

        <div className="membership-landing-content">
          {details && (
            <Modal>
              {details === "bulk-price-update" && (
                <BulkPriceStatusModal
                  handleSubmit={handleChangeAllPrice}
                  modalClose={modalClose}
                  bulkPriceStatusLoading={bulkPriceStatusLoading}
                  bulkPriceLoading={bulkPriceLoading}
                  bulkStatusLoading={bulkStatusLoading}
                />
              )}
            </Modal>
          )}

          <div className="search-and-button-row mt-24">
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Search by location name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {tableData && (
            <div className="table landing-table">
              {membershipName && (
                <div className="uppercase fs-12">
                  Membership Name: {membershipName}
                </div>
              )}
              <Table
                data={tableData}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                rowsPerPage={10}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleClick={handleClick}
                showBtn={true}
                btnName="Change Price / status"
                isBtnLoading={false}
                isBtnDisabled={true}
                nextToken={nextToken}
                handleNextToken={handleNextToken}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ChangeMembershipPrice;
