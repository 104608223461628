import { useEffect, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { REFUNDS_IN_LOCATION } from "../../../../gqloperations/queries";
import { Table } from "../../../../components";
import { useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { useLocationContext } from "../../../../contexts/LocationContext";
import { sortDesc } from "../../../../utils/sort";
const LocationRefunds = ({ locationId }) => {
  const client = useApolloClient();
  const { state } = useLocationContext();
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [nextToken, setNextToken] = useState(null);

  const getRefundsByLocationid = async (nextTokenFromResult = null) => {
    try {
      const result = await client.query({
        query: REFUNDS_IN_LOCATION,
        fetchPolicy: "no-cache",
        variables: {
          locationId: locationId,
          // limit: 1,
          nextToken: nextTokenFromResult,
        },
      });
      const sortDescData = sortDesc(
        result.data.getRefundTransactionsByLocationId.items,
        "createdAt"
      );
      if (result && nextTokenFromResult) {
        setTableData([...tableData, ...sortDescData]);
      } else {
        setTableData([...sortDescData]);
      }

      setNextToken(result.data.getRefundTransactionsByLocationId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  useEffect(() => {
    if (state?.index === 6) {
      getRefundsByLocationid();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.index]);

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleNextToken = () => {
    if (nextToken) {
      getRefundsByLocationid(nextToken);
    }
  };
  const columns = [
    {
      accessorKey: "refundDate",
      header: "refund date",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "refundDate",
    },
    {
      accessorFn: (row) =>
        `${row?.memberDetails.surname}, ${row?.memberDetails.givenName}`,
      header: () => {
        return <span> SURNAME, NAME</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
      filterFn: "stringColumnFilter",
      enableColumnFilter: true,
    },
    {
      accessorKey: "refundAmount",
      header: "Refund Amount",
      cell: (info) => `$ ${info?.getValue().toFixed(2)}`,
      enableSorting: true,
      enableGlobalFilter: true,
      id: "refundAmount",
    },
    {
      accessorFn: (row) =>
        `${row?.requestByDetails?.surname ?? ""} ${
          row?.requestByDetails?.givenName ?? ""
        }`,
      header: () => {
        return <span> Requested By</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "requestedBy",
      enableColumnFilter: true,
    },
    {
      accessorFn: (row) =>
        `${row?.refundByDetails?.surname ?? ""} ${
          row?.refundByDetails?.givenName ?? ""
        }`,
      header: () => {
        return <span> Refunded By</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "givenName",
      enableColumnFilter: true,
    },
    {
      accessorFn: (row) =>
        row.refundStatus === "FAILED" ? (
          <div className="text-red">DECLINED</div>
        ) : (
          <div className="text-green">{row.refundStatus}</div>
        ),
      header: "refund status",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "refundStatus",
    },
  ];
  return (
    <>
      {tableData && (
        <>
          <div className="actions-row ">
            <div></div>
            <div className="left-elements">
              <div className="search-and-button-row">
                <div className="search-container">
                  <form onSubmit={onSearchBarSubmit}>
                    <div className="search-bar">
                      <div className="search-bar-container">
                        <input
                          className="fs-10"
                          type="text"
                          name="refund-search"
                          id="refund-search"
                          value={searchBarInputVal}
                          onChange={handleChange}
                          placeholder="first name, surname"
                        />
                        <button type="submit">
                          <AiOutlineSearch />
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>

          <div className="landing-table location-refunds">
            <Table
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
            />
          </div>
        </>
      )}
    </>
  );
};
export default LocationRefunds;
