import React, { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { CHARGE_SINGLE_PAYMENT } from "../../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { MakePaymentModal, Button, Input } from "../../../components";
import { isAfter, isBefore, isSameDay } from "date-fns";
import { useMemberContext } from "../../../contexts/MemberContext";

const MembershipOptionModal = (props) => {
  const [showModalPayment, setShowModalPayment] = useState(false);

  const { sidebarBrandId } = useMemberContext();
  const [doesMemberHaveValidMembership, setDoesMemberHaveValidMembership] =
    useState([]);
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);

  const calcCancellation = (data) => {
    let isCancellationAfterToday = false;
    let isCancellationToday = false;
    let isCancellationBeforeToday = false;
    const cancellationDate = data?.expiryDateTime;
    if (cancellationDate) {
      isCancellationToday = isSameDay(new Date(cancellationDate), new Date());
      isCancellationBeforeToday = isBefore(
        new Date(cancellationDate),
        new Date()
      );
      isCancellationAfterToday = isAfter(
        new Date(cancellationDate),
        new Date()
      );
    }

    return {
      isCancellationBeforeToday,
      isCancellationToday,
    };
  };
  const membershipLocation =
    props?.voucherDetails?.membershipDetail?.membershipLocations.items.filter(
      (item) => item.locationId === props.memberDetail?.homeLocationDetails?.id
    );
  useEffect(() => {
    const validMembership = props?.memberContracts?.filter((cur) => {
      const { isCancellationBeforeToday, isCancellationToday } =
        calcCancellation(cur);

      if (
        props.voucherDetails?.type === "COMPLIMENTARY" ||
        props.voucherDetails?.type === "VISIT_PASS"
      ) {
        return (
          cur?.membershipId ===
            props.fetchVoucherData?.getVoucher?.membershipId &&
          (isCancellationToday || isCancellationBeforeToday)
        );
      } else {
        return (
          cur?.membershipId === props.fetchVoucherData?.getVoucher?.membershipId
        );
      }
    });
    setDoesMemberHaveValidMembership(validMembership);
  }, [props.fetchVoucherData]);

  const submitApplyToMember = (values) => {
    const paymentInformationId = props.memberDetail?.paymentDetails?.items.find(
      (i) => i.primary
    )?.id;
    if (membershipLocation[0].id) {
      chargeSinglePayment({
        variables: {
          amount: props.amount,
          memberId: props.memberDetail?.memberId,
          brandId: sidebarBrandId,
          paymentInformationId: paymentInformationId,
          operation: "AVM",
          futurePaymentOption: "CARD_ON_FILE_IS_DEFAULT",
          applyVoucherMemberArguments: {
            voucherId: props?.voucherDetails?.id,
            memberId: props.memberDetail?.memberId,
            membershipId:
              !props?.memberContracts.length &&
              (props.voucherDetails?.type === "COMPLIMENTARY" ||
                props.voucherDetails?.type === "VISIT_PASS")
                ? props.voucherDetails?.membershipDetail?.id
                : (props?.memberContracts?.filter(
                    (item) =>
                      item.membershipId ===
                      props.voucherDetails?.membershipDetail?.id
                  ))[0].membershipId,
            membershipLocationId: membershipLocation[0]?.id,
          },
          membershipLocationId: membershipLocation[0]?.id,
        },
        onCompleted: () => {
          toast.success("Applied Voucher successfully!");
          props.refetch();
          props.modalClose();
        },
        onError: (error) => {
          toast.error(`Error: ${error}`);
        },
      });
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        membership:
          !props?.memberContracts.length &&
          (props.voucherDetails?.type === "COMPLIMENTARY" ||
            props.voucherDetails?.type === "VISIT_PASS")
            ? props.voucherDetails?.membershipDetail?.membershipName
            : (props?.memberContracts?.filter(
                (item) =>
                  item.membershipId ===
                  props.voucherDetails?.membershipDetail?.id
              ))[0]?.membershipName,
      }}
      onSubmit={async (values) => {
        if (props.amount) {
          console.log(props.memberDetail, "log");
          console.log(props.voucherDetails, "log");

          setShowModalPayment(true);
        } else {
          submitApplyToMember(values);
        }
        // submitApplyToMember(values);
      }}
      validate={(values, _props) => {
        const { membership } = values;
        let errors = {};

        if (!membership) {
          const errorText = "Please choose membership type";
          errors.membership = errorText;
        }
        return errors;
      }}
    >
      {(formik) => {
        return (
          <div className="instructor-description-container">
            {doesMemberHaveValidMembership?.length > 0 ||
            (!props?.memberContracts.length &&
              (props.voucherDetails?.type === "COMPLIMENTARY" ||
                props.voucherDetails?.type === "VISIT_PASS")) ? (
              <>
                <Form
                  className="status-modal cancel-membership pd-328"
                  id="instructor-description-form"
                >
                  <div className="credential-total-container">
                    <div className="upload-container">
                      <h3 className="fs-35 uppercase ">Membership</h3>

                      <div className="upload-item flex-wrap">
                        {props?.memberContracts?.length > 0 ||
                        (!props?.memberContracts.length &&
                          (props.voucherDetails?.type === "COMPLIMENTARY" ||
                            props.voucherDetails?.type === "VISIT_PASS")) ? (
                          <div className="selector mb-12 mt-12">
                            {/* <Select
                              label="Membership"
                              optionvalues={props?.memberContracts}
                              objectvalue={"membershipId"}
                              objectname={"membershipName"}
                              selecttype="object"
                              name="membership"
                              id="membership"
                              autoComplete="off"
                              className="fs-12"
                            /> */}
                            <Input
                              className="fs-12"
                              type="text"
                              name="membership"
                              id="membership"
                              autoComplete="off"
                              readOnly
                            />
                          </div>
                        ) : (
                          <p className="error fs-12">
                            This member does not have any active memberships
                          </p>
                        )}
                      </div>
                      <div className="btn-group pt-18">
                        {props?.memberContracts?.length > 0 ||
                        (!props?.memberContracts.length &&
                          (props.voucherDetails?.type === "COMPLIMENTARY" ||
                            props.voucherDetails?.type === "VISIT_PASS")) ? (
                          <>
                            <Button
                              loading={chargeSinglePaymentLoading}
                              name="Save"
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-confirm btn-small"
                              disabled={
                                chargeSinglePaymentLoading ? true : false
                              }
                            />
                            <Button
                              name="Cancel"
                              btntype="button" // Assuming you want a button type here
                              btnname="cancel" // Assuming you want a different value for btnname
                              className="btn btn-deny btn-small"
                              click={props.modalClose}
                            />
                          </>
                        ) : (
                          <Button
                            name="Cancel"
                            btntype="button" // Assuming you want a button type here
                            btnname="cancel" // Assuming you want a different value for btnname
                            className="btn btn-deny btn-small"
                            click={props.modalClose}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </Form>
                {showModalPayment && props.amount && (
                  <MakePaymentModal
                    memberDetail={props.memberDetail}
                    amount={props.amount}
                    setShowModalPayment={setShowModalPayment}
                    operation="AVM"
                    locationId={
                      props.memberDetail?.contracts?.items[0]?.locationId
                    }
                    membershipType={
                      (props?.memberContracts?.filter(
                        (item) =>
                          item.membershipId ===
                          props.voucherDetails?.membershipDetail?.id
                      ))[0]?.recurring
                        ? "weekly"
                        : "fortnightly"
                    }
                    payloadData={{
                      applyVoucherMemberArguments: {
                        voucherId: props?.voucherDetails?.id,
                        memberId: props.memberDetail?.memberId,
                        membershipId:
                          !props?.memberContracts.length &&
                          (props.voucherDetails?.type === "COMPLIMENTARY" ||
                            props.voucherDetails?.type === "VISIT_PASS")
                            ? props.voucherDetails?.membershipDetail?.id
                            : (props?.memberContracts?.filter(
                                (item) =>
                                  item.membershipId ===
                                  props.voucherDetails?.membershipDetail?.id
                              ))[0].membershipId,
                        membershipLocationId: membershipLocation[0]?.id,
                      },
                    }}
                    onApiCompleted={() => {
                      toast.success("Applied Voucher successfully!");
                      props.refetch();
                      props.modalClose();
                      setShowModalPayment(false);
                    }}
                    onApiError={(e) => {
                      toast.error(`Error: ${e}`);
                    }}
                  />
                )}
              </>
            ) : (
              <div className="status-modal">
                {props?.memberContracts.length &&
                (props.voucherDetails?.type === "COMPLIMENTARY" ||
                  props.voucherDetails?.type === "VISIT_PASS")
                  ? "Complimantry vouchers cannot be applied to active members."
                  : "You do not have any valid memberships that this voucher can be applied to"}
                <div className="btn-group">
                  <Button
                    name="Close"
                    btntype="button" // Assuming you want a button type here
                    btnname="cancel" // Assuming you want a different value for btnname
                    className="btn btn-deny btn-small"
                    click={props.modalClose}
                  />
                </div>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default MembershipOptionModal;
