import { useEffect, useState } from "react";
import Table from "../../table/Table";
import ExportGroup from "../../export/ExportGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { useLazyQuery } from "@apollo/client";
import { GET_SALES_REPORT } from "../../../gqloperations/queries";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { ReportGeneratorFormWithMonth } from "../../../pages/index";
import "./executiveReportsTables.scss";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";

export default function TotalMemberReport() {
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [totalSaleReport, { loading }] = useLazyQuery(GET_SALES_REPORT);
  const [tableData, setTableData] = useState();
  const { locationAccess } = useAuthContext();

  const columns = [
    {
      accessorKey: "location.name",
      header: "CLUB",
      cell: (info) => info.getValue(),
      footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "club",
    },
    {
      accessorKey: "location.state",
      header: "STATE",
      cell: (info) => info.getValue(),
      // footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "state",
    },
    // {
    //   // accessorKey: "locationDetail",
    //   header: "ACCOUNT CODE",
    //   cell: (info) => info.getValue(),
    //   // footer: "TOTAL",
    //   enableSorting: true,
    //   enableGlobalFilter: true,
    //   // id: "region",
    // },
    // {
    //   // accessorKey: "region",
    //   header: "REGION",
    //   cell: (info) => info.getValue(),
    //   // footer: "TOTAL",
    //   enableSorting: true,
    //   enableGlobalFilter: true,
    //   // id: "totalMember",
    // },
    {
      accessorKey: "newJoinersCount",
      header: "NEW JOINERS",
      cell: (info) => info.getValue(),
      footer: () => data?.data?.total.newJoinersCount,
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalNewJoiners",
    },
    {
      accessorKey: "returningMembersCount",
      header: "TOTAL RETURNING MEMBERS",
      cell: (info) => info.getValue(),
      // footer: "TOTAL",
      footer: () => data?.data?.total.returningMembersCount,
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalReturningMembers",
    },
    {
      accessorKey: "transfersCount",
      header: "TOTAL TRANSFERS",
      cell: (info) => info.getValue(),
      // footer: "TOTAL",
      footer: () => data?.data?.total.transfersCount,
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalTransfer",
    },
  ];
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );

  const handleGenerate = (values, onSubmitProps) => {
    setGenerate(true);
    setEndPoint(API.executiveSaleReport);
    let parameters;
    if (values.chooseDateFrom && values.chooseDateTo) {
      parameters = {
        ...(values.location === "-1" ? {} : { locationId: values.location }),
        startDate: values.chooseDateFrom,
        endDate: values.chooseDateTo,
        paymentType: values.reportCategory,
        brandId: values.brand,
        locationType: values.additionalSelect,
      };
    }
    setParams(parameters);
  };

  useEffect(() => {
    console.log(data);
    if (error && endPoint) {
      setTableData([]);
      toast.error(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) setTableData(data.data.data);
  }, [data, isLoading, error]);

  return (
    <>
      <ReportGeneratorFormWithMonth
        brandAndLocation
        // onlyStartDate
        additionalSelect={{
          option: [
            { id: "ALL", name: "ALL" },
            { id: "FRANCHISEE", name: "FRANCHISEE" },
            { id: "CORPORATE", name: "CORPORATE" },
          ],
          label: "Location type",
        }}
        noTimezone
        startDateRequired
        optionFirst={[
          { id: "ALL", name: "ALL MEMBERS" },
          { id: "DD", name: "DD MEMBERS" },
          { id: "PIF", name: "PIF MEMBERS" },
        ]}
        // option={}
        handleSubmit={handleGenerate}
      />
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && generate && (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}

      {/* <p className="totalCount">TOTAL: {tableData.length}</p> */}

      {!isLoading && (generate || tableData) && (
        <div className="report-table-container-all no-scroll-bar">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={true}
          />
        </div>
      )}
    </>
  );
}
