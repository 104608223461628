import "./clubImage.scss";
import { toast } from "react-toastify";
import { useState, useEffect } from "react";
import { EditButton } from "../../../formUiElements/editButton/EditButton";
import { v4 as uuidv4 } from "uuid";
import { useMemberContext } from "../../../../contexts/MemberContext";

const ClubImage = ({ onSubmit, state, locationId }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [key, setKey] = useState(false);
  const [file, setFile] = useState(false);

  const {
    downloadAndDecode,
    imgUrl,
    setImgUrl,
    encodeAndUploadImageToAws: uploadImageToAws,
  } = useMemberContext();

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleCancel = async (e) => {
    toast.warning("Update Cancelled");
    document.getElementById("editEmergencyContactForm").reset();
    setIsEditing(false);

    if (state?.location?.getLocation?.clubImage) {
      await downloadAndDecode(state?.location?.getLocation?.clubImage);
    } else {
      setImgUrl("");
    }
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5_242_880) {
      toast.error("Image needs to be less than 5MB");
    } else {
      setFile(file);
      const clientSideImgUrl = URL.createObjectURL(file);
      setImgUrl(clientSideImgUrl);
      const extension = file.name.split(".")[1];

      // const name = file.name.split(".")[0];
      // /clubimages/<locationid>/<uuid>.jpg
      setKey(`clubimages/${locationId}${uuidv4()}.${extension}`);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const uploadedFile = await uploadImageToAws(file, key);
    // await Storage.put(key, file, {
    //   contentType: file.type,
    // });

    const values = {
      id: state.id,
      clubImage: uploadedFile.key,
    };

    await onSubmit(values);
    setIsEditing(false);
  };

  // useEffect(() => {
  //   if (state?.location?.getLocation?.clubImage) {
  //     Storage.get(state?.location?.getLocation?.clubImage)
  //       .then((data) => setClientSideImg(data))
  //       .catch((err) => toast.error(`Error: ${err}`));
  //   }
  // }, [state]);

  useEffect(() => {
    try {
      (async () => {
        state?.location?.getLocation?.clubImage &&
          (await downloadAndDecode(state?.location?.getLocation?.clubImage));
      })();
    } catch (error) {
      toast.error(`Error: ${error}`);
    }
    return () => {
      setImgUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state]);

  return (
    <form
      onSubmit={handleSubmit}
      className="club-image-container pd-12"
      id="editEmergencyContactForm"
    >
      <div className="title-bar">
        <h3 className="fs-14 uppercase">Club Image</h3>
        <span className="icon-container edit">
          <EditButton
            isEditing={isEditing}
            handleCancel={handleCancel}
            handleClick={handleClick}
          />
        </span>
      </div>
      <div className="club-image">
        <input
          type="file"
          onChange={handleImageUpload}
          accept="image/*"
          disabled={!isEditing}
          className="club-img-input pb-12"
        />
        <div className="img-container">
          <div className="img-wrap">
            {/* {imgUrl && (
              <img
                src={
                  imgUrl
                    ? imgUrl
                    : state?.location?.getLocation?.clubImage ?? ""
                }
                alt=""
              />
            )} */}
            <img
              src={
                imgUrl ? imgUrl : state?.location?.getLocation?.clubImage ?? ""
              }
              alt=""
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default ClubImage;
