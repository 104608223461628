import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import GraphQlClient from "./GraphQLClient";
import { AuthProvider } from "./contexts";
import { MemberContextProvider } from "./contexts/MemberContext";
import { LocationContextProvider } from "./contexts/LocationContext";
import { ClassContextProvider } from "./contexts/ClassContext";
import { NotificationContextProvider } from "./contexts/NotificationContext";

// Function to handle sessionStorage transfer between tabs
const sessionStorageTransfer = async () => {
  return new Promise((resolve, reject) => {
    const transferHandler = (event) => {
      console.log("storage event detected", event);
      if (!event) {
        event = window.event; // IE compatibility
      }

      if (!event.newValue) {
        console.log("No new value in event, resolving promise");
        resolve(); // Do nothing if no new value to work with
        return;
      }

      if (event.key === "getSessionStorage") {
        console.log("Another tab requested sessionStorage");
        // Another tab asked for the sessionStorage -> send it
        localStorage.setItem("sessionStorage", JSON.stringify(sessionStorage));
        // The other tab should now have it, so we're done with it.
        localStorage.removeItem("sessionStorage"); // Could use a short timeout as well
      } else if (
        event.key === "sessionStorage" &&
        Object.keys(sessionStorage).length === 0
      ) {
        console.log("Receiving sessionStorage data from another tab");
        // Another tab sent data -> get it
        const data = JSON.parse(event.newValue);
        for (const key in data) {
          sessionStorage.setItem(key, data[key]);
        }
        console.log("sessionStorage data received, resolving promise");
        resolve(); // Data received, resolve the promise
      }
    };

    // Listen for changes to localStorage
    window.addEventListener("storage", transferHandler, false);

    // Ask other tabs for session storage (this is ONLY to trigger the event)
    if (Object.keys(sessionStorage).length === 0) {
      console.log("sessionStorage is empty, requesting data from other tabs");
      localStorage.setItem("getSessionStorage", "request");
      localStorage.removeItem("getSessionStorage");
    } else {
      console.log("sessionStorage is not empty, resolving promise immediately");
      resolve(); // sessionStorage is not empty, resolve immediately
    }

    // Set a timeout to avoid potential indefinite waiting
    setTimeout(() => {
      console.log("Timeout reached, resolving promise");
      resolve();
    }, 2000); // Wait for a maximum of 5 seconds
  });
};

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("./service-worker.js", { scope: "/" })
    .then((registration) => {
      console.log("Service Worker registered:", registration);
    })
    .catch((error) => {
      console.log("Service Worker registration failed:", error);
    });
}
const root = ReactDOM.createRoot(document.getElementById("root"));

const initialize = async () => {
  try {
    console.log("Starting sessionStorage transfer");
    await sessionStorageTransfer();
    console.log("sessionStorage transfer complete, rendering app");
  } catch (e) {
    console.log(e);
  } finally {
    // Now you can safely call root.render here
    root.render(
      <GraphQlClient>
        <AuthProvider>
          <MemberContextProvider>
            <LocationContextProvider>
              <ClassContextProvider>
                <NotificationContextProvider>
                  <App />
                </NotificationContextProvider>
              </ClassContextProvider>
            </LocationContextProvider>
          </MemberContextProvider>
        </AuthProvider>
      </GraphQlClient>
    );
  }
};

initialize();
