import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { SEARCH_MEMBER, GET_GROUP } from "../../gqloperations/queries";
import { ADD_STAFF_TO_GROUP } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { AiOutlineSearch } from "react-icons/ai";
import { BeatLoader } from "react-spinners";
import { Header, RadioButton, Table } from "../../components";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";

const StaffToGroup = () => {
  const { id: groupId } = useParams();
  const navigate = useNavigate();
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [isFiltered, setIsFiltered] = useState(false);
  const [tableData, setTableData] = useState();
  const [searchValue, setSearchValue] = useState("");
  const [rowSelection, setRowSelection] = useState({});
  const [addedStaff, setAddedStaff] = useState([]);

  const [addStaffToGroup, { loading: addStaffToGroupLoading }] =
    useMutation(ADD_STAFF_TO_GROUP);
  const [
    searchMembers,
    {
      loading: searchMembersLoading,
      error: searchMembersError,
      data: searchMembersData,
    },
  ] = useLazyQuery(SEARCH_MEMBER, {
    fetchPolicy: "no-cache",
  });
  const {
    loading: groupLoading,
    error: groupError,
    data: groupData,
    refetch,
  } = useQuery(GET_GROUP, {
    variables: {
      id: groupId,
    },
    onCompleted: (data) => {
      if (data?.getGroup?.groupStaff?.items.length <= 0) return;
      const hasAddedToGroup = data?.getGroup?.groupStaff?.items;
      setAddedStaff(hasAddedToGroup);
    },
  });

  const getSearchMemberVariables = () => {
    if (searchValue.includes("@")) {
      return {
        filter: {
          type: { eq: "STAFF" },
          email: { eq: `${searchValue}` },
        },
        limit: 100,
      };
    } else if (/\d/.test(searchValue)) {
      let value = searchValue;
      if (searchValue.startsWith("+")) {
        value = searchValue.replace("+", "");
      }
      if (searchValue.startsWith("0")) {
        value = searchValue.replace("0", "");
      }
      return {
        filter: {
          type: { eq: "STAFF" },
          mobileNumber: { wildcard: `*${value}*` },
        },
        limit: 100,
      };
    } else {
      return {
        filter: {
          type: { eq: "STAFF" },
          or: [
            {
              givenName: { wildcard: `*${searchValue.toUpperCase()}*` },
            },
            { surname: { wildcard: `*${searchValue.toUpperCase()}*` } },
          ],
        },
        limit: 100,
      };
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setRowSelection({});
    searchMembers({
      // variables: { ...getSearchMemberVariables(), limit: 2 },
      variables: getSearchMemberVariables(),

      onCompleted: (data) => {
        setTableData(data?.searchMembers?.items);
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
  };
  const handleNextToken = () => {
    if (searchMembersData?.searchMembers?.nextToken) {
      searchMembers({
        variables: {
          ...getSearchMemberVariables(),
          nextToken: searchMembersData?.searchMembers?.nextToken,
          // limit: 2,
        },
        onCompleted: (data) => {
          setTableData([...tableData, ...data?.searchMembers?.items]);
        },
        onError: (error) => {
          toast.error(`${error}`);
        },
      });
    }
  };

  const findSelectedStaffId = () => {
    const keysOfRowSelection = Object.keys(rowSelection);
    if (keysOfRowSelection?.length === 0) return;
    const selectedRowIndex = keysOfRowSelection[0];

    const selectedStaffId = tableData[selectedRowIndex]?.memberId;
    return selectedStaffId;
  };

  const handleClick = () => {
    addStaffToGroup({
      variables: {
        groupIds: [groupId],
        operation: "ADD_STAFF_TO_GROUP",
        staffId: findSelectedStaffId(),
      },
      onCompleted: () => {
        toast.success("Staff successfully added to the group");
        setRowSelection({});
        refetch();
      },

      onError: (error) => {
        toast.error(`${error}`);
      },
    });
  };
  const handleCancel = () => {
    navigate(-1);
  };

  const columns = [
    {
      id: "radio-select",
      header: "Select",
      cell: ({ row, table }) => (
        <div className="px-1">
          {addedStaff?.some(
            (curStaff) => curStaff.staffId === row?.original?.memberId
          ) ? (
            <RadioButton disabled />
          ) : (
            <RadioButton
              name="staff-to-group"
              checked={row?.getIsSelected()}
              onChange={() => {
                const selected = row.isSelected; // get selected status of current row.
                table?.toggleAllRowsSelected(false); // deselect all.
                row.toggleSelected(!selected); // reverse selected status of current row.
              }}
            />
          )}
        </div>
      ),
    },
    {
      accessorFn: (originalRow) => {
        if (!originalRow?.brandDetails?.name) return;
        const brandName = originalRow?.brandDetails
          ? originalRow?.brandDetails?.name
          : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      header: "Brand",

      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "brand",
    },
    {
      accessorFn: (row) => `${row.surname}, ${row.givenName}`,
      header: "Staff name",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
    },

    {
      accessorKey: "email",
      header: "Staff email address",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },
  ];
  if (searchMembersLoading || groupLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (searchMembersError) {
    return (
      <div>
        <div>Error: Problem getting staff</div>
      </div>
    );
  }

  if (groupError) {
    return (
      <div>
        <div>Error: Problem getting groups</div>
      </div>
    );
  }

  return (
    <div className="staff-access-container">
      <Header pageTitle="Add staff access" />
      <div className="staff-access-content">
        <div className="row2 actions-row flex-wrap">
          <div className="left-elements mt-32">
            {groupData?.getGroup?.name && (
              <div className="fs-12 uppercase">
                Group Name: {groupData?.getGroup?.name}
              </div>
            )}
          </div>
          <div className="right-elements flex-wrap">
            <div className="search-container">
              <form onSubmit={handleSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchValue}
                      onChange={(e) => setSearchValue(e.target.value)}
                      placeholder="Staff Name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="table ">
          {!tableData ? (
            <table>
              <thead>
                <tr className="fs-14">
                  <th>Select</th>
                  <th>Brand</th>
                  <th>Staff name</th>
                  <th>Staff email address</th>
                </tr>
              </thead>
              <tbody></tbody>
            </table>
          ) : (
            <Table
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columns={columns}
              columnFilter={isFiltered}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              setColumnFilter={setIsFiltered}
              rowsPerPage={10}
              handleNextToken={handleNextToken}
              nextToken={searchMembersData?.searchMembers?.nextToken}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              sorting={sorting}
              setSorting={setSorting}
              btnName="Apply"
              showTwoBtns={true}
              handleClick={handleClick}
              handleCancel={handleCancel}
              isBtnLoading={addStaffToGroupLoading}
              isBtnDisabled={Object.keys(rowSelection)?.length === 0}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default StaffToGroup;
