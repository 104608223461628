import "./specificReport.scss";
import { useParams } from "react-router-dom";
import MemberReport from "./MemberReport";
import {
  Header,
  MemberVisitsReport,
  MembershipMovementReport,
  MembershipBreakupReport,
  PaymentsReport,
  DirectDebitReport,
  PIFExpiringReport,
  DDFailedPaymentReport,
  RefundReport,
  UpgradeDowngradeReport,
  TotalChurnReport,
  SaleReport,
  TotalMemberVisitsReport,
  TotalLeaverReport,
  SuspensionDetailReport,
  TotalMemberReport,
  SuspendedMembersReport,
  FPmembershipReport,
  FPVisitReport,
} from "../../components";

const reports = [
  // member report
  { id: "allMembers", title: "ALL MEMBERS" },
  { id: "memberVisits", title: "MEMBER VISITS" },
  // membership report
  { id: "membershipMovement", title: "MEMBERSHIP MOVEMENT" },
  { id: "membershipBreakup", title: "MEMBERSHIP BREAKUP" },
  { id: "upgradeDowngrade", title: "UPGRADE/DOWNGRADE" },
  { id: "fpmembership", title: "FP MEMBERSHIP" },
  { id: "fpVisit", title: "FP VISIT" },
  // financial report
  { id: "payments", title: "PAYMENTS" },
  { id: "directDebit", title: "DIRECT DEBIT" },
  { id: "pifExpiring", title: "PIF EXPIRING" },
  { id: "directDebitFailedPayment", title: "DIRECT DEBIT - FAILED PAYMENT" },
  { id: "refunds", title: "REFUNDS" },
  // executive report
  { id: "saleReport", title: "SALE " },
  { id: "totalMember", title: "TOTAL MEMBERS" },
  { id: "suspendedMember", title: "SUSPENDED MEMBERS" },
  { id: "suspensionDetail", title: "SUSPENSION DETAILS" },
  { id: "totalVisits", title: "TOTAL VISITS " },
  { id: "totalLeavers", title: "LEAVERS" },
  { id: "churnReport", title: "CHURN REPORT" },
];
export default function SpecificReport() {
  const reportId = useParams().id;
  const PageTitle = reports.find((report) => report.id === reportId).title;
  return (
    <div>
      {/* <Link to="/dashboard/report">
        <IoArrowBackCircleOutline />
      </Link> */}
      <Header pageTitle={PageTitle} />
      <div>
        {reportId === "allMembers" && <MemberReport />}
        {reportId === "memberVisits" && <MemberVisitsReport />}
        {reportId === "membershipMovement" && <MembershipMovementReport />}
      </div>
      {reportId === "churnReport" && <TotalChurnReport />}
      {reportId === "saleReport" && <SaleReport />}
      {reportId === "totalVisits" && <TotalMemberVisitsReport />}
      {reportId === "totalLeavers" && <TotalLeaverReport />}
      {reportId === "suspensionDetail" && <SuspensionDetailReport />}
      {reportId === "totalMember" && <TotalMemberReport />}
      {reportId === "suspendedMember" && <SuspendedMembersReport />}
      {reportId === "membershipBreakup" && <MembershipBreakupReport />}
      {reportId === "upgradeDowngrade" && <UpgradeDowngradeReport />}
      {reportId === "fpmembership" && <FPmembershipReport />}
      {reportId === "fpVisit" && <FPVisitReport />}

      {reportId === "payments" && <PaymentsReport />}
      {reportId === "directDebit" && <DirectDebitReport />}
      {reportId === "pifExpiring" && <PIFExpiringReport />}
      {reportId === "directDebitFailedPayment" && <DDFailedPaymentReport />}
      {reportId === "refunds" && <RefundReport />}
    </div>
  );
}
