import { useQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import "./viewRejection.scss";
import { BeatLoader } from "react-spinners";
import { LIST_REJECTIONS } from "../../gqloperations/queries";
import React, { useEffect, useState } from "react";
import * as Papa from "papaparse";
import { toast } from "react-toastify";
import { useMemberContext } from "../../contexts/MemberContext";
import { Table, Header} from "../../components";

const ViewRejection = () => {
  const { id } = useParams();
  const [loadingRejection, setLoadingRejection] = useState(false);
  const [columns, setColumns] = useState([]);
  const [dataTable, setDataTable] = useState([]);
  const { data: rejectionData } = useQuery(LIST_REJECTIONS, {
    onError: (error) => {
      toast.error(`${error}`);
    },
  });

  const { imgUrl, downloadAndDecode } = useMemberContext();

  useEffect(() => {
    rejectionData?.listRejectionProcesses?.items.foreach((item) => {
      if (item.id === id) {
        downloadAndDecode(item.s3Key.replaceAll("public/", ""));
        setLoadingRejection(true);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //parse csv file
  useEffect(() => {
    if (imgUrl) {
      Papa.parse(imgUrl, {
        download: true,
        header: true, // Treat the first row as headers
        dynamicTyping: true, // Automatically detect and convert data types
        complete: function (results) {
          if (results.data.length > 0) {
            const arrColumn = results.meta.fields.map((i) => {
              return { header: i, accessorKey: i, enableSorting: false };
            });
            setColumns(arrColumn);
            setDataTable(results.data.slice(0, -1));
            setLoadingRejection(false);
          }
        },
        error: function (error) {
          console.error("Error parsing CSV:", error);
        },
      });
    }
  }, [imgUrl]);

  return (
    <>
      {loadingRejection ? (
        <div className="dashboard-loading">
          <BeatLoader color="white" />
        </div>
      ) : (
        <>
          <Header pageTitle="Rejection Details" />
          <div className="aba-table">
            <Table columns={columns} data={dataTable} rowsPerPage={10} />
          </div>
        </>
      )}
    </>
  );
};

export default ViewRejection;
