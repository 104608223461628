import "../../components/member/cardInformation.scss";
import React from "react";
import { Formik, Form } from "formik";
import { ADD_CARD_TO_MEMBER } from "../../gqloperations/mutations";
import { GET_CARD_DETAILS_BY_MEMBERID } from "../../gqloperations/queries";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { getUTCdate } from "../../utils/getUTCdate";
import { Input, Button } from "../../components";
import { cardSchema } from "../../validations/Validation";
import { useMemberContext } from "../../contexts/MemberContext";
import { format } from "date-fns";

const AddCardToMember = (props) => {
  const { member } = useMemberContext();
  const [addCardToaMember] = useMutation(ADD_CARD_TO_MEMBER);

  const onSubmit = (values, OnSubmitProps) => {
    let input = values?.endDate
      ? {
          cardNumber: values?.cardNumber,
          endDateTime: getUTCdate(values.endDate),
          memberId: member?.getMember?.memberId,
          operationName: "addCardToMember",
        }
      : {
          cardNumber: values?.cardNumber,
          memberId: member?.getMember?.memberId,
          operationName: "addCardToMember",
        };

    addCardToaMember({
      variables: input,

      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        toast.success("Card created successfully");
        props.onClick();
      },
      onError: (error) => {
        toast.error(`${error}`);
        return;
      },
      refetchQueries: [
        {
          query: GET_CARD_DETAILS_BY_MEMBERID,
          variables: {
            memberId: member.getMember.memberId,
          },
        },
      ],
    });
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        cardNumber: "",
        endDate: "",
      }}
      validationSchema={cardSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <div className="add-card-container mt-12">
            <div className=" ">
              <Form>
                <div className="info-card add-card">
                  <h2 className="fs-14 uppercase">Card Information</h2>
                  <div className="card-input-row">
                    <Input
                      type="text"
                      label="Card Number *"
                      name="cardNumber"
                      id="cardNumber"
                      autoComplete="off"
                    />
                    <Input
                      type="date"
                      label="Card Expiry Date"
                      name="endDate"
                      id="endDate"
                      autoComplete="off"
                      min={`${format(new Date(), "yyyy-MM-dd")}`}
                    />
                  </div>
                </div>

                <div className="btn-group">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-large fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />

                  <Button
                    name="Cancel"
                    btntype="button"
                    btnname="cancel"
                    className="btn btn-deny btn-large fs-12"
                    click={props.onClick}
                  />
                </div>
              </Form>
            </div>
          </div>
        );
      }}
    </Formik>
  );
};

export default AddCardToMember;
