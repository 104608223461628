import "./applyMembershipTo.scss";
import { React, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { AiOutlineSearch } from "react-icons/ai";
import { useLazyQuery } from "@apollo/client";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";
import { toast } from "react-toastify";
import { GET_LOCATION_BY_BRANDID } from "../../gqloperations/queries";
import { ADD_MEMBERSHIP_TO_LOCATION } from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import { Toggle, Table, Checkbox, Header, Modal } from "../../components";

const ApplyMembershipToLocation = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();

  const { locationAccess } = useAuthContext();
  const { sidebarBrandId } = useMemberContext();
  const [details, setDetailsId] = useState("");
  const [nextToken, setNextToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [membershipName, setMembershipName] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [checkedRows, setCheckedRows] = useState({ isAllRowsChecked: false });

  const [addMembershipToLocation, { loading: addMembershipToLocationLoading }] =
    useMutation(ADD_MEMBERSHIP_TO_LOCATION);
  const modalClose = () => {
    setDetailsId("");
  };
  const handleCancel = () => {
    navigate(-1);
  };
  const handleClick = async (tableRows) => {
    const selectedLocationIds = tableRows.map((item) => item.original.id);

    const checkedRowsKeys = Object.keys(checkedRows);
    const filteredLocationIds = selectedLocationIds.filter((loc) => {
      return !checkedRowsKeys.includes(loc);
    });
    try {
      await addMembershipToLocation({
        variables: {
          locationIds: [...filteredLocationIds],
          membershipId: `${id}`,
        },

        onCompleted: () => {
          toast.success("Membership added to location successfully");
          setPaginating(false);
          getLocationByBrandId({
            variables: sidebarBrandId,
          });
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const handleNextToken = () => {
    if (locationData?.getLocationByBrandId?.nextToken) {
      setPaginating(true);
      // setNextToken(locationData?.getLocationByBrandId?.nextToken);
      getLocationByBrandId({
        variables: { brandId: sidebarBrandId, nextToken: nextToken },
      });
    }
  };
  const [isPaginating, setPaginating] = useState(false);
  const [
    getLocationByBrandId,
    { data: locationData, loading: locationLoading, error: locationError },
  ] = useLazyQuery(GET_LOCATION_BY_BRANDID, {
    fetchPolicy: "no-cache",
    variables: { brandId: sidebarBrandId, membershipLimit: 1000, limit: 30 },
    onCompleted: (data) => {
      const nameMembership = data?.getLocationByBrandId?.items
        .flatMap((cur) => {
          const { memberships } = cur;
          if (memberships?.items?.length > 0) {
            const foundItem = memberships.items.find(
              (item) => item?.membershipId === id
            );
            if (foundItem) {
              return foundItem?.membershipDetails?.membershipName;
            }
          }
          return null;
        })
        .filter((item) => item !== undefined && item !== null);

      setMembershipName(nameMembership[0]);

      const matchingLocations = locationAccess?.locationAll.flatMap(
        (curLocationId) => {
          return data?.getLocationByBrandId?.items.filter(
            (cur) => cur.id === curLocationId
          );
        }
      );

      const numOfRows = [...tableData, ...matchingLocations]?.length ?? 0;

      if (numOfRows <= 0) {
        setCheckedRows({ isAllRowsChecked: true });
        setTableData([...tableData, ...matchingLocations]);
      } else {
        const checkedRowsObj = [...tableData, ...matchingLocations]?.reduce(
          (acc, cur) => {
            const doesMembershipIdMatch = cur?.memberships?.items?.find(
              (curMembership) => {
                return curMembership.membershipId === id;
              }
            );

            if (doesMembershipIdMatch) {
              acc[cur.id] = true;
            }

            return acc;
          },
          {}
        );

        const numOfCheckedRows = Object.keys(checkedRowsObj)?.length ?? 0;

        const isAllRowsChecked = numOfCheckedRows === numOfRows;

        checkedRowsObj.isAllRowsChecked = isAllRowsChecked;
        setCheckedRows(checkedRowsObj);
        if (tableData && isPaginating) {
          setTableData([...tableData, ...matchingLocations]);
        } else {
          setTableData([...matchingLocations]);
        }
      }
      setNextToken(data?.getLocationByBrandId?.nextToken);
    },
  });

  useEffect(() => {
    if (sidebarBrandId) {
      getLocationByBrandId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId]);

  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const columns = [
    {
      id: "select",
      header: ({ table }) => {
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              {checkedRows?.isAllRowsChecked ? (
                <Checkbox type="checkbox" disabled={true} />
              ) : (
                // <input type="checkbox" checked disabled />
                <Checkbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              )}
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          {checkedRows?.hasOwnProperty(row.original.id) ? (
            // <input type="checkbox" checked disabled />
            <Checkbox type="checkbox" disabled={true} />
          ) : (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          )}
        </div>
      ),
    },

    {
      accessorFn: (row) => row?.brandDetails?.name ?? "",
      header: "BRAND",
      cell: (info) => {
        const brandName = info.getValue();
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "brandDetails",
    },
    {
      accessorFn: (row) => row?.name ?? "",
      header: "LOCATION NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "locationName",
    },
  ];

  if (locationLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (locationError) {
    return (
      <div>
        <div>Error: Problem getting memberships</div>
      </div>
    );
  }
  return (
    <>
      <div className="apply-membership-to-container">
        <Header pageTitle="Apply to Location" />
        <div className="membership-landing-content">
          {details && (
            <Modal>
              {details === "change-price" && (
                <>
                  <div className="change-price">
                    <h3 className="title fs-35">Change Price</h3>

                    <div className="details-container">
                      <div className="detail">
                        <h3 className="fs-8">Price</h3>
                        <p className="fs-12"></p>
                        <p className="fs-12"></p>
                      </div>
                      <div className="detail ">
                        <Toggle label="ACTIVATION STATUS" />
                      </div>
                    </div>

                    <div className="btn-container">
                      <button
                        className="btn btn-xsmall btn-transparent"
                        onClick={() => modalClose()}
                      >
                        SAVE
                      </button>
                      <button
                        className="btn btn-xsmall btn-transparent-red"
                        onClick={() => modalClose()}
                      >
                        CANCEL
                      </button>
                    </div>
                  </div>
                </>
              )}
            </Modal>
          )}

          <div className="search-and-button-row mt-32 flex-row">
            {locationData?.getLocationByBrandId && (
              <div>
                {membershipName && (
                  <div className="uppercase fs-12">
                    Membership Name: {membershipName}
                  </div>
                )}
              </div>
            )}
            <div className="search-container ">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Search by location name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>

          {locationData?.getLocationByBrandId && (
            <div className="table landing-table">
              {/* {membershipName && (
                <div className="uppercase fs-12">
                  Membership Name: {membershipName}
                </div>
              )} */}
              <Table
                data={tableData}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={columns}
                rowsPerPage={10}
                handleNextToken={handleNextToken}
                nextToken={nextToken}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                rowSelection={rowSelection}
                setRowSelection={setRowSelection}
                handleCancel={handleCancel}
                handleClick={handleClick}
                showTwoBtns={true}
                btnName="Apply"
                isBtnLoading={addMembershipToLocationLoading}
                isBtnDisabled={
                  checkedRows.isAllRowsChecked ||
                  Object.keys(rowSelection).length === 0
                }
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ApplyMembershipToLocation;
