import { Formik, Form } from "formik";
import { CREATE_GROUP } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { useMutation } from "@apollo/client";
import { createGroupSchema } from "../../validations/groupValidation";
import { Header, Button, Input} from "../../components";
import { LIST_GROUPS } from "../../gqloperations/queries";
import { useNavigate } from "react-router-dom";
const AddGroup = () => {
  const navigate = useNavigate();
  const [createGroup] = useMutation(CREATE_GROUP);
  const handleCancel = () => {
    navigate(-1);
  };
  const onSubmit = (values, OnSubmitProps) => {
    createGroup({
      variables: {
        input: {
          name: values.groupName.toUpperCase(),
          description: values.groupDescription,
        },
      },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Group Created Successfully");
        navigate(-1);
      },
      refetchQueries: [
        {
          query: LIST_GROUPS,
        },
      ],
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
    OnSubmitProps.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{
        groupName: "",
        groupDescription: "",
      }}
      validationSchema={createGroupSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Add A Group" />
            <Form>
              <div className="add-group-container info-card mt-32 pd-18 mb-12">
                <div className="mb-24">
                  <h3 className="mb-18">GROUP INFORMATION</h3>
                  <Input
                    type="text"
                    label="Group Name *"
                    name="groupName"
                    autoComplete="off"
                  />
                </div>
                <h3 className="fs-12 text-gray mb-8">Description</h3>
                <textarea
                  className="reason-box fs-12"
                  id="groupDescription"
                  name="groupDescription"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.groupDescription}
                ></textarea>
              </div>
              <div className="right-btn ">
                {/* {formik.isSubmitting && (
                  <Button
                    name={<BeatLoader color="white" size={11} />}
                    btnname="submit"
                    btntype="Update"
                    className="btn btn-large btn-confirm  fs-12"
                    disabled={true}
                  />
                )}
                {!formik.isSubmitting && (
                  <Button
                    name="Save"
                    btnname="submit"
                    btntype="submit"
                    className="btn btn-large btn-confirm  fs-12"
                  />
                )} */}
                <Button
                  loading={formik.isSubmitting}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-large btn-confirm  fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="Cancel"
                  btntype="reset"
                  btnname="submit"
                  className="btn btn-large btn-deny fs-12"
                  click={handleCancel}
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default AddGroup;
