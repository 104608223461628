import "./addlocation.scss";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { state, country } from "../../constants/commonConstant";
import { useMemberContext } from "../../contexts/MemberContext";
import { locationSchema } from "../../validations/LocationValidation";
import { LIST_LOCATION } from "../../gqloperations/queries";
import { ADD_LOCATION } from "../../gqloperations/mutations";
import { locationTypes } from "../../constants/commonConstant";
import { Button, Input, Select, Header } from "../../components";

const AddLocation = () => {
  const navigate = useNavigate();
  const { sidebarBrandId, sidebarBrandName } = useMemberContext();

  const ddDescriptorCreditCardInitialValue = `CC_${sidebarBrandName}_` || "";
  const ddDescriptorBankAccountInitialValue = `DD_${sidebarBrandName}_` || "";

  const [addLocation] = useMutation(ADD_LOCATION);
  const onSubmit = (values, OnSubmitProps) => {
    addLocation({
      variables: {
        input: {
          ...values,
          brandId: sidebarBrandId,
          name: values.name.toUpperCase(),
          address1: values.address1.toUpperCase(),
          state: values.state.toUpperCase(),
          city: values.city.toUpperCase(),
          country: values.country.toUpperCase(),
          postCode: parseInt(values.postCode),
          suburb: values.suburb.toUpperCase(),
          // ddDescriptorCreditCard: values.ddDescriptorCreditCard,
          // ddDescriptorBankAccount: values.ddDescriptorBankAccount,
        },
      },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Location Added Successfully");
        navigate(-1);
      },
      onError: (error) => {
        toast.error(`${error}`);
        return;
      },
      refetchQueries: [
        {
          query: LIST_LOCATION,
        },
      ],
    });
    OnSubmitProps.setSubmitting(false);
  };

  const handleLocationNameChange = (e, formik) => {
    const value = e.target.value;
    formik.handleChange(e);
    formik.setFieldValue(
      "ddDescriptorCreditCard",
      `${ddDescriptorCreditCardInitialValue}${value}`
    );
    formik.setFieldValue(
      "ddDescriptorBankAccount",
      `${ddDescriptorBankAccountInitialValue}${value}`
    );
  };

  return (
    <Formik
      enableReinitialize
      initialValues={{
        name: "",
        shortName: "",
        locationType: "",
        address1: "",
        state: "",
        city: "",
        country: "",
        postCode: "",
        suburb: "",
        ddDescriptorCreditCard: ddDescriptorCreditCardInitialValue,
        ddDescriptorBankAccount: ddDescriptorBankAccountInitialValue,
      }}
      validationSchema={locationSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Add Location" />
            <Form>
              <div className="add-info-container">
                <div className="details-container loc-card">
                  <h3>Location Name</h3>
                  {/* <div>
                    <h3 className="fs-12 text-gray">Brand</h3>
                    <p className="fs-12">{sidebarBrandName}</p>
                  </div> */}

                  <div className="detail loc flex-wrap">
                    <div>
                      <h5 className="fs-12">Brand *</h5>
                      <p className="fs-12"> {sidebarBrandName}</p>
                    </div>
                    {/* <Input
                      type="text"
                      label="Brand *"
                      name="name"
                      id="name"
                      value={sidebarBrandName}
                      autoComplete="off"
                      readOnly={true}
                    /> */}
                    <Input
                      type="text"
                      label="Location Name * "
                      name="name"
                      id="name"
                      autoComplete="off"
                      onChange={(e) => handleLocationNameChange(e, formik)}
                    />
                    <Input
                      type="text"
                      label="Short Name * "
                      name="shortName"
                      id="shortName"
                      autoComplete="off"
                    />
                    <Select
                      label="Location Type"
                      optionvalues={locationTypes}
                      selecttype="array"
                      name="locationType"
                      autoComplete="off"
                      className="select-element-value"
                    />
                  </div>
                </div>
                <div className="details-container loc-card">
                  <h3 className="title">Location Address Details</h3>
                  <div className="address-grid ">
                    <div className="detail address">
                      <Input
                        type="text"
                        label="Address *"
                        name="address1"
                        id="address1"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail city">
                      <Input
                        type="text"
                        label="City *"
                        name="city"
                        id="city"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail state">
                      <Select
                        label="State *"
                        optionvalues={state}
                        selecttype="array"
                        name="state"
                        id="state"
                        autoComplete="off"
                        className="fs-12 select-element-value"
                      />
                    </div>
                    <div className="detail suburb">
                      <Input
                        type="text"
                        label="Suburb *"
                        name="suburb"
                        id="suburb"
                        autoComplete="off"
                      />
                    </div>

                    <div className="detail post">
                      <Input
                        type="text"
                        label="Postcode *"
                        name="postCode"
                        id="postCode"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail country">
                      <Select
                        label="Country *"
                        optionvalues={country}
                        selecttype="array"
                        name="country"
                        id="add-location-country"
                        autoComplete="off"
                        className="fs-12 select-element-value"
                      />
                    </div>
                  </div>
                </div>
                <div className="details-container loc-card">
                  <h3>Bank Details</h3>

                  <div className="detail loc flex-wrap">
                    <Input
                      type="text"
                      label="DD Descriptor Credit Card"
                      name="ddDescriptorCreditCard"
                      id="ddDescriptorCreditCard"
                      autoComplete="off"
                    />
                    <Input
                      type="text"
                      label="DD Descriptor bank account"
                      name="ddDescriptorBankAccount"
                      id="ddDescriptorBankAccount"
                      autoComplete="off"
                    />
                  </div>
                </div>
              </div>
              <div className="save-btn right-btn">
                <Button
                  loading={formik.isSubmitting}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-large fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />

                <Link to="/dashboard/location">
                  <Button
                    name="Cancel"
                    btntype="button"
                    btnname="cancel"
                    className="btn btn-deny btn-large fs-12"
                  />
                </Link>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default AddLocation;
