import { Formik, Form } from "formik";
import { UPDATE_GROUP } from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import { useMutation, useQuery } from "@apollo/client";
import { createGroupSchema } from "../../validations/groupValidation";
import { Input, Header, Button } from "../../components";
import { BeatLoader } from "react-spinners";
import { LIST_GROUPS, GET_GROUP } from "../../gqloperations/queries";
import { useNavigate, useParams } from "react-router-dom";
const EditGroup = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [updateGroup] = useMutation(UPDATE_GROUP);
  const {
    loading: getGroupLoading,
    error: getGroupError,
    data: getGroupData,
  } = useQuery(GET_GROUP, {
    variables: {
      id,
    },
  });
  const handleCancel = () => {
    navigate(-1);
  };
  const onSubmit = (values, OnSubmitProps) => {
    updateGroup({
      variables: {
        input: {
          id,
          name: values.groupName.toUpperCase(),
          description: values.groupDescription,
        },
      },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Group Update Successfully");
        navigate(-1);
      },
      refetchQueries: [
        {
          query: LIST_GROUPS,
        },
      ],
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
    OnSubmitProps.setSubmitting(false);
  };

  if (getGroupLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (getGroupError) {
    return (
      <div>
        <div>Error: Problem getting group information</div>
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        groupName: getGroupData?.getGroup.name ?? "",
        groupDescription: getGroupData?.getGroup.description ?? "",
      }}
      validationSchema={createGroupSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Edit A Group" />
            <Form>
              <div className="edit-group-container info-card mt-32 pd-18 mb-12">
                <div className="mb-24">
                  <h3 className="mb-18">GROUP INFORMATION</h3>
                  <Input
                    type="text"
                    label="Group Name *"
                    name="groupName"
                    autoComplete="off"
                  />
                </div>
                <h3 className="fs-12 text-gray mb-8">Description *</h3>
                <textarea
                  className="reason-box fs-12"
                  id="groupDescription"
                  name="groupDescription"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.groupDescription}
                ></textarea>
              </div>
              <div className="right-btn add-membership">
                {/* {formik.isSubmitting && (
                  <Button
                    name={<BeatLoader color="white" size={11} />}
                    btnname="submit"
                    btntype="Update"
                    className="btn btn-large btn-confirm  fs-12"
                    disabled={true}
                  />
                )}
                {!formik.isSubmitting && (
                  <Button
                    name="Save"
                    btnname="submit"
                    btntype="submit"
                    className="btn btn-large btn-confirm  fs-12"
                  />
                )} */}
                <Button
                  loading={formik.isSubmitting}
                  name={"Submit"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-large btn-confirm  fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="Cancel"
                  btntype="reset"
                  btnname="submit"
                  className="btn btn-large btn-deny fs-12"
                  click={handleCancel}
                />
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default EditGroup;
