import { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import { brandTypes } from "../../../constants/commonConstant";
import { BeatLoader } from "react-spinners";
import getTypeIcon from "../../../utils/getTypeIcon";
import { dateFormatForColumn } from "../../../utils/dateFormatForColumn";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { useCheckMemberProfile } from "../../../hooks/useCheckMemberProfile";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";
import { Table, ExportGroup, GenerateBox } from "../../../components";

export default function RefundReport() {
  const paginationData = useRef();
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const { sidebarLocationId, sidebarBrandId, sidebarBrandName } =
    useMemberContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );
  const { checkMemberProfile } = useCheckMemberProfile();

  useEffect(() => {
    if (sidebarBrandId && sidebarLocationId) handleGenerate(100, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);
  useEffect(() => {
    if (endDate && startDate && generate) {
      handleGenerate(100, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      toast.error(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }
    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  useEffect(() => {
    setGenerate(false);
  }, [sidebarBrandId, sidebarLocationId]);

  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    let parameters = {
      brandId: sidebarBrandId,
      startDate: startDate,
      locationId: sidebarLocationId,
      endDate: endDate,
    };
    if (sidebarLocationId === 0) {
      delete parameters.locationId;
    }
    if (startDate && endDate) {
      const pagination = limit ? { limit: limit, page: page } : undefined;
      if (pagination) parameters = { ...parameters, ...pagination };

      setEndPoint(API.financialRefund);
      setParams(parameters);
    }
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const columns = [
    {
      accessorFn: (row) => {
        return row.surname + ", " + row.givenName;
      },
      header: "SURNAME, NAME",
      cell: (info) => info.getValue(),
      footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "fullName",
    },
    {
      accessorFn: (row) => {
        return row?.aliasMemberId;
      },
      header: "MEMBER ID",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "memberId",
    },
    {
      header: "BRAND",
      cell: (info) => {
        const icon = getTypeIcon(sidebarBrandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "homeClub",
    },
    {
      accessorFn: (row) => {
        return row?.mobileNumber;
      },
      header: "MOBILE NUMBER",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "mobileNumber",
    },
    {
      accessorFn: (row) => {
        return row?.email;
      },
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },

    {
      accessorFn: (row) => {
        return row.refunds
          .map((item) => dateFormatForColumn(item.refundDate))
          .toString();
      },
      header: "Refund Date",
      cell: (info) => {
        const date = info?.getValue().split(",");
        if (date?.length > 1)
          return (
            <details>
              <summary>{date[0]} ,...</summary>
              {date?.map((i, index) => (
                <p key={index}>{index + 1 + ". " + i}</p>
              ))}
            </details>
          );
        else if (date?.length === 1)
          return date?.map((i, index) => <p key={index}>{i}</p>);
        else return "";
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "refundDate",
    },
    {
      accessorFn: (row) => {
        return row.refunds
          .map((item) => item.refundAmount.toFixed(2))
          .toString();
      },
      header: "REFUND AMOUNT",
      cell: (info) => {
        const amount = info?.getValue().split(",");
        if (amount?.length > 1)
          return (
            <details>
              <summary>
                $
                {amount
                  .reduce((total, item) => {
                    return Number(total) + Number(item);
                  }, 0)
                  .toFixed(2)}{" "}
                ,...
              </summary>
              {amount?.map((i, index) => (
                <p key={index}>{index + 1 + ". $" + Number(i).toFixed(2)}</p>
              ))}
            </details>
          );
        else if (amount?.length === 1)
          return amount?.map((i, index) => (
            <p key={index}>${Number(i).toFixed(2)}</p>
          ));
        else return "";
      },
      footer: () => {
        return `$${data?.data?.total.amount.toFixed(2)}`;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "totalRefunds",
    },
  ];
  const footerForExport = {
    totalRefunds: data?.data?.total.amount ?? "",
  };

  return (
    <>
      <GenerateBox
        type="Payment"
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setGenerate={setGenerate}
      />
      {tableData.length && !isLoading ? (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
            footerForExport={footerForExport}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="payment-report-search"
                    id="payment-report-search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by ID, surname or email"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {tableData.length > 0 && (
        <p className="totalCount">TOTAL: {data?.data?.total.records}</p>
      )}

      {isLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : null}
      {!isLoading ? (
        <div className="report-table-container-all">
          <Table
            onRowClick={(i) => {
              checkMemberProfile(i.memberName, i.memberId);
            }}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={true}
            nextToken={tableData.length < data?.data?.total.records}
            handleNextToken={handleNextToken}
          />
        </div>
      ) : null}
    </>
  );
}
