import { useState } from "react";
import { MdOutlineDelete } from "react-icons/md";
import { AiOutlineSearch } from "react-icons/ai";
import {
  DELETE_GROUP_LOCATION,
  DELETE_GROUP_STAFF,
} from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import Table from "../table/Table";
import Modal from "../modal/Modal";
import StatusModal from "../modal/StatusModal";
import getTypeIcon from "../../utils/getTypeIcon";
import { brandTypes } from "../../constants/commonConstant";

const GroupDetailsTable = ({ tableData, searchBy, refetch }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [selectedItem, setSelectedItem] = useState("");
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [deleteGroupLocation] = useMutation(DELETE_GROUP_LOCATION);
  const [deleteGroupStaff] = useMutation(DELETE_GROUP_STAFF);

  const groupLocationsColumns = [
    {
      accessorFn: (row) => {
        if (!row?.locationDetails?.brandDetails?.name) return;
        const brandName = row?.locationDetails?.brandDetails
          ? row?.locationDetails?.brandDetails?.name
          : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      header: "Brand",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "brand",
    },
    {
      accessorFn: (row) =>
        row?.locationDetails?.state || !row?.locationDetails?.state === null
          ? `${row?.locationDetails?.name} (${row?.locationDetails?.state})`
          : `${row?.locationDetails?.name}`,
      header: "Location Name",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },

    {
      id: "row-actions",
      cell: (props) => (
        <span className="button-td">
          <MdOutlineDelete
            onClick={() => setSelectedItem(props.row.original.id)}
            className="delete-icon"
          />
        </span>
      ),
    },
  ];
  const groupStaffsColumns = [
    {
      accessorFn: (row) =>
        `${row?.staffDetails?.givenName} ${row?.staffDetails?.surname}`,
      header: "Staff Name",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      accessorFn: (row) => `${row?.staffDetails?.email}`,
      header: "Email Address",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      id: "row-actions",
      cell: (props) => (
        <span className="button-td">
          <MdOutlineDelete
            onClick={() => setSelectedItem(props.row.original.id)}
            className="delete-icon"
          />
        </span>
      ),
    },
  ];
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleDeleteClick = () => {
    searchBy === "Location Name"
      ? deleteGroupLocation({
          variables: {
            input: {
              id: selectedItem,
            },
          },
          onCompleted: () => {
            toast.success("Delete group location successfully");
            setGlobalFilter("");
            setSelectedItem("");
            refetch();
          },
          onError: (error) => {
            toast.error(`${error}`);
          },
        })
      : deleteGroupStaff({
          variables: {
            input: {
              id: selectedItem,
            },
          },
          onCompleted: () => {
            toast.success("Delete group staff successfully");
            setSelectedItem("");
            refetch();
          },
          onError: (error) => {
            toast.error(`${error}`);
          },
        });
  };
  return (
    <>
      <div className="search-container">
        <form onSubmit={onSearchBarSubmit}>
          <div className="search-bar">
            <div className="search-bar-container">
              <input
                className="fs-10"
                type="text"
                name="search"
                id="search"
                value={searchBarInputVal}
                onChange={handleChange}
                placeholder={searchBy}
              />
              <button type="submit">
                <AiOutlineSearch />
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="table landing-table">
        <div className="table">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={
              searchBy === "Location Name"
                ? groupLocationsColumns
                : groupStaffsColumns
            }
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        </div>
      </div>
      {selectedItem && (
        <Modal>
          <StatusModal
            modalHeaderTxt={
              searchBy === "Location Name"
                ? "Delete Group Location"
                : "Delete Group Staff"
            }
            modalParaText={
              searchBy === "Location Name"
                ? "Are you sure you want to delete this group location"
                : "Are you sure you want to delete this group staff"
            }
            handleStatusClick={handleDeleteClick}
            modalClose={() => setSelectedItem("")}
          />
        </Modal>
      )}
    </>
  );
};

export default GroupDetailsTable;
