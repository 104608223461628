import "./studio.scss";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { MdOutlineDelete } from "react-icons/md";
import {
  UPDATE_LOCATION_STUDIO,
  DELETE_LOCATION_STUDIO,
} from "../../../../gqloperations/mutations";
import Table from "../../../table/Table";
import Button from "../../../formUiElements/Button";
import StatusModal from "../../../modal/StatusModal";
import Modal from "../../../modal/Modal";
import Toggle from "../../../formUiElements/Toggle";
import { useLocationContext } from "../../../../contexts/LocationContext";

export default function Studio(props) {
  let navigate = useNavigate();
  const { state, getLocationById } = useLocationContext();
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [sorting, setSorting] = useState([]);
  const [updateStudio] = useMutation(UPDATE_LOCATION_STUDIO);
  const [deleteStudio] = useMutation(DELETE_LOCATION_STUDIO);
  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  const handleRowClick = (id) => {
    navigate(`/dashboard/location/editstudio/${id}`);
  };

  const handleStatusClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
      isActive: !rowObj.isActive,
    };
    try {
      await updateStudio({
        variables: {
          input: values,
        },
        onCompleted: () => {
          getLocationById();
          toast.success("Updated successfully");
          modalClose();
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const handleDeleteClick = async (rowObj) => {
    try {
      await deleteStudio({
        variables: {
          input: {
            id: rowObj.id,
          },
        },
        onCompleted: () => {
          getLocationById();
          toast.success("Deleted successfully");
          modalClose();
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const studiosData = state.location?.getLocation?.studios?.items;
  const columns = [
    {
      accessorKey: "studioName",
      header: "STUDIO NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "isActive",
      header: "ACTIVE",
      cell: (info) => {
        return (
          <Toggle
            checked={info.row.original.isActive}
            value={info.row.original.isActive}
            onChange={() => {
              handleComponent("status-confirm", {
                id: info.row.original.id,
                className: info.row.original.className,
                isActive: info.row.original.isActive,
              });
            }}
          />
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "active",
    },

    {
      id: "row-actions",
      cell: (props) => (
        <>
          <div className="pin-btn-container">
            <AiOutlineEdit
              onClick={() => {
                handleRowClick(props.row.original.id);
              }}
            />
            <MdOutlineDelete
              onClick={() => {
                handleComponent("delete-template", {
                  id: props?.row?.original?.id,
                });
              }}
              className="delete-icon"
            />
          </div>
        </>
      ),
    },
  ];

  return (
    <>
      <div className="studio-page-container">
        <div className="right-btn pb-12">
          <Link to="/dashboard/location/addStudio/">
            <Button
              name="add studio"
              btntype="submit"
              btnname="submit"
              className="btn btn-primary fs-12"
            />
          </Link>
        </div>
        {componentName && (
          <Modal>
            {componentName === "delete-template" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={"Delete Studio"}
                modalParaText={"Are you sure you want to delete this studio?"}
                handleStatusClick={handleDeleteClick}
                modalClose={modalClose}
              />
            )}
            {componentName === "status-confirm" && (
              <StatusModal
                curRow={curRow}
                className={curRow.className}
                modalHeaderTxt={
                  curRow.isActive ? "Deactivate Studio" : "Activate Studio"
                }
                modalParaText={`Are you sure you want to ${
                  curRow.isActive ? "deactivate" : "activate"
                } this studio?`}
                handleStatusClick={handleStatusClick}
                modalClose={modalClose}
              />
            )}
          </Modal>
        )}
        <div>
          {studiosData && (
            <Table
              data={studiosData}
              className="pincode-table"
              sorting={sorting}
              setSorting={setSorting}
              columns={columns}
              rowsPerPage={10}
            />
          )}
        </div>
      </div>
    </>
  );
}
