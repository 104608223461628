import React from "react";
import { useRef } from "react";
import { useMemberContext } from "../../contexts/MemberContext";
import { toast } from "react-toastify";
import Select from "../formUiElements/select/Select";
import { GET_CLASS_INSTRUCTOR } from "../../gqloperations/queries";
import { certificates } from "../../constants/commonConstant";
import { CREATE_INSTRUCTOR_CREDENTIAL } from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";

import { v4 as uuidv4 } from "uuid";

const UploadCredential = ({ instructorId }) => {
  const fileInputRef = useRef();
  // const [file, setFile] = useState(false);
  const { encodeAndUploadImageToAws: uploadImageToAws } = useMemberContext();

  const [createInstructorCredential] = useMutation(
    CREATE_INSTRUCTOR_CREDENTIAL
  );

  const handleImageUpload = async (e, formik) => {
    const file = e.target.files[0];
    const extension = file.name.split(".");
    const extensionName = extension[extension.length - 1];

    if (file.size > 5_242_880) {
      toast.error("Image needs to be less than 5MB");
    } else {
      // setFile(file);
      // const clientSideImgUrl = URL.createObjectURL(file);
      // setImgUrl(clientSideImgUrl);

      const updatedKey = `/instructors/credentials/${uuidv4()}.${extensionName}`;

      const uploadedFile = await uploadImageToAws(file, updatedKey);

      const imageUrl = uploadedFile?.key;
      let input = {
        instructorId,
        imageUrl,
      };
      if (!formik.values.credentialDescription) {
        input = {
          ...input,
          credentialName: `${formik.values.credentialName}`,
        };
      } else {
        input = {
          ...input,
          credentialName: `${formik.values.credentialName}`,
          credentialDescription: `${formik.values.credentialDescription}`,
        };
      }

      createInstructorCredential({
        variables: {
          input,
        },
        onCompleted: () => {
          toast.success("Credential updated successfully");
        },
        refetchQueries: [
          {
            query: GET_CLASS_INSTRUCTOR,
            variables: {
              id: `${instructorId}`,
            },
          },
        ],
        onError: (err) => {
          toast.error(`${err}`);
        },
      });
    }
  };
  const handleImgUploadBtn = (e) => {
    e.preventDefault();

    fileInputRef.current.click();
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          imageUrl: "",
          credentialName: "",
          credentialDescription: "",
        }}
        onSubmit={async (values, OnSubmitProps) => {}}
        validate={(values, props) => {
          const { credentialName, credentialDescription } = values;
          let errors = {};
          if (!credentialName || credentialName.length === 0) {
            const errorText = "Please choose credential type";
            errors.credentialName = errorText;
          }
          if (credentialName === "Other" && !credentialDescription) {
            const errorText = "Please provide credential description";
            errors.credentialDescription = errorText;
          }

          console.log(errors);
          return errors;
        }}
      >
        {(formik) => {
          return (
            <div className="instructor-description-container">
              <Form
                className="first-card pd-18"
                id="instructor-description-form"
              >
                <div className="credential-total-container">
                  <div className="upload-container">
                    <div className="title-bar mb-18">
                      <h3 className="fs-14 uppercase">Upload Credential</h3>
                    </div>
                    <div className="upload-item flex-wrap">
                      <div className="selector">
                        <Select
                          label="Credential"
                          optionvalues={certificates}
                          selecttype="array"
                          name="credentialName"
                          id="credentialName"
                          autoComplete="off"
                          className="fs-12"
                        />
                      </div>
                      {formik.values.credentialName ===
                        certificates[certificates.length - 1] && (
                        <div className="">
                          <label
                            htmlFor="credentialDescription"
                            className="fs-12"
                          >
                            Credential Description *
                          </label>
                          <textarea
                            className="fs-8 text-box"
                            id="credentialDescription"
                            name="credentialDescription"
                            onBlur={formik.handleBlur}
                            onChange={formik.handleChange}
                            value={formik.values.credentialDescription}
                          ></textarea>
                          {formik.touched.credentialDescription &&
                            formik.errors.credentialDescription && (
                              <div className="fs-12 error">
                                {formik.errors.credentialDescription}
                              </div>
                            )}
                        </div>
                      )}
                      <input
                        type="file"
                        onChange={(e) => handleImageUpload(e, formik)}
                        accept="image/*,application/pdf"
                        ref={fileInputRef}
                        className="staff-img-input pb-12"
                      />

                      <button
                        className={`${
                          formik.values.credentialName === "Other" &&
                          !formik.values.credentialDescription
                            ? "btn-grey"
                            : "btn btn-large btn-confirm"
                        }`}
                        onClick={handleImgUploadBtn}
                        disabled={
                          formik.values.credentialName === "Other" &&
                          !formik.values.credentialDescription
                        }
                      >
                        Upload Document
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
};

export default UploadCredential;
