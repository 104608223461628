import "./medicalSuspension.scss";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import actions from "../../assets/images/icons/action_menu.png";
import { useMutation, useQuery } from "@apollo/client";
import { useMemberContext } from "../../contexts/MemberContext";
import { GET_MEMBER_CONTRACT_SUSPENSION_BY_LOCATIONID } from "../../gqloperations/queries";
import { MEDICAL_SUSPENSION_OPERATION } from "../../gqloperations/mutations";
import { BeatLoader } from "react-spinners";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import { StatusModal, Modal, Button, Table, Header } from "../../components";
import { toast } from "react-toastify";
import { sortDesc } from "../../utils/sort";
import { utcToZonedTime } from "date-fns-tz";

function MedicalSuspension() {
  const ref = useRef();
  const navigate = useNavigate();
  const { createLog, createAuditLog, sidebarLocationId, member } =
    useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [componentName, setComponentName] = useState("");
  const [tableData, setTableData] = useState([]);
  const [isPaginating, setIsPaginating] = useState(false);
  const [curRow, setCurRow] = useState({});
  const [medicalSuspensionOperation, { loading: suspensionOperationLoading }] =
    useMutation(MEDICAL_SUSPENSION_OPERATION);

  const {
    data: getMemberContractSuspension,
    loading: getMemberContractSuspensionLoading,
    error: getMemberContractSuspensionError,
    refetch,
  } = useQuery(GET_MEMBER_CONTRACT_SUSPENSION_BY_LOCATIONID, {
    fetchPolicy: "no-cache",
    variables: {
      limit: 10000,
      locationId: sidebarLocationId,
      // sortDirection: "DESC",

      filter: {
        cancelledDateTime: { attributeExists: false },
        suspensionStatus: { eq: "PENDING" },
        suspensionType: { eq: "MEDICAL" },
      },
      //TODO: Need backend fixing sortDirection, then change the limit argument per requirements.
    },
  });
  const handleNextToken = () => {
    if (nextToken) {
      setIsPaginating(true);
      refetch({
        nextToken: nextToken,
      });
    }
  };

  useEffect(() => {
    createLog("CLICKED Medical Suspension tab on the sidebar", "sidebar");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getMemberContractSuspension) {
      const { items, nextToken } =
        getMemberContractSuspension?.getMemberContractSuspensionByLocationId;
      if (tableData && isPaginating) {
        const sortedCombinedItems = sortDesc(
          [...tableData, ...items],
          "createdAt"
        );
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortDesc([...items], "createdAt");
        setTableData(sortedItems);
      }

      setIsPaginating(false);
      setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getMemberContractSuspension]);

  const handleView = (medicalSuspensionId) => {
    navigate(
      `/dashboard/medicalsuspensions/viewmedicalsuspension/${medicalSuspensionId}`
    );
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const columns = [
    {
      accessorFn: (row) => {
        const memberDetails = row.memberDetails;
        const memberName =
          memberDetails?.givenName + " " + memberDetails?.surname;
        return memberName;
      },
      header: "NAME",

      cell: (info) => {
        const memberDetails = info.getValue();
        return memberDetails;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      cell: (info) => {
        if (!info.getValue()) {
          return "";
        }
        const createdAt = new Date(info.getValue());
        const result = format(new Date(createdAt ?? ""), "d-LLL-yyyy p");
        return result;
      },
      header: "SUBMITTED",
      enableSorting: true,
      enableGlobalFilter: false,
      id: "createdAt",
      accessorKey: "createdAt",
    },
    {
      accessorKey: "suspensionStartDateTime",
      header: "START DATE",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionStartDateTime",
      cell: (info) => {
        return info?.getValue()
          ? format(
              utcToZonedTime(
                info.getValue(),
                info.row.original.contractDetails.timezone ?? "Australia/sydney"
              ),
              "dd/MM/yyyy"
            )
          : "";
      },
    },
    {
      header: "END DATE",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionEndDateTime",
      accessorKey: "suspensionEndDateTime",
      cell: (info) => {
        return info?.getValue()
          ? format(
              utcToZonedTime(
                info.getValue(),
                info.row.original.contractDetails.timezone ?? "Australia/sydney"
              ),
              "dd/MM/yyyy"
            )
          : "";
      },
    },
    {
      header: "STATUS",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "status",
      accessorKey: "status",
      cell: () => {
        return "AWAITING VERIFICATION";
      },
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prevShowActions) => ({
                  ...prevShowActions,
                  [id]: !areActionsVisible,
                }));
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() => {
                      handleComponent("approve-confirm", {
                        id: id,
                        suspensionInfo: props?.row?.original,
                      });
                    }}
                  >
                    <Button
                      name="APPROVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleComponent("decline-confirm", {
                        id: id,
                        suspensionInfo: props?.row?.original,
                      });
                    }}
                  >
                    <Button
                      name="DECLINE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleView(id);
                    }}
                  >
                    <Button
                      name="VIEW SUSPENSION"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const handleApproveClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
    };
    try {
      await medicalSuspensionOperation({
        variables: {
          operation: "REVIEW_SUSPENSION",
          suspensionId: values.id,
          suspensionStatus: "APPROVED",
        },
        onCompleted: () => {
          refetch();
          createAuditLog(
            {
              suspensionId: values.id,
              operation: "REVIEW_SUSPENSION",
              suspensionStatus: "APPROVED",
            },
            {
              operation: "",
              suspensionId: values.id,
              suspensionStatus: curRow.suspensionInfo.suspensionStatus,
            },
            curRow.suspensionInfo.memberId,
            "UPDATE",
            "suspendMedicalMembership"
          );
          setIsPaginating(false);
          setNextToken(null);

          toast.success("Suspension approve Successfully ");
          modalClose();
        },
        onError: (error) => {
          toast.error(`${error}`);
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleDeclineClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
    };
    try {
      await medicalSuspensionOperation({
        variables: {
          operation: "REVIEW_SUSPENSION",
          suspensionId: values.id,
          suspensionStatus: "REJECTED",
        },
        onCompleted: () => {
          createAuditLog(
            {
              operation: "REVIEW_SUSPENSION",
              suspensionId: values.id,
              suspensionStatus: "REJECTED",
            },
            {
              suspensionId: values.id,
              suspensionStatus: curRow.suspensionInfo.suspensionStatus,
            },
            curRow.suspensionInfo.memberId,
            "UPDATE",
            "suspendMedicalMembership"
          );
          setIsPaginating(false);
          setNextToken(null);
          refetch();
          toast.success("Suspension reject Successfully ");
          createLog(
            `Clicked approve button on medical suspension for suspensionID: ${values.id}`,
            "member",
            `${member.getMember.memberId}`
          );
          modalClose();
        },
        onError: (error) => {
          toast.error(`${error}`);
          createLog(
            `Clicked approve button on medical suspension for suspensionID: ${values.id}`,
            "member",
            `${member.getMember.memberId}`
          );
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  if (!sidebarLocationId) {
    return (
      <div className="no-data-holder">
        <div className="no-data">
          <h3 className="fs-12 uppercase">
            Please Choose a Club Location First
          </h3>
        </div>
      </div>
    );
  }
  if (getMemberContractSuspensionLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );

  if (getMemberContractSuspensionError) {
    return (
      <div>
        <div>Problem in getting memberships</div>
      </div>
    );
  }
  return (
    <div className="medicalSuspension-container">
      <Header pageTitle="Medical Suspension" />
      {componentName && (
        <Modal>
          {componentName === "approve-confirm" && (
            <StatusModal
              loading={suspensionOperationLoading}
              curRow={curRow}
              modalHeaderTxt={"APPROVE MEDICAL SUSPENSION"}
              modalParaText={
                "ARE YOU SURE YOU WANT TO APPROVE THIS MEMBERSHIP SUSPENSION?"
              }
              handleStatusClick={handleApproveClick}
              modalClose={modalClose}
            />
          )}

          {componentName === "decline-confirm" && (
            <StatusModal
              loading={suspensionOperationLoading}
              curRow={curRow}
              modalHeaderTxt={`DECLINE MEDICAL SUSPENSION`}
              modalParaText={
                "ARE YOU SURE YOU WANT TO DECLINE THIS MEMBERSHIP SUSPENSION?"
              }
              handleStatusClick={handleDeclineClick}
              modalClose={modalClose}
            />
          )}

          {/* {componentName === "status-confirm" && (
              <StatusModal
                curRow={curRow}
                modalHeaderTxt={`${
                  curRow.isActive ? "deactivate" : "activate"
                } Membership`}
                modalParaText={`Are you sure you want to ${
                  curRow.isActive ? "deactivate" : "activate"
                } this membership?`}
                handleStatusClick={handleStatusClick}
                modalClose={modalClose}
              />
            )} */}
        </Modal>
      )}
      <div className="actions-row mt-32 feature-row ">
        <div className="right-elements">
          <div className="search-and-button-row">
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Member name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div className="landing-table">
        {
          <Table
            onRowClick={(i) => handleView(i.id)}
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
            setSorting={setSorting}
            rowsPerPage={10}
            handleNextToken={handleNextToken}
            nextToken={nextToken}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
          />
        }
      </div>
    </div>
  );
}

export default MedicalSuspension;
