import { AiOutlineSearch } from "react-icons/ai";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useTableStateProps } from "../../../hooks/useTableStateprops";
import { BeatLoader } from "react-spinners";
import getTypeIcon from "../../../utils/getTypeIcon";
import { brandTypes } from "../../../constants/commonConstant";
import MembershipOptionModal from "./MembershipOptionModal";
import { Modal, Button, Table, Header } from "../../../components";
import { useParams } from "react-router-dom";
import {
  GET_VOUCHER_BY_ID,
  SEARCH_MEMBER,
  GET_VOUCHER_LOCATION,
} from "../../../gqloperations/queries";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import isSameDay from "date-fns/isSameDay";
import isBefore from "date-fns/isBefore";
import isAfter from "date-fns/isAfter";
import { CALCULATE_VOUCHER_AMOUNT } from "../../../gqloperations/mutations";
const AddVoucherToMember = () => {
  const { locationAccess } = useAuthContext();
  const { voucherId } = useParams();
  const [searchValue, setSearchValue] = useState("");
  const [nextToken, setNextToken] = useState(null);
  const [tableData, setTableData] = useState();
  const [modalOpen, setModalOpen] = useState(false);
  const [memberContracts, setMemberContracts] = useState([]);
  const [memberDetail, setMemberDetail] = useState({});
  const [amount, setAmount] = useState(0);
  const [locationsToSearch, setLocationsToSearch] = useState([]);
  const {
    globalFilter,
    setGlobalFilter,
    columnFilters,
    setColumnFilters,
    sorting,
    setSorting,
  } = useTableStateProps();
  const [calculateVoucherAmount, { loading: calculateVoucherLoading }] =
    useMutation(CALCULATE_VOUCHER_AMOUNT);
  const { data: fetchVoucherLocation } = useQuery(GET_VOUCHER_LOCATION, {
    fetchPolicy: "no-cache",
    variables: {
      voucherId,
    },
  });

  const { data: fetchVoucherData } = useQuery(GET_VOUCHER_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: voucherId,
    },
    onCompleted: (data) => {},
  });

  const [
    searchMembers,
    {
      loading: searchMembersLoading,
      error: searchMembersError,
      data: searchMembersData,
      refetch,
    },
  ] = useLazyQuery(SEARCH_MEMBER, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    const accessibleLocationsWithVoucherApplied =
      locationAccess?.locationAll?.filter((location) => {
        const doesLocationMatch =
          fetchVoucherLocation?.getVoucherLocationByVoucherId?.items.find(
            (cur) => {
              return cur?.locationDetail?.id === location;
            }
          );

        return doesLocationMatch;
      });

    const homeLocationIds = accessibleLocationsWithVoucherApplied?.map(
      (matchedLocation) => {
        return { homeLocationId: { eq: matchedLocation } };
      }
    );

    setLocationsToSearch(homeLocationIds);
  }, [searchValue]);

  const getSearchMemberVariables = () => {
    setGlobalFilter("");
    if (searchValue.includes("@")) {
      return {
        filter: {
          email: { eq: `${searchValue.toUpperCase()}` },
          // homeLocationId: { eq: `${findLocationAccessVoucherLocation}` },
          and: {
            or: locationsToSearch,
          },
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: 500,
      };
    } else if (/\d/.test(searchValue)) {
      let value = searchValue;
      if (searchValue.startsWith("+")) {
        value = searchValue.replace("+", "");
      }
      if (searchValue.startsWith("0")) {
        value = searchValue.replace("0", "");
      }
      return {
        filter: {
          mobileNumber: { wildcard: `*${value}*` },
          and: {
            or: locationsToSearch,
          },
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: 500,
      };
    } else {
      return {
        filter: {
          and: {
            or: locationsToSearch,
          },
          or: [
            {
              givenName: { wildcard: `*${searchValue.toUpperCase()}*` },
            },
            { surname: { wildcard: `*${searchValue.toUpperCase()}*` } },
          ],
        },
        sort: [{ field: "createdAt", direction: "desc" }],
        limit: 500,
      };
    }
  };
  const selectedMemberData = async (memberObj) => {
    const currentDate = new Date();

    const homeLocationId = memberObj?.homeLocationDetails?.id;
    const membershipLocation =
      fetchVoucherData?.getVoucher?.membershipDetail?.membershipLocations.items.filter(
        (item) => item.locationId === homeLocationId
      );
    const isMemberContractActive = memberObj?.contracts?.items?.filter(
      (curContract) => {
        const isContractCancelled = isAfter(
          new Date(),
          new Date(curContract?.expiryDateTime)
        );

        const isMemberContractSuspended = curContract?.suspensions?.items.some(
          (curSuspension) => {
            const { suspensionStartDateTime, suspensionEndDateTime } =
              curSuspension || {};
            const isContractInActiveSus =
              (isBefore(new Date(suspensionStartDateTime), currentDate) &&
                isAfter(new Date(suspensionEndDateTime), currentDate)) ||
              isSameDay(new Date(suspensionStartDateTime), currentDate) ||
              isSameDay(new Date(suspensionEndDateTime), currentDate);
            return (
              isContractInActiveSus &&
              isBefore(new Date(), new Date(curSuspension?.expiryDateTime))
            );
          }
        );
        return (
          (!isContractCancelled || !curContract?.expiryDateTime) &&
          !isMemberContractSuspended
        );
      }
    );
    try {
      const result = await calculateVoucherAmount({
        variables: {
          input: {
            action: "CALCULATE_VOUCHER_AMOUNT",
            data: {
              memberId: memberObj?.memberId,
              voucherId: fetchVoucherData?.getVoucher.id,
              contractId: isMemberContractActive[0]?.id,
              membershipLocationId: membershipLocation[0]?.id,
            },
          },
        },
      });
      if (result) {
        setAmount(result.data?.calculateVoucherAmount.data.totalFee);
      }
    } catch (error) {
      toast.error(`${error}`);
    }

    setMemberContracts(isMemberContractActive);
    return isMemberContractActive;
  };
  const handleNextToken = () => {
    setNextToken(searchMembersData?.searchMembers?.nextToken);
    if (searchMembersData?.searchMembers?.nextToken) {
      searchMembers({
        variables: {
          nextToken: searchMembersData?.searchMembers?.nextToken,
          ...getSearchMemberVariables(),
        },
        onCompleted: (data) => {
          setTableData([...tableData, ...data?.searchMembers?.items]);
          setNextToken(searchMembersData?.searchMembers?.nextToken);
        },
        onError: (error) => {
          toast.error(`${error}`);
        },
      });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    searchMembers({
      variables: getSearchMemberVariables(),
      onCompleted: (data) => {
        setTableData(data?.searchMembers?.items);
        setNextToken(searchMembersData?.searchMembers?.nextToken);
        setColumnFilters([]);
        refetch();
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
  };

  const handleClick = (memberObj) => {
    selectedMemberData(memberObj);
    setModalOpen(true);
    setMemberDetail(memberObj);
  };

  const columns = [
    {
      accessorKey: "brandDetails",
      header: "BRAND",
      cell: (info) => {
        const brandName = info.getValue() ? info.getValue().name : "";
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "branddetails",
    },
    {
      accessorFn: (row) => `${row?.homeLocationDetails?.name} (${row?.state})`,
      header: () => {
        return <span> HOME CLUB</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "homeLocationDetails",
    },

    {
      accessorFn: (row) => `${row?.surname}, ${row?.givenName}`,
      header: () => {
        return <span> SURNAME, NAME</span>;
      },
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "surname",
      filterFn: "stringColumnFilter",
      enableColumnFilter: true,
    },

    {
      accessorKey: "mobileNumber",
      header: "MOBILE",
      cell: (info) => {
        const mobile = info.getValue();
        return `${mobile?.slice(0, 3)}***${mobile?.slice(-2)}`;
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "mobile",
      filterFn: "includesString",
      enableColumnFilter: true,
    },
    {
      accessorKey: "email",
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
      filterFn: "includesString",
      enableColumnFilter: true,
    },
    {
      id: "row-actions",
      cell: (props) => {
        const { voucherMembers } = props.row.original;
        const isVoucherApplied = voucherMembers?.items.some(
          (cur) =>
            cur.voucherId === voucherId &&
            (cur.status === "ACTIVE" || !cur.status)
        );
        return (
          <Button
            name="apply"
            btntype="submit"
            btnname="submit"
            className={`btn btn-${
              isVoucherApplied ? "disabled" : "large btn-transparent"
            } `}
            click={() => handleClick(props.row.original)}
            disabled={isVoucherApplied}
            loading={
              calculateVoucherLoading &&
              props.row.original.memberId === memberDetail.memberId
            }
          />
        );
      },
    },
  ];

  if (searchMembersLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (searchMembersError) {
    return (
      <div>
        <div>Error: Problem in getting members</div>
      </div>
    );
  }
  return (
    <>
      <div className="apply-membership-to-container">
        <Header pageTitle="Apply to Member" />

        <div className="header-voucher">
          <p className="pl-42 voucher-code">
            {fetchVoucherData?.getVoucher?.voucherCode}
          </p>
          <div className="search-container">
            <form onSubmit={handleSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchValue}
                    onChange={(e) => setSearchValue(e.target.value)}
                    placeholder="first name, surname, mobile or email"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        {tableData && (
          <Table
            data={tableData}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            handleNextToken={handleNextToken}
            nextToken={searchValue ? nextToken : null}
            sorting={sorting}
            setSorting={setSorting}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
          />
        )}
        {modalOpen && fetchVoucherData && !calculateVoucherLoading && (
          <Modal>
            <MembershipOptionModal
              modalClose={() => setModalOpen(false)}
              memberDetail={memberDetail}
              memberContracts={memberContracts}
              voucherDetails={fetchVoucherData?.getVoucher}
              refetch={refetch}
              membershipDetails={fetchVoucherData?.getVoucher?.membershipDetail}
              fetchVoucherData={fetchVoucherData}
              amount={amount}
            />
          </Modal>
        )}
      </div>
    </>
  );
};

export default AddVoucherToMember;
