import { NavLink } from "react-router-dom";
const SidebarItem = ({
  link,
  anchorLink,
  target,
  onClick,
  itemName,
  classname,
}) => {
  return (
    <>
      {link && (
        <NavLink className={link} end={link === "/dashboard"} activeClassName="active" to={link}>
          <li className={`${classname} fs-14`}>{itemName}</li>
        </NavLink>
      )}
      {!link && !anchorLink && (
        <li className={`${classname} fs-14`} onClick={onClick}>
          {itemName}
        </li>
      )}
      {anchorLink && (
        <a href={anchorLink} target={target}>
          <li className={`${classname} fs-14`} onClick={onClick}>
            {itemName}
          </li>
        </a>
      )}
    </>
  );
};

export default SidebarItem;
