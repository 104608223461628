import Table from "../../../table/Table";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_SETTLEMENT_REPORT_BY_LOCATION_ID } from "../../../../gqloperations/queries";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { FiDownload } from "react-icons/fi";
import "./settlementreport.scss";
import { BeatLoader } from "react-spinners";
import { RiMailSendLine } from "react-icons/ri";
import { isAfter } from "date-fns";
import { SEND_SETTLEMENT_REPORT } from "../../../../gqloperations/mutations";
import { useLocationContext } from "../../../../contexts/LocationContext";

function SettlementReport({ locationId, locationDetails }) {
  const [sendSettlementEmail] = useMutation(SEND_SETTLEMENT_REPORT);
  const [getSettleMentReport, { data, error }] = useLazyQuery(
    GET_SETTLEMENT_REPORT_BY_LOCATION_ID
  );
  const { state } = useLocationContext();
  const [tableData, setTableData] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [nextToken, setNextToken] = useState();

  const [sendEmailLoading, setSendEmailLoading] = useState({});

  const handleSendEmail = async (id) => {
    setSendEmailLoading({ ...sendEmailLoading, [id]: true });
    try {
      await sendSettlementEmail({
        variables: {
          input: {
            operation: "SEND_SETTLEMENT_REPORT",
            settlementReportId: id,
          },
        },
      });
      toast.success("Email successfully sent");
    } catch (err) {
      toast.error(err.message);
    } finally {
      setSendEmailLoading({ ...sendEmailLoading, [id]: false });
    }
  };
  const columns = [
    {
      accessorFn: (row) => row?.startDate?.split("-").reverse().join("/"),
      header: "START DATE",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "startDate",
    },
    {
      accessorFn: (row) => row?.endDate?.split("-").reverse().join("/"),
      header: "END DATE",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "endDate",
    },
    {
      accessorFn: () => locationDetails?.settlementEmail ?? "",
      header: "SETTLEMENT EMAIL",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      cell: (props) => (
        <div className="email-btn-container">
          <div className="beat-or-send">
            <span className="tooltip">
              {sendEmailLoading[props.row.original.id] ? (
                <BeatLoader className="loading" color="white" size={4} />
              ) : (
                <RiMailSendLine
                  className={
                    sendEmailLoading[props.row.original?.id] ||
                    locationDetails?.settlementEmail === "" ||
                    locationDetails?.settlementEmail === null ||
                    locationDetails?.settlementEmail === undefined
                      ? "disabled"
                      : ""
                  }
                  onClick={() => {
                    if (!sendEmailLoading[props.row.original?.id]) {
                      handleSendEmail(props.row.original?.id);
                    }
                  }}
                />
              )}
              <span className="tooltip-text fs-10">Send EMAIL</span>
            </span>
          </div>
        </div>
      ),
      header: "SEND EMAIL",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "actioncell",
    },
  ];
  const [isPaginating, setIsPaginating] = useState(false);
  const handleNextToken = () => {
    if (nextToken) {
      setIsPaginating(true);
      getSettleMentReport({
        variables: { locationId: locationId, nextToken: nextToken },
      });
    }
  };
  useEffect(() => {
    if (state.index === 9 && locationId && locationId !== "")
      getSettleMentReport({
        variables: { locationId: locationId },
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.index]);
  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.startDate), new Date(b.startDate));
      return result ? -1 : 1;
    });
  };
  useEffect(() => {
    if (data && data?.getSettlementReportByLocationId) {
      const { items, nextToken } = data?.getSettlementReportByLocationId;

      if (tableData && isPaginating) {
        const sortedCombinedItems = sortByCreatedAtDesc([
          ...tableData,
          ...items,
        ]);
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortByCreatedAtDesc([...items]);
        setTableData(sortedItems);
      }

      setIsPaginating(false);
      setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  if (error) return <p>SORRY THERE IS AN ERROR WHEN FETCHING DATA</p>;
  return (
    <div className="settlement-report-tab">
      <Table
        data={tableData}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
        sorting={sorting}
        setSorting={setSorting}
        columns={columns}
        rowsPerPage={10}
        columnFilters={columnFilters}
        setColumnFilters={setColumnFilters}
        handleNextToken={handleNextToken}
        nextToken={nextToken}
      />
    </div>
  );
}

export default SettlementReport;
