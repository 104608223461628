import React, { useEffect, useState } from "react";
import InputWithoutFormik from "../formUiElements/input/InputWithoutFormik";
import { Formik, Form, Field } from "formik";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import { useMemberContext } from "../../contexts/MemberContext";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";
import {
  ADD_MEMBER_PAYMENT_INFORMATION,
  CHARGE_SINGLE_PAYMENT,
} from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import Modal from "./Modal";
import "./modal.scss";

const MakePaymentActivateModal = (props) => {
  const { member, sidebarBrandId } = useMemberContext();
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);
  const [addMemberPaymentInfo, { loading: addMemberPaymentInfoLoading }] =
    useMutation(ADD_MEMBER_PAYMENT_INFORMATION);

  const [paymentType, setPaymentType] = useState("cardOnFile");
  const [openVivaPay, setOpenVivaPay] = useState(false);
  const [cardRequestId, setCardRequestId] = useState("");
  const [paymentInformation, setPaymentInformation] = useState({});
  const iframeSrc = `${process.env.REACT_APP_VIVAPAY_IFRAME}/${cardRequestId}`;
  const handleSubmit = async () => {
    try {
      const futurePaymentOption = "CARD_ON_FILE_IS_DEFAULT";
      const payload = {
        amount: props.amount,
        memberId: props.memberDetail?.memberId
          ? props.memberDetail?.memberId
          : member?.getMember?.memberId,
        brandId: sidebarBrandId,
        paymentInformationId:
          paymentType === "cardOnFile"
            ? cardPrimary?.id
            : paymentInformation?.paymentInformation?.paymentInformationId,
        operation: props.operation,
        futurePaymentOption: futurePaymentOption,
        ...(props?.payloadData && { ...props.payloadData }),
      };
      const data = await chargeSinglePayment({
        variables: payload,
        onCompleted: (data) => {
          props?.onApiCompleted && props?.onApiCompleted();
        },
        onError: (e) => {
          props?.onApiError && props?.onApiError(`${e}`);
        },
        refetchQueries: props?.apiRefetchQueries,
      });
      if (!data.errors) {
        props.setShowModalPayment(false);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
    }
  };
  const openModalVivaPay = async () => {
    setOpenVivaPay(true);
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: "GET_SAVE_CARD_TOKEN_REQUEST",
            memberId: member?.getMember?.memberId,
            requestApplication: "HUB",
            locationId: props.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.cardRequestId) {
        setCardRequestId(data.data?.addMemberPaymentInformation.cardRequestId);
      }
    } catch (e) {
    } finally {
    }
  };
  const closeModalVivaPay = async () => {
    setOpenVivaPay(false);
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: "ADD_CREDIT_CARD_REQUEST",
            memberId: member?.getMember?.memberId,
            cardRequestId: cardRequestId,
            requestApplication: "HUB",
            locationId: props.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.paymentInformationId) {
        setPaymentInformation({
          ...paymentInformation,
          paymentInformation: data.data?.addMemberPaymentInformation,
        });
        toast.success("Save card successfully");
      } else {
        if (paymentType === "differentCard") {
          setPaymentType("cardOnFile");
        }
        toast.error("Save card fail");
      }
    } catch (e) {
    } finally {
    }
  };
  const [cardPrimary, setCardPrimary] = useState({});
  useEffect(() => {
    if (Object.keys(props.memberDetail || {}).length) {
      props.memberDetail?.paymentDetails?.items?.map((i) => {
        if (i?.primary && i?.paymentType === "CREDIT_CARD") {
          setCardPrimary(i);
        }
      });
    } else {
      member?.getMember?.paymentDetails?.items?.map((i) => {
        if (i?.primary && i?.paymentType === "CREDIT_CARD") {
          setCardPrimary(i);
        }
      });
    }
  }, []);
  return (
    <div>
      <Modal>
        <Formik
          enableReinitialize
          initialValues={{
            cardType: "",
            cardNumber: "",
            expiry: "",
            nameOnCard: "",
            cardCvv: "",
          }}
        >
          {(formik) => {
            return (
              <div className="status-modal make-a-payment-modal pd-32">
                <Form>
                  <div className="make-payment-title ">
                    <h3 className="fs-35 pb-24 uppercase">Make a Payment</h3>
                  </div>{" "}
                  <div>
                    <div className="member-details-modal-container">
                      <h3>Member Details</h3>

                      <div className="member-details-inputs pb-24">
                        <InputWithoutFormik
                          type="text"
                          label="member No:"
                          name="memberNo"
                          autoComplete="off"
                          id="memberNo"
                          className="fs-12"
                          value={
                            member?.getMember?.aliasMemberId
                              ? member?.getMember?.aliasMemberId
                              : props.memberDetail?.aliasMemberId
                          }
                          readOnly
                        />
                        <InputWithoutFormik
                          type="text"
                          label="member Name:"
                          name="memberName"
                          autoComplete="off"
                          id="memberName"
                          className="fs-12"
                          value={
                            member?.getMember?.givenName
                              ? member?.getMember?.givenName
                              : props.memberDetail?.givenName
                          }
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="outstanding-payments-container pb-24">
                      <h3>{props.paymentType}</h3>
                      <InputWithoutFormik
                        type="text"
                        label="Amount:"
                        name="amount"
                        autoComplete="off"
                        id="amount"
                        className="fs-12"
                        value={props.amount || ""}
                        readOnly
                      />
                    </div>

                    <h3 className="pb-8 credit-description">
                      Credit Card surcharge will be added to single payments
                      only.
                      <br />
                      There is no fee for ongoing fortnightly memberships.
                    </h3>
                    <h3 className="pb-8">
                      (VISA/MASTSERCARD: 1.5%, AMEX: 3.5%)
                    </h3>
                  </div>
                  <div
                    role="group"
                    aria-labelledby="my-radio-group"
                    className=" payment-options-container pb-18 fs-12"
                  >
                    <label>
                      <Field
                        name="cardOnFile"
                        type="radio"
                        checked={paymentType === "cardOnFile" || false}
                        onClick={(e) => {
                          setPaymentType("cardOnFile");
                        }}
                      />
                      USE CARD ON FILE
                    </label>
                    <label>
                      <Field
                        type="radio"
                        name="differentCard"
                        checked={paymentType === "differentCard" || false}
                        onClick={(e) => {
                          setPaymentType("differentCard");
                          openModalVivaPay();
                        }}
                      />
                      USE DIFFERENT CARD
                    </label>
                  </div>
                  {paymentType === "cardOnFile" && (
                    <div>
                      <div className="payment-details-modal-container">
                        <Input
                          disabled={true}
                          type="text"
                          label="Type: "
                          name="cardType"
                          id="cardType"
                          autoComplete="off"
                          value={
                            cardPrimary.cardType ? cardPrimary.cardType : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Card"
                          name="cardNumber"
                          id="cardNumber"
                          autoComplete="off"
                          value={
                            cardPrimary.cardNumber ? cardPrimary.cardNumber : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Expiry"
                          name="expiry"
                          id="expiry"
                          autoComplete="off"
                          value={
                            cardPrimary.cardExpiryDate
                              ? cardPrimary.cardExpiryDate
                              : ""
                          }
                          className="fs-12"
                        />
                      </div>
                    </div>
                  )}
                  {paymentType === "differentCard" &&
                    paymentInformation.paymentInformation && (
                      <div className="payment-details-modal-container">
                        <Input
                          disabled={true}
                          type="text"
                          label="Type: "
                          name="cardType"
                          id="cardType"
                          autoComplete="off"
                          value={
                            paymentInformation.paymentInformation?.cardType
                              ? paymentInformation?.paymentInformation.cardType
                              : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Card"
                          name="cardNumber"
                          id="cardNumber"
                          autoComplete="off"
                          value={
                            paymentInformation?.paymentInformation.cardNumber
                              ? paymentInformation?.paymentInformation
                                  .cardNumber
                              : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Expiry"
                          name="expiry"
                          id="expiry"
                          autoComplete="off"
                          value={
                            paymentInformation?.paymentInformation
                              .cardExpiryDate
                              ? paymentInformation?.paymentInformation
                                  .cardExpiryDate
                              : ""
                          }
                          className="fs-12"
                        />
                      </div>
                    )}
                  {/* {memberContractId: {eq: "memberContractId"}} */}
                  <div className="make-payment-btn-container mt-24">
                    <Button
                      loading={
                        chargeSinglePaymentLoading ||
                        addMemberPaymentInfoLoading
                      }
                      name={"Make payment"}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-primary btn-lg fs-12"
                      disabled={
                        addMemberPaymentInfoLoading ||
                        chargeSinglePaymentLoading
                      }
                      click={handleSubmit}
                    />

                    <Button
                      name="CANCEL"
                      btntype="button"
                      disabled={chargeSinglePaymentLoading}
                      btnname="cancel"
                      click={() => props.setShowModalPayment(false)}
                      className="btn btn-deny btn-lg fs-12"
                    />
                  </div>
                </Form>
              </div>
            );
          }}
        </Formik>
      </Modal>
      {paymentType === "differentCard" && openVivaPay && (
        <Modal>
          <div className="status-modal make-a-payment-modal viva-pay pd-32">
            <iframe
              id="pay-with-vivapay"
              title="pay with vivapay"
              src={iframeSrc}
              className="iframe-vivapay"
              loading="lazy"
            />
            <Button
              name="Continue"
              btntype="button"
              btnname="cancel"
              className="btn btn-confirm btn-small"
              click={() => closeModalVivaPay()}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default MakePaymentActivateModal;
