import "./memberinteraction.scss";
import React, { useState, useEffect } from "react";
import { useMutation, useApolloClient } from "@apollo/client";
import { toast } from "react-toastify";
import { format, addDays } from "date-fns";
import { AiFillPhone, AiOutlineEdit } from "react-icons/ai";
import { BsCardText } from "react-icons/bs";
import { RiRefund2Line } from "react-icons/ri";
import { MdOutlineChangeCircle } from "react-icons/md";
import { TbLogin } from "react-icons/tb";
import { BiCalendarCheck, BiPencil, BiMinusCircle } from "react-icons/bi";
import { LiaDoorOpenSolid } from "react-icons/lia";
import { CgProfile } from "react-icons/cg";
import { getUTCdate } from "../../../utils/getUTCdate";
import { Modal, Table, DatePicker, RadioButton } from "../../../components";
import { UPDATE_NOTE } from "../../../gqloperations/mutations";
import {
  GET_MEMBER_ACTIVITY_BY_MEMBERID,
  GET_AUDITOPERATION_BY_MEMBERID,
  GET_AUDITVISITLOG_BY_MEMBERID,
  GET_NOTES_BY_MEMBERID,
  GET_SMS_BY_MEMBERID,
  GET_SMS_MESSAGE,
  GET_MEMBER_ACTIVITY,
  GET_AUDIT_VISIT_LOG,
  GET_AUDIT_OPERATION,
  GET_SMS,
  GET_NOTE,
  GET_MEMBER_CONTRACT_LOG_BY_MEMBERID,
  GET_CLASSBOOKINGS_BY_MEMBERID,
  GET_MEMBER_ACCESS_LOGS_BY_MEMBER_ID,
  GET_MEMBER_SUSPENSIONS_BY_MEMBER_ID,
  GET_REFUNDS_BY_MEMBER_ID,
} from "../../../gqloperations/queries";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import { sortDesc } from "../../../utils/sort";
import { utcToZonedTime } from "date-fns-tz";

const MemberInteraction1 = () => {
  const client = useApolloClient();
  const {
    modal,
    member,
    openModal,
    closeModal,
    createLog,
    memberInteraction,
    setMemberInteraction,
  } = useMemberContext();
  const [loading, setLoading] = useState(false);
  const { permissions } = useAuthContext();
  const [variable, setVariable] = useState({});
  const [tableData, setTableData] = useState([]);
  const [nextToken, setNextToken] = useState("");
  const [from, setFrom] = useState("");
  const [to, setTo] = useState(format(new Date(), "yyyy-MM-dd"));
  const [detailsId, setDetailsId] = useState("");
  const [detailsData, setDetailsData] = useState("");
  const [editNote, setEditNote] = useState(false);
  const [noteMessage, setNoteMessage] = useState("");
  const [error, setError] = useState("");
  const [incomingMessages, setInComingMessages] = useState([]);
  const [outGoingMessages, setOutGoingMessages] = useState([]);
  const [incomingMessageVariable, setIncomingMessageVariable] = useState({});
  const [nextTokenForIncomingMessage, setNextTokenForIncomingMessage] =
    useState("");
  const [updateNote, { loading: updatenoteloading }] = useMutation(UPDATE_NOTE);
  useEffect(() => {
    return () => setMemberInteraction("");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setTableData([]);
    setNextToken("");
    if (memberInteraction) setLoading(true);
    if (memberInteraction === "changes") {
      getAuditOperationByMemberId();
    } else if (memberInteraction === "notes") {
      getNotesByMemberId();
    } else if (memberInteraction === "profile") {
      getAuditVisitLogByMemberId();
    } else if (memberInteraction === "logins") {
      getMemberActivityByMemberId();
    } else if (memberInteraction === "sms") {
      getSmsByMemberId();
    } else if (memberInteraction === "membershipAmount") {
      getMemberContractLogByMemberId({
        ...variable,
        logType: { eq: "CHANGE_IN_MEMBERSHIP_AMOUNT" },
      });
    } else if (memberInteraction === "ddAmount") {
      getMemberContractDirectDebitLogMemberId();
    } else if (memberInteraction === "classBookings") {
      getClassBookingsMemberId();
    } else if (memberInteraction === "accessLogs") {
      getMemberAccessLogs();
    } else if (memberInteraction === "suspensions") {
      getMemberSuspensions();
    } else if (memberInteraction === "refunds") {
      getMemberRefunds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [variable]);
  useEffect(() => {
    if (memberInteraction === "sms") getSmsMessage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [incomingMessageVariable]);

  useEffect(() => {
    setError("");
    setTableData([]);
    setInComingMessages([]);
    setOutGoingMessages([]);
    setVariable({
      memberId: member?.getMember?.memberId,
    });
    setIncomingMessageVariable({
      filter: { memberId: { eq: member?.getMember?.memberId } },
    });
    if (from > to) {
      return setError("From should be less than to");
    }
    const dateTo = to ? new Date(to) : new Date();
    const utcTo = getUTCdate(format(addDays(dateTo, 1), "yyyy-MM-dd"));
    if (
      from &&
      (memberInteraction === "profile" ||
        memberInteraction === "sms" ||
        memberInteraction === "logins" ||
        memberInteraction === "ddAmount" ||
        memberInteraction === "classBookings" ||
        memberInteraction === "notes" ||
        memberInteraction === "changes" ||
        memberInteraction === "suspensions" ||
        memberInteraction === "refunds" ||
        memberInteraction === "accessLogs")
    ) {
      setVariable({
        memberId: member?.getMember?.memberId,
        createdAt: {
          between: [getUTCdate(from), utcTo],
        },
      });
      if (memberInteraction === "sms") {
        setIncomingMessageVariable({
          filter: {
            memberId: { eq: member?.getMember?.memberId },
            and: {
              createdAt: { range: [getUTCdate(from), utcTo] },
            },
          },
        });
      }
    }
    if (from && memberInteraction === "membershipAmount") {
      setVariable({
        memberId: member?.getMember?.memberId,
        logType: { eq: "CHANGE_IN_MEMBERSHIP_AMOUNT" },
        filter: {
          createdAt: {
            between: [getUTCdate(from), utcTo],
          },
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [from, to, memberInteraction, member?.getMember?.memberId]);

  useEffect(() => {
    if (detailsId) {
      if (memberInteraction === "changes") {
        getAuditOperationDetails();
      } else if (memberInteraction === "notes") {
        getNoteDetails();
      } else if (memberInteraction === "profile") {
        getAuditVisitLogDetails();
      } else if (memberInteraction === "logins") {
        getMemberActivityDetails();
      } else if (memberInteraction === "sms") {
        getSmsDetails();
      } else if (memberInteraction === "membershipAmount") {
        getMemberContractLogByMemberContractId();
      } else if (memberInteraction === "ddAmount") {
        getDirectDebitLogDetails();
      } else if (memberInteraction === "suspensions") {
        getSuspensionDetails();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [detailsId]);
  useEffect(() => {
    const newIncomingMessages = incomingMessages.map((item) => {
      const updatedItem = {
        ...item,
        status: "received",
        sentDateTime: item.dateTime,
      };
      const { dateTime, ...newItem } = updatedItem;
      return newItem;
    });
    const smsData = [...outGoingMessages, ...newIncomingMessages];
    const uniqueObjects = [];
    const uniqueIds = new Set();
    smsData.forEach((obj) => {
      if (!uniqueIds.has(obj.id)) {
        uniqueIds.add(obj.id);
        uniqueObjects.push(obj);
      }
    });
    setTableData(sortDesc(uniqueObjects, "sentDateTime"));
  }, [outGoingMessages, incomingMessages]);

  const handleChange = (e) => {
    setTableData("");
    setNextToken("");
    setMemberInteraction(e.target.value);
    createLog(
      `Clicked ${e.target.value} in member intreaction tab of member module for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member?.getMember?.memberId}`
    );
  };
  const getAuditVisitLogByMemberId = async () => {
    try {
      const result = await client.query({
        query: GET_AUDITVISITLOG_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getAuditVisitLogByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getAuditVisitLogByMemberId.items,
        ]);
      }
      setNextToken(result.data.getAuditVisitLogByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getNotesByMemberId = async () => {
    try {
      const result = await client.query({
        query: GET_NOTES_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (editNote) {
        return setTableData(result.data.notesByMemberId.items);
      }
      if (!tableData && !editNote) {
        setTableData(result.data.notesByMemberId.items);
      } else {
        setTableData([...tableData, ...result.data.notesByMemberId.items]);
      }
      setNextToken(result.data.notesByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getMemberActivityByMemberId = async () => {
    try {
      const result = await client.query({
        query: GET_MEMBER_ACTIVITY_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getMemberActivityByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getMemberActivityByMemberId.items,
        ]);
      }
      setNextToken(result.data.getMemberActivityByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getAuditOperationByMemberId = async () => {
    try {
      const result = await client.query({
        query: GET_AUDITOPERATION_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: { ...variable },
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getAuditOperationByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getAuditOperationByMemberId.items,
        ]);
      }
      setNextToken(result.data.getAuditOperationByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const getSmsMessage = async () => {
    try {
      const result = await client.query({
        query: GET_SMS_MESSAGE,
        fetchPolicy: "no-cache",
        variables: incomingMessageVariable,
      });
      if (result) setLoading(false);
      if (!incomingMessages) {
        setInComingMessages(result.data.searchSmsIncomings.items);
      } else {
        setInComingMessages([
          ...incomingMessages,
          ...result.data.searchSmsIncomings.items,
        ]);
      }
      // setInComingMessages(result.data.searchSmsIncomings.items);
      setNextTokenForIncomingMessage(result.data.searchSmsIncomings.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const getSmsByMemberId = async () => {
    try {
      const result = await client.query({
        query: GET_SMS_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!outGoingMessages) {
        setOutGoingMessages(result.data.getSmsOutboxByMemberId.items);
      } else {
        setOutGoingMessages([
          ...outGoingMessages,
          ...result.data.getSmsOutboxByMemberId.items,
        ]);
      }
      // setOutGoingMessages(
      //   sortDesc(result.data.getSmsOutboxByMemberId.items, "sentDateTime")
      // );
      setNextToken(result.data.getSmsOutboxByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getMemberContractLogByMemberId = async (variables) => {
    let variable = { ...variables, sortDirection: "DESC" };
    try {
      const result = await client.query({
        query: GET_MEMBER_CONTRACT_LOG_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      const filteredResult =
        result.data.getMemberContractLogByMemberId.items.filter((item) => {
          return (
            item.oldMembershipAmount !== null &&
            item.newMembershipAmount !== null
          );
        });
      if (!tableData) {
        setTableData(filteredResult);
      } else {
        setTableData([...tableData, ...filteredResult]);
      }
      setNextToken(result.data.getMemberContractLogByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getMemberContractDirectDebitLogMemberId = async () => {
    const billings = member?.getMember?.contracts?.items.map((item) => {
      return item.billings;
    });
    let logs = [];
    billings?.map((billing) => {
      return (logs = [...logs, ...billing.items]);
    });
    let directDebitLogs = [];
    logs.map((log) => {
      return (directDebitLogs = [
        ...directDebitLogs,
        ...log.directDebitLog.items,
      ]);
    });

    directDebitLogs.sort(function (a, b) {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);

      return dateB - dateA;
    });
    if (variable.createdAt) {
      const filteredDDLogs = directDebitLogs.filter(
        (ddLog) =>
          ddLog.createdAt >= variable.createdAt.between[0] &&
          ddLog.createdAt <= variable.createdAt.between[1]
      );
      setTableData(filteredDDLogs);
    } else {
      setTableData(directDebitLogs);
    }
    setLoading(false);
    // if (!tableData) {
    //   setTableData(result.data.getMemberContractLogByMemberId.items);
    // } else {
    //   setTableData([
    //     ...tableData,
    //     ...result.data.getMemberContractLogByMemberId.items,
    //   ]);
    // }
    // setNextToken(result.data.getMemberContractLogByMemberId.nextToken);
  };

  const getClassBookingsMemberId = async () => {
    try {
      const result = await client.query({
        query: GET_CLASSBOOKINGS_BY_MEMBERID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getClassBookingsByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getClassBookingsByMemberId.items,
        ]);
      }
      setNextToken(result.data.getClassBookingsByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getMemberAccessLogs = async () => {
    try {
      const result = await client.query({
        query: GET_MEMBER_ACCESS_LOGS_BY_MEMBER_ID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getAccessLogByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getAccessLogByMemberId.items,
        ]);
      }
      setNextToken(result.data.getAccessLogByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const getMemberSuspensions = async () => {
    try {
      const result = await client.query({
        query: GET_MEMBER_SUSPENSIONS_BY_MEMBER_ID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getSuspensionByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getSuspensionByMemberId.items,
        ]);
      }
      setNextToken(result.data.getSuspensionByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const getMemberRefunds = async () => {
    try {
      const result = await client.query({
        query: GET_REFUNDS_BY_MEMBER_ID,
        fetchPolicy: "no-cache",
        variables: variable,
      });
      if (result) setLoading(false);
      if (!tableData) {
        setTableData(result.data.getRefundTransactionsByMemberId.items);
      } else {
        setTableData([
          ...tableData,
          ...result.data.getRefundTransactionsByMemberId.items,
        ]);
      }
      setNextToken(result.data.getRefundTransactionsByMemberId.nextToken);
    } catch (error) {
      toast.error(`${error}`);
    }
  };
  const getMemberActivityDetails = async () => {
    try {
      const result = await client.query({
        query: GET_MEMBER_ACTIVITY,
        fetchPolicy: "no-cache",
        variables: {
          id: detailsId,
        },
      });
      openModal();
      setDetailsData(result.data);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getAuditOperationDetails = async () => {
    try {
      const result = await client.query({
        query: GET_AUDIT_OPERATION,
        fetchPolicy: "no-cache",
        variables: {
          id: detailsId,
        },
      });
      openModal();
      setDetailsData(result.data);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getAuditVisitLogDetails = async () => {
    try {
      const result = await client.query({
        query: GET_AUDIT_VISIT_LOG,
        fetchPolicy: "no-cache",
        variables: {
          id: detailsId,
        },
      });
      openModal();
      setDetailsData(result.data);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getSmsDetails = async () => {
    try {
      const result = await client.query({
        query: GET_SMS,
        fetchPolicy: "no-cache",
        variables: {
          id: detailsId,
        },
      });
      openModal();
      setDetailsData(result.data);
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getNoteDetails = async () => {
    try {
      const result = await client.query({
        query: GET_NOTE,
        fetchPolicy: "no-cache",
        variables: {
          id: detailsId,
        },
      });
      openModal();
      setDetailsData(result.data);
      if (editNote) {
        setNoteMessage(result?.data?.getNote?.note);
      }
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const getMemberContractLogByMemberContractId = async () => {
    const result = tableData.filter((log) => {
      return log.id === detailsId;
    });
    openModal();
    setDetailsData(result);
  };

  const getDirectDebitLogDetails = async () => {
    const result = tableData.filter((log) => {
      return log.id === detailsId;
    });
    openModal();
    setDetailsData(result);
  };
  const getSuspensionDetails = async () => {
    const result = tableData.filter((log) => {
      return log.id === detailsId;
    });
    openModal();
    setDetailsData(result);
  };
  const handleNextToken = () => {
    if (memberInteraction === "sms") {
      if (nextToken)
        setVariable((prevValue) => {
          return { ...prevValue, nextToken: nextToken };
        });
      if (nextTokenForIncomingMessage)
        setIncomingMessageVariable((prevValue) => {
          return { ...prevValue, nextToken: nextTokenForIncomingMessage };
        });
      return;
    }
    setVariable((prevValue) => {
      return { ...prevValue, nextToken: nextToken };
    });
  };
  const viewSms = (data) => {
    setDetailsData(data);
    openModal();
  };
  const handleDetailsClick = (data) => {
    setDetailsData("");
    setDetailsId(data);
  };
  const handleEditClick = (data) => {
    setDetailsData("");
    setDetailsId(data);
    setEditNote(true);
  };

  const modalClose = () => {
    setDetailsId("");
    setDetailsData("");
    closeModal();
  };

  const handleFrom = (e) => {
    setFrom(e.target.value);
  };

  const handleTo = (e) => {
    setTo(e.target.value);
  };

  const handleMessageChange = (event) => {
    setNoteMessage(event.target.value);
  };
  const handleUpdateNote = () => {
    setTableData("");

    updateNote({
      variables: {
        input: {
          id: `${detailsData?.getNote?.id}`,
          note: noteMessage,
        },
      },
      onCompleted: () => {
        setDetailsId("");
        setDetailsData("");
        closeModal();
        setEditNote(false);
        getNotesByMemberId();
        toast.success("Update successfully");
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
  };

  const columns = [
    {
      accessorKey: "createdAt",
      header: "DATE & TIME",
      cell: (props) => (
        <span>{format(new Date(props.getValue()), "E d/LL/yy k:mm:ss")}</span>
      ),

      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        if (memberInteraction === "notes") {
          return "notes";
        }
        if (memberInteraction === "profile") {
          return "profile";
        }
        if (memberInteraction === "changes") {
          return row?.endpoint;
        }
        if (memberInteraction === "logins") {
          return "logins";
        }
        if (memberInteraction === "sms") {
          return "sms";
        }
        if (memberInteraction === "sms") {
          return "sms";
        }
        return "";
      },
      header: "TYPE",
      cell: (props) => <span>{props.getValue() ?? ""}</span>,
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorKey: `${
        memberInteraction === "notes"
          ? "note"
          : memberInteraction === "profile"
          ? "whatwasDone"
          : memberInteraction === "changes"
          ? "action"
          : memberInteraction === "logins"
          ? "eventType"
          : ""
      }`,
      header: `${memberInteraction === "changes" ? "ACTIONS" : "DETAILS"}`,
      cell: (props) => (
        <span>
          {props.getValue() ? props.getValue().toString().substring(0, 20) : ""}
        </span>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },

    {
      accessorFn: (row) => {
        if (memberInteraction === "notes") {
          return row.addedBy ?? "";
        }
        if (memberInteraction === "profile") {
          return row?.clickedByDetails?.givenName ?? "";
        }
        if (memberInteraction === "changes") {
          return row?.updaterDetails?.givenName && row?.updaterDetails?.surname
            ? row?.updaterDetails?.surname +
                ", " +
                row?.updaterDetails?.givenName
            : "";
        }
        if (memberInteraction === "logins") {
          return row?.email ?? "";
        }
        if (memberInteraction === "sms") {
          return row?.locationDetails?.name ?? "";
        }
        if (memberInteraction === "sms") {
          return row?.locationDetails?.name ?? "";
        }
      },
      header: `${
        memberInteraction === "notes"
          ? "STAFF"
          : memberInteraction === "profile"
          ? "STAFF"
          : memberInteraction === "changes"
          ? "STAFF"
          : memberInteraction === "logins"
          ? "Email"
          : memberInteraction === "sms"
          ? "STAFF"
          : ""
      }`,
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      // accessorKey: "id",
      id: "row-actions-edit",
      accessorKey: "id",
      header: "",
      enableSorting: false,
      enableGlobalFilter: false,
      cell: (info) =>
        memberInteraction === "notes" ? (
          <AiOutlineEdit
            onClick={() => {
              handleEditClick(info.getValue());
            }}
          />
        ) : (
          ""
        ),
    },
    {
      id: "row-actions",
      accessorKey: "id",
      header: "",
      enableSorting: false,
      enableGlobalFilter: false,
      cell: (info) => (
        <BsCardText
          onClick={() => {
            handleDetailsClick(info.getValue());
          }}
        />
      ),
    },
  ];
  const refundColumns = [
    {
      accessorFn: (row) => {
        if (row.paymentType === "DIRECT_DEBIT") {
          return utcToZonedTime(
            new Date(row.updatedAt),
            member?.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney"
          );
        } else {
          return utcToZonedTime(
            new Date(row.refundDate),
            member?.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney"
          );
        }
      },
      header: "DATE & TIME",
      cell: (props) => (
        <span>{format(new Date(props.getValue()), "E d/LL/yy k:mm:ss")}</span>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "refundType",
      header: "REFUND TYPE",
      cell: (props) => <span>{props.getValue() ?? ""}</span>,
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "refundAmount",
      header: "REFUND AMOUNT",
      cell: (props) =>
        props.getValue() ? `$${props.getValue().toFixed(2)}` : "",
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) =>
        row.refundStatus === "FAILED" ? (
          <div className="text-red">DECLINED</div>
        ) : (
          <div className="text-green">{row.refundStatus}</div>
        ),
      header: "refund status",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "refundStatus",
    },
    {
      accessorFn: (row) => {
        return `${row?.requestByDetails?.givenName ?? ""} ${
          row?.requestByDetails?.surname ?? ""
        }`;
      },
      header: "REQUESTED BY",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return `${row?.refundByDetails?.givenName ?? ""} ${
          row?.refundByDetails?.surname ?? ""
        }`;
      },
      header: "REFUND BY",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ];
  const smsColumns = [
    {
      accessorKey: "sentDateTime",
      header: "DATE / TIME",
      cell: (props) => (
        <span>{format(new Date(props.getValue()), "E d/LL/yy k:mm:ss")}</span>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      header: "TYPE",
      cell: memberInteraction.toUpperCase(),
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "status",
      header: "status",
      cell: (props) => <span>{props.getValue() ? props.getValue() : ""}</span>,
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "locationDetails.name",
      header: "Location",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      id: "row-actions",
      cell: (info) => {
        return <BsCardText onClick={() => viewSms(info.row.original)} />;
      },

      // cell: (info) => <BsCardText onClick={() => viewSms(info.row.original)} />,
    },
  ];

  const membershipChangeColumns = [
    {
      accessorKey: "createdAt",
      header: "DATE / TIME",
      cell: (props) => (
        <span>{format(new Date(props.getValue()), "E d/LL/yy k:mm:ss")}</span>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) =>
        memberInteraction === "ddAmount"
          ? `${row?.debitAmountOriginal}`
          : `${row?.oldMembershipAmount}`,
      header: "Previous $",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "previous_amount",
    },
    {
      accessorFn: (row) =>
        memberInteraction === "ddAmount"
          ? `${row.debitAmountNew}`
          : `${row.newMembershipAmount}`,
      header: "New $",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "new_amount",
    },
    {
      accessorFn: (row) =>
        memberInteraction === "ddAmount"
          ? `${row?.description ?? ""}`
          : `${row.logDescription ?? ""}`,
      header: "DETAILS",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "description",
    },
    {
      accessorFn: (row) =>
        `${row?.updaterDetails?.givenName} ${row?.updaterDetails?.surname}`,
      header: "staff",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      id: "row-actions",
      accessorKey: "id",
      header: "",
      enableSorting: false,
      enableGlobalFilter: false,
      cell: (info) => (
        <BsCardText
          onClick={() => {
            handleDetailsClick(info.getValue());
          }}
        />
      ),
    },
  ];
  const classBookingsColumns = [
    {
      accessorKey: "createdAt",
      header: "DATE / TIME",
      cell: (props) => (
        <span>{format(new Date(props.getValue()), "E d/LL/yy k:mm:ss")}</span>
      ),
      enableSorting: false,
      enableGlobalFilter: false,
    },

    {
      accessorFn: (row) => `${row?.classTemplateDetails?.className}`,
      header: "class",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "class",
    },
    {
      accessorFn: (row) =>
        `${row?.classScheduleDetails?.instructorDetails?.givenName} ${row?.classScheduleDetails?.instructorDetails?.surname}`,
      header: "instructor",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "instructor",
    },
    {
      accessorFn: (row) => `${row?.locationDetails?.name}`,
      header: "bookings",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "bookings",
    },
  ];
  const accessLogsColumns = [
    {
      accessorKey: "swipePhoto",
      header: "SWIPE PHOTO",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "swipePhoto",
    },
    {
      accessorFn: (row) => row?.memberPhoto ?? "",
      header: "LINKS PHOTO",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberPhoto",
    },
    {
      accessorFn: (row) => {
        if (row?.processLocationTime) {
          const day = row.processLocationTime.slice(8, 10);
          const month = row.processLocationTime.slice(5, 7);
          const year = row.processLocationTime.slice(0, 4);
          const time = row.processLocationTime.slice(11, 19);
          return `${day}/${month}/${year} ${time}`;
        }
      },
      header: "TIME",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "createdAt",
    },
    {
      accessorKey: "isValid",
      header: "VALID",
      cell: (info) => {
        return info.getValue() ? "YES" : "NO";
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "isValid",
    },
    {
      accessorKey: "isOnline",
      header: "ONLINE",
      cell: (info) => {
        return info.getValue() ? "YES" : "NO";
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "isOnline",
    },
    {
      accessorKey: "memberHomeClub",
      header: "HOME CLUB",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "memberHomeClub",
    },
    {
      accessorFn: (row) => row?.memberContract?.membershipName ?? "",
      header: "MEMBERSHIP",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "membershipName",
    },
    {
      accessorFn: (row) => row,
      header: "LOCATION",
      cell: (info) => {
        let deviceName = "OTHER";
        if (info.getValue()?.deviceType === "DOOR") deviceName = "FOB";
        if (info.getValue()?.deviceType === "MOBILE") deviceName = "APP";
        return (
          <div className="location-cell">
            <span>{info.getValue()?.doorDetails?.doorName}</span>
            <span className="orange-text">{deviceName}</span>
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: true,
      id: "location",
    },
  ];
  const suspensionsColumns = [
    {
      accessorKey: "suspensionType",
      header: "TYPE",
      cell: (info) => info.getValue() ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionType",
    },
    {
      accessorKey: "createdAt",
      header: "CREATED AT",
      cell: (info) => format(new Date(info.getValue()), "dd/MM/yy"),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "createdAt",
    },
    {
      accessorKey: "suspensionStartDateTime",
      header: "SUSPENSION START DATE",
      cell: (info) =>
        format(
          utcToZonedTime(
            info.getValue(),
            member?.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney"
          ),
          "dd/MM/yy"
        ),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionStartDate",
    },
    {
      accessorKey: "suspensionEndDateTime",
      header: "SUSPENSION END DATE",
      cell: (info) =>
        format(
          utcToZonedTime(
            info.getValue(),
            member?.getMember?.homeLocationDetails?.timezone ??
              "Australia/Sydney"
          ),
          "dd/MM/yy"
        ),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "suspensionEndDateTime",
    },
    {
      accessorKey: "suspensionReason",
      header: "Reason",
      cell: (info) => info.getValue() ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
      id: "reason",
    },
    {
      accessorFn: (row) => {
        const status = row.cancelledDateTime
          ? "CANCELLED"
          : row.suspensionStatus;
        return (
          <>
            {status === "APPROVED" && <p className="text-green">{status}</p>}
            {(status === "CANCELLED" || status === "REJECTED") && (
              <p className="text-red">{status}</p>
            )}
            {status === "PENDING" && (
              <p style={{ color: "yellow" }}>{status}</p>
            )}
          </>
        );
      },
      header: "Status",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "status",
    },
    {
      id: "row-actions",
      accessorKey: "id",
      header: "",
      enableSorting: false,
      enableGlobalFilter: false,
      cell: (info) => (
        <BsCardText
          onClick={() => {
            handleDetailsClick(info.getValue());
          }}
        />
      ),
    },
  ];
  const turnArrToObj = (nameArr, valueArr) => {
    return nameArr.reduce((obj, curr, ind) => {
      return { ...obj, [curr]: valueArr[ind] };
    }, {});
  };
  return (
    <>
      <div className="member-interation">
        <>
          <div className="filter-grid mb-12">
            <div className="dates">
              <div className="from">
                <div>
                  <DatePicker
                    type="date"
                    name="from"
                    id="from"
                    value={from}
                    onChange={(e) => handleFrom(e)}
                  />
                </div>
              </div>

              <div className="to">
                <DatePicker
                  type="date"
                  name="to"
                  id="to"
                  value={to}
                  onChange={(e) => handleTo(e)}
                />
              </div>
            </div>
            {setError && <div className="fs-10 red">{error}</div>}
            {modal && (
              <Modal>
                {memberInteraction === "notes" &&
                  !editNote &&
                  detailsData?.getNote && (
                    <>
                      <div className="member-changes-modal">
                        <h2 className="modal-title fs-35">Notes</h2>

                        <div className="details-container">
                          <div className="detail">
                            <h3 className="fs-10">Added By</h3>
                            <p className="fs-12">
                              {detailsData?.getNote.addedBy}
                            </p>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Member</h3>
                            <p className="fs-12">
                              {detailsData?.getNote.memberDetails?.givenName}
                              <span>
                                {detailsData?.getNote.memberDetails?.middleName}
                              </span>
                              <span>
                                {detailsData?.getNote.memberDetails?.surname}
                              </span>
                            </p>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Created At</h3>
                            <p className="fs-12">
                              {format(
                                new Date(detailsData?.getNote.createdAt),
                                "E d/LL/yy"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="msg-container">
                          <h3 className="fs-10">Message</h3>
                          <textarea
                            className="msg-container fs-12"
                            defaultValue={detailsData?.getNote.note}
                            readOnly
                          ></textarea>
                        </div>
                        {/* <div className="msg-container edit-message-container">
                          <h3 className="fs-8">Message</h3>
                          <div className="fs-12">
                            {detailsData?.getNote.note}
                          </div>
                        </div> */}

                        <button
                          className="btn btn-small btn-confirm"
                          onClick={() => {
                            modalClose();
                          }}
                        >
                          CLOSE
                        </button>
                      </div>
                    </>
                  )}

                {memberInteraction === "notes" &&
                  editNote &&
                  detailsData?.getNote && (
                    <>
                      <div className="member-changes-modal">
                        <h2 className="modal-title fs-35">Notes</h2>

                        <div className="details-container">
                          <div className="detail">
                            <h3 className="fs-10">Added By</h3>
                            <p className="fs-12">
                              {detailsData?.getNote.addedBy}
                            </p>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Member</h3>
                            <p className="fs-12">
                              {detailsData?.getNote.memberDetails?.givenName}
                              <span>
                                {detailsData?.getNote.memberDetails?.middleName}
                              </span>
                              <span>
                                {detailsData?.getNote.memberDetails?.surname}
                              </span>
                            </p>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Created At</h3>
                            <p className="fs-12">
                              {format(
                                new Date(detailsData?.getNote?.createdAt),
                                "E d/LL/yy"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="edit-msg-container">
                          <h3 className="fs-10">Message</h3>

                          <textarea
                            className="fs-12"
                            name="noteMessage"
                            id=""
                            cols="30"
                            rows="10"
                            value={noteMessage}
                            onChange={handleMessageChange}
                          ></textarea>
                        </div>

                        <div className="btn-container">
                          <button
                            className="btn btn-small btn-confirm"
                            onClick={() => handleUpdateNote()}
                            disabled={updatenoteloading}
                          >
                            SAVE
                          </button>
                          <button
                            className="btn btn-small btn-deny"
                            onClick={() => {
                              setEditNote(false);
                              modalClose();
                            }}
                          >
                            CANCEL
                          </button>
                        </div>
                      </div>
                    </>
                  )}

                {memberInteraction === "logins" &&
                  detailsData?.getMemberActivity && (
                    <>
                      <div className="member-changes-modal">
                        <h2 className="modal-title fs-35">Web/App Logins</h2>
                        <div className="details-container">
                          <div className="detail full-width">
                            <h3 className="fs-10">Created At</h3>
                            <p className="fs-12">
                              {format(
                                new Date(
                                  detailsData?.getMemberActivity.createdAt
                                ),
                                "E d/LL/yy"
                              )}
                            </p>
                          </div>
                          <div className="msg-container small-input">
                            <h3 className="fs-10">Email</h3>
                            <textarea
                              rows={1}
                              className=" fs-12"
                              defaultValue={
                                detailsData?.getMemberActivity.email
                              }
                            ></textarea>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Member Name</h3>
                            <p className="fs-12">
                              {
                                detailsData?.getMemberActivity?.memberDetails
                                  ?.givenName
                              }
                              <span>
                                {
                                  detailsData?.getMemberActivity?.memberDetails
                                    ?.middleName
                                }
                              </span>
                              <span>
                                {
                                  detailsData?.getMemberActivity?.memberDetails
                                    ?.surname
                                }
                              </span>
                            </p>
                          </div>

                          <div className="msg-container">
                            <h3 className="fs-10">Event Type</h3>

                            <textarea
                              className="msg-container fs-12"
                              defaultValue={
                                detailsData?.getMemberActivity?.eventType
                              }
                            ></textarea>
                          </div>
                        </div>
                        <button
                          className="btn btn-small btn-confirm"
                          onClick={() => modalClose()}
                        >
                          CLOSE
                        </button>
                      </div>
                    </>
                  )}

                {memberInteraction === "profile" &&
                  detailsData?.getAuditVisitLog && (
                    <>
                      <div className="member-changes-modal">
                        <h2 className="modal-title fs-35">Profile Views</h2>
                        <div className="details-container">
                          <div className="detail">
                            <h3 className="fs-10">Created At</h3>
                            <p className="fs-12">
                              {format(
                                new Date(
                                  detailsData?.getAuditVisitLog.createdAt
                                ),
                                "E d/LL/yy"
                              )}
                            </p>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Member Name</h3>
                            <p className="fs-12">
                              {
                                detailsData?.getAuditVisitLog?.memberDetails
                                  ?.givenName
                              }{" "}
                              <span>
                                {
                                  detailsData?.getAuditVisitLog?.memberDetails
                                    ?.middleName
                                }
                              </span>
                              <span>
                                {
                                  detailsData?.getAuditVisitLog?.memberDetails
                                    ?.surname
                                }
                              </span>
                            </p>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">clicked By</h3>
                            <p className="fs-12">
                              {
                                detailsData?.getAuditVisitLog?.clickedByDetails
                                  ?.givenName
                              }{" "}
                              <span>
                                {
                                  detailsData?.getAuditVisitLog
                                    ?.clickedByDetails?.middleName
                                }
                              </span>
                              <span>
                                {
                                  detailsData?.getAuditVisitLog
                                    ?.clickedByDetails?.surname
                                }
                              </span>
                            </p>
                          </div>
                          <div className="msg-container">
                            <h3 className="fs-10">What Was Done</h3>
                            <textarea
                              className="msg-container fs-12"
                              defaultValue={
                                detailsData?.getAuditVisitLog?.whatwasDone
                              }
                            >
                              {/* {detailsData?.getAuditVisitLog?.whatwasDone} */}
                            </textarea>
                          </div>
                        </div>

                        <button
                          className="btn btn-small btn-confirm"
                          onClick={() => modalClose()}
                        >
                          CLOSE
                        </button>
                      </div>
                    </>
                  )}
                {memberInteraction === "changes" &&
                  detailsData?.getAuditOperation && (
                    <>
                      <div className="member-changes-modal changes-modal">
                        <h2 className="modal-title fs-35">Changes</h2>
                        <div class="table">
                          <table className="fs-12">
                            <tr>
                              <th>ATTRIBUTES</th>
                              <th>OLD VALUE</th>
                              <th>NEW VALUE</th>
                            </tr>
                            {(() => {
                              const newObj = turnArrToObj(
                                detailsData?.getAuditOperation
                                  ?.newAttributeName,
                                detailsData?.getAuditOperation
                                  ?.newAttributeValue
                              );
                              const oldObj = turnArrToObj(
                                detailsData?.getAuditOperation
                                  ?.oldAttributeName,
                                detailsData?.getAuditOperation
                                  ?.oldAttributeValue
                              );
                              return detailsData?.getAuditOperation?.newAttributeName.map(
                                (i) => {
                                  const a = turnArrToObj(
                                    detailsData?.getAuditOperation
                                      ?.newAttributeName,
                                    detailsData?.getAuditOperation
                                      ?.newAttributeValue
                                  );
                                  return (
                                    <>
                                      <tr>
                                        <td>{i}</td>
                                        <td>{oldObj[i] ?? "-"}</td>
                                        <td>{newObj[i]}</td>
                                      </tr>
                                    </>
                                  );
                                }
                              );
                            })()}
                          </table>
                        </div>
                        <div className="details-container">
                          <div className="detail-entire-row">
                            <div className="detail">
                              <h3 className="fs-10">Updated By</h3>
                              <p className="fs-12">
                                {
                                  detailsData?.getAuditOperation?.updaterDetails
                                    ?.givenName
                                }
                                <span>
                                  {
                                    detailsData?.getAuditOperation
                                      ?.updaterDetails?.middleName
                                  }
                                </span>
                                <span>
                                  {
                                    detailsData?.getAuditOperation
                                      ?.updaterDetails?.surname
                                  }
                                </span>
                              </p>
                            </div>
                          </div>
                          <div className="detail">
                            <h3 className="fs-10">Created At</h3>
                            <p className="fs-12">
                              {format(
                                new Date(
                                  detailsData?.getAuditOperation?.createdAt
                                ),
                                "E d/LL/yy"
                              )}
                            </p>
                          </div>
                        </div>
                        <button
                          className="btn btn-small btn-confirm"
                          onClick={() => modalClose()}
                        >
                          CLOSE
                        </button>
                      </div>
                    </>
                  )}

                {memberInteraction === "sms" && detailsData && (
                  <>
                    <div className="member-changes-modal">
                      <h2 className="modal-title fs-35">SMS</h2>
                      <div className="details-container">
                        <div className="detail-entire-row">
                          <div className="detail">
                            <h3 className="fs-10">Date</h3>
                            <p className="fs-12">
                              {format(
                                new Date(detailsData?.createdAt),
                                "E d/LL/yy"
                              )}
                            </p>
                          </div>
                        </div>
                        <div className="detail">
                          <h3 className="fs-10">Sent By</h3>
                          <p className="fs-12">
                            {detailsData?.status === "sent"
                              ? detailsData?.locationDetails.name
                              : `${detailsData?.memberDetails.givenName} ${detailsData?.memberDetails.surname} `}
                          </p>
                        </div>
                        <div className="detail">
                          <h3 className="fs-10">Status</h3>
                          <p className="fs-12">{detailsData?.status}</p>
                        </div>
                        <div className="msg-container">
                          <h3 className="fs-10">Message</h3>

                          <textarea
                            className="msg-container fs-12"
                            defaultValue={detailsData?.message}
                          ></textarea>
                        </div>
                      </div>
                      <button
                        className="btn btn-small btn-confirm"
                        onClick={() => modalClose()}
                      >
                        CLOSE
                      </button>
                    </div>
                  </>
                )}
                {memberInteraction === "suspensions" && detailsData[0] && (
                  <>
                    <div className="member-changes-modal">
                      <h2 className="modal-title fs-35">SUSPENSION</h2>
                      <div className="details-container">
                        <div className="msg-container">
                          <h3 className="fs-10">Reason</h3>

                          <textarea
                            className="msg-container fs-12"
                            defaultValue={detailsData[0].suspensionReason}
                          ></textarea>
                        </div>
                      </div>
                      <button
                        className="btn btn-small btn-confirm"
                        onClick={() => modalClose()}
                      >
                        CLOSE
                      </button>
                    </div>
                  </>
                )}
              </Modal>
            )}
            {memberInteraction === "membershipAmount" &&
              detailsData[0]?.logType === "CHANGE_IN_MEMBERSHIP_AMOUNT" && (
                <Modal>
                  <div className="status-modal payment-log">
                    <h3 className="fs-35 uppercase ">Logs</h3>
                    <div className="first-row-container fs-12">
                      <div>
                        <label htmlFor="staff" className="text-gray">
                          Staff
                        </label>
                        <input
                          type="text"
                          htmlFor="staff"
                          defaultValue={`${
                            detailsData[0]?.updaterDetails?.givenName ?? ""
                          } ${detailsData[0]?.updaterDetails?.surname ?? ""}`}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="date" className="text-gray">
                          Date
                        </label>
                        <input
                          type="text"
                          htmlFor="date"
                          defaultValue={detailsData[0]?.createdAt.split("T")[0]}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="reason" className="text-gray">
                          Reason
                        </label>
                        <input
                          type="text"
                          htmlFor="reason"
                          defaultValue={detailsData[0]?.reason}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                    </div>
                    <div className="second-row-container fs-12">
                      <div>
                        <label htmlFor="payment method" className="text-gray">
                          Amount changed
                        </label>
                        <input
                          type="text"
                          htmlFor="payment method"
                          defaultValue={(
                            Number(detailsData[0]?.newMembershipAmount) -
                            Number(detailsData[0]?.oldMembershipAmount)
                          ).toFixed(2)}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="previous amount" className="text-gray">
                          Previous amount
                        </label>
                        <input
                          type="text"
                          htmlFor="previous amount"
                          defaultValue={detailsData[0]?.oldMembershipAmount}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="new amount" className="text-gray">
                          New amount
                        </label>
                        <input
                          type="text"
                          htmlFor="new amount"
                          defaultValue={detailsData[0]?.newMembershipAmount}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                    </div>
                    <textarea
                      readOnly
                      defaultValue={detailsData[0]?.description}
                    ></textarea>
                    <button
                      className="btn btn-small btn-confirm"
                      onClick={() => modalClose()}
                    >
                      CLOSE
                    </button>
                  </div>
                </Modal>
              )}
            {memberInteraction === "ddAmount" &&
              detailsData[0]?.__typename === "DirectDebitLog" && (
                <Modal>
                  <div className="status-modal payment-log">
                    <h3 className="fs-35 uppercase ">Logs</h3>
                    <div className="first-row-container fs-12">
                      <div>
                        <label htmlFor="staff" className="text-gray">
                          Staff
                        </label>
                        <input
                          type="text"
                          htmlFor="staff"
                          defaultValue={`${
                            detailsData[0]?.updaterDetails?.givenName ?? ""
                          } ${detailsData[0]?.updaterDetails?.surname ?? ""}`}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="date" className="text-gray">
                          Date
                        </label>
                        <input
                          type="text"
                          htmlFor="date"
                          defaultValue={detailsData[0]?.createdAt.split("T")[0]}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="reason" className="text-gray">
                          Reason
                        </label>
                        <input
                          type="text"
                          htmlFor="reason"
                          defaultValue={detailsData[0]?.reason}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                    </div>
                    <div className="second-row-container fs-12">
                      <div>
                        <label htmlFor="payment method" className="text-gray">
                          Amount changed
                        </label>
                        <input
                          type="text"
                          htmlFor="payment method"
                          defaultValue={(
                            Number(detailsData[0]?.debitAmountNew) -
                            Number(detailsData[0]?.debitAmountOriginal)
                          ).toFixed(2)}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="previous amount" className="text-gray">
                          Previous amount
                        </label>
                        <input
                          type="text"
                          htmlFor="previous amount"
                          defaultValue={detailsData[0]?.debitAmountOriginal}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                      <div>
                        <label htmlFor="new amount" className="text-gray">
                          New amount
                        </label>
                        <input
                          type="text"
                          htmlFor="new amount"
                          defaultValue={detailsData[0]?.debitAmountNew}
                          readOnly
                          className="input-standard"
                        />
                      </div>
                    </div>
                    <textarea
                      readOnly
                      defaultValue={detailsData[0]?.description}
                    ></textarea>
                    <button
                      className="btn btn-small btn-confirm"
                      onClick={() => modalClose()}
                    >
                      CLOSE
                    </button>
                  </div>
                </Modal>
              )}
            <div className="filter-cell-container mt-12">
              {permissions.includes("CanViewSms") && (
                <label className="filter-cell sms">
                  <RadioButton
                    type="radiobutton"
                    name="memberInteraction"
                    id="sms"
                    value="sms"
                    onChange={handleChange}
                    label="SMS"
                  />
                  <span className="icon fs-16">
                    <AiFillPhone />
                  </span>
                </label>
              )}
              <label className="filter-cell changes">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="changes"
                  value="changes"
                  onChange={handleChange}
                  label="Changes"
                />
                <span className="icon fs-16">
                  <MdOutlineChangeCircle />
                </span>
              </label>
              {permissions.includes("CanViewMemberActivity") && (
                <label className="filter-cell web">
                  <RadioButton
                    type="radiobutton"
                    name="memberInteraction"
                    id="logins"
                    value="logins"
                    onChange={handleChange}
                    label="Logins"
                  />
                  <span className="icon fs-16">
                    <TbLogin />
                  </span>
                </label>
              )}
              {permissions.includes("CanViewNotes") && (
                <label className="filter-cell notes">
                  <RadioButton
                    type="radiobutton"
                    name="memberInteraction"
                    id="notes"
                    value="notes"
                    onChange={handleChange}
                    label="Notes"
                  />
                  <span className="icon fs-16">
                    <BiPencil />
                  </span>
                </label>
              )}
              {permissions.includes("CanViewVisitHistory") && (
                <label className="filter-cell profile">
                  <RadioButton
                    type="radiobutton"
                    name="memberInteraction"
                    id="profile"
                    value="profile"
                    onChange={handleChange}
                    label="Profile Views"
                  />
                  <span className="icon fs-16">
                    <CgProfile />
                  </span>
                </label>
              )}
              <label className="filter-cell profile">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="membershipAmount"
                  value="membershipAmount"
                  onChange={handleChange}
                  label="Membership $"
                />
                <span className="icon fs-16">
                  <CgProfile />
                </span>
              </label>
              <label className="filter-cell profile">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="ddAmount"
                  value="ddAmount"
                  onChange={handleChange}
                  label="Direct Debit $"
                />

                <span className="icon fs-16">
                  <CgProfile />
                </span>
              </label>
              <label className="filter-cell profile">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="classBookings"
                  value="classBookings"
                  onChange={handleChange}
                  label="Bookings"
                />
                <span className="icon fs-16">
                  <BiCalendarCheck />
                </span>
              </label>
              <label className="filter-cell profile">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="accessLogs"
                  value="accessLogs"
                  onChange={handleChange}
                  label="ACCESS LOGS"
                />
                <span className="icon fs-16">
                  <LiaDoorOpenSolid />
                </span>
              </label>
              <label className="filter-cell profile">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="suspensions"
                  value="suspensions"
                  onChange={handleChange}
                  label="Suspensions"
                />
                <span className="icon fs-16">
                  <BiMinusCircle />
                </span>
              </label>
              <label className="filter-cell profile">
                <RadioButton
                  type="radiobutton"
                  name="memberInteraction"
                  id="refunds"
                  value="refunds"
                  onChange={handleChange}
                  label="Refunds"
                />
                <span className="icon fs-16">
                  <RiRefund2Line />
                </span>
              </label>
            </div>
          </div>
        </>
      </div>
      {memberInteraction && (
        <div className="table">
          {loading ? (
            <div className="loader">
              <BeatLoader color="white" size={15} />
            </div>
          ) : (
            <Table
              data={tableData}
              className="interactions"
              columns={
                memberInteraction === "sms"
                  ? smsColumns
                  : memberInteraction === "membershipAmount" ||
                    memberInteraction === "ddAmount"
                  ? membershipChangeColumns
                  : memberInteraction === "classBookings"
                  ? classBookingsColumns
                  : memberInteraction === "accessLogs"
                  ? accessLogsColumns
                  : memberInteraction === "suspensions"
                  ? suspensionsColumns
                  : memberInteraction === "refunds"
                  ? refundColumns
                  : columns
              }
              rowsPerPage={10}
              handleNextToken={handleNextToken}
              nextToken={nextToken || nextTokenForIncomingMessage}
            />
          )}
        </div>
      )}
    </>
  );
};

export default MemberInteraction1;
