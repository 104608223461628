import { useMutation } from "@apollo/client";
import { useState, useRef, useEffect } from "react";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";
import { format } from "date-fns";
import { Formik, Form } from "formik";
import { AiOutlineEdit } from "react-icons/ai";
import { UPDATE_MEMBER } from "../../gqloperations/mutations";
import { useMemberContext } from "../../contexts/MemberContext";
import { userPersonalInfoSchema } from "../../validations/profileValidation";

// eslint-disable-next-line no-unused-vars
import {
  title as titles,
  gender as genders,
} from "../../constants/commonConstant";
import {
  Input,
  Button,
  Select,
  HiddenDatePicker,
} from "../../components/index";

const UserPersonalInfo = ({ staffData, refetch, staffId, showEditBTN }) => {
  const fileInputRef = useRef();
  const [editable, setEditable] = useState(false);
  const [file, setFile] = useState(false);
  const [updateMember] = useMutation(UPDATE_MEMBER);
  const {
    downloadAndDecode,
    imgUrl,
    setImgUrl,
    encodeAndUploadImageToAws: uploadImageToAws,
  } = useMemberContext();
  useEffect(() => {
    try {
      (async () => {
        staffData.getMember.imageUrl &&
          (await downloadAndDecode(staffData.getMember.imageUrl));
      })();
    } catch (error) {
      toast.error(`Error: ${error}`);
    }
    return () => {
      setImgUrl("");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [staffData.getMember.imageUrl]);

  const { givenName, middleName, surname, dob, memberId, gender } =
    staffData.getMember;

  const handleCancel = async () => {
    toast.warning("Update Cancelled");
    document.getElementById("personalInfoForm").reset();
    setEditable(false);
    if (staffData.getMember.imageUrl) {
      await downloadAndDecode(staffData.getMember.imageUrl);
    } else {
      setImgUrl("");
    }
  };

  const updateProfileImage = async () => {
    const extension = file.name.split(".");

    const updatedKey = `/staffImages/${staffId}/profile/${uuidv4()}.${extension}`;
    const uploadedFile = await uploadImageToAws(file, updatedKey);
    const imageUrl = uploadedFile?.key;
    return imageUrl;
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];

    if (file.size > 5_242_880) {
      toast.error("Image needs to be less than 5MB");
    } else {
      setFile(file);
      const clientSideImgUrl = URL.createObjectURL(file);
      setImgUrl(clientSideImgUrl);
    }
  };

  const handleImgUploadBtn = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const onSubmit = async (values) => {
    let updatedFields = {};

    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(staffData.getMember[key]).toUpperCase()
      ) {
        updatedFields[key] = values[key].toUpperCase();
      }
    }

    if (file) {
      const imageUrl = await updateProfileImage();

      updatedFields.imageUrl = imageUrl;
    }
    await updateMember({
      variables: {
        input: { ...updatedFields, memberId },
      },
      onCompleted: () => {
        toast.success("Update successfully");
        setEditable(false);
        refetch();
      },
    });
  };

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          givenName: givenName ?? "",
          middleName: middleName ?? "",
          surname: surname ?? "",
          dob: dob ?? "",
          gender: gender ?? "",
        }}
        validationSchema={userPersonalInfoSchema}
        onSubmit={(values) => onSubmit(values)}
      >
        {(formik) => {
          return (
            <Form id="personalInfoForm">
              <div className="personal-info-card flex-wrap">
                <input
                  type="file"
                  onChange={handleImageUpload}
                  accept="image/*"
                  ref={fileInputRef}
                  className="staff-img-input pb-12"
                />
                <div className="img-container">
                  <img
                    src={
                      imgUrl
                        ? imgUrl
                        : "https://www.seekpng.com/png/detail/966-9665317_placeholder-image-person-jpg.png"
                    }
                    alt="Staff Member"
                  />

                  {editable && (
                    <button className="btn" onClick={handleImgUploadBtn}>
                      Upload Picture
                    </button>
                  )}
                </div>
                <div className="info-section">
                  <div className="title-bar">
                    <h3 className="fs-14 uppercase pb-18">
                      Personal Information
                    </h3>
                    {showEditBTN && (
                      <span className="icon-container edit">
                        {editable === false ? (
                          <>
                            <span
                              className="icon-container edit"
                              onClick={() => setEditable(true)}
                            >
                              <AiOutlineEdit />
                            </span>
                          </>
                        ) : (
                          <span className="icon-container">
                            <div className="sub-container save">
                              <Button
                                iconname="BiSave"
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-edit save btn-lg fs-12 "
                              />
                            </div>
                            <div className="sub-container cancel">
                              <Button
                                iconname="AiOutlineCloseCircle"
                                btntype="reset"
                                btnname="reset"
                                className="btn  btn-edit cancel btn-lg fs-12"
                                click={handleCancel}
                              />
                            </div>
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                  <div className="staff-info">
                    <div className="First">
                      <Input
                        type="text"
                        label="First name *"
                        name="givenName"
                        id="givenName"
                        autoComplete="off"
                        disabled={editable ? false : true}
                        className={editable ? "blue" : ""}
                      />
                    </div>
                    <div className="Middle">
                      <Input
                        type="text"
                        label="Middle name"
                        name="middleName"
                        id="middleName"
                        autoComplete="off"
                        disabled={editable ? false : true}
                        className={editable ? "blue" : ""}
                      />
                    </div>
                    <div className="Last">
                      <Input
                        type="text"
                        label="Last name *"
                        name="surname"
                        id="surname"
                        autoComplete="off"
                        disabled={editable ? false : true}
                        className={editable ? "blue" : ""}
                      />
                    </div>

                    <div className="DOB">
                      <HiddenDatePicker
                        disabled={!editable}
                        name="dob"
                        label="Date of Birth"
                      />
                    </div>

                    <div className="gender">
                      <Select
                        label="Gender *"
                        optionvalues={genders}
                        selecttype="array"
                        name="gender"
                        autoComplete="off"
                        disabled={editable ? false : true}
                        className="select-element-value fs-12"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default UserPersonalInfo;
