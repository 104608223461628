import { useEffect, useState } from "react";
import Table from "../../table/Table";
import ExportGroup from "../../export/ExportGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { useLazyQuery } from "@apollo/client";
import { GET_TOTAL_SUSPENDED_REPORT } from "../../../gqloperations/queries";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";
import { ReportGeneratorFormWithMonth } from "../../../pages/index";
import "./executiveReportsTables.scss";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";

export default function SuspendedMembersReport() {
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState();
  const [monthYear, setMonthYear] = useState("");

  const columns = [
    {
      accessorKey: "location.name",
      header: "CLUB",
      cell: (info) => info.getValue(),
      footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "club",
    },
    {
      accessorKey: "location.state",
      header: "STATE",
      cell: (info) => info.getValue(),
      // footer: "TOTAL",
      enableSorting: true,
      enableGlobalFilter: true,
      id: "state",
    },

    {
      accessorKey: "totalSuspended",
      header: "SUSPENDED MEMBERS",
      cell: (info) => info.getValue(),
      footer: () => data?.data?.total?.suspensionCount,
      enableSorting: true,
      enableGlobalFilter: true,
      id: "totalSuspended",
    },
  ];
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();
  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );

  const handleGenerate = (values, onSubmitProps) => {
    setGenerate(true);
    setEndPoint(API.executiveSuspension);
    const parameters = {
      brandId: values.brand,
      locationId: values.location,
      endDate: values.chooseDateFrom,
      startDate: values.chooseDateFrom,
      suspensionType: values.reportCategory,
    };
    if (values.location === "-1") delete parameters.locationId;
    if (values.reportCategory === "ALL") delete parameters.suspensionType;
    setParams(parameters);
  };

  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      toast.error(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) setTableData(data.data.data);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, isLoading, error]);

  return (
    <>
      <ReportGeneratorFormWithMonth
        startDateRequired
        onlyStartDate
        // monthSelect
        noTimezone
        brandAndLocation
        additionalSelect={{
          option: [
            { id: "ALL", name: "ALL" },
            { id: "FRANCHISEE", name: "FRANCHISEE" },
            { id: "CORPORATE", name: "CORPORATE" },
          ],
          label: "Location type",
        }}
        optionFirst={[
          { id: "ALL", name: "ALL" },
          { id: "OTHER", name: "STANDARD SUSPENSION" },
          { id: "MEDICAL", name: "MEDICAL SUSPENSION" },
        ]}
        handleSubmit={handleGenerate}
      />
      {generate && (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData ? tableData : []}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="search"
                    id="search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      )}
      {isLoading && (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      )}
      {!isLoading && (generate || tableData) && (
        <div className="report-table-container-all no-scroll-bar">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={true}
          />
        </div>
      )}
    </>
  );
}
