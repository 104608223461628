import "./contactDetails.scss";
import React, { useRef, useState } from "react";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { state } from "../../constants/commonConstant";
import { toast } from "react-toastify";
import { FiEyeOff } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { UPDATE_MEMBER } from "../../gqloperations/mutations";
import { GET_MEMBER_BY_ID } from "../../gqloperations/queries";
import { contactDetailsSchema } from "../../validations/MembershipValidation";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import Select from "../formUiElements/select/Select";
import InternationalPhoneInput from "../formUiElements/input/InternationalPhoneInput";
import { AiOutlineEdit } from "react-icons/ai";
import { useMemberContext } from "../../contexts/MemberContext";
import { ModalConfirmOtp } from "../../pages/index";
import { BeatLoader } from "react-spinners";

const ContactDetails = (props) => {
  const { permissions } = useAuthContext();
  const { member, createLog, getMemberById } = useMemberContext();
  const [mobileVisible, setMobileVisible] = useState(false);
  const [workPhoneVisible, setWorkPhoneVisible] = useState(false);
  const [homePhoneVisible, setHomePhoneVisible] = useState(false);
  const [addressVisible, setAddressVisible] = useState(false);
  const [isActive, setIsActive] = useState(true);
  const [loadingSaveEmail, setLoadingSaveEmail] = useState(false);
  const updatedRef = useRef();

  const handleClick = () => {
    setIsActive(false);
    setMobileVisible(true);
    setWorkPhoneVisible(true);
    setHomePhoneVisible(true);
    setAddressVisible(true);
    createLog(
      `Clicked Contact detail edit icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("contactDetailseditForm").reset();
    setIsActive(true);
    setMobileVisible(false);
    setWorkPhoneVisible(false);
    setHomePhoneVisible(false);
    setAddressVisible(false);
    createLog(
      `Clicked Contact detail cancel icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleMobileClick = (mobileNumber) => {
    setMobileVisible(!mobileVisible);
    createLog(
      `Clicked mobile view icon for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleWorkPhoneClick = (workPhone) => {
    setWorkPhoneVisible(!workPhoneVisible);
    createLog(
      `Clicked Work phone view icon for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleHomePhoneClick = (homePhone) => {
    setHomePhoneVisible(!homePhoneVisible);
    createLog(
      `Clicked Home phone view icon for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleAddressClick = (address) => {
    setAddressVisible(!addressVisible);
    createLog(
      `Clicked address view icon for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  // const createLog = (values) => {
  //   delete values.memberId;

  //   const newAttributeName = Object.keys(values);
  //   const newAttributeValue = Object.values(values);
  //   const oldAttributeName = Object.keys(oldAttribute);
  //   const oldAttributeValue = Object.values(oldAttribute);

  //   const auditLog = {
  //     newAttributeValue: newAttributeValue,
  //     newAttributeName: newAttributeName,
  //     oldAttributeValue: oldAttributeValue,
  //     oldAttributeName: oldAttributeName,
  //     memberId: `${member.getMember.memberId}`,
  //     updatedBy: updatedBy,
  //     // ipAddress: ipAddress,
  //   };

  //   auditOperation({
  //     variables: {
  //       input: auditLog,
  //     },
  //     onCompleted: () => {},
  //     refetchQueries: [
  //       {
  //         query: GET_MEMBER_BY_ID,
  //         variables: {
  //           memberId: `${member.getMember.memberId}`,
  //         },
  //       },
  //     ],
  //   });
  // };

  //calling a mutation
  // const [
  //   auditOperation,
  //   {
  //     data: auditoperationdata,
  //     loading: auditoperationloading,
  //     error: auditoperationerror,
  //   },
  // ] = useMutation(AUDIT_OPERATION);

  //calling a mutation
  const [updateMember] = useMutation(UPDATE_MEMBER);

  //Saving into the database
  const onSubmit = (values, OnSubmitProps) => {
    if (!values.postCode) {
      delete values.postCode;
    }

    let updatedFields = {};
    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(member.getMember[key]).toUpperCase()
      ) {
        if (key === "secondaryEmail" && values[key] === "") {
          updatedFields[key] = null;
        } else {
          updatedFields[key] = values[key].toUpperCase();
        }
      }
    }
    if (updatedFields.email) {
      updatedRef.current = { updatedFields, OnSubmitProps };
      setNewEmail(updatedFields.email);
      setModalConfirm(true);
      setLoadingSaveEmail(true);
    } else updateMemberDetails(updatedFields, OnSubmitProps);
  };
  const [newEmail, setNewEmail] = useState("");
  const updateMemberDetails = (updatedFields, OnSubmitProps) => {
    updateMember({
      variables: {
        input: { ...updatedFields, memberId: member.getMember.memberId },
      },
      onCompleted: () => {
        OnSubmitProps.resetForm();
        toast.success("Updated successfully");
        setIsActive(true);
        setMobileVisible(false);
        setWorkPhoneVisible(false);
        setHomePhoneVisible(false);
        setAddressVisible(false);
        createLog(
          `Clicked Contact detail save icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
        getMemberById();
      },
      onError: (error) => {
        toast.error(`${error}`);
        createLog(
          `Clicked Contact detail save icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: `${member.getMember.memberId}`,
          },
        },
      ],
    });
  };
  const [modalConfirm, setModalConfirm] = useState(false);
  return (
    <>
      {modalConfirm && (
        <ModalConfirmOtp
          onClose={() => {
            setModalConfirm(false);
          }}
          setNewEmail={setNewEmail}
          newEmail={newEmail}
          setLoading={setLoadingSaveEmail}
          onComplete={async () => {
            setNewEmail("");
            updateMemberDetails(
              updatedRef.current.updatedFields,
              updatedRef.current.OnSubmitProps
            );
          }}
        />
      )}
      <Formik
        enableReinitialize
        initialValues={{
          email: `${member?.getMember?.email}`,
          secondaryEmail: `${member.getMember.secondaryEmail || ""}`,
          mobileNumber: `${member.getMember.mobileNumber}`,
          workPhone: `${member.getMember.workPhone || ""}`,
          homePhone: `${member.getMember.homePhone || ""}`,
          address: `${member.getMember.address ?? ""}`,
          suburb: `${member.getMember.suburb ?? ""}`,
          state: `${member.getMember.state}`,
          postCode: `${member.getMember.postCode ?? ""}`,
        }}
        validationSchema={contactDetailsSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form
              id="contactDetailseditForm"
              className="contact-container pd-12"
            >
              <div className="title-bar fs-14 uppercase">
                <h3 className="fs-14">Contact Details</h3>
                {permissions.includes("CanEditMember") && (
                  <>
                    {isActive ? (
                      <>
                        <span
                          className="icon-container edit"
                          onClick={() => handleClick()}
                        >
                          <AiOutlineEdit />
                        </span>
                      </>
                    ) : (
                      <span className="icon-container">
                        <div className="sub-container save">
                          {loadingSaveEmail ? (
                            <BeatLoader
                              className="loading"
                              color="white"
                              size={10}
                            />
                          ) : (
                            <Button
                              iconname="BiSave"
                              btntype="submit"
                              btnname="submit"
                              className="btn btn-edit save btn-lg fs-12 "
                            />
                          )}
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="contact-info-container">
                <div className="email">
                  {" "}
                  <Input
                    type="text"
                    label="Email address (primary) "
                    name="email"
                    id="email"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
                <div className="email-sec">
                  {" "}
                  <Input
                    type="text"
                    label="Email address (secondary) "
                    name="secondaryEmail"
                    id="secondaryEmail"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
                <div className="mobile">
                  {isActive ? (
                    <>
                      <div className="hidden-title fs-12">Mobile Number *</div>
                      {!mobileVisible ? (
                        <>
                          <p className="hidden-value fs-12">
                            {member.getMember.mobileNumber.substring(0, 3)}***
                            {member.getMember.mobileNumber.substring(
                              member.getMember.mobileNumber.length - 2,
                              member.getMember.mobileNumber.length
                            )}
                            <span className="fs-14">
                              <FiEyeOff
                                onClick={() =>
                                  handleMobileClick(
                                    member.getMember.mobileNumber
                                  )
                                }
                              />
                            </span>
                          </p>
                        </>
                      ) : (
                        <>
                          <p className="hidden-value fs-12">
                            {member.getMember.mobileNumber}
                            <span className="fs-14">
                              <FiEye
                                onClick={() =>
                                  handleMobileClick(
                                    member.getMember.mobileNumber
                                  )
                                }
                              />
                            </span>
                          </p>
                        </>
                      )}
                    </>
                  ) : (
                    <InternationalPhoneInput
                      title="Mobile Number*"
                      name="mobileNumber"
                      id="mobileNumber"
                      editable={true}
                    />
                  )}
                </div>

                <div className="mobile work-phone">
                  {isActive ? (
                    <>
                      <div className="hidden-title fs-12">Work Phone</div>
                      {!workPhoneVisible ? (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.workPhone ? (
                              <>
                                {member.getMember.workPhone.substring(0, 3)}
                                ***
                                {member.getMember.workPhone.substring(
                                  member.getMember.workPhone.length - 2,
                                  member.getMember.workPhone.length
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <span className="fs-14">
                              <FiEyeOff
                                onClick={() =>
                                  handleWorkPhoneClick(
                                    `${member.getMember.workPhone}`
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.workPhone
                              ? member.getMember.workPhone
                              : ""}
                            <span className="fs-14">
                              <FiEye
                                onClick={() =>
                                  handleWorkPhoneClick(
                                    `${member.getMember.workPhone}`
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <InternationalPhoneInput
                      title="Work Phone"
                      name="workPhone"
                      id="workPhone"
                      editable={true}
                    />
                  )}
                </div>

                <div className="mobile home-phone">
                  {isActive ? (
                    <>
                      <div className="hidden-title fs-12">Home Phone</div>
                      {!homePhoneVisible ? (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.homePhone ? (
                              <>
                                {member.getMember.homePhone.substring(0, 3)}
                                ***
                                {member.getMember.homePhone.substring(
                                  member.getMember.homePhone.length - 2,
                                  member.getMember.homePhone.length
                                )}
                              </>
                            ) : (
                              ""
                            )}
                            <span className="fs-14">
                              <FiEyeOff
                                onClick={() =>
                                  handleHomePhoneClick(
                                    `${member.getMember.homePhone}`
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.homePhone
                              ? member.getMember.homePhone
                              : ""}
                            <span className="fs-14">
                              <FiEye
                                onClick={() =>
                                  handleHomePhoneClick(
                                    `${member.getMember.homePhone}`
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <InternationalPhoneInput
                      title="Home Phone"
                      name="homePhone"
                      id="homePhone"
                      editable={true}
                    />
                  )}
                </div>

                <div className="mobile address">
                  {!addressVisible ? (
                    <>
                      <div className="hidden-title fs-12">Address</div>
                      <div className="hidden-value fs-12">
                        {member.getMember.address ? (
                          <>
                            {member.getMember.address.substring(0, 2)}*****
                            {member.getMember.address.substring(
                              member.getMember.address.length - 2,
                              member.getMember.address.length
                            )}
                          </>
                        ) : (
                          ""
                        )}

                        <span className="fs-14">
                          <FiEyeOff
                            onClick={() =>
                              handleAddressClick(member.getMember.address)
                            }
                          />
                        </span>
                      </div>
                    </>
                  ) : (
                    <>
                      <span
                        className={
                          isActive
                            ? "input-value-gray abs-error"
                            : "input-value-persianblue abs-error"
                        }
                      >
                        <Input
                          type="text"
                          label="Address"
                          name="address"
                          id="address"
                          icon="true"
                          autoComplete="off"
                          disabled={isActive ? true : false}
                          className={isActive ? "" : "blue"}
                        />
                        <span className="fs-14">
                          <FiEye
                            onClick={() =>
                              handleAddressClick(member.getMember.address)
                            }
                          />
                        </span>
                      </span>
                    </>
                  )}
                </div>
                <div className="suburb">
                  <Input
                    type="text"
                    label="Suburb"
                    name="suburb"
                    id="suburb"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
                <div className="state">
                  <Select
                    label="State"
                    optionvalues={state}
                    selecttype="array"
                    name="state"
                    id="state"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    labelclass={isActive ? "" : "blue"}
                    className={
                      isActive
                        ? "select-element-value "
                        : "select-element-value blue"
                    }
                  />
                </div>
                <div className="post">
                  <Input
                    type="text"
                    label="Postcode"
                    name="postCode"
                    id="postCode"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default ContactDetails;
