import React, { useRef } from "react";

import "./yourMembership.scss";

import { useAddMemberContext } from "../../../../contexts/AddMemberContext";
import { addDays, addWeeks, format, isAfter } from "date-fns";
import { useMemberContext } from "../../../../contexts/MemberContext";
import { DatePicker } from "../../../../components";
import checkDateOverlap from "../../../../utils/checkDateOverlap";

function YourMembership() {
  const { memberData, setMemberData, memberInformation, step } =
    useAddMemberContext();
  const { sidebarLocationName } = useMemberContext();

  const isPaymentDelay =
    memberInformation?.membershipDetails.membershipDetails?.paymentDelay
      ?.amount !== 0 &&
    memberInformation?.membershipDetails.membershipDetails?.paymentDelay !==
      null;
  const isVoucherDelay =
    memberInformation?.voucherDetail?.paymentDelay?.amount !== 0 &&
    Object.keys(memberInformation?.voucherDetail?.paymentDelay || {}).length;
  const zeroCostMembership =
    !memberInformation.yourMembershipData?.membershipPrice &&
    !memberInformation?.yourMembershipData?.joiningFee &&
    !memberInformation?.yourMembershipData?.activationFee &&
    !memberInformation?.yourMembershipData?.accessFee &&
    !isPaymentDelay &&
    !isVoucherDelay;

  const calculateDateFromSpecificDate = ({ type, amount, date }) => {
    const currentDate = date ? new Date(date) : new Date();
    if (type === "DAYS") {
      currentDate.setDate(currentDate.getDate() + amount);
    } else if (type === "MONTHS") {
      currentDate.setMonth(currentDate.getMonth() + amount);
    } else if (type === "YEARS") {
      currentDate.setFullYear(currentDate.getFullYear() + amount);
    } else if (type === "WEEKS") {
      currentDate.setDate(currentDate.getDate() + amount * 7);
    }

    return currentDate;
  };

  let nextDebit;
  if (isPaymentDelay || isVoucherDelay) {
    const maintenanceDates =
      memberInformation?.locationDetail?.locationMaintenance?.items.filter(
        (maintenance) => !maintenance.isDeleted
      );
    const currentMaintenance = maintenanceDates?.find(
      ({ startDateTime, endDateTime }) => {
        return checkDateOverlap(new Date(), {
          start: startDateTime,
          end: endDateTime,
        });
      }
    );
    nextDebit = calculateDateFromSpecificDate({
      type: isVoucherDelay
        ? memberInformation?.voucherDetail?.paymentDelay?.type
        : memberInformation?.membershipDetails.membershipDetails?.paymentDelay
            ?.type,
      amount: isVoucherDelay
        ? memberInformation?.voucherDetail?.paymentDelay?.amount
        : memberInformation?.membershipDetails.membershipDetails?.paymentDelay
            ?.amount,
      date:
        memberInformation.locationDetail.openDate &&
        isAfter(new Date(memberInformation.locationDetail.openDate), new Date())
          ? memberInformation.locationDetail.openDate
          : currentMaintenance
          ? addDays(new Date(currentMaintenance.endDateTime), 1)
          : new Date(),
    });
  }

  const startDayRef = useRef(memberData.membershipStartDate);

  const calculateMinDate = () => {
    const startDay = startDayRef.current;
    const openDay = memberInformation.locationDetail.openDate;

    if (startDay && !openDay) return format(new Date(startDay), "yyyy-MM-dd");

    if (!startDay && openDay) return format(new Date(openDay), "yyyy-MM-dd");

    if (startDay && openDay) {
      const startDate = new Date(startDay);
      const openDate = new Date(openDay);

      if (isAfter(startDate, openDate)) return format(startDate, "yyyy-MM-dd");

      return format(openDate, "yyyy-MM-dd");
    }

    return format(new Date(), "yyyy-MM-dd");
  };

  return (
    <div className="your-membership-container">
      <h1 className="title">Your Membership</h1>
      <div className="location">
        <div>
          <p>{sidebarLocationName}</p>
        </div>
        <p className="address">{memberInformation.locationDetail.address1}</p>
      </div>
      <div className="price-details">
        <div className="membership-plan">
          <p>Membership plan</p>
        </div>
        <div className="membership-price">
          <p>
            {memberInformation?.membershipDetails?.membershipDetails?.membershipName.toUpperCase()}
          </p>
          <p>
            $
            {isVoucherDelay || isPaymentDelay
              ? memberInformation?.yourMembershipData.membershipPriceDisplay.toFixed(
                  2
                )
              : memberInformation?.yourMembershipData?.membershipPrice.toFixed(
                  2
                )}
          </p>
        </div>
        <div className="membership-fee">
          <p>JOINING FEE</p>
          <p>${memberInformation?.yourMembershipData?.joiningFee.toFixed(2)}</p>
        </div>
        <div className="membership-fee">
          <p>ACTIVATION FEE</p>
          <p>
            ${memberInformation?.yourMembershipData?.activationFee.toFixed(2)}
          </p>
        </div>
        <div className="membership-fee">
          <p>ACCESS FEE</p>
          <p>${memberInformation?.yourMembershipData?.accessFee.toFixed(2)}</p>
        </div>
      </div>
      <div className="promo-code">
        <p>Promo code</p>
        <input
          value={
            "PF-" +
            (memberInformation?.voucherDetail
              ? memberInformation?.voucherDetail?.voucherCode ?? ""
              : "")
          }
          disabled
        />
      </div>
      {memberInformation.age >= 18 && (
        <div className="membership-start-date">
          <p>Your start date</p>
          <div className="date-input">
            <DatePicker
              value={format(
                new Date(memberData.membershipStartDate),
                "yyyy-MM-dd"
              )}
              disabled={
                isPaymentDelay ||
                isVoucherDelay ||
                memberInformation.age < 18 ||
                step > 2
              }
              onChange={(e) => {
                setMemberData({
                  ...memberData,
                  membershipStartDate: new Date(e.target.value),
                });
              }}
              min={calculateMinDate()}
              max={format(
                memberInformation.locationDetail.openDate &&
                  isAfter(
                    new Date(memberInformation.locationDetail.openDate),
                    new Date()
                  )
                  ? new Date(
                      new Date(
                        memberInformation.locationDetail.openDate
                          .split("/")
                          .reverse()
                          .join("-")
                      ).setMonth(
                        new Date(
                          memberInformation.locationDetail.openDate
                            .split("/")
                            .reverse()
                            .join("-")
                        ).getMonth() + 3
                      )
                    )
                  : new Date(new Date().setMonth(new Date().getMonth() + 3)),
                "yyyy-MM-dd"
              )}
            />
          </div>
          {/* <input value={"PF-alskdfj"} disabled /> */}
        </div>
      )}
      {memberInformation?.membershipDetails.membershipDetails?.recurring &&
        memberInformation?.voucherDetail?.type !== "COMPLIMENTARY" &&
        memberInformation.age >= 18 &&
        !zeroCostMembership && (
          <div className="next-direct">
            <p>Next direct debit</p>
            <p>
              {format(
                isVoucherDelay || isPaymentDelay
                  ? new Date(nextDebit)
                  : addWeeks(new Date(memberData.membershipStartDate), 2),
                "dd/MM/yyyy"
              )}
            </p>
          </div>
        )}
      {memberInformation?.membershipDetails.membershipDetails?.recurring &&
        memberInformation?.voucherDetail?.type !== "COMPLIMENTARY" && (
          <div className="fortnightly">
            <p>Cost per fortnight</p>
            <p>
              $
              {isVoucherDelay || isPaymentDelay
                ? memberInformation?.yourMembershipData.membershipPriceDisplay.toFixed(
                    2
                  )
                : (memberInformation?.yourMembershipData.membershipPrice).toFixed(
                    2
                  )}
            </p>
          </div>
        )}
      <div className="total">
        <p>TOTAL DUE TODAY*</p>
        <p>
          $
          {Number(
            memberInformation?.yourMembershipData.joiningFee +
              memberInformation?.yourMembershipData.activationFee +
              memberInformation?.yourMembershipData.accessFee +
              memberInformation?.yourMembershipData.membershipPrice
          ).toFixed(2)}
        </p>
      </div>
    </div>
  );
}

export default YourMembership;
