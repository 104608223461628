import React, { useEffect } from "react";
import Table from "../table/Table";
import { useState } from "react";
import Button from "../formUiElements/Button";
import { format } from "date-fns";
import { sortDesc } from "../../utils/sort";
const BillingDebitLogModal = ({ curRow, modalClose }) => {
  const [tableData, setTableData] = useState();
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);

  useEffect(() => {
    setTableData(sortDesc(curRow?.directDebitLog?.items, "createdAt"));
  }, [curRow]);

  const columns = [
    {
      // format(new Date(info.getValue().split("T")[0]), "dd/MM/yy")
      accessorFn: (row) =>
        format(new Date(row?.createdAt?.split("T")[0]), "dd/MM/yy"),
      header: "Date",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) =>
        `${row.updaterDetails?.givenName} ${row?.updaterDetails?.surname}`,
      header: "User",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => row?.reason,
      header: "Reason",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "reason",
    },
    {
      accessorFn: (row) =>
        `${Number(row?.debitAmountNew - row?.debitAmountOriginal).toFixed(2)}`,
      header: "Amount changed",
      cell: (info) => `$${info?.getValue()}`,
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => `${Number(row?.debitAmountOriginal).toFixed(2)}`,
      header: "Previous amount",
      cell: (info) => `$${info?.getValue()}`,
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ];
  return (
    <div>
      <div className="status-modal payment-log">
        <h3 className="fs-35 uppercase">Logs</h3>

        <Table
          data={tableData}
          columns={columns}
          sorting={sorting}
          setSorting={setSorting}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
          columnFilters={columnFilters}
          setColumnFilters={setColumnFilters}
        />
        <Button
          name="Close"
          btntype="submit"
          btnname="submit"
          className="btn btn-confirm btn-large"
          click={modalClose}
        />
      </div>
    </div>
  );
};

export default BillingDebitLogModal;
