import "./bankAccountRefund.scss";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineSearch } from "react-icons/ai";
import actions from "../../assets/images/icons/action_menu.png";
import { useLazyQuery, useMutation } from "@apollo/client";
import { useMemberContext } from "../../contexts/MemberContext";
import { GET_REFUND_TRANSACTION_BY_BRAND } from "../../gqloperations/queries";
import {
  GENERATE_ABA_FILE,
  REFUND_TRANSACTION,
} from "../../gqloperations/mutations";
import { BeatLoader } from "react-spinners";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import {
  StatusModal,
  Modal,
  Button,
  Table,
  Header,
  Checkbox,
} from "../../components";
import { toast } from "react-toastify";
import { utcToZonedTime } from "date-fns-tz";
import DeclineRefundModal from "../../components/modal/DeclineRefundModal";
import { SET_MEMBER_ID } from "../../actions";
import { sortDesc } from "../../utils/sort";

function BankAccountRefund() {
  const ref = useRef();
  const navigate = useNavigate();
  const { sidebarBrandId, dispatch } = useMemberContext();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [showActions, setShowActions] = useState({});
  const [componentName, setComponentName] = useState("");
  const [requestedTableData, setRequestedTableData] = useState([]);
  const [refundedTableData, setRefundedTableData] = useState([]);
  const [curRow, setCurRow] = useState({});
  const [refundTransaction, { loading: refundTransactionLoading }] =
    useMutation(REFUND_TRANSACTION);
  const [variables, setVariables] = useState({
    brandId: sidebarBrandId,
    operationName: "getRefundTransactionsByBrandId",
    filter: JSON.stringify({
      accountNumber: { condition: "attribute_exists" },
    }),
  });
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(`false`);
  const [rowSelection, setRowSelection] = useState({});

  const [tab, setTab] = useState("requested");

  const [generatABAFile, { loading: generatABAFileLoading }] =
    useMutation(GENERATE_ABA_FILE);

  const [
    getListRefundTransactions,
    { loading: listRefundtransactionLoading, refetch },
  ] = useLazyQuery(GET_REFUND_TRANSACTION_BY_BRAND);

  useEffect(() => {
    if (tab === "requested") {
      setRefundedTableData([]);
      getListRefundTransactions({
        fetchPolicy: "no-cache",
        variables: {
          brandId: sidebarBrandId,
          operationName: "getRefundTransactionsByBrandId",
          filter: JSON.stringify({
            accountNumber: { condition: "attribute_exists" },
            refundStatus: { value: "PENDING" },
          }),
        },
        onCompleted: (data) => {
          const sortListTransactions = sortDesc(
            data.getRefundTransactionsByByBrandIdAndFilter,
            "createdAt"
          );
          setRequestedTableData((prevTableData) => [
            ...prevTableData,
            ...sortListTransactions,
          ]);
          setNextToken(
            data.getRefundTransactionsByByBrandIdAndFilter?.nextToken
          );
        },
        onError: (err) => {
          toast.error(`${err}`);
        },
      });
    } else {
      setRequestedTableData([]);
      getListRefundTransactions({
        fetchPolicy: "no-cache",
        variables: {
          brandId: sidebarBrandId,
          operationName: "getRefundTransactionsByBrandId",
          filter: JSON.stringify({
            accountNumber: { condition: "attribute_exists" },
            refundStatus: { condition: "<>", value: "PENDING" },
          }),
        },
        onCompleted: (data) => {
          const sortListTransactions = sortDesc(
            data.getRefundTransactionsByByBrandIdAndFilter,
            "updatedAt"
          );
          setRefundedTableData((prevTableData) => [
            ...prevTableData,
            ...sortListTransactions,
          ]);
          setNextToken(
            data.getRefundTransactionsByByBrandIdAndFilter?.nextToken
          );
        },
        onError: (err) => {
          toast.error(`${err}`);
        },
      });
    }
  }, [tab]);

  const handleNextToken = () => {
    if (nextToken) {
      setVariables({
        ...variables,
        nextToken: nextToken,
      });
    }
  };
  const handleRowClick = (id) => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: id,
      },
    });
    navigate(`/dashboard/member/${id}`);
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const requestColumns = [
    {
      id: "select",
      header: ({ table }) => {
        setIsSelectAllChecked(table.getIsAllRowsSelected());
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              <Checkbox
                {...{
                  checked: table.getIsAllRowsSelected(),
                  indeterminate: table.getIsSomeRowsSelected(),
                  onChange: table.getToggleAllRowsSelectedHandler(),
                }}
              />
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => {
        return (
          <div className="row-checkbox">
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          </div>
        );
      },
    },
    {
      accessorFn: (row) => {
        const memberDetails = row.memberDetails;
        const memberName =
          memberDetails?.givenName + " " + memberDetails?.surname;
        return memberName;
      },
      header: "NAME",

      cell: (info) => {
        const memberDetails = info.getValue();
        return memberDetails;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      accessorFn: (row) => {
        const memberDetails = row.memberDetails;

        return memberDetails?.aliasMemberId;
      },
      header: "Alias Id",

      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "aliasMemberId",
    },
    {
      accessorFn: (row) => {
        const locationDetails = row?.locationDetails;

        return locationDetails?.name;
      },
      header: "Home Club",

      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "homeClub",
    },
    {
      accessorKey: "refundAmount",
      header: "Refund Amount",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "refundAmount",
    },
    {
      accessorKey: "accountNumber",
      header: "Account number",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "accountNumber",
    },
    {
      accessorKey: "bsb",
      header: "BSB",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "bsb",
    },
    {
      accessorFn: (row) => {
        const billingDetail = row.billingDetail;

        return billingDetail.debitDate;
      },
      header: "Debit date",

      cell: (info) => {
        return info?.getValue()
          ? format(
              utcToZonedTime(
                info.getValue(),
                info.row.original.billingDetail.timezone ?? "Australia/sydney"
              ),
              "dd/MM/yyyy"
            )
          : "";
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "Debit date",
    },
    {
      accessorKey: "createdAt",
      header: "Requested refund date",
      cell: (info) => {
        return info?.getValue()
          ? format(
              utcToZonedTime(
                info.getValue(),
                info.row.original.billingDetail.timezone ?? "Australia/sydney"
              ),
              "dd/MM/yyyy"
            )
          : "";
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "createdAt",
    },
    {
      accessorFn: (row) => {
        return `${row?.requestByDetails?.givenName ?? ""} ${
          row?.requestByDetails?.surname ?? ""
        }`;
      },
      header: "Requested by",

      cell: (info) => {
        const memberDetails = info.getValue();
        return memberDetails;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "requestedBy",
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prevShowActions) => ({
                  ...prevShowActions,
                  [id]: !areActionsVisible,
                }));
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() =>
                      handleRowClick(
                        props?.row?.original.memberDetails.memberId
                      )
                    }
                  >
                    <Button
                      name="View"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleComponent("approve-confirm", {
                        id: id,
                        requestRefundInfo: props?.row?.original,
                      });
                    }}
                  >
                    <Button
                      name="APPROVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleComponent("decline-confirm", {
                        id: id,
                        requestRefundInfo: props?.row?.original,
                      });
                    }}
                  >
                    <Button
                      name="DECLINE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];
  const refundedColumns = [
    {
      accessorFn: (row) => {
        const memberDetails = row.memberDetails;
        const memberName =
          memberDetails?.givenName + " " + memberDetails?.surname;
        return memberName;
      },
      header: "NAME",

      cell: (info) => {
        const memberDetails = info.getValue();
        return memberDetails;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      accessorFn: (row) => {
        const memberDetails = row.memberDetails;

        return memberDetails?.aliasMemberId;
      },
      header: "Alias Id",

      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "aliasMemberId",
    },
    {
      accessorFn: (row) => {
        const locationDetails = row?.locationDetails;

        return locationDetails?.name;
      },
      header: "Home Club",

      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "homeClub",
    },
    {
      accessorKey: "refundAmount",
      header: "Refund Amount",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "refundAmount",
    },
    {
      accessorKey: "accountNumber",
      header: "Account number",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "accountNumber",
    },
    {
      accessorKey: "bsb",
      header: "BSB",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "bsb",
    },
    {
      accessorFn: (row) => {
        const billingDetail = row.billingDetail;

        return billingDetail.debitDate;
      },
      header: "Debit date",

      cell: (info) => {
        return info?.getValue()
          ? format(
              utcToZonedTime(
                info.getValue(),
                info.row.original.billingDetail.timezone ?? "Australia/sydney"
              ),
              "dd/MM/yyyy"
            )
          : "";
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "Debit date",
    },

    {
      accessorFn: (row) => {
        return `${row?.requestByDetails?.givenName ?? ""} ${
          row?.requestByDetails?.surname ?? ""
        }`;
      },
      header: "Requested by",

      cell: (info) => {
        const memberDetails = info.getValue();
        return memberDetails;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "requestedBy",
    },
    {
      accessorFn: (row) =>
        row.refundStatus === "FAILED" ? (
          <div className="text-red">DECLINED</div>
        ) : (
          <div className="text-green">{row.refundStatus}</div>
        ),
      header: "refund status",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "refundStatus",
    },
    {
      accessorKey: "refundDate",
      header: "Refunded date",
      cell: (info) => {
        return info?.getValue()
          ? format(
              utcToZonedTime(
                info.getValue(),
                info.row.original.billingDetail.timezone ?? "Australia/sydney"
              ),
              "dd/MM/yyyy"
            )
          : "";
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "createdAt",
    },
    {
      accessorFn: (row) => {
        return `${row?.refundByDetails?.givenName ?? ""} ${
          row?.refundByDetails?.surname ?? ""
        }`;
      },
      header: "Refunded by",

      cell: (info) => {
        const memberDetails = info.getValue();
        return memberDetails;
      },
      enableSorting: true,
      enableGlobalFilter: true,
      id: "refundedBy",
    },
  ];

  const handleComponent = (componentName, rowObj) => {
    setComponentName(componentName);
    setCurRow(rowObj);
  };

  const handleApproveClick = async (rowObj) => {
    let values = {
      id: rowObj.id,
    };
    try {
      await refundTransaction({
        variables: {
          operation: "UPDATE_STATUS",
          refundId: values.id,
          status: "SUCCESS",
        },
        onCompleted: () => {
          setRequestedTableData([]);
          refetch();
          setNextToken(null);
          toast.success("Request refund approve Successfully ");
          modalClose();
        },
        onError: (error) => {
          toast.error(`${error}`);
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleDeclineClick = async (rowObj) => {
    let values = {
      id: rowObj.curRow.id,
    };
    try {
      await refundTransaction({
        variables: {
          operation: "UPDATE_STATUS",
          refundId: values.id,
          status: "FAILED",
          reason: rowObj.reason,
        },
        onCompleted: () => {
          setRequestedTableData([]);
          setNextToken(null);
          refetch();
          toast.success("Request refund reject Successfully ");
          modalClose();
        },
        onError: (error) => {
          toast.error(`${error}`);
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const downloadFileWithName = async (ABAurl, fileName) => {
    const link = document.createElement("a");
    link.href = ABAurl;
    link.download = ABAurl.split("/")[ABAurl.length - 1];
    link.target = "_blank";
    link.click();
  };

  const handleGenerateABAFile = async () => {
    const selectedRefunds = Object.keys(rowSelection).map(
      (cur) => requestedTableData[cur].id
    );

    await generatABAFile({
      fetchPolicy: "no-cache",
      variables: {
        action: "GenRefundBatches",
        brandId: sidebarBrandId,
        refundIds: selectedRefunds,
      },
      onCompleted: (data) => {
        downloadFileWithName(data.generateABA.url, "test");
        console.log(data, "dadadadad");
      },
    });
  };

  return (
    <div className="bank-refund-container">
      <Header pageTitle="Bank account refund" />
      {componentName && (
        <Modal>
          {componentName === "approve-confirm" && (
            <StatusModal
              loading={refundTransactionLoading}
              curRow={curRow}
              modalHeaderTxt={"APPROVE REQUEST REFUND"}
              modalParaText={
                "ARE YOU SURE YOU WANT TO APPROVE THIS REQUEST REFUND?"
              }
              handleStatusClick={handleApproveClick}
              modalClose={modalClose}
            />
          )}

          {componentName === "decline-confirm" && (
            <DeclineRefundModal
              curRow={curRow}
              loading={refundTransactionLoading}
              cancelFn={modalClose}
              handleDeclineRefundSubmit={handleDeclineClick}
            />
          )}
        </Modal>
      )}
      <div className="tabs mt-32">
        <div className="tabs-list">
          <div
            className={
              tab !== "requested"
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setTab("requested");
            }}
          >
            REQUESTED
          </div>

          <div
            className={
              tab !== "report"
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setTab("report");
            }}
          >
            REPORT
          </div>
        </div>
      </div>
      <div className="actions-row mt-32 feature-row ">
        <div className="right-elements">
          <div className="search-and-button-row">
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Member name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {listRefundtransactionLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : (
        <div className="landing-table">
          {tab === "requested" ? (
            <div>
              <Table
                onRowClick={(i) => {
                  const id = i.memberDetails.memberId;
                  handleRowClick(id);
                }}
                data={requestedTableData}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columns={requestColumns}
                sorting={sorting}
                setSorting={setSorting}
                rowsPerPage={10}
                handleNextToken={handleNextToken}
                nextToken={nextToken}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
                setRowSelection={setRowSelection}
                rowSelection={rowSelection}
              />
              {requestedTableData.length > 0 && (
                <div className="button-container">
                  <div className="button-container">
                    <Button
                      name={"Generate ABA"}
                      btntype="button"
                      btnname="save-button"
                      className={`btn btn-confirm btn-large fs-12 ${
                        Object.keys(rowSelection || {}).length > 0
                          ? ""
                          : "btn-disabled"
                      }`}
                      click={handleGenerateABAFile}
                      loading={generatABAFileLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          ) : (
            <Table
              onRowClick={(i) => {
                const id = i.memberDetails.memberId;
                handleRowClick(id);
              }}
              data={refundedTableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columns={refundedColumns}
              sorting={sorting}
              setSorting={setSorting}
              rowsPerPage={10}
              handleNextToken={handleNextToken}
              nextToken={nextToken}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
            />
          )}
        </div>
      )}
    </div>
  );
}

export default BankAccountRefund;
