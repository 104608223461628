import { Formik, Form } from "formik";
import Input from "../formUiElements/input/Input";
import Button from "../formUiElements/Button";
import { useMemberContext } from "../../contexts/MemberContext";
import { getUTCdate } from "../../utils/getUTCdate";
import { toast } from "react-toastify";
import { cardSchema } from "../../validations/Validation";
import { GET_CARD_DETAILS_BY_MEMBERID } from "../../gqloperations/queries";
import { UPDATE_CARD_OF_MEMBER } from "../../gqloperations/mutations";
import { useMutation } from "@apollo/client";
import { format } from "date-fns";

export default function EditCardModal({ card, modalClose }) {
  const { member } = useMemberContext();
  const [updateCardOfMember] = useMutation(UPDATE_CARD_OF_MEMBER);
  const onSubmit = (values, OnSubmitProps) => {
    const input = {
      cardNumber: values?.cardNumber,
      endDateTime: values?.endDate ? getUTCdate(values.endDate) : null,
      memberId: member?.getMember?.memberId,
      id: card?.id,
      operationName: "updateCardOfMember",
    };
    updateCardOfMember({
      variables: input,
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        toast.success("Updated Successfully");
        modalClose();
      },
      onError: (error) => {
        toast.error(`${error}`);
        return;
      },
      refetchQueries: [
        {
          query: GET_CARD_DETAILS_BY_MEMBERID,
          variables: {
            memberId: member.getMember.memberId,
          },
        },
      ],
    });
    OnSubmitProps.setSubmitting(false);
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          cardNumber: card.cardNumber ?? "",
          endDate: card.endDateTime
            ? `${format(new Date(card.endDateTime), "yyyy-MM-dd")}`
            : "",
        }}
        validationSchema={cardSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <div className="status-modal edit-card-container pd-32">
              <Form>
                <h2 className="uppercase fs-35">Edit card</h2>
                <Input
                  type="text"
                  label="Card Number *"
                  name="cardNumber"
                  id="cardNumber"
                  autoComplete="off"
                />
                <Input
                  type="date"
                  label="Expiry Date"
                  name="endDate"
                  id="endDate"
                  autoComplete="off"
                  min={`${format(new Date(), "yyyy-MM-dd")}`}
                />

                <div className="btn-container">
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-primary btn-lg fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="cancel"
                    btntype="button"
                    btnname="cancel"
                    click={modalClose}
                    className="btn btn-deny btn-small fs-12"
                  />
                </div>
              </Form>
            </div>
          );
        }}
      </Formik>
    </>
  );
}
