import "./groups.scss";
import { React, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useQuery, useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { BeatLoader } from "react-spinners";
import isAfter from "date-fns/isAfter";
import { AiOutlineSearch } from "react-icons/ai";
import actions from "../../assets/images/icons/action_menu.png";
import { LIST_GROUPS } from "../../gqloperations/queries";
import { DELETE_GROUP } from "../../gqloperations/mutations";
import { StatusModal, Header, Button, Modal, Table } from "../../components";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";

const Groups = (props) => {
  const navigate = useNavigate();
  const { permissions } = useAuthContext();
  const [tableData, setTableData] = useState();
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [showActions, setShowActions] = useState({});
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [isPaginating, setIsPaginating] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState("");
  const { createLog } = useMemberContext();

  const ref = useRef();

  const [deleteGroup] = useMutation(DELETE_GROUP);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const handleEditClick = (id) => {
    navigate(`/dashboard/editgroup/${id}`);
  };
  const handleViewClick = (id) => {
    navigate(`/dashboard/groups/viewgroup/${id}`);
  };
  const handleAddLocationAccess = (id) => {
    navigate(`/dashboard/groups/grouplocations/${id}`);
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const handleDeleteClick = () => {
    deleteGroup({
      variables: {
        groupId: selectedGroup,
        operation: "DELETE_GROUP",
      },
      onCompleted: () => {
        toast.success("Delete group successfully");
        setSelectedGroup("");
        refetch();
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
  };
  const handleNextToken = () => {
    if (listGroupsData?.listGroups?.nextToken) {
      setIsPaginating(true);
      refetch({ nextToken: listGroupsData.listGroups.nextToken });
    }
  };

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };
  const handleRowClick = (id) => {
    navigate(`/dashboard/groups/${id}`);
  };

  const columns = [
    {
      accessorKey: "name",
      header: "GROUP NAME",
      cell: (info) => info?.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "name",
    },
    {
      accessorFn: (row) => row.groupLocation.items.length,
      header: "Total Locations",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "totalLocation",
    },
    {
      accessorFn: (row) => row.groupStaff.items.length,
      header: "Total Staff",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "totalStaff",
    },
    {
      id: "row-actions5",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;
        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions({
                  [id]: true,
                });
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu groups" ref={ref}>
                <ul className="membership-options">
                  <li
                    onClick={() =>
                      handleAddLocationAccess(props.row.original.id)
                    }
                  >
                    <Button
                      name="Add location access"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li
                    onClick={() => {
                      handleRowClick(props.row.original.id);
                    }}
                  >
                    <Button
                      name="Add staff access"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li onClick={() => setSelectedGroup(props.row.original.id)}>
                    <Button
                      name="Delete"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li onClick={() => handleEditClick(props.row.original.id)}>
                    <Button
                      name="Edit"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                  <li onClick={() => handleViewClick(props.row.original.id)}>
                    <Button
                      name="View"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];

  const {
    data: listGroupsData,
    loading: listGroupsLoading,
    error: listGroupsError,
    refetch,
  } = useQuery(LIST_GROUPS, {
    fetchPolicy: "no-cache",
  });

  useEffect(() => {
    if (listGroupsData) {
      const { items, nextToken } = listGroupsData?.listGroups;
      if (tableData && isPaginating) {
        const sortedCombinedItems = sortByCreatedAtDesc([
          ...tableData,
          ...items,
        ]);
        setTableData(sortedCombinedItems);
      } else {
        const sortedItems = sortByCreatedAtDesc([...items]);
        setTableData(sortedItems);
      }

      setIsPaginating(false);
      setNextToken(nextToken);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listGroupsData]);

  if (listGroupsLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (listGroupsError) {
    return (
      <div>
        <div>Error: Problem getting groups</div>
      </div>
    );
  }

  return (
    <>
      <div className="groups-container">
        <Header pageTitle="Groups" />
        <div className="groups-landing-content">
          {selectedGroup && (
            <Modal>
              <StatusModal
                modalHeaderTxt={"Delete Group"}
                modalParaText={"Are you sure you want to delete this group?"}
                handleStatusClick={handleDeleteClick}
                modalClose={() => setSelectedGroup("")}
              />
            </Modal>
          )}
          {!listGroupsLoading && !listGroupsError && tableData && (
            <>
              <div className="actions-row mb-32 mt-32">
                <div></div>
                <div className="right-elements flex-wrap">
                  <div className="search-and-button-row">
                    <div className="search-container">
                      <form onSubmit={onSearchBarSubmit}>
                        <div className="search-bar">
                          <div className="search-bar-container">
                            <input
                              className="fs-10"
                              type="text"
                              name="search"
                              id="search"
                              value={searchBarInputVal}
                              onChange={handleChange}
                              placeholder="Group name"
                            />
                            <button type="submit">
                              <AiOutlineSearch />
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* // <Link to="/dashboard/groups/addgroup">
                  //   <div className="add-membership-btn">
                  //     <Button
                  //       name="Add A GROUP"
                  //       btntype="submit"
                  //       btnname="submit"
                  //       className="btn btn-primary add-membership-btn"
                  //       click={props.openmodalclick}
                  //     />
                  //   </div>
                  // </Link> */}
                  {permissions.includes("CanCreateGroup") && (
                    <Link to="/dashboard/groups/addgroup">
                      <div className="add-membership-btn">
                        <Button
                          name="Add A GROUP"
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-primary add-membership-btn"
                          click={props.openmodalclick}
                        />
                      </div>
                    </Link>
                  )}
                </div>
              </div>

              <div className="groups-table">
                <Table
                  onRowClick={(i) => {
                    handleViewClick(i?.id);
                    createLog(`Viewed group ${i.name}!`);
                  }}
                  data={tableData}
                  globalFilter={globalFilter}
                  setGlobalFilter={setGlobalFilter}
                  sorting={sorting}
                  setSorting={setSorting}
                  columns={columns}
                  rowsPerPage={10}
                  handleNextToken={handleNextToken}
                  nextToken={nextToken}
                  columnFilters={columnFilters}
                  setColumnFilters={setColumnFilters}
                />
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Groups;
