import "./medicalsuspension.scss";
import React, { useEffect, useState } from "react";
import { BeatLoader } from "react-spinners";
import { format, add, subMonths, addMonths, isBefore, isAfter } from "date-fns";
import Button from "../../formUiElements/Button";
import { useNavigate } from "react-router-dom";
import {
  SUSPEND_MEMBERSHIP,
  SUSPEND_MEMBERSHIP_MEDICAL,
} from "../../../gqloperations/mutations";
import {
  GET_MEMBER_CONTRACT_BY_CONTRACTID,
  LIST_MEMBER_CONTRACT,
  GET_MEMBER_BY_ID,
} from "../../../gqloperations/queries";

import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { IoMdAddCircleOutline } from "react-icons/io";
import Input from "../../formUiElements/input/Input";
import TextArea from "../../formUiElements/input/TextArea";
import { suspensionSchema } from "../../../validations/Validation";
import { v4 as uuidv4 } from "uuid";
import { Storage } from "aws-amplify";
import { useMemberContext } from "../../../contexts/MemberContext";
import { getContract } from "../../../graphql/queries";
import { utcToZonedTime } from "date-fns-tz";
import { addFrequencyToDate } from "../../../utils/calculateTimePeriod";
import { sortAsc } from "../../../utils/sort";

const MedicalSuspension = ({ getmembercontract }) => {
  const { contractId } = useParams();
  const { createLog, member, createAuditLog } = useMemberContext();

  const [suspendMembership] = useMutation(SUSPEND_MEMBERSHIP_MEDICAL);
  const navigate = useNavigate();
  const [key, setKey] = useState(false);
  const [file, setFile] = useState(false);
  const [errorClientSideImg, setErrorClientSideImg] = useState(false);
  const [clientSideImgDetails, setClientSideImgDetails] = useState({});

  const handleCancel = () => {
    navigate(-1);
    createLog(
      `Clicked cancel button on medical suspension for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  // const [contractDetails, setContractDetails] = useState();
  // const [getContract] = useLazyQuery(GET_MEMBER_CONTRACT_BY_CONTRACTID);
  // const getContractInfo = async () => {
  //   const getContractId = await getContract({
  //     variables: { id: contractId },
  //   });
  //   setContractDetails(getContractId.data.getMemberContract);
  // };
  // useEffect(() => {
  //   if (contractId) {
  //     getContractInfo();
  //   }
  // }, [contractId]);

  const onSubmit = async (values, OnSubmitProps) => {
    const { suspendFrom, suspendTo, suspensionReason } = values;
    if (!file) {
      setErrorClientSideImg(true);
      OnSubmitProps.setSubmitting(false);
      return;
    }

    const uploadedFile = await Storage.put(key, file, {
      contentType: file.type,
    });
    suspendMembership({
      variables: {
        operation: "CREATE_SUSPENSION",
        imageUrl: uploadedFile.key,
        memberContractId: contractId,
        suspendFrom,
        suspendTo,
        suspensionReason,
        // suspensionAmount: 0,
      },
      onCompleted: () => {
        createAuditLog(
          {
            operation: "CREATE_SUSPENSION",
            imageUrl: uploadedFile.key,
            memberContractId: contractId,
            suspendFrom,
            suspendTo,
            suspensionReason,
            status: "AWAITING VERIFICATION",
            // suspensionAmount: 0,
          },
          {},
          // contractDetails.memberId,
          member?.getMember?.memberId,
          "CREATE",
          "suspendMedicalMembership"
        );
        OnSubmitProps.setSubmitting(false);
        toast.success("Suspension Added Successfully");
        createLog(
          `Clicked save button on medical suspension for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
        navigate(-1);
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
      },
      onError: (error) => {
        toast.error(`${error}`);
        OnSubmitProps.setSubmitting(false);
        createLog(
          `Clicked save button on medical suspension for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
      },
      refetchQueries: [
        {
          query: GET_MEMBER_CONTRACT_BY_CONTRACTID,
          variables: { id: contractId },
        },
        {
          query: LIST_MEMBER_CONTRACT,
          variables: { id: contractId },
        },
        {
          query: GET_MEMBER_BY_ID,
          variables: { memberId: member?.getMember?.memberId },
        },
      ],
    });
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setFile(file);
    const extension = file.name.split(".")[1];
    const name = file.name.split(".")[0];
    setKey(`medicalsuspension/${uuidv4()}${name}.${extension}`);
    const clientSideImgUrl = URL.createObjectURL(file);

    setClientSideImgDetails({
      imgUrl: clientSideImgUrl,
      fileName: file.name,
      uploadDate: format(new Date(), "dd/MM/yyyy"),
    });
    setErrorClientSideImg(false);
  };

  const handleMaxSusEndDate = (suspensionStartDate) => {
    if (!suspensionStartDate) return "";
    if (getmembercontract.getMemberContract.recurring) {
      //DD
      let suspensionEndDate;
      // const susStartDate = new Date(suspensionStartDate);
      // change to six month from now
      const sixMonthFromStartDate = addMonths(new Date(), 6);
      const contractEndDate = utcToZonedTime(
        new Date(getmembercontract?.getMemberContract.endDateTime),
        getmembercontract?.getMemberContract.timezone
      );

      const contractItems = getmembercontract?.getMemberContract.billings;
      const { items: billingsItems } = contractItems;
      const sortedBilling = sortAsc(billingsItems, "debitDate");
      const lastBilling = sortedBilling[sortedBilling.length - 1];

      // last date of last debit period billing

      if (
        getmembercontract?.getMemberContract.endDateTime &&
        isBefore(contractEndDate, sixMonthFromStartDate)
      ) {
        suspensionEndDate = format(contractEndDate, "yyyy-MM-dd");
      } else if (
        isBefore(
          utcToZonedTime(
            addFrequencyToDate(
              new Date(lastBilling.debitDate),
              getmembercontract?.getMemberContract.paymentFrequency
            ),
            getmembercontract?.getMemberContract.timezone
          ),
          sixMonthFromStartDate
        )
      ) {
        return format(
          utcToZonedTime(
            addFrequencyToDate(
              new Date(lastBilling.debitDate),
              getmembercontract?.getMemberContract.paymentFrequency
            ),
            getmembercontract?.getMemberContract.timezone
          ),
          "yyyy-MM-dd"
        );
      } else suspensionEndDate = format(sixMonthFromStartDate, "yyyy-MM-dd");
      return suspensionEndDate;
    } else {
      let suspensionEndDate;
      const susStartDate = new Date(suspensionStartDate);
      const sixMonthFromStartDate = addMonths(susStartDate, 6);
      const contractEndDate = utcToZonedTime(
        getmembercontract?.getMemberContract?.endDateTime,
        getmembercontract.getMemberContract.timezone
      );
      if (
        getmembercontract?.getMemberContract?.endDateTime &&
        isBefore(contractEndDate, sixMonthFromStartDate)
      ) {
        suspensionEndDate = format(contractEndDate, "yyyy-MM-dd");
      } else {
        suspensionEndDate = format(sixMonthFromStartDate, "yyyy-MM-dd");
      }
      return suspensionEndDate;
    }
  };
  const handleMinStartDate = () => {
    const contractStartDate = new Date(
      utcToZonedTime(
        getmembercontract?.getMemberContract?.startDateTime,
        getmembercontract.getMemberContract.timezone
      )
    );
    const threeMonthFromNow = subMonths(new Date(), 3);
    if (isAfter(contractStartDate, threeMonthFromNow)) {
      return format(contractStartDate, "yyyy-MM-dd");
    } else {
      return format(subMonths(new Date(), 3), "yyyy-MM-dd");
    }
  };
  const handleMaxStartDate = () => {
    return format(
      add(new Date(), {
        days: 1,
        months: 6,
      }),
      "yyyy-MM-dd"
    );
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        suspendFrom: "",
        suspendTo: "",
        suspensionReason: "",
      }}
      validationSchema={suspensionSchema}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <Form>
            <div className="medical-suspension-content">
              <div className="suspension-dialog-box info-card item-centered">
                <p className="uppercase fs-12">
                  Once details have been submitted a team member will review
                  your document and if approved,
                </p>
                <p className="uppercase fs-12">
                  {" "}
                  your request will be processed.
                </p>
              </div>
              <div className="suspension-details-box info-card">
                <h3 className="fs-14 uppercase">Suspension details</h3>
                <div className="input-details-row">
                  <div>
                    <Input
                      type="date"
                      label="Start Date *"
                      name="suspendFrom"
                      autoComplete="off"
                      onChange={(e) => {
                        formik.setValues({
                          ...formik.values,
                          suspendFrom: e.target.value,
                          suspendTo: "",
                        });
                      }}
                      min={handleMinStartDate()}
                      max={handleMaxStartDate()}
                    />
                  </div>
                  <div>
                    <Input
                      type="date"
                      label="End Date *"
                      name="suspendTo"
                      autoComplete="off"
                      min={format(
                        formik.values.suspendFrom
                          ? new Date(formik.values.suspendFrom)
                          : new Date(),
                        "yyyy-MM-dd"
                      )}
                      // max={
                      //   (format(
                      //     addMonths(
                      //       formik.values.suspendFrom
                      //         ? new Date(formik.values.suspendFrom)
                      //         : new Date(),
                      //       6
                      //     ),
                      //     "yyyy-MM-dd"
                      //   ) &&
                      //     isSameDay(
                      //       new Date(formik.values.suspendFrom),
                      //       new Date(getmembercontract.endDateTime)
                      //     )) ||
                      //   isBefore(
                      //     new Date(getmembercontract.endDateTime),
                      //     new Date(formik.values.suspendFrom)
                      //   )
                      // }
                      max={handleMaxSusEndDate(formik.values.suspendFrom)}
                    />
                  </div>
                </div>

                <div className="desc-row">
                  <TextArea
                    label="Reason *"
                    name="suspensionReason"
                    id="suspensionReason-standard"
                  />
                  {/* <h3 className="fs-12 mb-12 text-gray">Reason</h3>
                  <textarea
                    className="reason-box fs-12"
                    id="suspensionReason"
                    name="suspensionReason"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.suspensionReason}
                  ></textarea> */}
                </div>
                <div>
                  <label
                    htmlFor="addMedical"
                    className="add-certificate fs-12 text-gray"
                  >
                    <IoMdAddCircleOutline className="fs-18" />
                    Add Medical Certificate
                  </label>
                  {errorClientSideImg && (
                    <div className="error fs-12">
                      Please upload medical certificate
                    </div>
                  )}
                </div>

                <input
                  type="file"
                  onChange={handleImageUpload}
                  value={clientSideImgDetails.file}
                  accept="application/pdf,application/vnd.ms-excel,application/vnd.ms-word,pdf, image/jpg, image/jpeg, image/png"
                  name="imageUrl"
                  className="staff-img-input1"
                  id="addMedical"
                  hidden
                />

                <div className="info-card input-details-row column">
                  <h3 className="fs-14 uppercase">Medical Certificate</h3>
                  <div className="certificate-records">
                    <div className="record-row">
                      {clientSideImgDetails && (
                        <table>
                          <thead>
                            <tr className="table-header fs-14">
                              <th className="fs-12 text-gray">
                                <span>File Name</span>
                              </th>

                              <th className="fs-12 text-gray">
                                <span>Upload Date</span>
                              </th>

                              <th></th>
                              <th></th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td className="fs-12">
                                {clientSideImgDetails.fileName}
                              </td>
                              <td className="fs-12">
                                {" "}
                                {clientSideImgDetails.uploadDate}
                              </td>
                              <td>
                                {" "}
                                {/* View button in case we want it later */}
                                {/* <BsCardText
                                  onClick={() =>
                                    handleComponent("medical-certificate")
                                  }
                                /> */}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="right-btn mt-18 pb-24">
              <Button
                loading={formik.isSubmitting}
                name={"Save"}
                btntype="submit"
                btnname="submit"
                className="btn btn-large btn-confirm"
                disabled={formik.isSubmitting ? true : false}
              />
              <Button
                name="Cancel"
                btntype="reset"
                btnname="reset"
                className="btn btn-large btn-deny"
                click={handleCancel}
              />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default MedicalSuspension;
