import "./membershipmovementreport.scss";
import "./executiveReportsTables.scss";
import { useEffect, useState, useRef } from "react";
import GenerateBox from "../GenerateBox";
import Table from "../../table/Table";
import ExportGroup from "../../export/ExportGroup";
import { AiOutlineSearch } from "react-icons/ai";
import { useMemberContext } from "../../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import useCustomFetch from "../../../hooks/useCustomFetch";
import { API } from "../../../utils/API";
import { toast } from "react-toastify";

export default function MembershipMovementReport() {
  const paginationData = useRef();
  const [generate, setGenerate] = useState(false);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [tableData, setTableData] = useState([]);
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [endPoint, setEndPoint] = useState();
  const [params, setParams] = useState();

  const { data, isLoading, error } = useCustomFetch(
    endPoint && endPoint,
    params && params
  );

  useEffect(() => {
    if (sidebarBrandId && sidebarLocationId) handleGenerate(100, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);
  useEffect(() => {
    if (endDate && startDate && generate) {
      handleGenerate(100, 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [generate]);
  useEffect(() => {
    if (error && endPoint) {
      setTableData([]);
      toast.error(error?.response?.data?.message ?? "Error when fetching api");
    } else if (data) {
      if (params.page !== 1) {
        setTableData([...tableData, ...data.data.data]);
      } else setTableData([...data.data.data]);
    }

    setGenerate(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, error]);
  useEffect(() => {
    setGenerate(false);
  }, [sidebarBrandId, sidebarLocationId]);

  const handleGenerate = (limit, page) => {
    paginationData.current = { limit: limit, page: page };
    let parameters = {
      brandId: sidebarBrandId,
      startDate: startDate,
      locationId: sidebarLocationId,
      endDate: endDate,
    };

    if (sidebarLocationId === 0) {
      delete parameters.locationId;
    }
    if (startDate && endDate) {
      const pagination = limit ? { limit: limit, page: page } : undefined;
      if (pagination) parameters = { ...parameters, ...pagination };

      setEndPoint(API.membershipMovement);
      setParams(parameters);
    }
  };
  const handleNextToken = () => {
    handleGenerate(
      paginationData.current.limit,
      paginationData.current.page + 1
    );
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);
    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };
  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };

  const columns = [
    {
      accessorFn: (row) => {
        return row?.location?.name;
      },
      header: "LOCATION",
      cell: (info) => info.getValue(),

      enableSorting: true,
      enableGlobalFilter: true,
      id: "location",
    },
    {
      accessorFn: (row) => row?.location?.state,
      header: "STATE",
      cell: (info) => info.getValue(),

      enableSorting: true,
      enableGlobalFilter: true,
      id: "state",
    },
    {
      accessorFn: (row) => row?.startDateCount,
      header: `FIRST DATE`,
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "firstDate",
    },
    {
      accessorKey: "endDateCount",
      header: `END DATE`,
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "secondDate",
    },
    {
      accessorKey: "movement",
      header: "MOVEMENT",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "movement",
    },
    {
      accessorKey: "growth",
      header: "GROWTH",
      cell: (info) => (
        <span
          style={{
            color: `${
              info.getValue()
                ? info.getValue()?.toFixed(2) > 0
                  ? ""
                  : " var(--color-red)"
                : ""
            }`,
          }}
        >
          {info.getValue() ? info.getValue()?.toFixed(2) + "%" : "0%"}
        </span>
      ),

      enableSorting: true,
      enableGlobalFilter: true,
      id: "growth",
    },
  ];

  return (
    <>
      <GenerateBox
        type="Payment"
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        setGenerate={setGenerate}
      />
      {tableData.length && !isLoading ? (
        <div className="actions-row report-tables mt-24 mb-12">
          <ExportGroup
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columns={columns}
            sorting={sorting}
          />
          <div className="search-container">
            <form onSubmit={onSearchBarSubmit}>
              <div className="search-bar">
                <div className="search-bar-container">
                  <input
                    className="fs-10"
                    type="text"
                    name="payment-report-search"
                    id="payment-report-search"
                    value={searchBarInputVal}
                    onChange={handleChange}
                    placeholder="Search by Location"
                  />
                  <button type="submit">
                    <AiOutlineSearch />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      ) : null}
      {tableData.length > 0 && (
        <p className="mt-24 fs-14">TOTAL: {data?.data?.total}</p>
      )}

      {isLoading ? (
        <div className="report">
          <BeatLoader className="loading" color="white" size={20} />
        </div>
      ) : null}
      {!isLoading ? (
        <div className="report-table-container-all">
          <Table
            data={tableData}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            rowsPerPage={10}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            nextToken={tableData.length < data?.data?.total}
            handleNextToken={handleNextToken}
          />
        </div>
      ) : null}
    </>
  );
}
