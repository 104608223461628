import "../../pages/instructors/editInstructor.scss";
import Modal from "../modal/Modal";
import Table from "../table/Table";
import { useState } from "react";
import { BsCardText } from "react-icons/bs";
import { MdOutlineDelete } from "react-icons/md";
import { toast } from "react-toastify";
import { format } from "date-fns";
import { DELETE_INSTRUCTOR_CREDENTIAL } from "../../gqloperations/mutations";
import { GET_CLASS_INSTRUCTOR } from "../../gqloperations/queries";
import { useMutation } from "@apollo/client";
import StatusModal from "../modal/StatusModal";
import { Storage } from "aws-amplify";

const CredentialTable = ({ instructorInfo }) => {
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [componentName, setComponentName] = useState("");
  const [curRowId, setCurRowId] = useState();

  const modalClose = () => {
    setComponentName("");
    setCurRowId("");
  };
  const [deleteInstructorCredential] = useMutation(
    DELETE_INSTRUCTOR_CREDENTIAL
  );

  const handleViewCredential = async (credentialUrl) => {
    const downloadCredential = (data) => {
      // Create a temporary link element
      // const fileName = "credential.pdf";
      const link = document.createElement("a");
      link.href = data;
      link.target = "_blank";

      // Append the link to the body and trigger the download
      document.body.appendChild(link);
      link.click();

      //  Clean up by removing the link element
      document.body.removeChild(link);
    };
    if (credentialUrl) {
      try {
        await Storage.get(credentialUrl, {}).then((data) => {
          downloadCredential(data);
        });
      } catch (err) {
        toast.error(`Error: ${err}`);
      }
    }
  };

  const handleDeleteClick = async (rowObj) => {
    try {
      await deleteInstructorCredential({
        variables: {
          input: {
            id: curRowId,
          },
        },
        onCompleted: () => {
          toast.success("Credential deleted Successfully");
          modalClose();
        },
        refetchQueries: [
          {
            query: GET_CLASS_INSTRUCTOR,
            variables: {
              id: `${instructorInfo?.id}`,
            },
          },
        ],
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleRowDeleteClick = (id) => {
    setComponentName("delete-credential");
    setCurRowId(id);
  };
  const columns = [
    {
      accessorKey: "credentialName",
      header: "Credential",
      cell: (info) => info?.getValue(),
      enableSorting: false,
      enableGlobalFilter: false,
      id: "credentialName",
    },

    {
      accessorKey: "createdAt",
      header: "Upload Date",
      cell: (info) => format(new Date(info?.getValue()), "dd/MM/yyyy"),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "createdAt",
    },

    {
      id: "row-actions",
      cell: (props) => {
        return (
          <>
            <div className="flex-row">
              <BsCardText
                onClick={() =>
                  handleViewCredential(props?.row?.original?.imageUrl)
                }
              />
              <MdOutlineDelete
                onClick={() => handleRowDeleteClick(props?.row?.original?.id)}
              />
            </div>
          </>
        );
      },
    },
  ];

  return (
    <div className="info-card credential-view">
      <div className="title-bar ">
        <h3 className="fs-14 uppercase">Credentials</h3>
      </div>
      <div>
        {instructorInfo?.instructorCredentialList?.items.length > 0 ? (
          <Table
            data={instructorInfo?.instructorCredentialList?.items}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            sorting={sorting}
            setSorting={setSorting}
            columns={columns}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            hasFooter={false}
          />
        ) : (
          <p className="fs-12 ">
            No credentials have been uploaded for this instructor
          </p>
        )}
      </div>
      {componentName && (
        <Modal>
          {componentName === "delete-credential" && (
            <StatusModal
              modalHeaderTxt={"Delete Credential"}
              modalParaText={"Are you sure you want to delete this credential?"}
              handleStatusClick={handleDeleteClick}
              modalClose={modalClose}
            />
          )}
        </Modal>
      )}
    </div>
  );
};

export default CredentialTable;
