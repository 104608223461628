import "./billingInformation.scss";
import { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import { useMutation, useApolloClient, useLazyQuery } from "@apollo/client";
import {
  GET_MEMBER_BY_ID,
  GET_MEMBER_RECEIPT,
  LIST_TRANSACTION_BY_MEMBERID,
} from "../../gqloperations/queries";
import actions from "../../assets/images/icons/action_menu.png";
import { UPDATE_DIRECT_DEBIT, REFUND } from "../../gqloperations/mutations";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { useMemberContext } from "../../contexts/MemberContext";
import getDurationString from "../../utils/getDurationString";
import { sortAsc } from "../../utils/sort";
import { readUTCtimeToTargetTimeZone } from "../../utils/getUTCdate";
import {
  format,
  isAfter,
  isSameDay,
  startOfDay,
  isBefore,
  parseISO,
  differenceInDays,
} from "date-fns";
import RefundModal from "../modal/RefundModal";
import { typeOfPayment } from "../../constants/commonConstant";
import {
  Table,
  Button,
  Modal,
  SelectWithoutFormik,
  BillingDebitLogModal,
  UpdateAmount,
} from "../../components";
import { Storage } from "aws-amplify";
import FinancialYearExportModal from "./FinancialYearExportModal";

const BillingInformation = ({ membershipAndBillingsForAContract }) => {
  const { loggedUser, permissions } = useAuthContext();
  const { member, createLog } = useMemberContext();
  const [paymentHistoryTable, setPaymentHistoryTable] = useState();
  const [futurePaymentTable, setFuturePaymentTable] = useState();
  const [sorting, setSorting] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [columnFilters, setColumnFilters] = useState([]);
  const [nextToken, setNextToken] = useState(null);
  const [futurePaymentClicked, setFuturePaymentClicked] = useState(false);
  const [componentName, setComponentName] = useState("");
  const [paymentTable, setPaymentTable] = useState([]);
  const [isPaginating, setIsPaginating] = useState(false);
  const [curRow, setCurRow] = useState({});
  const [paymentFrequency, setPaymentFrequency] = useState({});
  const [showActions, setShowActions] = useState({});
  const [refundAmount, setRefundAmount] = useState();
  const [refundId, setRefundId] = useState("");

  const [rowAmount, setRowAmount] = useState({
    debitAmount: 0,
    membershipAmount: 0,
  });

  const { id: memberId } = useParams();
  const ref = useRef();
  const {
    memberContractByMembershipId,
    getContracts,
    setSelectedMembership,
    selectedMembershipId,
  } = useMemberContext();
  const [updatedebitamount] = useMutation(UPDATE_DIRECT_DEBIT);
  const [refundTransaction] = useMutation(REFUND);

  const handleNextToken = () => {
    if (nextToken) {
      setIsPaginating(true);
      getContracts();
    }
  };
  useEffect(() => {
    const contractItems =
      memberContractByMembershipId?.listMemberContracts?.items;

    if (contractItems?.length > 0) {
      const contract = contractItems[0];
      if (!contract) {
        return;
      }

      const billingFrequency = contract?.paymentFrequency;
      setPaymentFrequency(billingFrequency);

      const { items: billingsItems, nextToken } = contract?.billings;

      const sortedBilling = sortAsc(billingsItems, "debitDate");

      const historyData = sortedBilling.filter((cur) => {
        return (
          isBefore(startOfDay(new Date(cur?.debitDate)), new Date()) ||
          isSameDay(new Date(cur?.debitDate), new Date())
        );
      });
      if (isPaginating && paymentHistoryTable) {
        setPaymentHistoryTable([...paymentHistoryTable, ...historyData]);
      } else {
        setPaymentHistoryTable([...historyData]);
      }
      setIsPaginating(false);
      setNextToken(nextToken);

      const filteredData = sortedBilling.filter((cur) => {
        return isAfter(startOfDay(new Date(cur.debitDate)), new Date());
      });
      setFuturePaymentTable(filteredData);
    }
    return;

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [memberContractByMembershipId]);
  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (!ref?.current || ref.current.contains(e.target)) {
        return;
      }
      setShowActions({});
    };
    document.addEventListener("click", checkIfClickedOutside);
    return () => {
      document.removeEventListener("click", checkIfClickedOutside);
    };
  }, [ref]);
  const handleSubmit = (values, OnSubmitProps) => {
    const { amount, reason, description } = values;
    createLog(
      `Clicked saved button for edit debit amount ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member?.getMember?.memberId}`
    );
    updatedebitamount({
      variables: {
        billingId: curRow.id,
        updatedBy: loggedUser?.getMember?.memberId,
        reason: reason,
        description: description,
        amount: amount,
      },
      onCompleted: async () => {
        toast.success("Updated successfully!");
        await getContracts();
        modalClose();
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: `${memberId}`,
          },
        },
      ],
    });
  };
  const handleRefundSubmit = (values, OnSubmitProps) => {
    const variables =
      selectedMembershipId === "payment"
        ? {
            reason: values.reason,
            transactionId: refundId,
            refundType: "TRANSACTION",
          }
        : {
            reason: values?.reason,
            billingId: refundId,
            refundType: "BILLING",
          };
    refundTransaction({
      variables: variables,
      onCompleted: async () => {
        componentName !== "request-refund-modal" && OnSubmitProps.resetForm();
        componentName !== "request-refund-modal"
          ? toast.success("Refund successfully!")
          : toast.success("Request refund successfully!");
        await getContracts();
        modalClose();
        selectedMembershipId === "payment" ? handleContract() : getContracts();
      },
      onError: (error) => {
        toast.error(`${error}`);
        componentName !== "request-refund-modal" &&
          OnSubmitProps.setSubmitting(false);
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: `${memberId}`,
          },
        },
      ],
    });
  };

  const getRowField = (id) => {
    if (memberContractByMembershipId?.listMemberContracts.items?.length === 0) {
      return;
    }
    const billingData =
      memberContractByMembershipId?.listMemberContracts.items[0]?.billings?.items.find(
        (cur) => cur.id === id
      );

    const latestDebitLog =
      billingData?.directDebitLog.items[
        billingData.directDebitLog.items.length - 1
      ];

    return { ...billingData, latestDebitLog };
  };
  const handleComponent = (componentname, id, logButton) => {
    createLog(
      `Clicked ${logButton} log button ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
    setComponentName(componentname);
    setCurRow(getRowField(id));
  };
  const modalClose = () => {
    setComponentName("");
    createLog(
      `Clicked cancel button for edit debit amount ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleEditClick = (id) => {
    handleComponent("update-amount", id, "edit debit amount");
  };
  useEffect(() => {
    if (membershipAndBillingsForAContract?.length > 0) {
      setSelectedMembership(membershipAndBillingsForAContract[0].id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleFuturePaymentClick = () => {
    setFuturePaymentClicked(true);
    createLog(
      `Clicked Future payments button on payments tab in member module for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member?.getMember?.memberId}`
    );
  };

  const handleClick = () => {
    setFuturePaymentClicked(false);
    createLog(
      `Clicked Payment history button on payments tab in member module for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member?.getMember?.memberId}`
    );
  };

  const [getReceiptUrl] = useLazyQuery(GET_MEMBER_RECEIPT);
  const handleDownloadReceipt = async (receipt) => {
    try {
      let receiptResponse;
      if (
        receipt.transactionId === null ||
        receipt.transactionId.includes("XX")
      ) {
        receiptResponse = await getReceiptUrl({
          fetchPolicy: "no-cache",
          variables: {
            memberId: memberId,
            filter: { billingId: { eq: receipt.id } },
          },
        });
      } else if (receipt.transactionId !== null)
        receiptResponse = await getReceiptUrl({
          fetchPolicy: "no-cache",
          variables: {
            memberId: memberId,
            filter: { transactionId: { eq: receipt.transactionId } },
          },
        });
      const url = await Storage.get(
        receiptResponse.data.getMemberReceiptByMemberId.items[0].pdfUrl.split(
          "public/"
        )[1]
      );
      const link = document.createElement("a");
      link.href = url;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (e) {
      toast.error("Error on download receipt try again later");
    }
  };

  useEffect(() => {
    if (selectedMembershipId !== "payment") {
      getContracts();
    } else {
      setFuturePaymentClicked(false);
      handleContract();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMembershipId]);

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };
  const client = useApolloClient();
  const handleContract = async () => {
    const listTransactionData = await client.query({
      query: LIST_TRANSACTION_BY_MEMBERID,
      fetchPolicy: "no-cache",
      variables: {
        memberId: member?.getMember?.memberId,
      },
    });
    const tableDataTransaction = sortByCreatedAtDesc(
      listTransactionData.data.getTransactionByMemberId.items.map((i) => {
        const currentKey = Object.keys(typeOfPayment).filter((item) =>
          i?.orderId?.includes(item)
        );
        const currentValue = currentKey.length ? typeOfPayment[currentKey] : "";
        return {
          id: i.id,
          refundTransactionId: i.refundTransactionId,
          isPaid: i.gatewayCode,
          createdAt: i.createdAt,
          BillingCredit: [{ amount: i.amount }],
          paymentType: i.paymentType,
          debitDate: i.createdAt,
          isProcessed: i.gatewayCode === "APPROVED" ? true : false,
          debitAmount: i.amount,
          paymentFrequency: { amount: 0, type: "" },
          membershipAmount: 0,
          message: i.message,
          orderId: i.orderId,
          status: i.status,
          type:
            (currentValue === "Adult Registration" ||
              currentValue === "Membership Upgrade Upfront" ||
              currentValue === "Membership Downgrade") &&
            Object.keys(i?.memberContractDetails?.voucherDetail || {}).length
              ? currentValue + " (Voucher Apply)"
              : currentValue,
          transactionId: i.transactionId,
        };
      })
    );
    setPaymentTable(tableDataTransaction);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const id = e.target.value;
    setSelectedMembership(id);
  };

  const getRefundStatusMessage = (refundStatus) => {
    if (refundStatus === "PENDING") {
      return <span className="text-green">REFUND PENDING</span>;
    } else if (refundStatus === "SUCCESS") {
      return <span className="text-red">REFUNDED</span>;
    } else {
      return <span className="text-red">REFUND FAILED</span>;
    }
  };

  const futurePaymentColumns = [
    {
      accessorKey: "paymentType",
      header: "Payment Type",
      cell: (info) => info?.getValue().replace("_", " ") ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "debitDate",
      header: "Debit date",
      cell: (info) =>
        info ? format(new Date(info.getValue()), "dd/MM/yyyy") : "",
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return paymentFrequency ? getDurationString(paymentFrequency) : "";
      },
      header: "Payment Frequency",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "paymentFrequency",
    },
    {
      accessorFn: (row) => {
        const billingItem =
          row.BillingCredit.items.length > 0
            ? row.BillingCredit.items[0].amount
            : null;
        if (billingItem === undefined || billingItem === null)
          return (
            "$" + (row?.debitAmount < 0 ? 0 : row?.debitAmount).toFixed(2) ?? ""
          );
        const value = parseFloat(row?.debitAmount) - parseFloat(billingItem);
        return "$" + (value < 0 ? 0 : value).toFixed(2) ?? "";
      },
      accessorKey: "debitAmount",
      header: "Debit amount ",
      cell: (info) => {
        const billingCredit =
          info.row.original.BillingCredit?.items?.length > 0;

        if (billingCredit)
          return (
            <div>
              {info.getValue()}
              <br />
              (${info.row.original.BillingCredit?.items[0]?.amount} Billing
              Credit)
            </div>
          );
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "membershipAmount",
      header: "Membership Amount",
      cell: (info) => {
        if (selectedMembershipId !== "payment")
          return "$" + info?.getValue().toFixed(2) ?? "";
        return "";
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      id: "row-actions-future",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;

        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              alt=""
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prev) => ({
                  ...prev,
                  [id]: !prev[id],
                }));
              }}
            />
            {areActionsVisible && (
              <span className="action-options-menu" ref={ref}>
                <ul className="membership-options">
                  {getRowField(props.row.original.id).latestDebitLog && (
                    <li
                      onClick={() => {
                        handleComponent(
                          "view-membership-log",
                          props.row.original.id,
                          "futurePayments"
                        );
                      }}
                    >
                      <Button
                        name="View Log"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  )}
                  <li
                    onClick={() => {
                      handleEditClick(props.row.original.id);
                      setCurRow(getRowField(props.row.original.id));
                      setRowAmount({
                        debitAmount: props.row.original.debitAmount,
                        membershipAmount: props.row.original.membershipAmount,
                      });
                    }}
                  >
                    <Button
                      name="Edit debit amount"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-transparent btn-small"
                    />
                  </li>
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];
  const columns = [
    {
      accessorKey: "paymentType",
      header: "Payment Type",
      cell: (info) => info?.getValue()?.replace("_", " ") ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "debitDate",
      header: "Payment date",
      cell: (info) =>
        info.getValue() ? format(new Date(info.getValue()), "dd/MM/yyyy") : "",
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return row.type;
      },
      header: "Reason",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "reason",
    },
    {
      accessorKey: "debitAmount",
      header: "Debit amount",
      cell: (info) => "$" + info?.getValue()?.toFixed(2) ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return row.orderId;
      },
      header: "order Id",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return row.status;
      },
      header: "Paid",
      cell: (info) => {
        if (info?.getValue()?.toLowerCase() === "success") {
          return (
            <span
              className={
                info.row.original.refundTransactionId
                  ? "text-red"
                  : "text-green"
              }
            >
              {info.row.original.refundTransactionId ? "REFUNDED" : "APPROVED"}
            </span>
          );
        } else if (info?.getValue()?.toLowerCase() === "failure") {
          return <span className="text-red">DECLINED</span>;
        } else if (info?.getValue() === "APPROVED") {
          if (info.row.original.debitAmount === 0)
            return <span className="text-green">N/A</span>;
          else return <span className="text-green">YES</span>;
        } else if (info?.getValue() === "REJECTED") {
          return <span className="red">NO</span>;
        } else {
          return <span className="text-green">PENDING</span>;
        }
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      id: "row-actions-column",
      header: "ACTIONS",
      cell: (props) => {
        const { orderId: id } = props?.row?.original;
        const areActionsVisible = showActions.hasOwnProperty(id);
        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prev) => ({
                  ...prev,
                  [id]: !prev[id],
                }));
                setRefundAmount(props.row.original?.debitAmount);
                setRefundId(props.row.original?.id);
              }}
              alt=""
            />
            {areActionsVisible &&
              permissions.includes("CanRefundTransaction") && (
                <span className="action-options-menu memberships" ref={ref}>
                  <ul className="membership-options">
                    <li
                      onClick={() => {
                        if (props?.row?.original.refundTransactionId) return;
                        handleComponent("refund-modal", {
                          membershipId: id,
                        });
                      }}
                    >
                      <Button
                        name="refund"
                        btntype="submit"
                        btnname="submit"
                        className={
                          props?.row?.original.refundTransactionId
                            ? "disabled"
                            : "btn btn-transparent btn-small"
                        }
                        disabled={props?.row?.original.refundTransactionId}
                      />
                    </li>
                    <li>
                      <Button
                        click={() => handleDownloadReceipt(props.row.original)}
                        name="Download Receipt"
                        btntype="button"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  </ul>
                </span>
              )}
          </div>
        );
      },
    },
  ];

  const historyPaymentColumns = [
    {
      accessorKey: "paymentType",
      header: "Payment Type",
      cell: (info) => info?.getValue()?.replace("_", " ") ?? "",
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "debitDate",
      header: "debit date",
      cell: (info) =>
        info.getValue() ? format(new Date(info.getValue()), "dd/MM/yyyy") : "",
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorKey: "settlementDate",
      header: "settlement date",
      cell: (info) => {
        return info.getValue()
          ? readUTCtimeToTargetTimeZone(
              info.getValue(),
              info?.row?.original?.timezone,
              "yyyy-MM-dd, HH:mm:ss"
            )
          : "N/A";
      },
      enableSorting: true,
      enableGlobalFilter: false,
    },
    {
      accessorFn: () => {
        return paymentFrequency ? getDurationString(paymentFrequency) : "";
      },
      header: "Frequency",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: true,
      enableGlobalFilter: false,
      id: "paymentFrequency",
    },
    {
      accessorKey: "debitAmount",
      header: "Debit amount",
      cell: (info) => {
        const billingCredit = info.row.original.BillingCredit?.items;
        if (billingCredit.length > 0) {
          return (
            <div>
              {"$" + (info?.getValue() < 0 ? 0 : info?.getValue()).toFixed(2) ??
                ""}
              <br />
              (${billingCredit[0]?.amount} BILLING CREDIT)
            </div>
          );
        }
        return (
          "$" + (info?.getValue() < 0 ? 0 : info?.getValue()).toFixed(2) ?? ""
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return row.rejectionFee ? `$ ${row.rejectionFee}` : "";
      },
      header: "Rejection Fee",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        return row.actualDebitAmount
          ? `$ ${row.actualDebitAmount}`
          : `$ ${row.debitAmount}`;
      },
      header: "Actual Debit amount",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },

    {
      accessorFn: (row) => {
        return "$" + row.membershipAmount;
      },
      header: "Membership Amount",
      cell: (info) => {
        return info.getValue();
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        if (
          member?.getMember.DDFailureCount &&
          member?.getMember.DDFailureCount >= 3
        ) {
          const latestRejected = paymentHistoryTable
            .filter((item) => item.status === "REJECTED")
            .reduce((latest, item) => {
              const itemDate = parseISO(item?.debitDate);
              if (
                !latest ||
                differenceInDays(itemDate, parseISO(latest?.debitDate)) > 0
              ) {
                return item;
              }
              return latest;
            }, null);
          const datesAfterLatestRejected = paymentHistoryTable.filter(
            (item) => {
              const itemDate = parseISO(item?.debitDate);
              return (
                differenceInDays(
                  itemDate,
                  parseISO(latestRejected?.debitDate)
                ) > 0
              );
            }
          );
          const isPaymentAfterRejectedDate = datesAfterLatestRejected.filter(
            (item) => item.id === row.id
          );

          if (isPaymentAfterRejectedDate.length > 0) {
            return "NotBilled";
          } else {
            return row.status;
          }
        } else {
          return row.status;
        }
      },
      header: "Paid",
      cell: (info) => {
        if (info?.getValue()?.toLowerCase() === "success") {
          return <span className="text-green">APPROVED</span>;
        } else if (info?.getValue()?.toLowerCase() === "failure") {
          return <span className="text-red">DECLINED</span>;
        } else if (info?.getValue() === "APPROVED") {
          if (info.row.original?.debitAmount === 0) {
            return <span className="text-green">N/A</span>;
          } else if (info.row.original.refundTransactionId) {
            return (
              <div>
                {getRefundStatusMessage(
                  info.row.original?.refundDetails.refundStatus
                )}
              </div>
            );
          } else return <span className="text-green">YES</span>;
        } else if (
          info.row.original?.debitAmount ===
          info.row.original?.BillingCredit?.items[0]?.amount
        )
          return <span className="red">SUSPENDED</span>;
        else if (info?.getValue() === "REJECTED") {
          return <span className="text-red">{info?.getValue()}</span>;
        } else if (info?.getValue() === "NotBilled") {
          return <span className="text-red">NOT BILLED</span>;
        }
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
    {
      accessorFn: (row) => {
        const billings = row;
        if (
          billings?.isProcessed &&
          billings?.status === "REJECTED" &&
          billings?.paymentType === "DIRECT_DEBIT"
        ) {
          return billings?.reason;
        } else if (
          billings?.isProcessed &&
          billings?.status === "REJECTED" &&
          billings?.paymentType === "CREDIT_CARD"
        ) {
          return billings?.reason;
        } else if (billings?.directDebitLog.items.length) {
          return billings?.directDebitLog.items[0].reason;
        }
      },
      header: "Reason",
      cell: (info) => <span className="red">{info?.getValue()}</span>,
      enableSorting: false,
      enableGlobalFilter: false,
    },

    {
      id: "row-actions-history",
      header: "ACTIONS",
      cell: (props) => {
        const { id } = props?.row?.original;
        const areActionsVisible = showActions.hasOwnProperty(id);

        if (
          !getRowField(props.row.original.id)?.latestDebitLog &&
          props.row.original.status !== "APPROVED" &&
          props.row.original.debitAmount === 0
        )
          return null;

        return (
          <div className="action-container">
            <img
              className="dropdown-btn"
              src={actions}
              onClick={(e) => {
                e.stopPropagation();
                setShowActions((prev) => ({
                  ...prev,
                  [id]: !prev[id],
                }));
                setRefundAmount(props.row.original?.debitAmount);
                setRefundId(props.row.original?.id);
              }}
              alt=""
            />
            {areActionsVisible && (
              <span className="action-options-menu memberships" ref={ref}>
                <ul className="membership-options">
                  {props.row.original.status === "APPROVED" &&
                    props.row.original.debitAmount !== 0 && (
                      <>
                        <li
                          onClick={() => {
                            if (
                              props?.row?.original.paymentType ===
                              "DIRECT_DEBIT"
                            ) {
                              if (props?.row?.original.refundTransactionId)
                                return;
                              handleComponent("request-refund-modal", {
                                membershipId: id,
                              });
                            } else {
                              if (props?.row?.original.refundTransactionId)
                                return;
                              handleComponent("refund-modal", {
                                membershipId: id,
                              });
                            }
                          }}
                        >
                          <Button
                            name={
                              props?.row?.original.paymentType ===
                              "DIRECT_DEBIT"
                                ? "Request refund"
                                : "refund"
                            }
                            btntype="submit"
                            btnname="submit"
                            className={`btn btn-transparent btn-small ${
                              props?.row?.original.refundTransactionId
                                ? "btn-disabled"
                                : ""
                            }`}
                          />
                        </li>
                        <li>
                          <Button
                            click={
                              () => {
                                const rowModData = { ...props.row.original };

                                if (
                                  props.row.original?.outstandingLogDetail !==
                                  null
                                ) {
                                  rowModData.transactionId =
                                    props.row.original.outstandingLogDetail.transactionId;
                                }
                                handleDownloadReceipt(rowModData);
                              }
                              // handleDownloadReceipt(props.row.original)
                            }
                            name="Download Receipt"
                            btntype="button"
                            className="btn btn-transparent btn-small"
                          />
                        </li>
                      </>
                    )}
                  {getRowField(props.row.original.id)?.latestDebitLog && (
                    <li
                      onClick={() => {
                        handleComponent(
                          "view-membership-log",
                          props.row.original.id,
                          "paymentHistory"
                        );
                      }}
                    >
                      <Button
                        name="View Log"
                        btntype="submit"
                        btnname="submit"
                        className="btn btn-transparent btn-small"
                      />
                    </li>
                  )}
                </ul>
              </span>
            )}
          </div>
        );
      },
    },
  ];
  return (
    <div className="table payments-history">
      {componentName && (
        <Modal>
          {componentName === "update-amount" && (
            <UpdateAmount
              cancelFn={modalClose}
              headerText={"Edit Debit Amount"}
              initialAmount={rowAmount?.debitAmount}
              handleSubmit={handleSubmit}
              billingDetails={curRow}
            />
          )}
          {componentName === "export-financial-year" && (
            <FinancialYearExportModal closeModal={() => setComponentName("")} />
          )}
          {componentName === "view-membership-log" && (
            <BillingDebitLogModal modalClose={modalClose} curRow={curRow} />
          )}
          {componentName === "refund-modal" && (
            <RefundModal
              cancelFn={() => setComponentName("")}
              refundAmount={refundAmount}
              handleRefundSubmit={handleRefundSubmit}
              requestRefund={false}
            />
          )}
          {componentName === "request-refund-modal" && (
            <RefundModal
              cancelFn={() => setComponentName("")}
              refundAmount={refundAmount}
              handleRefundSubmit={handleRefundSubmit}
              requestRefund={true}
            />
          )}
        </Modal>
      )}

      <div className="display-payments-options right-btn mt-12 mb-12">
        <Button
          name="Generate Report"
          btntype="submit"
          btnname="submit"
          className={"btn btn-primary"}
          onClick={() => {
            setComponentName("export-financial-year");
          }}
        />
        {membershipAndBillingsForAContract?.length > 0 && (
          <SelectWithoutFormik
            label="Payment Type"
            name="membershipName"
            value={selectedMembershipId ?? ""}
            optionvalue={[
              ...membershipAndBillingsForAContract,
              { id: "payment", membershipName: "Transaction Payment" },
            ]}
            selecttype="object"
            optionkey="id"
            optiondisplay="membershipName"
            autoComplete="off"
            onChange={handleChange}
            className="select-element-value"
          />
        )}

        <Button
          name="Payment History"
          btntype="submit"
          btnname="submit"
          className={
            futurePaymentClicked ? "btn btn-primary gray" : "btn btn-primary "
          }
          onClick={handleClick}
        />
        {selectedMembershipId !== "payment" &&
          (member?.getMember.DDFailureCount < 3 ||
            !member?.getMember.DDFailureCount) && (
            <Button
              name="Future Payments"
              btntype="submit"
              btnname="submit"
              className={
                futurePaymentClicked
                  ? "btn btn-primary "
                  : "btn btn-primary gray"
              }
              onClick={handleFuturePaymentClick}
            />
          )}
      </div>
      {(paymentHistoryTable ||
        (paymentTable && selectedMembershipId === "payment")) &&
        selectedMembershipId && (
          <Table
            data={
              selectedMembershipId === "payment"
                ? paymentTable
                : futurePaymentClicked
                ? futurePaymentTable
                : paymentHistoryTable
            }
            columns={
              selectedMembershipId === "payment"
                ? columns
                : futurePaymentClicked
                ? futurePaymentColumns
                : historyPaymentColumns
            }
            sorting={sorting}
            setSorting={setSorting}
            globalFilter={globalFilter}
            setGlobalFilter={setGlobalFilter}
            columnFilters={columnFilters}
            setColumnFilters={setColumnFilters}
            handleNextToken={handleNextToken}
            nextToken={nextToken}
          />
        )}
    </div>
  );
};
export default BillingInformation;
