import "./addClass.scss";
import { useState, useRef } from "react";
import { Formik, Form } from "formik";
import { class_occurence } from "../../constants/commonConstant";
import { useClassContext } from "../../contexts/ClassContext";
import { useMemberContext } from "../../contexts/MemberContext";
import {
  CREATE_CLASS_SCHEDULE,
  UPDATE_CLASS_SCHEDULE,
} from "../../gqloperations/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { classScheduleSchema } from "../../validations/ClassValidation";
import {
  Button,
  Header,
  Select,
  SelectWithoutFormik,
  Input,
  ToggleFormik,
  Toggle,
} from "../../components";
import { BeatLoader } from "react-spinners";
import { format, addDays } from "date-fns";
import { toast } from "react-toastify";
import { GET_LOCTAION_BY_ID } from "../../gqloperations/queries";
import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { CLEAR_ID } from "../../actions";
import { v4 as uuidv4 } from "uuid";
import { getUTCdate } from "../../utils/getUTCdate";
import { AiOutlineSearch } from "react-icons/ai";

const AddClass = (props) => {
  const navigate = useNavigate();
  const {
    getTemplateById,
    classTemplateId,
    classTemplateDataById,
    studioData,
    listStudioLoading,
    listStudioError,
    allInstructor,
    listInstructor,
    listInstructorLoading,
    listInstructorError,
    listStudioByLocationId,
    classTemplateByBrandIdData,
    classTemplateByBrandId,
    getClassTemplateById,
    dispatch,
  } = useClassContext();
  const {
    sidebarBrandId: brandId,
    sidebarLocationId: locationId,
    encodeAndUploadImageToAws: uploadImageToAws,
  } = useMemberContext();
  const fileInputRef = useRef();
  const [file, setFile] = useState();
  const [clientSideImg, setClientSideImg] = useState();
  const [error, setError] = useState("");
  const [locationError, setLocationError] = useState("");
  const [selectedClass, setSelectedClass] = useState();
  const [updateClass] = useMutation(UPDATE_CLASS_SCHEDULE);
  const {
    data,
    loading,
    error: getClassTimezoneError,
  } = useQuery(GET_LOCTAION_BY_ID, {
    variables: {
      id: locationId ?? "",
    },
  });
  useEffect(() => {
    if (classTemplateId) {
      getClassTemplateById();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [classTemplateId]);

  useEffect(() => {
    classTemplateByBrandId();
    dispatch({
      type: CLEAR_ID,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    listInstructor();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (locationId) {
      listStudioByLocationId();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const handleChange = (templateId) => {
    setError("");
    getTemplateById(templateId);
  };

  //calling a mutations to add class schedule
  const [addClassSchedule] = useMutation(CREATE_CLASS_SCHEDULE);
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file.size > 5_242_880 || file.size === 5_242_880) {
      toast.error("Image needs to be less than 5MB");
    } else {
      setFile(file);
      const clientSideImgUrl = URL.createObjectURL(file);
      setClientSideImg(clientSideImgUrl);
    }
  };
  const handleImgUploadBtn = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };
  const updateClassImage = async (classId) => {
    const extension = file.name.split(".");
    const extensionName = extension[extension.length - 1];
    const updatedKey = `/classImages/${classId}/${uuidv4()}.${extensionName}`;
    const uploadedFile = await uploadImageToAws(file, updatedKey);
    const imageUrl = uploadedFile?.key;

    updateClass({
      variables: {
        input: {
          id: classId,
          imageUrl,
        },
      },
      onCompleted: () => {
        console.log("imageUrl has been updated successfully");
      },
      onError: (err) => {
        toast.error(err);
      },
    });
  };
  const onSubmit = async (values, OnSubmitProps) => {
    try {
      setError("");
      setLocationError("");

      if (classTemplateId.length === 0) {
        OnSubmitProps.setSubmitting(false);
        return setError("Please select class Template");
      }

      if (!locationId) {
        OnSubmitProps.setSubmitting(false);
        return setLocationError("Please select Location");
      }

      let input1 = {
        ...values,
        classTemplateId: classTemplateId,
        isThisRepeated: false,
        locationId: locationId,
        brandId: brandId,
        maxTeam: parseInt(values.maxTeam),
        maxPerTeam: parseInt(values.maxPerTeam),
        targetBooking: parseInt(values.targetBooking),
        classScheduleDateTime: getUTCdate(
          values.classScheduleDateTime,
          data.getLocation.timezone
        ),
      };

      delete input1.classDuration;
      delete input1.classDescription;
      delete input1.daysWithin;
      // delete input1.maxMembers;
      delete input1.hasVirtualClass;

      if (values.isThisRepeated === "SERIES CLASS") {
        let seriesId = uuidv4();
        const date = new Date(values.classScheduleDateTime);
        const nextDate = getUTCdate(
          format(addDays(date, 7), "yyyy-MM-dd'T'HH:mm"),
          data.getLocation.timezone
        );
        const nextDate2 = getUTCdate(
          format(addDays(date, 14), "yyyy-MM-dd'T'HH:mm"),
          data.getLocation.timezone
        );
        input1 = {
          ...input1,
          isThisRepeated: true,
          isThisSeries: true,
          seriesId: seriesId,
        };
        let input2 = {
          ...input1,
          classScheduleDateTime: nextDate,
        };
        let input3 = {
          ...input1,
          classScheduleDateTime: nextDate2,
        };

        await addClassSchedule({
          variables: {
            input: {
              ...input1,
            },
          },
          onCompleted: (data) => {
            if (file && file.size < 5_242_880) {
              updateClassImage(data.createClassSchedule.id);
            }
          },
        });

        await addClassSchedule({
          variables: {
            input: {
              ...input2,
            },
          },

          onCompleted: (data) => {
            if (file && file.size < 5_242_880) {
              updateClassImage(data.createClassSchedule.id);
            }
          },
        });
        await addClassSchedule({
          variables: {
            input: {
              ...input3,
            },
          },
          onCompleted: (data) => {
            OnSubmitProps.setSubmitting(false);
            OnSubmitProps.resetForm();
            toast.success("CLASS Added Successfully");
            if (file && file.size < 5_242_880) {
              updateClassImage(data.createClassSchedule.id);
            }
            navigate("/dashboard/classes");
          },
        });
      } else {
        await addClassSchedule({
          variables: {
            input: {
              ...input1,
            },
          },

          onCompleted: (data) => {
            OnSubmitProps.setSubmitting(false);
            OnSubmitProps.resetForm();
            toast.success("CLASS Added Successfully");
            if (file && file.size < 5_242_880) {
              updateClassImage(data.createClassSchedule.id);
            }
            navigate("/dashboard/classes");
          },
        });
      }
    } catch (error) {
      toast.error(`${error}`);
    }

    OnSubmitProps.setSubmitting(false);
    OnSubmitProps.resetForm();
  };
  if (!locationId) {
    return (
      <div className="no-data-holder">
        <div className="no-data">
          <h3 className="fs-12 uppercase">
            Please Choose a Club Location First
          </h3>
        </div>
      </div>
    );
  }
  if (getClassTimezoneError) {
    return (
      <div className="dashboard">
        <div className="dashboard-loading">
          Please select a location in sidebar
        </div>
      </div>
    );
  }
  if (listInstructorLoading || loading) {
    return (
      <div className="dashboard">
        <div className="dashboard-loading">
          <BeatLoader color="white" />
        </div>
      </div>
    );
  }
  if (listStudioError) {
    return (
      <div className="dashboard error fs-12">
        <div className="dashboard-loading">Couldn't load studio</div>
      </div>
    );
  }
  if (listStudioError) {
    return (
      <div className="dashboard error fs-12">
        <div className="dashboard-loading">Couldn't load studio</div>
      </div>
    );
  }
  if (listStudioLoading) {
    return (
      <div className="dashboard">
        <div className="dashboard-loading">
          <BeatLoader color="white" />
        </div>
      </div>
    );
  }

  return (
    <>
      {classTemplateByBrandIdData &&
        studioData &&
        allInstructor &&
        !listInstructorError &&
        !listInstructorLoading &&
        !listStudioError &&
        !listStudioLoading && (
          <>
            <Formik
              enableReinitialize
              initialValues={{
                classTemplateId: "",
                isThisRepeated: "",
                bookingLockoutMinutes: "",
                cancelLockoutMinutes: "",
                classDuration: `${classTemplateDataById?.getClassTemplate?.classDuration}`,
                daysWithin: `${
                  classTemplateDataById
                    ? classTemplateDataById?.getClassTemplate?.daysWithin
                    : ""
                }`,
                // maxMembers: `${
                //   classTemplateDataById
                //     ? classTemplateDataById.getClassTemplate.maxMembers
                //     : ""
                // }`,
                classDescription: `${
                  classTemplateDataById
                    ? classTemplateDataById.getClassTemplate.classDescription
                    : ""
                }`,
                hasVirtualClass: `${
                  classTemplateDataById
                    ? classTemplateDataById.getClassTemplate.hasVirtualClass
                    : ""
                }`,
                isBookable: false,
                canWaitlist: false,
                studioId: "",
                instructorId: "",
                classScheduleDateTime: "",
                maxBooking: "",
                targetBooking: "",
                maxTeam: "",
                maxPerTeam: "",
              }}
              validationSchema={classScheduleSchema}
              onSubmit={(values, OnSubmitProps) => {
                for (const key in values) {
                  if (
                    values.hasOwnProperty(key) &&
                    (values[key] === "" ||
                      values[key] === null ||
                      values[key] === undefined)
                  ) {
                    delete values[key];
                  }
                }
                onSubmit(values, OnSubmitProps);
              }}
            >
              {(formik) => {
                return (
                  <>
                    <Header pageTitle="Add Class Schedule" />
                    <Form>
                      <div className="add-class-container mt-32">
                        <div className="add-class-container">
                          <div className="tabs">
                            {/*  ClASS TEMPLATE DETAILS CONTENT */}
                            {classTemplateByBrandIdData && (
                              <div className="content-container add-class-main pd-18 mb-12">
                                {/* <label className="fs-14">Select Template</label> */}
                                <div className="inputs">
                                  {classTemplateByBrandIdData &&
                                  classTemplateDataById?.getClassTemplate ? (
                                    <>
                                      <div className="row">
                                        <div className="first flex-wrap">
                                          <div className="detail">
                                            <div className="membership-select">
                                              <span className="heading-and-icon">
                                                <label
                                                  className="location-heading fs-12"
                                                  htmlFor="membershipId"
                                                >
                                                  Select Template*
                                                  <AiOutlineSearch />
                                                </label>
                                              </span>

                                              <div className="location-value loc-select">
                                                <input
                                                  id="id"
                                                  type="text"
                                                  list="template-list-2"
                                                  placeholder="Search a template"
                                                  className="select-element fs-12 text-white"
                                                  name="id"
                                                  onChange={(e) => {
                                                    const selectedOption =
                                                      classTemplateByBrandIdData.find(
                                                        (option) =>
                                                          option.className ===
                                                          e.target.value
                                                      );
                                                    if (selectedOption) {
                                                      handleChange(
                                                        selectedOption.id
                                                      );
                                                    }
                                                  }}
                                                  value={selectedClass}
                                                  autoComplete="off"
                                                />

                                                <datalist id="template-list-2">
                                                  {classTemplateByBrandIdData.map(
                                                    (option) => {
                                                      return (
                                                        <option
                                                          key={option.id}
                                                          value={
                                                            option.className
                                                          }
                                                          className="fs-14"
                                                        ></option>
                                                      );
                                                    }
                                                  )}
                                                </datalist>
                                              </div>
                                            </div>
                                            {error && (
                                              <div className="fs-14 error">
                                                {error}
                                              </div>
                                            )}
                                          </div>

                                          <div className="detail">
                                            <Input
                                              type="number"
                                              label="Duration *"
                                              name="classDuration"
                                              id="classDuration"
                                              disabled
                                            />
                                          </div>
                                          <div className="detail">
                                            <Input
                                              type="number"
                                              label="Prebook Days"
                                              name="daysWithin"
                                              id="daysWithin"
                                              disabled
                                            />
                                          </div>
                                        </div>

                                        <div className="detail">
                                          <p className="reason-title pb-10 text-gray fs-12">
                                            Class Description
                                          </p>
                                          <textarea
                                            className="reason-box fs-12"
                                            id="classDescription"
                                            name="classDescription"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={
                                              formik.values.classDescription
                                            }
                                            disabled
                                          ></textarea>
                                        </div>

                                        <div className="toggles">
                                          <div className="detail">
                                            <ToggleFormik
                                              label="Virtual Class"
                                              id="hasVirtualClass"
                                              name="hasVirtualClass"
                                              value={formik.hasVirtualClass}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="row">
                                        <div className="first flex-wrap">
                                          <div className="detail">
                                            <div className="membership-select">
                                              <span className="heading-and-icon">
                                                <label
                                                  className="location-heading fs-12"
                                                  htmlFor="membershipId"
                                                >
                                                  Select Template*
                                                  <AiOutlineSearch />
                                                </label>
                                              </span>

                                              <div className="location-value loc-select">
                                                <input
                                                  id="id"
                                                  type="text"
                                                  list="template-list-2"
                                                  placeholder="Search a template"
                                                  className="select-element fs-12 text-white"
                                                  name="id"
                                                  onChange={(e) => {
                                                    const selectedOption =
                                                      classTemplateByBrandIdData.find(
                                                        (option) =>
                                                          option.className ===
                                                          e.target.value
                                                      );
                                                    if (selectedOption) {
                                                      handleChange(
                                                        selectedOption.id
                                                      );
                                                    }
                                                  }}
                                                  value={selectedClass}
                                                  autoComplete="off"
                                                />

                                                <datalist id="template-list-2">
                                                  {classTemplateByBrandIdData.map(
                                                    (option) => {
                                                      return (
                                                        <option
                                                          key={option.id}
                                                          value={
                                                            option.className
                                                          }
                                                          className="fs-14"
                                                        ></option>
                                                      );
                                                    }
                                                  )}
                                                </datalist>
                                              </div>
                                            </div>
                                            {error && (
                                              <div className="fs-14 error">
                                                {error}
                                              </div>
                                            )}
                                          </div>

                                          <div className="detail">
                                            <Input
                                              type="number"
                                              label="Duration *"
                                              name="classDuration"
                                              id="classDuration"
                                              value=""
                                              disabled
                                            />
                                          </div>
                                          <div className="detail">
                                            <Input
                                              type="number"
                                              label="Prebook Days"
                                              name="daysWithin"
                                              id="daysWithin"
                                              value=""
                                              disabled
                                            />
                                          </div>
                                        </div>

                                        <div className="detail">
                                          <p className="reason-title fs-12">
                                            Class Description
                                          </p>
                                          <textarea
                                            className="reason-box fs-12"
                                            id="classDescription"
                                            name="classDescription"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value=""
                                            disabled
                                          ></textarea>
                                        </div>

                                        <div className="toggles">
                                          <div className="detail">
                                            <Toggle
                                              label="Virtual Class"
                                              id="hasVirtualClass"
                                              name="Virtual Class"
                                              value=""
                                              checked={false}
                                              disabled
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  )}
                                  <div className="right-container"></div>
                                </div>
                              </div>
                            )}

                            <div className="content-container class-subdetails pd-18 flex-wrap">
                              <div>
                                <input
                                  type="file"
                                  onChange={handleImageUpload}
                                  accept="image/*"
                                  ref={fileInputRef}
                                  name="imageUrl"
                                  className="staff-img-input pb-12"
                                />
                                <div className="profile-img-container">
                                  <img
                                    src={
                                      clientSideImg
                                        ? clientSideImg
                                        : "https://via.placeholder.com/150"
                                    }
                                    alt=""
                                  />
                                  <button
                                    className="btn"
                                    onClick={handleImgUploadBtn}
                                  >
                                    Upload Picture
                                  </button>
                                </div>
                              </div>

                              <div className="right-container flex-wrap">
                                <div className="selectors flex-wrap">
                                  <Select
                                    label="Class Occurrence *"
                                    optionvalues={class_occurence}
                                    selecttype="array"
                                    name="isThisRepeated"
                                    id="isThisRepeated"
                                    className="select-element-value"
                                    autoComplete="off"
                                  />
                                  <Select
                                    label="Studio *"
                                    name="studioId"
                                    optionvalues={studioData?.getStudioByLocationId?.items.filter(
                                      (obj) => obj.isActive
                                    )}
                                    selecttype="object"
                                    objectvalue="id"
                                    objectname="studioName"
                                    className="select-element-value"
                                    autoComplete="off"
                                  />
                                  <div className="membership-select select-element">
                                    <span className="heading-and-icon">
                                      <label
                                        className="location-heading fs-12"
                                        htmlFor="membershipId"
                                      >
                                        Instructor*
                                        <AiOutlineSearch />
                                      </label>
                                    </span>

                                    <div className="location-value loc-select">
                                      <input
                                        id="id"
                                        type="text"
                                        list="instructor-list"
                                        placeholder="Search a instructor"
                                        className="select-element fs-12 text-white"
                                        name="id"
                                        onChange={(e) => {
                                          const selectedOption =
                                            allInstructor?.listClassInstructors?.items.find(
                                              (option) =>
                                                option.givenName +
                                                  " " +
                                                  option.surname ===
                                                e.target.value
                                            );
                                          if (selectedOption) {
                                            formik.setFieldValue(
                                              "instructorId",
                                              selectedOption.id
                                            );
                                          }
                                        }}
                                        autoComplete="off"
                                      />

                                      <datalist id="instructor-list">
                                        {allInstructor?.listClassInstructors?.items.map(
                                          (option) => {
                                            return (
                                              <option
                                                key={option.id}
                                                value={`${option.givenName} ${option.surname}`}
                                                className="fs-14"
                                              ></option>
                                            );
                                          }
                                        )}
                                      </datalist>
                                    </div>
                                  </div>
                                </div>

                                <div className="subdetails flex-wrap">
                                  <Input
                                    type="datetime-local"
                                    label="Date *"
                                    name="classScheduleDateTime"
                                    id="classScheduleDateTime"
                                    autoComplete="off"
                                  />

                                  <Input
                                    type="number"
                                    label="Max Bookings *"
                                    name="maxBooking"
                                    id="maxBooking"
                                    autoComplete="off"
                                    min={0}
                                  />
                                  <Input
                                    type="number"
                                    label="Target"
                                    name="targetBooking"
                                    id="targetBooking"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="subdetails flex-wrap">
                                  <Input
                                    type="number"
                                    label="Booking Lockout (mins)"
                                    name="bookingLockoutMinutes"
                                    id="bookingLockoutMinutes"
                                    autoComplete="off"
                                  />
                                  <Input
                                    type="number"
                                    label="Cancellation Window (mins)"
                                    name="cancelLockoutMinutes"
                                    id="cancelLockoutMinutes"
                                    autoComplete="off"
                                  />
                                </div>
                                <div className="subdetails flex-wrap">
                                  <Input
                                    type="number"
                                    label="Max Team"
                                    name="maxTeam"
                                    id="maxTeam"
                                    autoComplete="off"
                                  />
                                  <Input
                                    type="number"
                                    label="Max Per Team"
                                    name="maxPerTeam"
                                    id="maxPerTeam"
                                    autoComplete="off"
                                  />
                                  <div className="bookable-row">
                                    <div className="detail">
                                      <ToggleFormik
                                        label="Bookable"
                                        id="isBookable"
                                        name="isBookable"
                                        value={formik.isBookable}
                                      />
                                    </div>
                                    <div className="detail">
                                      <ToggleFormik
                                        label="Waitlist"
                                        id="canWaitlist"
                                        name="canWaitlist"
                                        value={formik.canWaitlist}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        {locationError && (
                          <div className="error fs-14 mt-32">
                            {locationError}
                          </div>
                        )}

                        <div className="add-btn-container right-btn mt-12 pb-32">
                          <Button
                            loading={formik.isSubmitting}
                            name={"Save"}
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-confirm fs-12"
                            disabled={formik.isSubmitting ? true : false}
                          />
                          <Link to={`/dashboard/classes`}>
                            <Button
                              name="Cancel"
                              btntype="button"
                              className="btn btn-deny  fs-12"
                            />
                          </Link>
                        </div>
                      </div>
                    </Form>
                  </>
                );
              }}
            </Formik>
          </>
        )}
    </>
  );
};

export default AddClass;
