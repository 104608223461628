import "./classBookings.scss";
import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useQuery, useLazyQuery, useMutation } from "@apollo/client";
import { FiCheck, FiX } from "react-icons/fi";
import { AiOutlineSearch } from "react-icons/ai";
import { toast } from "react-toastify";
import { Formik, Form } from "formik";
import { BeatLoader } from "react-spinners";
import { BsCardText } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import {
  GET_CLASS_BOOKINGS,
  GET_MEMBER_BY_EMAIL,
  GET_CLASS_SCHEDULE_BY_ID,
  GET_WAITINGLIST_CLASS_BOOKING,
} from "../../gqloperations/queries";
import {
  CANCEL_BOOKING,
  BOOK_CLASS,
  UPDATE_CLASS_BOOKING,
  ADD_CASUAL_BOOKING,
} from "../../gqloperations/mutations";
import { requiredPhoneSchema } from "../../validations/Validation";
import { casualBookingSchema } from "../../validations/ClassValidation";
import { useMemberContext } from "../../contexts/MemberContext";
import { SET_MEMBER_ID } from "../../actions";
import {
  Button,
  Table,
  Modal,
  ExportGroup,
  Header,
  InternationalPhoneInput,
  StatusModal,
  RadioButton,
  Input,
} from "../../components";


export default function ClassBookings() {
  let { id: classScheduleId } = useParams();
  const { createLog, dispatch } = useMemberContext();
  const navigate = useNavigate();
  const [tableData, setTableData] = useState();
  const [waitingListTable, setWaitinglistTable] = useState();
  const [member, setMember] = useState({});
  const [error, setError] = useState("");
  const [sorting, setSorting] = useState([]);
  const [modalBox, setModalBox] = useState(false);

  const [attendeeType, setAttendeeType] = useState("member");
  const [componentName, setComponentName] = useState("");
  const [curRow, setCurRow] = useState({});
  const [index, setIndex] = useState(0);

  const { data: sechduledClassInfo } = useQuery(GET_CLASS_SCHEDULE_BY_ID, {
    variables: {
      id: classScheduleId,
    },
  });

  const notCancelledItems = (arr) => {
    return arr.filter((cur) => !cur.cancelledDateTime);
  };
  const {
    loading: bookingsLoading,
    error: bookingsError,
    refetch,
  } = useQuery(GET_CLASS_BOOKINGS, {
    fetchPolicy: "no-cache",
    variables: { classScheduleId: classScheduleId, limit: 1000 },
    onCompleted: (data) => {
      const { items } = data.getClassBookingsByClassScheduleId;
      const notCancelledBookinggs = notCancelledItems(items);
      setTableData(notCancelledBookinggs);
    },
  });

  const { refetch: waitinglistRefetch } = useQuery(
    GET_WAITINGLIST_CLASS_BOOKING,
    {
      fetchPolicy: "no-cache",
      variables: { waitlistClassScheduleId: classScheduleId, limit: 1000 },
      onCompleted: (data) => {
        const { items } = data?.getWaitlistClassBookingsByClassScheduleId;
        const notCancelledWaitinglists = notCancelledItems(items);
        setWaitinglistTable(notCancelledWaitinglists);
      },
      onError: () => {
        toast.error("Waiting list getting failed");
      },
    }
  );

  // const [getMemberByMobile, { loading: memberLoading }] = useLazyQuery(
  //   GET_MEMBER_BY_MOBILE,
  //   {
  //     onCompleted: (data) => {
  //       const { items } = data.getMemberByMobile;
  //       if (items?.length > 0) {
  //         setMember(items[0]);
  //         setError("");
  //       } else {
  //         setMember({});
  //         setError("No member found with this mobile number");
  //       }
  //     },
  //   }
  // );
  const [getMemberByEmail, { loading: memberLoading }] = useLazyQuery(
    GET_MEMBER_BY_EMAIL,
    {
      onCompleted: (data) => {
        const { items } = data.getMemberByEmail;
        if (items?.length > 0) {
          setMember(items[0]);
          setError("");
        } else {
          setMember({});
          setError("No member found with this email");
        }
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
    }
  );
  const [cancelBooking, { loading: cancelBookingLoading }] =
    useMutation(CANCEL_BOOKING);
  const [addCasualBooking] = useMutation(ADD_CASUAL_BOOKING);

  const [bookClass, { loading: bookingLoading }] = useMutation(BOOK_CLASS, {
    variables: {
      memberId: member?.memberId,
      classSchedule: classScheduleId,
    },

    onCompleted: (data) => {
      refetch();
      waitinglistRefetch();
      toast.success("Attendee Added Successfully!");
      setMember({});
    },
  });

  const [updateBooking] = useMutation(UPDATE_CLASS_BOOKING, {
    onCompleted: () => {
      refetch();
    },
  });
  const handleAttended = async (bookingId, hasAttended) => {
    try {
      await updateBooking({
        variables: { input: { id: bookingId, hasAttended } },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleView = (id) => {
    dispatch({
      type: SET_MEMBER_ID,
      payload: {
        memberId: id,
      },
    });
    navigate(`/dashboard/member/${id}`);
  };

  const handleComponent = (componentname, rowObj) => {
    setComponentName(componentname);
    setCurRow(rowObj);
  };
  const modalClose = () => {
    setComponentName("");
    setCurRow({});
  };

  const onSubmitCasualBookings = (values, OnSubmitProps) => {
    let input = {};
    input = { ...values, classSchedule: classScheduleId };

    addCasualBooking({
      variables: { ...input },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();

        refetch();
        waitinglistRefetch();
        toast.success("Class booking added successfully");
        setModalBox(false);
      },
      onError: (error) => {
        OnSubmitProps.setSubmitting(false);
        toast.error(error.message);
      },
    });
  };

  const columns = [
    {
      accessorFn: (row) =>
        row.memberId
          ? `${row?.memberDetails?.givenName} ${row?.memberDetails?.surname}`
          : `${row?.casualFirstName} ${row?.casualLastName}`,
      header: "SURNAME, NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "name",
    },
    {
      accessorFn: (row) =>
        row.memberId ? row?.memberDetails?.mobileNumber : row?.casualPhone,
      header: "MOBILE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "mobileNumber",
    },
    {
      accessorFn: (row) =>
        row.memberId ? row?.memberDetails?.email : row?.casualEmail,
      header: "EMAIL",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "email",
    },
    {
      accessorFn: (row) => (row.memberId ? "Member Booking" : "Casual Booking"),
      header: "Booking Type",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "bookingType",
    },

    {
      id: "row-actions",
      cell: (props) => (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "40px" }}
        >
          {props?.row?.original?.memberId ? (
            <BsCardText
              className="icon-standard"
              onClick={() => {
                const id = props.row.original.memberId;
                handleView(id);
              }}
            />
          ) : (
            <div className="grayed-icon">
              <BsCardText />
            </div>
          )}
          <MdDeleteOutline
            className="icon-standard delete-icon"
            onClick={() => {
              handleComponent("delete-template", {
                id: props?.row?.original?.id,
              });
            }}
          />
        </div>
      ),
    },
    {
      accessorKey: "hasAttended",
      header: "ATTEND",
      cell: (props) => {
        const attendStatus = props.getValue();
        return (
          <div>
            <button
              className={
                attendStatus === true
                  ? "attendChecked attended"
                  : "attendChecked "
              }
              onClick={async () => {
                const bookingId = props.row.original.id;
                handleAttended(bookingId, true);
              }}
            >
              <FiCheck className="no-effect-icon" />
            </button>
            <button
              className={
                attendStatus === false
                  ? "attendCrossed not-attended"
                  : "attendCrossed "
              }
              onClick={() => {
                const bookingId = props.row.original.id;
                handleAttended(bookingId, false);
              }}
            >
              <FiX className="no-effect-icon" />
            </button>
          </div>
        );
      },
      enableSorting: false,
      enableGlobalFilter: false,
    },
  ];

  const columnsForWaitingList = [
    {
      accessorFn: (row) =>
        row?.isCasualBooking
          ? `${row.casualFirstName} ${row.casualLastName}`
          : `${row?.memberDetails?.givenName} ${row?.memberDetails?.surname}`,

      header: "SURNAME, NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "nameWaitinglist",
    },
    {
      accessorFn: (row) =>
        row?.isCasualBooking
          ? `${row.casualPhone}`
          : `${row?.memberDetails?.mobileNumber}`,
      header: "MOBILE",
      cell: (info) => info.getValue(),
      enableSorting: false,
      enableGlobalFilter: true,
      id: "mobileNumberWaitinglist",
    },

    {
      accessorFn: (row) =>
        row.isCasualBooking ? "Casual Booking" : "Member Booking",
      header: "Booking Type",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: false,
      id: "bookingTypeWaitinglist",
    },

    {
      id: "row-actions",
      cell: (props) => (
        <div
          style={{ display: "flex", justifyContent: "flex-end", gap: "40px" }}
        >
          {props.row.original.memberDetails?.memberId ? (
            <BsCardText
              className="icon-standard"
              onClick={() => {
                const id = props.row.original.memberDetails?.memberId;

                handleView(id);
              }}
            />
          ) : (
            <div className="grayed-icon">
              <BsCardText />
            </div>
          )}
          <MdDeleteOutline
            className="icon-standard delete-icon"
            onClick={() => {
              handleComponent("delete-template", {
                id: props?.row?.original?.id,
              });
            }}
          />
        </div>
      ),
    },
  ];

  const handleAddAttendee = async () => {
    if (
      tableData?.find(
        (item) => item.memberDetails?.email === member?.email
      )
    ) {
      toast.error("Member is already in the class");
      return;
    }
    try {
      await bookClass();
    } catch (error) {
      toast.error(`${error}`);
    }
    setModalBox(false);
    setError("");
    refetch();
  };

  const handleDeleteClick = async (rowObj) => {
    const variables = { bookingId: rowObj.id };

    try {
      await cancelBooking({
        variables,
        onCompleted: () => {
          refetch();
          waitinglistRefetch();

          toast.success("Deleted successfully");
          modalClose();
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
  };

  const handleCancelAdd = () => {
    setModalBox(false);
    setError("");
    setMember({});
    setAttendeeType("member");
  };

  if (bookingsLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (bookingsError) {
    return (
      <div>
        <div>Error: Problem getting bookings</div>
      </div>
    );
  }
  return (
    <>
      <div className="bookings-page">
        <Header pageTitle="Bookings" />
        <div className="tabs">
          <div className="tabs-list">
            <div
              className={
                index !== 0
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(0);
                createLog(
                  `Clicked Standard tab on AddSuspension page ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
                  "member",
                  `${member?.getMember?.memberId}`
                );
              }}
            >
              Bookings
            </div>
            <div
              className={
                index !== 1
                  ? "tab-head fs-14 back-mediumgray"
                  : "tab-head fs-14 back-persianblue"
              }
              onClick={() => {
                setIndex(1);
                createLog(
                  `Clicked Medical tab on AddSuspension page ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
                  "member",
                  `${member?.getMember?.memberId}`
                );
              }}
            >
              Waiting List
            </div>
          </div>
        </div>
        {tableData && (
          <>
            <div className=" btn-row-bookings ">
              <ExportGroup
                data={index === 0 ? tableData : waitingListTable}
                columns={index === 0 ? columns : columnsForWaitingList}
                sorting={sorting}
              />
              {index === 0 && (
                <Button
                  name="ADD ATTENDEE"
                  btntype="submit"
                  btnname="submit"
                  className="btn-primary"
                  click={() => setModalBox(true)}
                />
              )}
            </div>

            {modalBox && (
              <Modal>
                <div className="bookings-modal pd-32">
                  <h1 className="fs-35 pb-24 uppercase item-centered">
                    {attendeeType === "member" && "ADD NEW ATTENDEE"}
                    {attendeeType === "casualvisit" && "ADD CASUAL ATTENDEE"}
                  </h1>
                  <div className="booking-type-radio-btns pb-24">
                    <div className="filter-cell sms">
                      <RadioButton
                        type="radiobutton"
                        name="attendeeType"
                        value="member"
                        checked={attendeeType === "member"}
                        onChange={(e) => {
                          setAttendeeType(e.target.value);
                        }}
                        label="Member"
                      />
                    </div>
                    <div className="filter-cell sms">
                      <RadioButton
                        type="radiobutton"
                        name="attendeeType"
                        value="casualvisit"
                        checked={attendeeType === "casualvisit"}
                        onChange={(e) => {
                          setAttendeeType(e.target.value);
                        }}
                        label="Casual visit"
                      />
                    </div>
                  </div>

                  {/* <Button
                name="next"
                btntype="button"
                btnname="Next"
                className="btn-primary"
                click={() => setModalType(attendeeType)}
                
              />  
              <Button
              name="cancel"
              btntype="button"
              btnname="cancel"
              className="btn-primary"
              click={() => (setAttendeeType(""),setModalBox(false))}             
            /> */}
                  {attendeeType === "member" && (
                    <div className="search-booking item-centered">
                      <Formik
                        initialValues={{ search: "" }}
                        validationSchema={requiredPhoneSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          getMemberByEmail({
                            variables: {
                              email: values.search.trim().toUpperCase(),
                            },
                          });
                          setSubmitting(false);
                        }}
                      >
                        {(formik) => {
                          return (
                            <Form className="add-casual-container">
                              <div className="search-input fs-1">
                                <Input
                                  type="text"
                                  label="Search member by email"
                                  autoComplete="off"
                                  name="search"
                                  className="fs-12"
                                />
                                {/* <p className="fs-12 text-gray item-centered">
                                  Search member by mobile number
                                </p>
                                <InternationalPhoneInput
                                  title=""
                                  name="search"
                                  id="search"
                                  editable={true}
                                /> */}
                                <button type="submit">
                                  <AiOutlineSearch />
                                </button>
                              </div>
                            </Form>
                          );
                        }}
                      </Formik>

                      {member && (
                        <>
                          <p className="fs-10 mt-12 mb-8 member-header">
                            Member name:{" "}
                          </p>
                          <p className="fs-12 mb-24">
                            {member.surname} {member.givenName}
                          </p>
                        </>
                      )}
                      {memberLoading && (
                        <p className="fs-12 item-centered">
                          Finding Members...
                        </p>
                      )}
                      {error && (
                        <p className="fs-12" style={{ color: "red" }}>
                          {error}
                        </p>
                      )}

                      <div className="btn-hold pt-12">
                        <Button
                          name={
                            bookingLoading ? (
                              <BeatLoader color="white" size={10} />
                            ) : (
                              "SAVE"
                            )
                          }
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-confirm fs-12"
                          click={handleAddAttendee}
                          disabled={member.memberId ? false : true}
                        />
                        <Button
                          name="CANCEL"
                          btntype="submit"
                          btnname="submit"
                          className="btn btn-deny  fs-12"
                          click={handleCancelAdd}
                        />
                      </div>
                    </div>
                  )}
                  {attendeeType === "casualvisit" && (
                    <Formik
                      enableReinitialize
                      initialValues={{
                        casualFirstName: "",
                        casualLastName: "",
                        casualPhone: "",
                        casualEmail: "",
                      }}
                      validationSchema={casualBookingSchema}
                      onSubmit={(values, OnSubmitProps) => {
                        onSubmitCasualBookings(values, OnSubmitProps);
                      }}
                    >
                      {(formik) => {
                        return (
                          <Form className="add-casual-container">
                            <Input
                              type="text"
                              label="First Name * "
                              name="casualFirstName"
                              autoComplete="off"
                            />
                            <Input
                              type="text"
                              label="Surname *"
                              name="casualLastName"
                              autoComplete="off"
                            />
                            <Input
                              type="email"
                              label="Email *"
                              name="casualEmail"
                              autoComplete="off"
                            />
                            <InternationalPhoneInput
                              title="Mobile Number *"
                              name="casualPhone"
                              editable={true}
                            />
                            <div className="add-btn-container">
                              {/* {formik.isSubmitting && (
                                <Button
                                  name={<BeatLoader color="white" />}
                                  btntype="submit"
                                  btnname="submit"
                                  disabled={true}
                                  className="btn btn-confirm fs-12"
                                />
                              )}
                              {!formik.isSubmitting && (
                                <Button
                                  name="SAVE"
                                  btntype="submit"
                                  btnname="submit"
                                  className="btn btn-confirm fs-12"
                                />
                              )} */}
                              <Button
                                loading={formik.isSubmitting}
                                name={"Save"}
                                btntype="submit"
                                btnname="submit"
                                className="btn btn-confirm fs-12"
                                disabled={formik.isSubmitting ? true : false}
                              />
                              <Button
                                name="Cancel"
                                btntype="button"
                                className="btn btn-deny  fs-12"
                                click={handleCancelAdd}
                              />
                            </div>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                </div>
              </Modal>
            )}
            <div>
              {index === 0 && (
                <p className="fs-14 text-gray">
                  {tableData ? tableData?.length : "0"} out of{" "}
                  {sechduledClassInfo?.getClassSchedule?.maxBooking} booked
                </p>
              )}
              <Table
                data={index === 0 ? tableData : waitingListTable}
                columns={index === 0 ? columns : columnsForWaitingList}
                rowsPerPage={10}
                sorting={sorting}
                setSorting={setSorting}
              />
            </div>

            {componentName && (
              <Modal>
                {componentName === "delete-template" && (
                  <StatusModal
                    loading={cancelBookingLoading}
                    curRow={curRow}
                    modalHeaderTxt={"Cancel Booking"}
                    modalParaText={
                      "Are you sure you want to cancel this booking?"
                    }
                    handleStatusClick={handleDeleteClick}
                    modalClose={modalClose}
                  />
                )}
              </Modal>
            )}
          </>
        )}
      </div>
    </>
  );
}
