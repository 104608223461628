import "../addMembership.scss";
import { Button, Checkbox, DatePicker } from "../../../components";
import { useState, useRef } from "react";
import { useMemberContext } from "../../../contexts/MemberContext";
import { addDays, addWeeks, format, isAfter } from "date-fns";
import checkDateOverlap from "../../../utils/checkDateOverlap";
const YourMembership = (props) => {
  const {
    membershipDetails,
    locationDetails,
    age,
    membershipStartDate,
    setMembershipStartDate,
    voucherDetail,
    membershipPrice,
    currentPIFContract,
  } = props;
  const { sidebarLocationName } = useMemberContext();
  const isPaymentDelay =
    membershipDetails?.membershipDetails?.paymentDelay?.amount !== 0 &&
    membershipDetails?.membershipDetails?.paymentDelay !== null;
  const calculateDateFromSpecificDate = ({ type, amount, date }) => {
    const currentDate = date ? new Date(date) : new Date();
    if (type === "DAYS") {
      currentDate.setDate(currentDate.getDate() + amount);
    } else if (type === "MONTHS") {
      currentDate.setMonth(currentDate.getMonth() + amount);
    } else if (type === "YEARS") {
      currentDate.setFullYear(currentDate.getFullYear() + amount);
    } else if (type === "WEEKS") {
      currentDate.setDate(currentDate.getDate() + amount * 7);
    }
    return currentDate;
  };
  let nextDebit;
  if (isPaymentDelay) {
    const maintenanceDates = locationDetails?.locationMaintenance?.items.filter(
      (maintenance) => !maintenance.isDeleted
    );

    const currentMaintenance = maintenanceDates?.find(
      ({ startDateTime, endDateTime }) => {
        return checkDateOverlap(new Date(), {
          start: startDateTime,
          end: endDateTime,
        });
      }
    );
    nextDebit = calculateDateFromSpecificDate({
      type: membershipDetails?.membershipDetails?.paymentDelay?.type,
      amount: membershipDetails?.membershipDetails?.paymentDelay?.amount,
      date:
        locationDetails?.openDate &&
        isAfter(new Date(locationDetails?.openDate), new Date())
          ? locationDetails?.openDate
          : currentMaintenance
          ? addDays(new Date(currentMaintenance.endDateTime), 1)
          : new Date(),
    });
  }

  const startDayRef = useRef(membershipStartDate);

  const calculateMinDate = () => {
    const startDay = startDayRef.current;
    const openDay = locationDetails.openDate;
    const maintenanceDates = locationDetails?.locationMaintenance?.items.filter(
      (maintenance) => !maintenance.isDeleted
    );

    const overlappedMaintenance = maintenanceDates?.find(
      ({ startDateTime, endDateTime }) => {
        return checkDateOverlap(new Date(), {
          start: startDateTime,
          end: endDateTime,
        });
      }
    );

    if (startDay && !openDay) return format(new Date(startDay), "yyyy-MM-dd");

    if (!startDay && openDay) return format(new Date(openDay), "yyyy-MM-dd");

    if (startDay && openDay) {
      const startDate = new Date(startDay);
      const openDate = new Date(openDay);

      if (isAfter(startDate, openDate)) {
        return format(startDate, "yyyy-MM-dd");
      } else {
        return format(openDate, "yyyy-MM-dd");
      }
    } else if (startDay && overlappedMaintenance) {
      return format(overlappedMaintenance, "yyyy-MM-dd");
    } else {
      return format(new Date(), "yyyy-MM-dd");
    }
  };

  return (
    <>
      <div className="your-membership-container">
        <h1 className="title">Your Membership</h1>
        <div className="location">
          <div>
            <p>{sidebarLocationName}</p>
          </div>
          {/* <p className="address">{memberInformation.locationDetail.address1}</p> */}
        </div>
        <div className="price-details">
          <div className="membership-plan">
            <p>Membership plan</p>
          </div>
          <div className="membership-price">
            <p>
              {membershipDetails?.membershipDetails?.membershipName.toUpperCase()}
            </p>
            <p>
              $
              {isPaymentDelay
                ? Number(membershipDetails?.costPrice).toFixed(2)
                : membershipPrice?.membershipPrice}
            </p>
          </div>
          <div className="membership-fee">
            <p>JOINING FEE</p>
            <p>${membershipPrice?.joiningFee}</p>
          </div>
          <div className="membership-fee">
            <p>ACTIVATION FEE</p>
            <p>${membershipPrice?.activationFee}</p>
          </div>
          <div className="membership-fee">
            <p>ACCESS FEE</p>
            <p>${membershipPrice?.accessFee}</p>
          </div>
        </div>
        {!currentPIFContract && (
          <div className="promo-code">
            <p>Promo code</p>
            <input
              value={
                "PF-" + (voucherDetail ? voucherDetail?.voucherCode ?? "" : "")
              }
              disabled
            />
          </div>
        )}

        {age >= 18 && (
          <div className="membership-start-date">
            <p>Your start date</p>
            <div className="date-input">
              <DatePicker
                value={format(new Date(membershipStartDate), "yyyy-MM-dd")}
                disabled={isPaymentDelay}
                min={calculateMinDate()}
                onChange={(e) => setMembershipStartDate(e.target.value)}
              />
            </div>
            {/* <input value={"PF-alskdfj"} disabled /> */}
          </div>
        )}
        {membershipDetails?.membershipDetails.recurring &&
        voucherDetail?.type !== "COMPLIMENTARY" &&
        membershipPrice?.totalPrice ? (
          <div className="next-direct">
            <p>Next direct debit</p>
            <p>
              {format(
                isPaymentDelay
                  ? new Date(nextDebit)
                  : addWeeks(
                      new Date(membershipStartDate),
                      membershipDetails?.membershipDetails?.paymentFrequency
                        ?.amount
                    ),
                "dd/MM/yyyy"
              )}
            </p>
          </div>
        ) : null}
        {membershipDetails?.membershipDetails.recurring &&
          voucherDetail?.type !== "COMPLIMENTARY" && (
            <div className="fortnightly">
              <p>Cost per fortnight</p>
              <p>
                $
                {isPaymentDelay
                  ? membershipDetails.costPrice.toFixed(2)
                  : membershipPrice?.membershipPrice}
              </p>
            </div>
          )}
        <div className="total">
          <p>TOTAL DUE TODAY*</p>
          <p>${Number(membershipPrice?.totalPrice)}</p>
        </div>
      </div>
    </>
  );
};
export default YourMembership;
