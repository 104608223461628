import { useState } from "react";
import { format } from "date-fns";
import DatePicker from "../formUiElements/input/DatePicker";
import "./generateBox.scss";
import Button from "../formUiElements/Button";
import { Formik, Form } from "formik";
export default function GenerateBox({
  setGenerate,
  setStartDate,
  setEndDate,
  type,
  onlyStartDate = false,
}) {
  const getToDate = (values) => {
    if (values.from && !values.to && type !== "PIFExpiring") {
      return format(new Date(), "yyyy-MM-dd");
    }
    return values.to;
  };
  return (
    <Formik
      enableReinitialize
      initialValues={{
        from: "",
        to: "",
      }}
      validate={(values) => {
        let errors = {};

        const { from } = values;

        if (!from) {
          errors.from = "Please select a start date";
        }

        return errors;
      }}
      onSubmit={async (values, OnSubmitProps) => {
        if (!onlyStartDate && values.from && !values.to) {
          setEndDate(format(new Date(), "yyyy-MM-dd"));
        }
        setGenerate(true);
      }}
    >
      {(formik) => {
        return (
          <div>
            <Form>
              <div className="generate-box">
                <div className="left-content">
                  <p className="fs-12 text-gray report-betwwen">
                    {!onlyStartDate ? "Show reports between" : "Select a Date"}
                  </p>
                  <div className="date-input">
                    <DatePicker
                      name="from"
                      min={
                        type === "PIFExpiring"
                          ? format(new Date(), "yyyy-MM-dd")
                          : undefined
                      }
                      value={formik.values.from}
                      max={
                        formik.values.to
                          ? formik.values.to
                          : type === "PIFExpiring"
                          ? undefined
                          : type === "membershipBreakup"
                          ? undefined
                          : format(new Date(), "yyyy-MM-dd")
                      }
                      onChange={(e) => {
                        formik.handleChange(e);
                        setStartDate(e.target.value);
                      }}
                    />
                  </div>
                  {!onlyStartDate && (
                    <div className="date-input">
                      <DatePicker
                        name="to"
                        min={
                          formik.values.from ? formik.values.from : undefined
                        }
                        max={
                          type !== "PIFExpiring"
                            ? format(new Date(), "yyyy-MM-dd")
                            : undefined
                        }
                        value={getToDate(formik.values)}
                        onChange={(e) => {
                          formik.handleChange(e);
                          // setGenerate(false);
                          setEndDate(e.target.value);
                        }}
                      />
                    </div>
                  )}
                </div>
                {formik.errors.from && (
                  <div>
                    <p className="warning-text error fs-10">
                      {formik.errors.from}
                    </p>
                  </div>
                )}

                <Button
                  loading={formik.isSubmitting ? 1 : 0}
                  name="GENERATE"
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-transparent block btn-lg fs-12"
                />
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
}
