import { React, useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { BeatLoader } from "react-spinners";
import { AiOutlineSearch } from "react-icons/ai";
import getTypeIcon from "../../utils/getTypeIcon";
import chart_icon from "../../assets/images/chart_icon.svg";
import { brandTypes } from "../../constants/commonConstant";
import { toast } from "react-toastify";
import { GET_GROUP, LIST_LOCATION } from "../../gqloperations/queries";
import { ADD_LOCATION_TO_GROUP } from "../../gqloperations/mutations";
import { useMutation, useQuery } from "@apollo/client";
import { FilterByBrand, Table, Header, Checkbox} from "../../components";

const GroupLocations = (props) => {
  let { id } = useParams();
  const navigate = useNavigate();
  const [nextToken, setNextToken] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [sorting, setSorting] = useState([]);
  const [searchBarInputVal, setSearchBarInputVal] = useState("");
  const [rowSelection, setRowSelection] = useState([]);
  const [filter, setFilter] = useState(false);
  const [checkedRows, setCheckedRows] = useState({ isAllRowsChecked: false });
  const [addLocationToGroup, { loading: addLocationToGroupLoading }] =
    useMutation(ADD_LOCATION_TO_GROUP);
  const [isPaginating, setPaginating] = useState(false)
  const {
    loading: getLocationsLoading,
    error: getLocationsError,
    data: getLocationsData,
  } = useQuery(LIST_LOCATION, {
    variables: {
      nextToken: nextToken,
    },
    onCompleted: (data) => {
      if (data?.listLocations?.items)
        if (tableData && isPaginating)
          setTableData([...tableData, ...data.listLocations.items]);
        else setTableData(data.listLocations.items);
    },
    onError: (error) => {
      toast.error(`${error}`);
    },
  });

  const {
    loading: getGroupLoading,
    error: getGroupError,
    data: getGroupData,
    refetch,
  } = useQuery(GET_GROUP, {
    variables: {
      
      id,
    },
    onError: (error) => {
      toast.error(`${error}`);
    },
  });

  const handleCancel = () => {
    navigate(-1);
  };
  const handleFilterPopUp = (event) => {
    event.stopPropagation();
    setFilter(!filter);
  };
  const handleClick = (locationId) => {
    const locationIds = locationId.map((item) => item.original.id);
    const appliedLocationId = getGroupData?.getGroup?.groupLocation?.items.map(
      (loc) => {
        return loc.locationId;
      }
    );
    const filteredLocationId = locationIds.filter((loc) => {
      return !appliedLocationId.includes(loc);
    });
    addLocationToGroup({
      variables: {

        locationIds: [...filteredLocationId],
        groupId: id,
        operation: "ADD_LOCATION_TO_GROUP",
      },
      onCompleted: () => {
        toast.success("Location access added to group successfully");
        setPaginating(false)
        setNextToken(null);
        refetch();
        setRowSelection([]);
      },
      onError: (error) => {
        toast.error(`${error}`);
      },
    });
  };

  const handleNextToken = () => {
    if (getLocationsData?.listLocations?.nextToken) {
      setPaginating(true)
      setNextToken(getLocationsData?.listLocations?.nextToken);
    }
  };
  const handleChange = (event) => {
    const searchVal = event.target.value;
    setSearchBarInputVal(searchVal);

    if (searchVal.length === 0) {
      setGlobalFilter(searchVal);
    }
  };

  const onSearchBarSubmit = (event) => {
    event.preventDefault();
    setGlobalFilter(String(searchBarInputVal));
  };
  const typeColumnFilter = (row, columnId, filterValues) => {
    const columnVal = row.getValue(columnId);
    return filterValues.some((cur) => {
      return columnVal === cur;
    });
  };
  const columns = [
    {
      id: "select",
      header: ({ table }) => {
        return (
          <div className="head-checkbox-container">
            <div className="px-1">
              {checkedRows?.isAllRowsChecked ? (
                <Checkbox type="checkbox" disabled={true} />
              ) : (
                <Checkbox
                  {...{
                    checked: table.getIsAllRowsSelected(),
                    indeterminate: table.getIsSomeRowsSelected(),
                    onChange: table.getToggleAllRowsSelectedHandler(),
                  }}
                />
              )}
            </div>
            <div>SELECT ALL</div>
          </div>
        );
      },
      cell: ({ row }) => (
        <div className="px-1">
          {checkedRows?.hasOwnProperty(row.original.id) ? (
            <Checkbox type="checkbox" disabled={true} />
          ) : (
            <Checkbox
              {...{
                checked: row.getIsSelected(),
                indeterminate: row.getIsSomeSelected(),
                onChange: row.getToggleSelectedHandler(),
              }}
            />
          )}
        </div>
      ),
    },
    {
      accessorFn: (row) => row?.brandDetails?.name ?? "",
      header: (props) => {
        const handleSubmit = (values, OnSubmitProps) => {
          const { checked } = values;
          props.column.setFilterValue(checked);
          setFilter(false);
        };
        return (
          <>
            <div onClick={handleFilterPopUp} className="icon">
              <img
                className="sortpopupicon"
                width="13"
                height="10"
                src={chart_icon}
                alt=""
              />
              <span>BRAND</span>
            </div>
            {filter && (
              <div className="filter-td">
                <FilterByBrand
                  handleSubmit={handleSubmit}
                  cancelFn={() => setFilter(false)}
                />
              </div>
            )}
          </>
        );
      },
      cell: (info) => {
        const brandName = info.getValue();
        const icon = getTypeIcon(brandName, brandTypes);
        return icon;
      },
      enableSorting: false,
      enableGlobalFilter: false,
      id: "type",
      filterFn: "typeFilter",
      enableColumnFilter: true,
    },
    {
      accessorFn: (row) =>
        row?.state || !row.state === null
          ? `${row?.name} (${row?.state})`
          : `${row?.name}`,
      header: "LOCATION NAME",
      cell: (info) => info.getValue(),
      enableSorting: true,
      enableGlobalFilter: true,
      id: "locationName",
    },
  ];

  useEffect(() => {
    const numOfRows = tableData.length ?? 0;
    if (numOfRows <= 0) {
      setCheckedRows({ isAllRowsChecked: true });
      return;
    }
    const checkedRowsObj = tableData?.reduce((acc, cur) => {
      const doesLocationMatch =
        getGroupData?.getGroup?.groupLocation?.items.find(
          (groupLocation) => groupLocation.locationId === cur.id
        );
      if (doesLocationMatch) {
        acc[cur.id] = true;
      }
      return acc;
    }, {});

    const numOfCheckedRows = Object.keys(checkedRowsObj)?.length ?? 0;
    const isAllRowsChecked = numOfCheckedRows === numOfRows;

    checkedRowsObj.isAllRowsChecked = isAllRowsChecked;
    setCheckedRows(checkedRowsObj);
  }, [tableData, getGroupData]);

  if (getGroupLoading || getLocationsLoading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );
  if (getGroupError) {
    return (
      <div>
        <div>Error: Problem getting groups</div>
      </div>
    );
  }
  if (getLocationsError) {
    return (
      <div>
        <div>Error: Problem getting locations</div>
      </div>
    );
  }
  return (
    <>
      <div className="apply-group-to-loc">
        <Header pageTitle="Add Location Access" />
        <div className="apply-group-to-loc-content">
          <div className="actions-row mt-32 flex-wrap">
            {getGroupData?.getGroup?.name && (
              <p className="uppercase group-name fs-12">
                Group Name : {getGroupData?.getGroup?.name}
              </p>
            )}
            <div className="search-container">
              <form onSubmit={onSearchBarSubmit}>
                <div className="search-bar">
                  <div className="search-bar-container">
                    <input
                      className="fs-10"
                      type="text"
                      name="search"
                      id="search"
                      value={searchBarInputVal}
                      onChange={handleChange}
                      placeholder="Search by location name"
                    />
                    <button type="submit">
                      <AiOutlineSearch />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          {tableData && (
            <Table
              data={tableData}
              globalFilter={globalFilter}
              setGlobalFilter={setGlobalFilter}
              columns={columns}
              rowsPerPage={10}
              handleNextToken={handleNextToken}
              nextToken={getLocationsData?.listLocations?.nextToken}
              filterFns={{ typeFilter: typeColumnFilter }}
              columnFilters={columnFilters}
              setColumnFilters={setColumnFilters}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
              handleCancel={handleCancel}
              handleClick={handleClick}
              showTwoBtns={true}
              btnName="Apply"
              isBtnLoading={addLocationToGroupLoading}
              sorting={sorting}
              setSorting={setSorting}
              isBtnDisabled={
                checkedRows.isAllRowsChecked ||
                Object.keys(rowSelection).length === 0
              }
            />
          )}
        </div>
      </div>
    </>
  );
};

export default GroupLocations;
