import "./emergencyContact.scss";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
import { toast } from "react-toastify";
import { FiEyeOff } from "react-icons/fi";
import { FiEye } from "react-icons/fi";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { UPDATE_MEMBER } from "../../gqloperations/mutations";
import { GET_MEMBER_BY_ID } from "../../gqloperations/queries";
import React, { useState } from "react";
import { contactInformationSchema } from "../../validations/MembershipValidation";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import InternationalPhoneInput from "../formUiElements/input/InternationalPhoneInput";
import { AiOutlineEdit } from "react-icons/ai";
import { useMemberContext } from "../../contexts/MemberContext";

const EmergencyContact = (props) => {
  const { permissions } = useAuthContext();
  const { member, createLog, getMemberById } = useMemberContext();
  const [isActive, setIsActive] = useState(true);
  const [emergencyContact1, setEmergencyContact1] = useState(false);
  const [emergencyContact2, setEmergencyContact2] = useState(false);

  // const [oldAttribute, setOldAttribute] = useState({
  //   emergencyContactName: `${member.getMember.emergencyContactName}`,
  //   emergencyContactNumber: `${member.getMember.emergencyContactNumber}`,
  //   emergencyContactRelation: `${member.getMember.emergencyContactRelation}`,
  //   emergencyContactName2: `${member.getMember.emergencyContactName2}`,
  //   emergencyContactNumber2: `${member.getMember.emergencyContactNumber2}`,
  //   emergencyContactRelation2: `${member.getMember.emergencyContactRelation2}`,
  // });

  // useEffect(() => {
  //   setOldAttribute({
  //     emergencyContactName: `${member.getMember.emergencyContactName}`,
  //     emergencyContactNumber: `${member.getMember.emergencyContactNumber}`,
  //     emergencyContactRelation: `${member.getMember.emergencyContactRelation}`,
  //     emergencyContactName2: `${member.getMember.emergencyContactName2}`,
  //     emergencyContactNumber2: `${member.getMember.emergencyContactNumber2}`,
  //     emergencyContactRelation2: `${member.getMember.emergencyContactRelation2}`,
  //   });
  // }, [member]);

  const handleClick = () => {
    setIsActive(false);
    setEmergencyContact1(true);
    setEmergencyContact2(true);
    createLog(
      `Clicked Emergency contact edit icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("editForm").reset();
    setIsActive(true);
    setEmergencyContact1(false);
    setEmergencyContact2(false);
    createLog(
      `Clicked Emergency contact cancel icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleEmergencyContact1 = (emergencyContactNumber) => {
    setEmergencyContact1(!emergencyContact1);
    createLog(
      `Clicked Emergency contact 1 mobile number icon for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  const handleEmergencyContact2 = (emergencyContactNumber) => {
    setEmergencyContact2(!emergencyContact2);
    createLog(
      `Clicked Emergency contact 2 mobile number icon for ${member.getMember.givenName} ${member.getMember.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };

  // const createLog = (values) => {
  //   delete values.memberId;

  //   const newAttributeName = Object.keys(values);
  //   const newAttributeValue = Object.values(values);
  //   const oldAttributeName = Object.keys(oldAttribute);
  //   const oldAttributeValue = Object.values(oldAttribute);

  //   const auditLog = {
  //     newAttributeValue: newAttributeValue,
  //     newAttributeName: newAttributeName,
  //     oldAttributeValue: oldAttributeValue,
  //     oldAttributeName: oldAttributeName,
  //     memberId: `${member.getMember.memberId}`,
  //     updatedBy: updatedBy,
  //     // ipAddress: ipAddress,
  //   };

  //   auditOperation({
  //     variables: {
  //       input: auditLog,
  //     },
  //     onCompleted: () => {},
  //     refetchQueries: [
  //       {
  //         query: GET_MEMBER_BY_ID,
  //         variables: {
  //           memberId: `${member.getMember.memberId}`,
  //         },
  //       },
  //     ],
  //   });
  // };

  //calling a mutation
  // const [
  //   auditOperation,
  //   {
  //     data: auditoperationdata,
  //     loading: auditoperationloading,
  //     error: auditoperationerror,
  //   },
  // ] = useMutation(AUDIT_OPERATION);

  //calling a mutation
  const [updateMember] = useMutation(UPDATE_MEMBER);

  //Saving into the database
  const onSubmit = (values, OnSubmitProps) => {
    let updatedFields = {};
    for (let key in values) {
      if (
        String(values[key]).toUpperCase() !==
        String(member.getMember[key]).toUpperCase()
      ) {
        updatedFields[key] = values[key].toUpperCase();
      }
    }
    updateMember({
      variables: {
        input: { ...updatedFields, memberId: member.getMember.memberId },
      },

      onCompleted: () => {
        OnSubmitProps.resetForm();
        toast.success("Update successfully");
        setIsActive(true);
        setEmergencyContact1(false);
        setEmergencyContact2(false);
        createLog(
          `Clicked Emergency contact save icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
        getMemberById();
      },
      onError: (error) => {
        // handle the error, for example by displaying an error message
        toast.error(`${error}`);
        createLog(
          `Clicked Emergency contact save icon (member module) for ${member.getMember.givenName} ${member.getMember.surname}`,
          "member",
          `${member.getMember.memberId}`
        );
      },
      refetchQueries: [
        {
          query: GET_MEMBER_BY_ID,
          variables: {
            memberId: `${member.getMember.memberId}`,
          },
        },
      ],
    });
  };
  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          emergencyContactName: member?.getMember?.emergencyContactName
            ? member?.getMember?.emergencyContactName
            : "",
          emergencyContactNumber: member?.getMember?.emergencyContactNumber
            ? member?.getMember?.emergencyContactNumber
            : "",
          emergencyContactRelation: member?.getMember?.emergencyContactRelation
            ? member?.getMember?.emergencyContactRelation
            : "",
          emergencyContactName2: member?.getMember?.emergencyContactName2
            ? member?.getMember?.emergencyContactName2
            : "",
          emergencyContactNumber2: member?.getMember?.emergencyContactNumber2
            ? member?.getMember?.emergencyContactNumber2
            : "",
          emergencyContactRelation2: member?.getMember
            ?.emergencyContactRelation2
            ? member?.getMember?.emergencyContactRelation2
            : "",
          emergencyContactEmail: member?.getMember?.emergencyContactEmail
            ? member?.getMember?.emergencyContactEmail
            : "",
        }}
        validationSchema={contactInformationSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form id="editForm" className="emergency-container pd-12">
              <div className="title-bar fs-14">
                Emergency Contacts
                {permissions.includes("CanEditMember") && (
                  <>
                    {isActive ? (
                      <>
                        <span
                          className="icon-container edit"
                          onClick={handleClick}
                        >
                          <AiOutlineEdit />
                        </span>
                      </>
                    ) : (
                      <span className="icon-container">
                        <div className="sub-container save">
                          <Button
                            iconname="BiSave"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-edit save btn-lg fs-12 "
                          />
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </span>
                    )}
                  </>
                )}
              </div>
              <div className="emergency-info">
                <div className="primary-name">
                  <Input
                    type="text"
                    label="CONTACT 1 - Name "
                    name="emergencyContactName"
                    id="emergencyContactName"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
                <div className="sec-name">
                  <Input
                    type="text"
                    label="CONTACT 2 - Name "
                    name="emergencyContactName2"
                    id="emergencyContactName2"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue "}
                  />
                </div>
                <div className="primary-mobile sec-mobile">
                  {isActive ? (
                    <>
                      <div className="hidden-title fs-12">Mobile Number 2</div>
                      {!emergencyContact2 ? (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.emergencyContactNumber2 ? (
                              <>
                                {member.getMember.emergencyContactNumber2.substring(
                                  0,
                                  3
                                )}
                                ***
                                {member.getMember.emergencyContactNumber2.substring(
                                  member.getMember.emergencyContactNumber2
                                    .length - 2,
                                  member.getMember.emergencyContactNumber2
                                    .length
                                )}
                              </>
                            ) : (
                              " "
                            )}
                            <span className="fs-14">
                              <FiEyeOff
                                onClick={() =>
                                  handleEmergencyContact2(
                                    member.getMember.emergencyContactNumber2
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.emergencyContactNumber2
                              ? member.getMember.emergencyContactNumber2
                              : ""}
                            <span className="fs-14">
                              <FiEye
                                onClick={() =>
                                  handleEmergencyContact2(
                                    member.getMember.emergencyContactNumber2
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <InternationalPhoneInput
                      title="Mobile Number"
                      name="emergencyContactNumber2"
                      id="emergencyContactNumber2"
                      editable={true}
                    />
                  )}
                </div>
                <div className="sec-relation">
                  {" "}
                  <Input
                    type="text"
                    label="Relationship 2"
                    name="emergencyContactRelation2"
                    id="emergencyContactRelation2"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
                <div className="primary-mobile">
                  {isActive ? (
                    <>
                      <div className="hidden-title fs-12">Mobile Number</div>
                      {!emergencyContact1 ? (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.emergencyContactNumber ? (
                              <>
                                {member.getMember.emergencyContactNumber.substring(
                                  0,
                                  3
                                )}
                                ***
                                {member.getMember.emergencyContactNumber.substring(
                                  member.getMember.emergencyContactNumber
                                    .length - 2,
                                  member.getMember.emergencyContactNumber.length
                                )}
                              </>
                            ) : (
                              " "
                            )}
                            <span className="fs-14">
                              <FiEyeOff
                                onClick={() =>
                                  handleEmergencyContact1(
                                    member.getMember.emergencyContactNumber
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="hidden-value fs-12">
                            {member.getMember.emergencyContactNumber
                              ? member.getMember.emergencyContactNumber
                              : ""}
                            <span className="fs-14">
                              <FiEye
                                onClick={() =>
                                  handleEmergencyContact1(
                                    member.getMember.emergencyContactNumber
                                  )
                                }
                              />
                            </span>
                          </div>
                        </>
                      )}
                    </>
                  ) : (
                    <InternationalPhoneInput
                      title="Mobile Number"
                      name="emergencyContactNumber"
                      id="emergencyContactNumber"
                      editable={true}
                    />
                  )}
                </div>

                <div className="primary-relation">
                  {" "}
                  <Input
                    type="text"
                    label="Relationship "
                    name="emergencyContactRelation"
                    id="emergencyContactRelation"
                    autoComplete="off"
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  />
                </div>
                {/* SECONDARY CONTACTS */}
                <div className="primary-email">
                  <div className="email">
                    <Input
                      type="text"
                      label="Email"
                      name="emergencyContactEmail"
                      id="emergencyContactEmail"
                      autoComplete="off"
                      disabled={isActive ? true : false}
                      className={isActive ? "" : "blue"}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default EmergencyContact;
