export const API = {
  //MemberStats
  memberStatsReport: `v1/reports/members/count`,

  //ClubVisits
  memberVisitsReport: `v1/reports/club-visits`,
  clubVisitsReport: `v1/reports/club-visits/trend`,

  //GenderStats

  genderStatsReport: `v1/reports/members/count/gender`,

  //AverageMemberYieldReport
  averageMemberYeildReport: `v1/reports/financial/stats/average-yield`,

  //DirectDebitReport
  directDebitReport: `v1/reports/financial/stats/direct-debit`,

  //Report executive
  executiveLeaversReport: `v1/reports/executive/leavers`,
  executiveClubVisitReport: `/v1/reports/executive/club-visits`,
  executiveActiveMemberReport: `v1/reports/executive/active`,
  executiveSaleReport: `v1/reports/executive/sales`,
  executiveChurnReport: `v1/reports/executive/churn`,
  executiveSuspension: `v1/reports/executive/suspension`,

  //Report financial
  financialTransaction: `v1/reports/financial/payments/transactions`,
  financialDirectDebit: `v1/reports/financial/payments/direct-debit`,
  financialTransactionTotal: `v1/reports/financial/payments/transactions/total`,
  financialPIFExpiring: `v1/reports/financial/expiring-pif-members`,
  financialFailedPayment: `v1/reports/financial/failed-dd-members`,
  financialRefund: `v1/reports/financial/refunds`,

  //Report member
  memberReport: `v1/reports/members`,
  memberReportExport: "v1/reports/members/export",
  memberReportSummary: "v1/reports/members/summary",
  memberInteractionReport: (memberId) =>
    `v1/reports/members/${memberId}/interactions`,
  // Report membership
  membershipMovement: `v1/reports/membership/movement`,

  // FP membership report
  fpMembers: `v1/reports/membership/fitness-passport-members`,
  fpVisits: `v1/reports/membership/fp-club-visits`,
  fpVisitsSummary: `v1/reports/membership/fp-club-visits/summary`,
  fpVisitsExport: `v1/reports/membership/fp-club-visits/export`,

  //Report membership
  membershipBreakupReport: `v1/reports/membership/breakup`,
  membershipUpgradeDowngradeReport: `v1/reports/membership/upgrade-downgrade`,

  //Class stats
  classStats: `v1/reports/classes/stats`,
  bannderSummary: (memberId) => `v1/reports/members/${memberId}/banner-summary`,
};
