import "../../location/addlocation.scss";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { Link, useNavigate } from "react-router-dom";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import "./addVoucher.scss";
import { CREATE_VOUCHER } from "../../../gqloperations/mutations";
import React, { useContext, useEffect, useState, useRef } from "react";
import copyIcon from "../../../assets/images/icons/copy-icon.png";
import {
  GET_VOUCHER_BY_CODE,
  LIST_MEMBERSHIPS,
} from "../../../gqloperations/queries";
import { useMemberContext } from "../../../contexts/MemberContext";
import isAfter from "date-fns/isAfter";
import {
  frequency,
  memberTypeForDelayPayment,
  memberTypeForFixed,
  memberTypeForVisit,
} from "../../../constants/commonConstant";
import { format } from "date-fns";
import { AuthContext } from "../../../contexts";
import { addVoucherSchema } from "../../../validations/Validation";
import { Button, Header, Select, Input } from "../../../components";
import { AiOutlineSearch } from "react-icons/ai";
const AddVoucher = () => {
  const navigate = useNavigate();
  const {
    voucherType,
    sidebarLocationId,
    sidebarLocationName,
    sidebarBrandId,
  } = useMemberContext();
  const initialMembershipRef = useRef("");
  const { loggedUser } = useContext(AuthContext);
  const { givenName, surname, memberId } = loggedUser?.getMember;
  const [createVoucher, { loading: createVoucherLoading }] =
    useMutation(CREATE_VOUCHER);
  const DATE_OPTIONS = {
    weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    time: "numeric",
  };
  const [listMembership, setListMembership] = useState([]);

  const [voucherCode, setVoucherCode] = useState(
    Math.random().toString(36).slice(2, 10).toLocaleUpperCase()
  );
  const firstGenerateCode = useRef(true);
  const [validCode, setValidCode] = useState(true);
  const duration = [
    "1 DAY",
    "1 WEEK",
    "2 WEEKS",
    "3 WEEKS",
    "1 MONTH",
    "3 MONTHS",
    "6 MONTHS",
    "9 MONTHS",
    "1 YEAR",
  ];

  const [getVoucherByCode] = useLazyQuery(GET_VOUCHER_BY_CODE, {
    fetchPolicy: "no-cache",
    variables: {
      voucherCode: voucherCode.toUpperCase(),
    },
    onCompleted: (data) => {
      if (data?.getVoucherByVoucherCode.items.length) {
        setValidCode(false);
        if (firstGenerateCode.current) {
          setVoucherCode(
            Math.random().toString(36).slice(2, 10).toLocaleUpperCase()
          );
        }
      } else {
        setValidCode(true);
      }
      firstGenerateCode.current = false;
    },
    onError: () => {},
  });

  useEffect(() => {
    getVoucherByCode();
  }, [voucherCode]);

  const { data: listMembershipData } = useQuery(LIST_MEMBERSHIPS, {
    fetchPolicy: "no-cache",
    variables: {
      filter: {
        and: [
          { isDeleted: { ne: true } },
          { brandId: { eq: `${sidebarBrandId}` } },
        ],
      },
      //TODO: Need backend fixing sortDirection, then change the limit argument per requirements.
      limit: 1000,
    },
  });
  useEffect(() => {
    if (listMembershipData) {
      const { items } = listMembershipData?.listMemberships;

      const sortedCombinedItems = sortByCreatedAtDesc([...items]);

      setListMembership(sortedCombinedItems);
    }
  }, [listMembershipData]);

  const sortByCreatedAtDesc = (arr) => {
    return arr.sort((a, b) => {
      const result = isAfter(new Date(a.createdAt), new Date(b.createdAt));
      return result ? -1 : 1;
    });
  };

  const onSubmit = (values, OnSubmitProps) => {
    const membershipName = values.membershipId;
    const membership = listMembership.find((curMembership) => {
      return curMembership.membershipName.toUpperCase() === membershipName;
    });
    const { id: membershipId } = membership;
    const startDate = new Date(values.startDate);
    startDate.setHours(0);
    startDate.setMinutes(0);
    let payload = {
      membershipId: membershipId,
      voucherCode: voucherCode.toUpperCase(),
      startDateTime: startDate.toISOString(),
      quantity: values.quantity ? values.quantity : 0,
      passFee: values.pass ? values.pass : 0,
      type: voucherType,
      locationId: sidebarLocationId,
      activationFee: values.activation ? values.activation : 0,
      createdBy: memberId,
      updatedAt: new Date(),
      note: values.note,
      memberType: values.memberType,
      joiningFee: values.joiningFee,
      isActive: true,
    };
    if (values.endDate) {
      payload.endDateTime = new Date(
        new Date(values.endDate).setHours(23, 59, 59, 999)
      );
    }
    if (voucherType === "FIXED") {
      payload = {
        ...payload,
        cost: values.cost ? values.cost : 0,
        paymentDelay: {
          amount: values.amount ? values.amount : 0,
          type: values.frequency ? values.frequency : "DAYS",
        },
      };
    } else if (voucherType === "PERCENT") {
      payload = {
        ...payload,
        cost: values.cost ? values.cost : 0,
      };
    } else if (voucherType === "VISIT_PASS") {
      payload = {
        ...payload,
        cost: values.cost ? values.cost : 0,
        visit: values.visit ? values.visit : 0,
      };
    } else {
      let duration = 0;
      if (values.duration) {
        if (values.duration.toString().includes("WEEK")) {
          duration = values.duration.charAt(0) * 7;
        } else if (values.duration.toString().includes("MONTH")) {
          duration = values.duration?.charAt(0) * 30;
        } else if (values.duration.toString().includes("YEAR")) {
          duration = values.duration?.charAt(0) * 365;
        } else if (values.duration.toString().includes("DAY")) {
          duration = values.duration.charAt(0);
        }
      }
      payload = {
        ...payload,
        duration: duration ? duration : 0,
      };
    }
    createVoucher({
      variables: {
        input: payload,
      },
      onCompleted: () => {
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
        toast.success("Voucher Added Successfully");
        navigate(-1);
      },
      onError: (error) => {
        toast.error(`${error}`);
        return;
      },
    });
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{
        createdBy: givenName + " " + surname,
        date: new Date(),
        code: voucherCode,
        membershipId: "",
        quantity: "",
        endDate: "",
        startDate: "",
        cost: "",
        pass: "",
        activation: "",
        note: "",
        amount: "",
        frequency: "",
        visit: "",
        duration: "",
        voucherType: voucherType,
        costApplied: "",
        joiningFee: "",
        memberType: "",
      }}
      validationSchema={addVoucherSchema}
      validate={() => {
        let errors = {};

        if (!validCode) {
          const errorText = "Voucher code already exist";
          errors.code = errorText;
        }
        return errors;
      }}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Header
              pageTitle={`${voucherType === "FIXED" ? "Add fixed Voucher" : ""}
                                           ${
                                             voucherType === "PERCENT"
                                               ? "Add % Voucher"
                                               : ""
                                           }
                                           ${
                                             voucherType === "VISIT_PASS"
                                               ? "Add visit pass Voucher"
                                               : ""
                                           }
                                           ${
                                             voucherType === "COMPLIMENTARY"
                                               ? "Add complimentary Voucher"
                                               : ""
                                           }`}
            />
            <Form>
              <div className="add-info-container">
                <div className="voucher-details-container loc-card">
                  <h3 className="title">Voucher information</h3>
                  <div className="voucher-grid-4 ">
                    <div className="detail address voucher-code-container">
                      <Input
                        type="text"
                        label="Voucher code*"
                        id="code"
                        name="code"
                        autoComplete="off"
                        value={voucherCode}
                        className="voucher-code"
                        onChange={(e) => {
                          formik.setFieldValue("code", e.target.value);
                          setVoucherCode(e.target.value);
                        }}
                      />
                      <img
                        id="icon"
                        src={copyIcon}
                        alt="icon"
                        onClick={() =>
                          navigator.clipboard.writeText(voucherCode)
                        }
                      />
                    </div>

                    <div className="detail city">
                      <Input
                        type="text"
                        label="Created by*"
                        name="createdBy"
                        id="createdBy"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail city">
                      <Input
                        type="text"
                        label="Date and time*"
                        name="date"
                        value={new Date().toLocaleTimeString(
                          "en-GB",
                          DATE_OPTIONS
                        )}
                        id="date"
                        autoComplete="off"
                      />
                    </div>

                    <div className="membership-select">
                      <span className="heading-and-icon">
                        <label
                          className="location-heading fs-12"
                          htmlFor="membershipId"
                        >
                          Membership*
                          <AiOutlineSearch />
                        </label>
                      </span>

                      <div className="location-value loc-select">
                        <input
                          id="membershipId"
                          type="text"
                          list="membership-list"
                          placeholder="Search a Membership"
                          className="select-element fs-12 text-white"
                          name="membershipId"
                          onChange={(e) => {
                            formik.setFieldValue(
                              "membershipId",
                              e.target.value
                            );
                          }}
                          onFocus={() =>
                            formik.setFieldValue("membershipId", "")
                          }
                          value={formik.values.membershipId}
                          autoComplete="off"
                        />

                        <datalist id="membership-list">
                          {listMembership.map((option) => {
                            return (
                              <option
                                key={option.id}
                                value={option.membershipName.toUpperCase()}
                                className="fs-14"
                              ></option>
                            );
                          })}
                        </datalist>
                      </div>
                    </div>
                  </div>

                  <div className="voucher-grid-4 ">
                    {(voucherType === "FIXED" || voucherType === "PERCENT") && (
                      <div className="detail ">
                        <Input
                          type="number"
                          label={
                            voucherType === "FIXED"
                              ? "PIF/DD (fortnightly)*"
                              : "% Discount*"
                          }
                          name="cost"
                          id="cost"
                          autoComplete="off"
                          onChange={(e) => {
                            const curMembership =
                              listMembershipData?.listMemberships?.items.find(
                                (cur) => cur.id === formik.values.membershipId
                              );
                            formik.setFieldValue(
                              "costApplied",
                              (
                                curMembership?.costPrice -
                                curMembership?.costPrice *
                                  (e.target.value * 0.01)
                              ).toFixed(2)
                            );
                            formik.handleChange(e);
                          }}
                        />
                      </div>
                    )}
                    {voucherType === "VISIT_PASS" && (
                      <div className="detail suburb">
                        <Input
                          type="text"
                          label="Cost*"
                          name="cost"
                          id="cost"
                          autoComplete="off"
                        />
                      </div>
                    )}

                    {voucherType === "COMPLIMENTARY" && (
                      <div className="detail ">
                        <Select
                          label="Duration*"
                          optionvalues={duration}
                          selecttype="array"
                          name="duration"
                          id="duration"
                          autoComplete="off"
                          className="fs-12 select-element-value"
                        />
                      </div>
                    )}
                    <div className="detail suburb">
                      <Input
                        type="number"
                        label=" Joining Fee*"
                        name="joiningFee"
                        id="joiningFee"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail ">
                      <Input
                        type="number"
                        label="Activation Fee*"
                        name="activation"
                        id="activation"
                        autoComplete="off"
                      />
                    </div>
                    <div className="detail ">
                      <Input
                        type="number"
                        label="Access Fee*"
                        name="pass"
                        id="pass"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                  <div className="voucher-grid-4 ">
                    {voucherType === "VISIT_PASS" && (
                      <div className="detail suburb">
                        <Input
                          type="number"
                          label="Quantity"
                          name="quantity"
                          id="quantity"
                          autoComplete="off"
                        />
                      </div>
                    )}
                    {voucherType === "VISIT_PASS" && (
                      <div className="detail suburb">
                        <Input
                          type="number"
                          label="Visit*"
                          name="visit"
                          id="visit"
                          autoComplete="off"
                        />
                      </div>
                    )}
                    {voucherType !== "VISIT_PASS" && (
                      <div className="detail suburb">
                        <Input
                          type="number"
                          label="Quantity*"
                          name="quantity"
                          id="quantity"
                          autoComplete="off"
                        />
                      </div>
                    )}
                    <div className="detail post">
                      <Input
                        type="date"
                        label="Start Date*"
                        name="startDate"
                        id="startDate"
                        autoComplete="off"
                        min={format(new Date(), "yyyy-MM-dd")}
                        max={
                          formik?.values?.endDate ? formik.values.endDate : ""
                        }
                      />
                    </div>
                    <div className="detail ">
                      <Input
                        type="date"
                        label="End Date"
                        name="endDate"
                        id="endDate"
                        autoComplete="off"
                        min={formik.values?.startDate}
                      />
                    </div>
                    {voucherType === "FIXED" && (
                      <div className="detail ">
                        <div className="notice-period input-select-title">
                          <p className="fs-12 text-gray">Delay payment </p>
                          <div className="input-select">
                            <Input
                              type="number"
                              label=""
                              name="amount"
                              className="fs-12"
                              autoComplete="off"
                            />
                            <Select
                              optionvalues={frequency}
                              selecttype="array"
                              name="frequency"
                              autoComplete="off"
                              className="select-element-value"
                            />
                          </div>
                        </div>
                      </div>
                    )}
                    {(voucherType === "COMPLIMENTARY" ||
                      voucherType === "PERCENT") && (
                      <Select
                        label="Member type*"
                        optionvalues={
                          voucherType === "FIXED" || voucherType === "PERCENT"
                            ? formik.values.amount && formik.values.frequency
                              ? memberTypeForDelayPayment
                              : memberTypeForFixed
                            : memberTypeForVisit
                        }
                        selecttype="array"
                        name="memberType"
                        autoComplete="off"
                        className="select-element-value mb-12"
                      />
                    )}
                  </div>
                  {voucherType !== "COMPLIMENTARY" &&
                    voucherType !== "PERCENT" && (
                      <div className="fourth-row voucher-grid-4">
                        <Select
                          label="Member type*"
                          optionvalues={
                            voucherType === "FIXED" || voucherType === "PERCENT"
                              ? formik.values.amount && formik.values.frequency
                                ? memberTypeForDelayPayment
                                : memberTypeForFixed
                              : memberTypeForVisit
                          }
                          selecttype="array"
                          name="memberType"
                          autoComplete="off"
                          className="select-element-value mb-12"
                        />
                      </div>
                    )}

                  <div className="desc-row">
                    <div htmlFor="note" className="fs-12 mb-8 text-gray">
                      Notes
                    </div>
                    <textarea
                      className="reason-box fs-12"
                      id="note"
                      name="note"
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                </div>
              </div>
              <div className="save-btn right-btn">
                <Button
                  loading={createVoucherLoading}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-confirm btn-large fs-12"
                  disabled={createVoucherLoading ? true : false}
                />

                <Link to="/dashboard/membership">
                  <Button
                    name="Cancel"
                    btntype="button"
                    btnname="cancel"
                    className="btn btn-deny btn-large fs-12"
                  />
                </Link>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};
export default AddVoucher;
