import "./confirmsignup.scss";
import { BeatLoader } from "react-spinners";
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { Formik, Form } from "formik";
import { signupSchema } from "../../validations/Validation";
import { AuthContext } from "../../contexts";
import { toast } from "react-toastify";

const ConfirmSignup = () => {
  const [inviteToken, setInviteToken] = useState("");
  const [inviteEmail, setInviteEmail] = useState("");
  const [signupError, setSignupError] = useState(false);
  const [memberId, setMemberId] = useState("");
  const [brandId, setBrandId] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");

  const { handleSignUp } = useContext(AuthContext);

  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  let [searchParams] = useSearchParams();
  const searchEmail = window.location.search.split("&");
  var searchEmailObject = searchEmail.reduce(function (o, v) {
    var kv = v.split("=");
    kv[0] = kv[0].replace("?", "");
    o[kv[0]] = kv[1];
    return o;
  }, {});
  console.log(searchEmailObject);
  useEffect(() => {
    signupConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const signupConfirm = async () => {
    try {
      setLoading(true);
      const inviteToken = searchParams.get("inviteToken");
      const email = searchEmailObject.email;
      const mobileNumber = searchParams.get("mobileNumber");
      const brandId = searchParams.get("brandId");
      const memberId = searchParams.get("memberId");
      setInviteEmail(email);
      setInviteToken(inviteToken);
      setMobileNumber(mobileNumber);
      setBrandId(brandId);
      setMemberId(memberId);

      await handleSignUp(
        email,
        "12345678",
        inviteToken,
        brandId,
        memberId,
        mobileNumber
      );
      toast.success("Successfully confirm your member.");
    } catch (e) {
      toast.error(e.message);
    } finally {
      setLoading(false);
    }
  };
  if (!inviteEmail)
    return (
      <div className="login">
        <BeatLoader color="white" />
      </div>
    );

  return (
    <Formik
      initialValues={{
        email: inviteEmail,
        password: "",
        confirmPassword: "",
      }}
      validationSchema={signupSchema}
      errors
      onSubmit={async (values, { setSubmitting }) => {
        const { email, password } = values;
        const { error, signup } = await handleSignUp(
          email,
          password,
          inviteToken,
          brandId,
          memberId,
          mobileNumber
        );
        if (!signup) {
          setSubmitting(false);
          setSignupError(error.message);
          return;
        }
        setSubmitting(false);
        navigate("/");
      }}
    >
      {(formik) => {
        return (
          <div className="sign-up ">
            <div className="sign-up-container pd-32">
              {signupError && <p className="fs-20 error"> {signupError}</p>}
              <h1 className="heading fs-35 item-centered mb-12">Signup</h1>
              <Form className="frm-group flex-col">
                <p>
                  Thank you for verifying your email address. Your account is
                  now confirmed and ready for use.
                  <br /> You can now log in to your hub by clicking the button
                  below. This will direct you to the login page where you can
                  enter your credentials and access your profile.
                </p>
                {/* <Input
                  type="email"
                  name="email"
                  id="email"
                  label="Email address"
                />
                <Input
                  type="password"
                  name="password"
                  id="password"
                  label="Password"
                />
                <Input
                  type="password"
                  name="confirmPassword"
                  id="confirmPassword"
                  label="Confirm Password"
                /> */}
                {/* <div className="button item-centered">
                  <Button
                      name={
                      formik.isSubmitting ? (
                        <ClipLoader color={"red"} size={28} />
                      ) : (
                        "SIGN UP"
                      )
                    }
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-lg fs-12"
                    disabled={Object.keys(formik.errors).length !== 0}
                    isSubmitting={formik.isSubmitting}
                  />
                </div> */}
              </Form>
            </div>
            {!loading && (
              <div className="join-now-container fs-12">
                <p className="signup-link subheading3">
                  Already have an account ?
                </p>
                <Link to="/">
                  <span className="subheading3">Login</span>
                </Link>
              </div>
            )}
          </div>
        );
      }}
    </Formik>
  );
};

export default ConfirmSignup;
