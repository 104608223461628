import "./changepassword.scss";
import React, { useContext } from "react";
import { ToastContainer, toast } from "react-toastify";
import { Formik, Form } from "formik";
import { AuthContext } from "../../contexts";
import { changePasswordSchema } from "../../validations/Validation";
import hub_logo from "../../assets/images/hub_logo.svg";
import { Input, Button, Header } from "../../components";

const ChangePassword = () => {
  const { handleConfirmPassword, error } = useContext(AuthContext);
  const notify = () =>
    toast.error(error, {
      toastId: "customId",
    });
  const toastMessage = error && notify() && (
    <ToastContainer
      position="top-center"
      autoClose={1000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick={false}
      rtl={false}
      pauseOnFocusLoss={false}
      draggable={false}
      pauseOnHover={false}
    />
  );
  return (
    <Formik
      initialValues={{
        oldPassword: "",
        newPassword: "",
        confirmNewPassword: "",
      }}
      validationSchema={changePasswordSchema}
      onSubmit={async (values, OnSubmitProps) => {
        await handleConfirmPassword(
          values.oldPassword,
          values.newPassword,
          values.confirmNewPassword
        );
        OnSubmitProps.setSubmitting(false);
        OnSubmitProps.resetForm();
      }}
      toastMessage={toastMessage}
    >
      {(formik) => {
        return (
          <>
            <Header pageTitle="Change Password" />
            <div className="change-password">
              <div className="change-password-container pd-24">
                <img src={hub_logo} alt="logo" width="173" height="47" />
                <h1 className="heading fs-12 uppercase">
                  Update Your Password
                </h1>

                <Form>
                  <Input
                    type="password"
                    label="Old Password * "
                    name="oldPassword"
                    id="oldPassword"
                    autoComplete="off"
                  />
                  <Input
                    type="password"
                    label="New Password *"
                    name="newPassword"
                    id="newPassword"
                    autoComplete="off"
                  />
                  <Input
                    type="password"
                    label="Confirm new Password *"
                    name="confirmNewPassword"
                    id="confirmNewPassword"
                    autoComplete="off"
                  />
                  <div className="save-btn">
                    {/* {formik.isSubmitting && (
                      <Button
                        name={<BeatLoader color="white" size={11} />}
                        btnname="submit"
                        btntype="Update"
                        className="btn btn-primary fs-12"
                        disabled={true}
                      />
                    )}
                    {!formik.isSubmitting && (
                      <Button
                        name="Update Password"
                        btnname="submit"
                        btntype="submit"
                        className="btn btn-primary fs-12"
                      />
                    )} */}
                    <Button
                      loading={formik.isSubmitting}
                      name={"Update Password"}
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-primary fs-12"
                      disabled={formik.isSubmitting ? true : false}
                    />
                  </div>
                </Form>
              </div>
              {/* {toastMessage} */}
            </div>
          </>
        );
      }}
    </Formik>
  );
};

export default ChangePassword;
