import { useEffect, useRef } from "react";
import Swal from "sweetalert2";

function useUpdateSW() {
  const timer = useRef();
  const isTimerSet = useRef(false);

  const setTimer = () => {
    const handleUpdate = () => {
      //   if (window.confirm("A new version is available. Reload to update?")) {
      //     window.location.reload();
      //   }
      Swal.fire({
        title: "New Version Available!",
        html: "We've just rolled out some exciting updates to enhance your experience on the Hub.<p></p><br/> To access the new features and improvements, please click the 'Refresh' button below. This will ensure that you're using the latest version.",
        confirmButtonColor: "#60a5fa",
        confirmButtonText: "REFRESH",
        allowOutsideClick: false,
        allowEscapeKey: false,
        footer: "Thank you for using the Hub.",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload(true);
        }
      });
    };

    navigator.serviceWorker.addEventListener("controllerchange", handleUpdate);

    if (!isTimerSet.current)
      timer.current = setInterval(function () {
        try {
          navigator.serviceWorker.getRegistrations().then((res) => {
            if (res[0]) {
              res[0].update();
            }
          });
        } catch (e) {
          console.error("failed to load the new sw due to : ", e);
        }
      }, 120000);
  };
  setTimer();

  return;
}

export default useUpdateSW;
