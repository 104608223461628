import React, { useEffect, useState } from "react";
import InputWithoutFormik from "../formUiElements/input/InputWithoutFormik";
import { Formik, Form, Field } from "formik";
import Button from "../formUiElements/Button";
import Input from "../formUiElements/input/Input";
import { useMemberContext } from "../../contexts/MemberContext";
import { format } from "date-fns";
import { useMutation } from "@apollo/client";
import {
  ADD_MEMBER_PAYMENT_INFORMATION,
  CHARGE_SINGLE_PAYMENT,
} from "../../gqloperations/mutations";
import { toast } from "react-toastify";
import Modal from "./Modal";
import "./modal.scss";
import { useAuthContext } from "../../contexts/AuthContext/AuthProvider";
const MakePaymentModal = (props) => {
  const { member, sidebarBrandId } = useMemberContext();
  const { permissions } = useAuthContext();
  const [chargeSinglePayment, { loading: chargeSinglePaymentLoading }] =
    useMutation(CHARGE_SINGLE_PAYMENT);
  const [addMemberPaymentInfo, { loading: addMemberPaymentInfoLoading }] =
    useMutation(ADD_MEMBER_PAYMENT_INFORMATION);
  const [cardRequestId, setCardRequestId] = useState("");
  const [paymentType, setPaymentType] = useState("cardOnFile");
  const [paymentInformation, setPaymentInformation] = useState({});
  const [openVivaPay, setOpenVivaPay] = useState(false);
  const [futurePayment, setFuturePayment] = useState("oldCardAsDefault");
  const [paymentInfoBank, setPaymentInfoBank] = useState({
    holderName: "",
    accountNumber: "",
    bsb: "",
  });
  const iframeSrc = `${process.env.REACT_APP_VIVAPAY_IFRAME}/${cardRequestId}`;

  const handleSubmit = async (values) => {
    try {
      let futurePaymentOption = "CARD_ON_FILE_IS_DEFAULT";
      if (futurePayment === "bankAccount") {
        futurePaymentOption = "BANK_ACCOUNT_IS_DEFAULT";
      } else if (paymentType === "EFTPOS") {
        futurePaymentOption = "EFTPOS";
      } else if (paymentType === "CASH") {
        futurePaymentOption = "CASH";
      } else if (paymentType === "WAIVE") {
        futurePaymentOption = "WAIVE";
      } else if (
        (paymentType === "differentCard" &&
          futurePayment === "oldCardAsDefault") ||
        (Object.keys(cardPrimary).length &&
          futurePayment === "oldCardAsDefault")
      ) {
        futurePaymentOption = "CARD_ON_FILE_IS_DEFAULT";
      } else if (
        (paymentType === "differentCard" &&
          futurePayment === "newCardAsDefault") ||
        paymentType === "creditCard"
      ) {
        futurePaymentOption = "DIFFERENT_CARD_IS_DEFAULT";
      }
      const payload = {
        amount: props.amount,
        memberId: props.memberDetail?.memberId
          ? props.memberDetail?.memberId
          : member?.getMember?.memberId,
        brandId: sidebarBrandId,
        paymentInformationId:
          paymentType === "CASH" ||
          paymentType === "EFTPOS" ||
          paymentType === "WAIVE"
            ? undefined
            : paymentType === "cardOnFile"
            ? cardPrimary?.id
            : paymentInformation?.paymentInformation?.paymentInformationId,
        operation: props.operation,
        futurePaymentOption: futurePaymentOption,
        bankAccountDetails:
          futurePayment === "bankAccount" ? paymentInfoBank : undefined,
        ...(props?.payloadData && { ...props.payloadData }),
      };
      if (props.operation === "OSB" && paymentType === "EFTPOS") {
        payload.outstandingBalanceArguments = {
          invoice: values.transactionNumber,
        };
      }
      if (props.operation === "CMS") {
        payload.cancelMembershipArguments = props.payloadData;
        if (paymentType === "EFTPOS") {
          payload.cancelMembershipArguments = {
            ...props.payloadData,
            invoice: values.transactionNumber,
          };
        }
      }
      const data = await chargeSinglePayment({
        variables: payload,
        onCompleted: props?.onApiCompleted
          ? (data) => props?.onApiCompleted(data)
          : () => {},
        onError: props?.onApiError ? (e) => props?.onApiError(e) : () => {},
        refetchQueries: props?.apiRefetchQueries,
      });
      if (!data.errors) {
        props.setShowModalPayment(false);
      }
    } catch (e) {
      toast.error(e.message);
    } finally {
    }
  };
  const openModalVivaPay = async () => {
    setOpenVivaPay(true);
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: "GET_SAVE_CARD_TOKEN_REQUEST",
            memberId: props.memberDetail?.memberId
              ? props.memberDetail?.memberId
              : member?.getMember?.memberId,
            requestApplication: "HUB",
            locationId: props.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.cardRequestId) {
        setCardRequestId(data.data?.addMemberPaymentInformation.cardRequestId);
      }
    } catch (e) {
    } finally {
    }
  };
  const closeModalVivaPay = async () => {
    setOpenVivaPay(false);
    try {
      const data = await addMemberPaymentInfo({
        variables: {
          input: {
            action: "ADD_CREDIT_CARD_REQUEST",
            memberId: props.memberDetail?.memberId
              ? props.memberDetail?.memberId
              : member?.getMember?.memberId,
            cardRequestId: cardRequestId,
            requestApplication: "HUB",
            locationId: props.locationId,
          },
        },
      });
      if (data?.data.addMemberPaymentInformation.paymentInformationId) {
        setPaymentInformation({
          ...paymentInformation,
          paymentInformation: data.data?.addMemberPaymentInformation,
        });
        toast.success("Save card successfully");
      } else {
        if (paymentType === "differentCard") {
          setPaymentType("cardOnFile");
        } else {
          setPaymentType("");
        }
        toast.error("Save card fail");
      }
    } catch (e) {
    } finally {
    }
  };
  const setDataBank = (e) => {
    setPaymentInfoBank({ ...paymentInfoBank, [e.target.name]: e.target.value });
  };
  const [cardPrimary, setCardPrimary] = useState({});
  useEffect(() => {
    if (Object.keys(props.memberDetail || {}).length) {
      props.memberDetail?.paymentDetails?.items?.map((i) => {
        if (i?.primary && i?.paymentType === "CREDIT_CARD") {
          setCardPrimary(i);
        }
      });
    } else {
      member?.getMember?.paymentDetails?.items?.map((i) => {
        if (i?.primary && i?.paymentType === "CREDIT_CARD") {
          setCardPrimary(i);
        }
      });
    }
    // console.log(loggedUser, permissions, "haha");
  }, [member, props.memberDetail]);
  return (
    <div>
      <Modal>
        <Formik
          enableReinitialize
          initialValues={{
            cardType: "",
            cardNumber: "",
            expiry: "",
            nameOnCard: "",
            cardCvv: "",
            holderName: "",
            accountNumber: "",
            bsb: "",
            transactionNumber: "",
          }}
          validate={(values, props) => {
            let errors = {};
            if (
              (!paymentInfoBank?.holderName ||
                !paymentInfoBank?.accountNumber ||
                !paymentInfoBank?.bsb) &&
              futurePayment === "bankAccount"
            ) {
              const errorText = "Please fill all the bank account information";
              errors.holderName = errorText;
            }
            return errors;
          }}
          onSubmit={(values, OnSubmitProps) => {
            handleSubmit(values);
          }}
        >
          {(formik) => {
            return (
              <Form>
                <div className="status-modal make-a-payment-modal pd-32">
                  <div className="make-payment-title ">
                    <h3 className="fs-35 pb-24 uppercase">Make a Payment</h3>
                  </div>{" "}
                  <div>
                    <div className="member-details-modal-container">
                      <h3>Member Details</h3>
                      <div className="member-details-inputs pb-24">
                        <InputWithoutFormik
                          type="text"
                          label="member No:"
                          name="memberNo"
                          autoComplete="off"
                          id="memberNo"
                          className="fs-12"
                          value={
                            member?.getMember?.aliasMemberId
                              ? member?.getMember?.aliasMemberId
                              : props.memberDetail?.aliasMemberId
                          }
                          readOnly
                        />
                        <InputWithoutFormik
                          type="text"
                          label="member Name:"
                          name="memberName"
                          autoComplete="off"
                          id="memberName"
                          className="fs-12"
                          value={
                            member?.getMember?.givenName
                              ? member?.getMember?.givenName
                              : props.memberDetail?.givenName
                          }
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="outstanding-payments-container pb-24">
                      <h3>{props.paymentType}</h3>

                      {props.operation === "OSB" &&
                      permissions.includes("CanEditOutstandingAmount") ? (
                        props.customProperties()
                      ) : (
                        <InputWithoutFormik
                          type="text"
                          label="Amount:"
                          name="amount"
                          autoComplete="off"
                          id="amount"
                          className="fs-12"
                          value={props.amount || ""}
                          readOnly
                        />
                      )}
                    </div>
                    <h3 className="pb-8">
                      Credit Card surcharge will be added to single payments
                      only.
                      <br />
                      <p className="pt-8">
                        There is no fee for ongoing fortnightly memberships.
                      </p>
                    </h3>
                    <h3 className="pb-8">
                      (VISA/MASTSERCARD: 1.5%, AMEX: 3.5%)
                    </h3>
                  </div>
                  {Object.keys(cardPrimary).length !== 0 &&
                  cardPrimary?.vivaPaymentInformationId &&
                  cardPrimary?.vivaPaymentInformationId !== "" ? (
                    <>
                      <div
                        role="group"
                        aria-labelledby="my-radio-group"
                        className={`${
                          props.operation === "OSB" || props.operation === "CMS"
                            ? "payment-options-container"
                            : "payment-options-container-1"
                        } pb-18 fs-12
                        `}
                      >
                        <label>
                          <Field
                            name="cardOnFile"
                            type="radio"
                            checked={paymentType === "cardOnFile"}
                            onClick={(e) => {
                              setPaymentType("cardOnFile");
                            }}
                          />
                          USE CARD ON FILE
                        </label>
                        <label>
                          <Field
                            type="radio"
                            name="differentCardAsDefault"
                            checked={paymentType === "differentCard"}
                            onClick={(e) => {
                              setPaymentType("differentCard");
                              openModalVivaPay();
                            }}
                          />
                          USE DIFFERENT CARD
                        </label>
                        {(props.operation === "OSB" ||
                          props.operation === "CMS") && (
                          <>
                            <label>
                              <Field
                                type="radio"
                                name="paymentType"
                                checked={paymentType === "EFTPOS" || false}
                                onClick={(e) => {
                                  setPaymentType("EFTPOS");
                                  // openModalVivaPay();
                                }}
                              />
                              EFTPOS
                            </label>{" "}
                            <label>
                              <Field
                                type="radio"
                                name="paymentType"
                                checked={paymentType === "CASH" || false}
                                onClick={(e) => {
                                  setPaymentType("CASH");
                                  // openModalVivaPay();
                                }}
                              />
                              CASH
                            </label>
                            <label>
                              <Field
                                type="radio"
                                name="paymentType"
                                checked={paymentType === "WAIVE" || false}
                                onClick={(e) => {
                                  setPaymentType("WAIVE");
                                  // openModalVivaPay();
                                }}
                              />
                              WAIVE
                            </label>
                          </>
                        )}
                      </div>
                      <div>
                        {/* {paymentType === "EFTPOS" && (
                          <div className="payment-details-modal-container">
                            <Input
                              type="text"
                              label="Transaction number:"
                              name="transactionNumber"
                              id="transactionNumber"
                              autoComplete="off"
                              className="fs-12"
                            />
                          </div>
                        )} */}
                        {paymentType === "cardOnFile" && (
                          <div className="payment-details-modal-container">
                            <Input
                              disabled={true}
                              type="text"
                              label="Type: "
                              name="cardType"
                              id="cardType"
                              autoComplete="off"
                              value={
                                cardPrimary.cardType ? cardPrimary.cardType : ""
                              }
                              className="fs-12"
                            />
                            <Input
                              disabled={true}
                              type="text"
                              label="Card"
                              name="cardNumber"
                              id="cardNumber"
                              autoComplete="off"
                              value={
                                cardPrimary?.cardNumber
                                  ? cardPrimary?.cardNumber
                                  : ""
                              }
                              className="fs-12"
                            />
                            <Input
                              disabled={true}
                              type="text"
                              label="Expiry"
                              name="expiry"
                              id="expiry"
                              autoComplete="off"
                              value={
                                cardPrimary?.cardExpiryDate
                                  ? cardPrimary?.cardExpiryDate
                                  : ""
                              }
                              className="fs-12"
                            />
                          </div>
                        )}
                      </div>
                    </>
                  ) : (
                    <div
                      role="group"
                      aria-labelledby="my-radio-group"
                      // className=" payment-options-container pb-18 fs-12"
                      className={`${
                        props.operation === "OSB" || props.operation === 'CMS'
                          ? "payment-options-container"
                          : "payment-options-container-1"
                      } pb-18 fs-12
                      `}
                    >
                      <label>
                        <Field
                          type="radio"
                          name="paymentType"
                          checked={paymentType === "creditCard" || false}
                          onClick={(e) => {
                            setPaymentType("creditCard");
                            openModalVivaPay();
                          }}
                        />
                        PAY WITH CREDIT CARD
                      </label>
                      {(props.operation === "OSB" ||
                        props.operation === "CMS") && (
                        <>
                          <label>
                            <Field
                              type="radio"
                              name="paymentType"
                              checked={paymentType === "EFTPOS" || false}
                              onClick={(e) => {
                                setPaymentType("EFTPOS");
                                // openModalVivaPay();
                              }}
                            />
                            EFTPOS
                          </label>{" "}
                          <label>
                            <Field
                              type="radio"
                              name="paymentType"
                              checked={paymentType === "CASH" || false}
                              onClick={(e) => {
                                setPaymentType("CASH");
                                // openModalVivaPay();
                              }}
                            />
                            CASH
                          </label>
                          <label>
                            <Field
                              type="radio"
                              name="paymentType"
                              checked={paymentType === "WAIVE" || false}
                              onClick={(e) => {
                                setPaymentType("WAIVE");
                                // openModalVivaPay();
                              }}
                            />
                            WAIVE
                          </label>
                        </>
                      )}
                    </div>
                  )}
                  {(paymentType === "differentCard" ||
                    paymentType === "creditCard") &&
                    paymentInformation.paymentInformation && (
                      <div className="payment-details-modal-container">
                        <Input
                          disabled={true}
                          type="text"
                          label="Type: "
                          name="cardType"
                          id="cardType"
                          autoComplete="off"
                          value={
                            paymentInformation.paymentInformation?.cardType
                              ? paymentInformation?.paymentInformation.cardType
                              : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Card"
                          name="cardNumber"
                          id="cardNumber"
                          autoComplete="off"
                          value={
                            paymentInformation?.paymentInformation.cardNumber
                              ? paymentInformation?.paymentInformation
                                  .cardNumber
                              : ""
                          }
                          className="fs-12"
                        />
                        <Input
                          disabled={true}
                          type="text"
                          label="Expiry"
                          name="expiry"
                          id="expiry"
                          autoComplete="off"
                          value={
                            paymentInformation?.paymentInformation
                              ?.cardExpiryDate
                              ? paymentInformation?.paymentInformation
                                  ?.cardExpiryDate
                              : ""
                          }
                          className="fs-12"
                        />
                      </div>
                    )}
                  {paymentType === "EFTPOS" && (
                    <div className="payment-details-modal-container">
                      <Input
                        type="text"
                        label="Transaction number:"
                        name="transactionNumber"
                        id="transactionNumber"
                        autoComplete="off"
                        className="fs-12"
                      />
                    </div>
                  )}
                  {/* {memberContractId: {eq: "memberContractId"}} */}
                  {(props.operation === "MDO" ||
                    props.operation === "MUU" ||
                    props.operation === "AMS" ||
                    props.operation === "AVM") &&
                    props.membershipType &&
                    props.membershipType === "weekly" && (
                      <div>
                        <div className="future-payment fs-14  noselect pb-24">
                          <h3 className="future-title">
                            Future Payment Options
                          </h3>
                          <label>
                            <Field
                              name="oldCard"
                              type="radio"
                              checked={
                                futurePayment === "oldCardAsDefault" || false
                              }
                              onClick={() =>
                                setFuturePayment("oldCardAsDefault")
                              }
                            />
                            Future payment will be proceeded with the card above
                            as default
                          </label>
                          {paymentType === "differentCard" && (
                            <label>
                              <Field
                                type="radio"
                                name="differentCard"
                                checked={
                                  futurePayment === "newCardAsDefault" || false
                                }
                                onClick={() =>
                                  setFuturePayment("newCardAsDefault")
                                }
                              />
                              Select this option if you would like to pay your
                              subsequent fees with the new card same as above
                              rather than the card on file
                            </label>
                          )}
                          {props.operation !== "AMS" ? (
                            <label>
                              <Field
                                type="radio"
                                name="differentCard"
                                checked={
                                  futurePayment === "bankAccount" || false
                                }
                                onClick={() => setFuturePayment("bankAccount")}
                              />
                              Select this option if you would like to enter
                              Bank/Savings Account number to pay your subsequent
                              fees rather than the above credit card
                            </label>
                          ) : null}
                        </div>
                        {futurePayment === "bankAccount" && (
                          <div className="payment-details-modal-container bank-account">
                            <Input
                              type="text"
                              label="Account name: "
                              name="holderName"
                              id="holderName"
                              autoComplete="off"
                              className="fs-12"
                              value={paymentInfoBank.holderName || ""}
                              onChange={(e) => setDataBank(e)}
                            />
                            <Input
                              type="text"
                              label="Account number"
                              autoComplete="off"
                              name="accountNumber"
                              id="accountNumber"
                              className="fs-12"
                              value={paymentInfoBank.accountNumber || ""}
                              onChange={(e) => setDataBank(e)}
                            />
                            <Input
                              type="text"
                              label="BSB"
                              name="bsb"
                              id="bsb"
                              autoComplete="off"
                              className="fs-12"
                              value={paymentInfoBank.bsb || ""}
                              onChange={(e) => setDataBank(e)}
                            />
                          </div>
                        )}
                      </div>
                    )}
                  <div className="make-payment-btn-container ">
                    <Button
                      loading={
                        addMemberPaymentInfoLoading ||
                        chargeSinglePaymentLoading
                      }
                      name={"Make payment"}
                      btntype="submit"
                      btnname="submit"
                      className={`btn btn-primary btn-lg fs-12  ${
                        paymentType !== "CASH" &&
                        paymentType !== "WAIVE" &&
                        paymentType !== "EFTPOS" &&
                        !cardPrimary?.id &&
                        !paymentInformation?.paymentInformation
                          ?.paymentInformationId
                          ? "btn-disabled"
                          : ""
                      }`}
                      disabled={
                        paymentType !== "CASH" &&
                        paymentType !== "WAIVE" &&
                        paymentType !== "EFTPOS" &&
                        (addMemberPaymentInfoLoading ||
                          chargeSinglePaymentLoading ||
                          (!cardPrimary?.id &&
                            !paymentInformation?.paymentInformation
                              ?.paymentInformationId))
                      }
                    />
                    <Button
                      name="CANCEL"
                      btntype="button"
                      btnname="cancel"
                      click={() => props.setShowModalPayment(false)}
                      className="btn btn-deny btn-lg fs-12"
                    />
                  </div>
                </div>
              </Form>
            );
          }}
        </Formik>
      </Modal>
      {(paymentType === "creditCard" || paymentType === "differentCard") &&
        openVivaPay && (
          <Modal>
            <div className="status-modal make-a-payment-modal viva-pay pd-32">
              <iframe
                id="pay-with-vivapay"
                title="pay with vivapay"
                src={iframeSrc}
                className="iframe-vivapay"
                loading="lazy"
              />
              <Button
                name="Continue"
                btntype="button"
                btnname="cancel"
                className="btn btn-confirm btn-small"
                click={() => closeModalVivaPay()}
              />
            </div>
          </Modal>
        )}
    </div>
  );
};
export default MakePaymentModal;
