import "../../location/addlocation.scss";
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import "./addVoucher.scss";
import { useMemberContext } from "../../../contexts/MemberContext";
import { Header } from "../../../components";
import React, { useState } from "react";
import { GET_VOUCHER_BY_ID } from "../../../gqloperations/queries";
import ViewVoucherLocations from "./viewVoucherLocations";
import ViewVoucherMembers from "./viewVoucherMembers";

const ViewVoucher = () => {
  const { voucherType } = useMemberContext();
  const { idVoucher } = useParams();
  const [index, setIndex] = useState(0);

  const DATE_OPTIONS = {
    weekday: "short",
    day: "numeric",
    month: "numeric",
    year: "numeric",
    time: "numeric",
  };
  const DATE_OPTIONS_SHORT = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
  };
  const { data: fetchVoucherData } = useQuery(GET_VOUCHER_BY_ID, {
    fetchPolicy: "no-cache",
    variables: {
      id: idVoucher,
    },
  });

  return (
    <>
      <Header
        pageTitle={`${voucherType === "FIXED" ? "View fixed Voucher" : ""}
                                           ${
                                             voucherType === "PERCENT"
                                               ? "View % Voucher"
                                               : ""
                                           }
                                           ${
                                             voucherType === "VISIT_PASS"
                                               ? "View visit pass Voucher"
                                               : ""
                                           }
                                           ${
                                             voucherType === "COMPLIMENTARY"
                                               ? "View complimentary Voucher"
                                               : ""
                                           }`}
      />
      <div className="tabs mt-12">
        <div className="tabs-list">
          <div
            className={
              index !== 0
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setIndex(0);
            }}
          >
            DETAILS
          </div>
          <div
            className={
              index !== 1
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setIndex(1);
            }}
          >
            MEMBERS
          </div>
          <div
            className={
              index !== 2
                ? "tab-head fs-14 back-mediumgray"
                : "tab-head fs-14 back-persianblue"
            }
            onClick={() => {
              setIndex(2);
            }}
          >
            LOCATIONS
          </div>
        </div>
      </div>
      {index === 0 && (
        <div className="add-info-container">
          <div className="voucher-details-container loc-card">
            <h3 className="title">Voucher information</h3>
            <div className="voucher-grid-4 ">
              <div className="detail address">
                <h6 className="fs-12 text-gray">Voucher Code </h6>
                <p className="fs-12">
                  {fetchVoucherData?.getVoucher.voucherCode}
                </p>
              </div>
              <div className="detail city">
                <h6 className="fs-12 text-gray">Created By</h6>
                <p className="fs-12">
                  {fetchVoucherData?.getVoucher?.createdByDetail?.givenName}{" "}
                  {fetchVoucherData?.getVoucher?.createdByDetail?.surname}
                </p>
              </div>
              <div className="detail city">
                <h6 className="fs-12 text-gray">Date and time</h6>
                <p className="fs-12">
                  {new Date(
                    fetchVoucherData?.getVoucher.createdAt
                  ).toLocaleTimeString("en-GB", DATE_OPTIONS)}
                </p>
              </div>
              <div className="detail state">
                <h6 className="fs-12 text-gray">Membership</h6>
                <p className="fs-12">
                  {
                    fetchVoucherData?.getVoucher?.membershipDetail
                      ?.membershipName
                  }
                </p>
              </div>
              {voucherType === "VISIT_PASS" && (
                <div className="detail suburb">
                  <h6 className="fs-12 text-gray">Visit</h6>
                  <p className="fs-12">{fetchVoucherData?.getVoucher.visit}</p>
                </div>
              )}
              {voucherType !== "VISIT_PASS" && (
                <div className="detail suburb">
                  <h6 className="fs-12 text-gray">Quantity</h6>
                  <p className="fs-12">
                    {fetchVoucherData?.getVoucher.quantity}
                  </p>
                </div>
              )}
              <div className="detail post">
                <h6 className="fs-12 text-gray">Start Date</h6>
                <p className="fs-12">
                  {new Date(
                    fetchVoucherData?.getVoucher.startDateTime
                  ).toLocaleDateString("en-GB", DATE_OPTIONS_SHORT)}
                </p>
              </div>
              <div className="detail ">
                <h6 className="fs-12 text-gray">End Date</h6>
                <p className="fs-12">
                  {fetchVoucherData?.getVoucher.endDateTime
                    ? new Date(
                        fetchVoucherData?.getVoucher.endDateTime
                      ).toLocaleDateString("en-GB", DATE_OPTIONS_SHORT)
                    : ""}
                </p>
              </div>
              {voucherType !== "COMPLIMENTARY" && (
                <div className="detail ">
                  <h6 className="fs-12 text-gray">
                    {voucherType === "PERCENT"
                      ? "% Discount"
                      : voucherType === "FIXED"
                      ? "PIF/DD (fortnightly)"
                      : "Cost"}
                  </h6>
                  <p className="fs-12">
                    {voucherType === "PERCENT" ? "" : "$"}
                    {fetchVoucherData?.getVoucher.cost}
                  </p>
                </div>
              )}
              {voucherType === "COMPLIMENTARY" && (
                <div className="detail ">
                  <h6 className="fs-12 text-gray">Duration</h6>
                  <p className="fs-12">
                    {fetchVoucherData?.getVoucher.duration === 0 && "0"}
                    {fetchVoucherData?.getVoucher.duration === 1 && "1 DAY"}
                    {fetchVoucherData?.getVoucher.duration === 7 && "1 WEEK"}
                    {fetchVoucherData?.getVoucher.duration === 14 && "2 WEEKS"}
                    {fetchVoucherData?.getVoucher.duration === 21 && "3 WEEKS"}
                    {fetchVoucherData?.getVoucher.duration === 30 && "1 MONTH"}
                    {fetchVoucherData?.getVoucher.duration === 365 && "1 YEAR"}

                    {fetchVoucherData?.getVoucher.duration > 30 &&
                      fetchVoucherData?.getVoucher.duration < 365 &&
                      `${fetchVoucherData?.getVoucher.duration / 30} MONTHS`}
                  </p>
                </div>
              )}
            </div>
            <div className="voucher-grid-4">
              {voucherType === "VISIT_PASS" && (
                <div className="detail suburb">
                  <h6 className="fs-12 text-gray">Quantity</h6>
                  <p className="fs-12">
                    {fetchVoucherData?.getVoucher.quantity}
                  </p>
                </div>
              )}

              <div className="detail ">
                <h6 className="fs-12 text-gray">Joining Fee</h6>
                <p className="fs-12">
                  ${fetchVoucherData?.getVoucher.joiningFee}
                </p>
              </div>
              <div className="detail ">
                <h6 className="fs-12 text-gray">Pass Fee</h6>
                <p className="fs-12">${fetchVoucherData?.getVoucher.passFee}</p>
              </div>
              <div className="detail ">
                <h6 className="fs-12 text-gray">Activation Fee</h6>
                <p className="fs-12">
                  ${fetchVoucherData?.getVoucher.activationFee}
                </p>
              </div>
              {voucherType === "FIXED" && (
                <div className="detail ">
                  <h6 className="fs-12 text-gray">Delay payment</h6>
                  <p className="fs-12">
                    {fetchVoucherData?.getVoucher.paymentDelay.amount}{" "}
                    {fetchVoucherData?.getVoucher.paymentDelay.type}
                  </p>
                </div>
              )}
              <div className="detail mb-12">
                <h6 className="fs-12 text-gray">Member Type</h6>
                <p className="fs-12">
                  {fetchVoucherData?.getVoucher.memberType}
                </p>
              </div>
            </div>
            <div className="desc-row note">
              <h6 className="fs-12 text-gray mb-12">Notes</h6>
              <p className="fs-12">{fetchVoucherData?.getVoucher.note}</p>
            </div>
          </div>
        </div>
      )}
      {index === 1 && (
        <ViewVoucherMembers
          data={fetchVoucherData?.getVoucher?.voucherMembers?.items}
        />
      )}
      {index === 2 && (
        <ViewVoucherLocations
          data={fetchVoucherData?.getVoucher?.voucherLocation?.items}
        />
      )}
    </>
  );
};
export default ViewVoucher;
