import { useNavigate } from "react-router-dom";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { locationStudioSchema } from "../../../../validations/LocationValidation";
import { CREATE_LOCATION_STUDIO } from "../../../../gqloperations/mutations";
import { Input, Button, Header, ToggleFormik } from "../../../../components";
import { useLocationContext } from "../../../../contexts/LocationContext";

const AddStudio = () => {
  const { state, dispatch, getLocationById } = useLocationContext();
  const [addStudio] = useMutation(CREATE_LOCATION_STUDIO);
  let navigate = useNavigate();

  const handleCancel = () => {
    dispatch({ type: "SET_INDEX", payload: 3 });
    navigate(`/dashboard/location/specificLocation/${state.id}`);
  };

  const onSubmit = async (values, OnSubmitProps) => {
    try {
      await addStudio({
        variables: {
          input: {
            ...values,
            studioName: values.studioName.toUpperCase(),
            locationId: state.id,
          },
        },
        onCompleted: () => {
          getLocationById();
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Studio Added Successfully");
          dispatch({ type: "SET_INDEX", payload: 3 });
          navigate(`/dashboard/location/specificLocation/${state.id}`);
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };

  return (
    <>
      {state.location && (
        <Formik
          initialValues={{
            studioName: "",
            description: "",
            isActive: false,
          }}
          validationSchema={locationStudioSchema}
          onSubmit={(values, OnSubmitProps) => {
            onSubmit(values, OnSubmitProps);
          }}
        >
          {(formik) => {
            return (
              <>
                <Form>
                  <Header pageTitle="Add Studio" />
                  <div className="add-studio-container pin-card pd-12 mt-32 mb-12">
                    <div className="details-container pin-codes">
                      <div className="add-studio-info pb-12 flex-wrap">
                        <Input
                          type="text"
                          label="Studio Name * "
                          name="studioName"
                          id="studioName"
                          autoComplete="off"
                          className="mb-12"
                        />
                        <ToggleFormik
                          label="Studio Active"
                          name="isActive"
                          id="isActive"
                          value={formik.isActive}
                          disabled={false}
                        />
                      </div>

                      <div>
                        <p className="fs-12 text-gray mb-8">Description</p>
                        <textarea
                          className="reason-box fs-12"
                          id="description"
                          name="description"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.description}
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div className="save-btn right-btn">
                    <Button
                      name="SAVE"
                      btntype="submit"
                      btnname="submit"
                      className="btn btn-confirm btn-large fs-12 "
                    />
                    <Button
                      name="CANCEL"
                      btntype="reset"
                      btnname="reset"
                      className="btn btn-deny btn-large fs-12"
                      click={handleCancel}
                    />
                  </div>
                </Form>
              </>
            );
          }}
        </Formik>
      )}
    </>
  );
};
export default AddStudio;
