import Button from "../../../formUiElements/Button";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { format, addDays, addMonths, isBefore } from "date-fns";
import { BeatLoader } from "react-spinners";
import { SUSPEND_MEMBERSHIP } from "../../../../gqloperations/mutations";
import {
  GET_MEMBER_CONTRACT_BY_CONTRACTID,
  GET_MEMBER_BY_ID,
  LIST_TRANSACTION_BY_MEMBERID,
} from "../../../../gqloperations/queries";
import { useMutation } from "@apollo/client";
import { Formik, Form } from "formik";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import Input from "../../../formUiElements/input/Input";
import TextArea from "../../../formUiElements/input/TextArea";
import { suspensionSchema } from "../../../../validations/Validation";
import { useMemberContext } from "../../../../contexts/MemberContext";
import SuspensionCost from "./SuspensionCost";
import Modal from "../../../modal/Modal";
import MakePaymentModal from "../../../modal/MakePaymentModal";
import { utcToZonedTime } from "date-fns-tz";
import { sortAsc } from "../../../../utils/sort";
import { addFrequencyToDate } from "../../../../utils/calculateTimePeriod";

const StandardSuspension = ({ minimumStartDate, getmembercontract }) => {
  const { contractId } = useParams();
  const { createLog, member } = useMemberContext();
  const [editable, setEditable] = useState(false);
  const [suspensionType, setSuspensionType] = useState("OTHER");
  const [suspendMembership] = useMutation(SUSPEND_MEMBERSHIP);
  const navigate = useNavigate();

  const handleCancel = () => {
    navigate(-1);
    createLog(
      `Clicked cancel button on medical suspension for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
  };
  const [onSubmitState, setOnSubmitState] = useState();
  const onSubmit = (values, OnSubmitProps) => {
    setOnSubmitState({ value: values, onSubmitProps: OnSubmitProps });
    // setUpfrontPrice()
    if (values.suspensionAmount > 0) {
      setPaymentModal(true);
    } else
      suspendMembership({
        variables: {
          ...values,
          memberContractId: contractId,
          suspensionType,
          operation: "CREATE_SUSPENSION",
        },
        onCompleted: () => {
          onSubmitComplete(OnSubmitProps);
        },
        onError: (error) => {
          onSubmitError(error, OnSubmitProps);
        },

        refetchQueries: refetchQueries,
      });
  };
  const onSubmitComplete = (OnSubmitProps) => {
    const onsubmitprops = onSubmitState?.onSubmitProps || OnSubmitProps;
    toast.success("Suspension Added Successfully");
    createLog(
      `Clicked save button on medical suspension for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
    navigate(-1);
    onsubmitprops.setSubmitting(false);
    onsubmitprops.resetForm();
  };
  const onSubmitError = (error, OnSubmitProps) => {
    const onsubmitprops = onSubmitState?.onSubmitProps || OnSubmitProps;
    toast.error("Error: Cannot create a suspension Message: " + error.message);

    createLog(
      `Clicked save button on medical suspension for ${member?.getMember?.givenName} ${member?.getMember?.surname}`,
      "member",
      `${member.getMember.memberId}`
    );
    onsubmitprops.setSubmitting(false);
    onsubmitprops.resetForm();
  };
  const refetchQueries = [
    {
      query: GET_MEMBER_CONTRACT_BY_CONTRACTID,
      variables: { id: contractId },
    },
    {
      query: GET_MEMBER_BY_ID,
      variables: { memberId: member?.getMember?.memberId },
    },
    {
      query: LIST_TRANSACTION_BY_MEMBERID,
      variables: { memberId: member?.getMember?.memberId },
    },
  ];
  const handleMaxSusEndDate = (suspensionStartDate) => {
    if (!suspensionStartDate) return "";
    if (getmembercontract.getMemberContract.recurring) {
      //DD
      let suspensionEndDate;
      // const susStartDate = new Date(suspensionStartDate);
      // change to six month from now
      const sixMonthFromStartDate = addMonths(new Date(), 6);
      const contractEndDate = utcToZonedTime(
        new Date(getmembercontract?.getMemberContract.endDateTime),
        getmembercontract?.getMemberContract.timezone
      );

      const contractItems = getmembercontract?.getMemberContract.billings;
      const { items: billingsItems } = contractItems;
      const sortedBilling = sortAsc(billingsItems, "debitDate");
      const lastBilling = sortedBilling[sortedBilling.length - 1];

      // last date of last debit period billing

      if (
        getmembercontract?.getMemberContract.endDateTime &&
        isBefore(contractEndDate, sixMonthFromStartDate)
      ) {
        suspensionEndDate = format(contractEndDate, "yyyy-MM-dd");
      } else if (
        isBefore(
          utcToZonedTime(
            addFrequencyToDate(
              new Date(lastBilling.debitDate),
              getmembercontract?.getMemberContract.paymentFrequency
            ),
            getmembercontract?.getMemberContract.timezone
          ),
          sixMonthFromStartDate
        )
      ) {
        return format(
          utcToZonedTime(
            addFrequencyToDate(
              new Date(lastBilling.debitDate),
              getmembercontract?.getMemberContract.paymentFrequency
            ),
            getmembercontract?.getMemberContract.timezone
          ),
          "yyyy-MM-dd"
        );
      } else suspensionEndDate = format(sixMonthFromStartDate, "yyyy-MM-dd");
      return suspensionEndDate;
    } else {
      let suspensionEndDate;
      // const susStartDate = new Date(suspensionStartDate);
      // change to six month from now
      const sixMonthFromStartDate = addMonths(new Date(), 6);
      const contractEndDate = utcToZonedTime(
        new Date(getmembercontract?.getMemberContract.endDateTime),
        getmembercontract?.getMemberContract.timezone
      );
      if (
        getmembercontract?.getMemberContract.endDateTime &&
        isBefore(contractEndDate, sixMonthFromStartDate)
      ) {
        suspensionEndDate = format(contractEndDate, "yyyy-MM-dd");
      } else {
        suspensionEndDate = format(sixMonthFromStartDate, "yyyy-MM-dd");
      }
      return suspensionEndDate;
    }
  };
  const [paymentModal, setPaymentModal] = useState(false);
  return (
    <>
      {paymentModal && (
        <Modal>
          <MakePaymentModal
            amount={onSubmitState.value.suspensionAmount}
            setShowModalPayment={setPaymentModal}
            operation="SSC"
            locationId={getmembercontract.getMemberContract.locationId}
            payloadData={{
              suspensionArguments: {
                operation: "CREATE_SUSPENSION",
                suspensionAmount: onSubmitState.value.suspensionAmount,
                suspendFrom: onSubmitState.value.suspendFrom,
                suspendTo: onSubmitState.value.suspendTo,
                suspensionReason: onSubmitState.value.suspensionReason,
                suspensionType: "OTHER",
              },
            }}
            onApiError={(e) => onSubmitError(e)}
            onApiCompleted={() => {
              onSubmitComplete();
            }}
            apiRefetchQueries={refetchQueries}
          />
        </Modal>
      )}
      <Formik
        enableReinitialize
        initialValues={{
          suspendFrom: minimumStartDate,
          suspendTo: "",
          suspensionAmount: 0,
          suspensionReason: "",
        }}
        validationSchema={suspensionSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="medical-suspension-content">
                {/* <div className="suspension-dialog-box info-card item-centered">
                  <p className="uppercase fs-12">
                    Suspension cost: $2.50 per week
                  </p>
                  <p className="uppercase fs-12">
                    (Rounded to next full week I.E. If you suspend for 8 days
                    the suspension fee will be $5)
                  </p>
                  <p className="uppercase fs-12">
                    (Minimum suspension is 1 week)
                  </p>
                </div> */}
                <div className="suspension-details-box info-card">
                  <h3 className="fs-14 uppercase">Suspension details</h3>
                  <div className="input-details-row">
                    <div>
                      <Input
                        type="date"
                        label="Start Date *"
                        name="suspendFrom"
                        autoComplete="off"
                        onChange={(e) => {
                          formik.setValues({
                            ...formik.values,
                            suspendFrom: e.target.value,
                            suspendTo: "",
                          });
                        }}
                        min={minimumStartDate}
                        max={format(
                          addMonths(new Date(minimumStartDate), 6),
                          "yyyy-MM-dd"
                        )}
                      />
                    </div>
                    <div>
                      <Input
                        type="date"
                        label="End Date *"
                        name="suspendTo"
                        autoComplete="off"
                        min={format(
                          addDays(new Date(formik.values.suspendFrom), 6),
                          "yyyy-MM-dd"
                        )}
                        value={formik.values.suspendTo}
                        max={handleMaxSusEndDate(formik.values.suspendFrom)}
                      />
                    </div>
                    {/* <div>
                      <SuspensionCost
                        name="suspensionAmount"
                        editable={editable}
                        setEditable={setEditable}
                        setSuspensionType={setSuspensionType}
                      />
                    </div> */}
                  </div>
                  <div className="desc-row">
                    <TextArea
                      label="Reason *"
                      name="suspensionReason"
                      id="suspensionReason-standard"
                    />
                  </div>
                </div>
              </div>
              <div className="right-btn mt-18 pb-24">
                <Button
                  loading={formik.isSubmitting}
                  name={"Save"}
                  btntype="submit"
                  btnname="submit"
                  className="btn btn-primary btn-lg fs-12"
                  disabled={formik.isSubmitting ? true : false}
                />
                <Button
                  name="Cancel"
                  btntype="reset"
                  btnname="reset"
                  className="btn btn-large btn-deny"
                  click={handleCancel}
                />
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default StandardSuspension;
