import './doorAccess.scss';

import { useState } from 'react';

import { toast } from 'react-toastify';

import { useMutation } from '@apollo/client';

import { useLocationContext } from '../../../../contexts/LocationContext';
import { ADD_DOOR_ACCESS_HOURS } from '../../../../gqloperations/mutations';
import validateTimePeriodInput from '../../../../utils/validateTimePeriodInput';

export default function AddTimePeriodRow({
  accessPointId,
  day,
  setAddTime,
  getLocationById,
  timePeriods,
}) {
  const { state } = useLocationContext();
  const [openTime, setOpenTime] = useState("");
  const [closeTime, setCloseTime] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [addHours] = useMutation(ADD_DOOR_ACCESS_HOURS);
  const cancelTimePeriod = () => {
    setAddTime("");
    toast.warning("Update Cancelled");
  };
  const saveTimePeriod = async () => {
    setErrorMessage("");
    if (!openTime || !closeTime) {
      setErrorMessage("Time period should have start time and end time!");
      return;
    }
    if (openTime >= closeTime) {
      setErrorMessage("Start time should be earlier than end time!");
      return;
    }
    const isValid = validateTimePeriodInput(timePeriods, openTime, closeTime);
    if (!isValid) {
      setErrorMessage("Time exists!");
      return;
    }
    try {
      await addHours({
        variables: {
          input: {
            locationId: state.id,
            day,
            accessPointId,
            openTime,
            closeTime,
          },
        },
        onCompleted: () => {
          getLocationById();
          toast.success("Time Period Added Successfully");
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
    setAddTime("");
  };
  return (
    <>
      <div className="add-time-row door-access">
        <input
          type="time"
          value={openTime}
          onChange={(e) => setOpenTime(e.target.value)}
          required
        />
        <input
          type="time"
          value={closeTime}
          onChange={(e) => setCloseTime(e.target.value)}
          required
        />
        <button
          className="btn btn-confirm btn-xsmall fs-10"
          type="submit"
          onClick={saveTimePeriod}
        >
          Save
        </button>
        <button
          className="btn btn-deny btn-xsmall fs-10"
          type="submit"
          onClick={cancelTimePeriod}
        >
          Cancel
        </button>
      </div>
      {errorMessage && <p className="fs-8 error">{errorMessage}</p>}
    </>
  );
}
