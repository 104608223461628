import React, { useEffect, useState } from "react";
import { Formik, Form, Field } from "formik";
import { sendVoucherSchema } from "../../../validations/Validation";
import { useApolloClient, useMutation } from "@apollo/client";
import { SEND_VOUCHER } from "../../../gqloperations/mutations";
import { toast } from "react-toastify";
import { Input, Button } from "../../../components";
import { SEARCH_BY_EMAIL } from "../../../gqloperations/queries";
import { useMemberContext } from "../../../contexts/MemberContext";
import { useAuthContext } from "../../../contexts/AuthContext/AuthProvider";

const SendVoucherModal = (props) => {
  const client = useApolloClient();
  const [contentSend, setContentSend] = useState("");
  const [sendVoucherMutation, { loading: sendVoucherLoading }] =
    useMutation(SEND_VOUCHER);
  const { locationAccess } = useAuthContext();
  const [initialDataVariables, setInitialDataVariables] = useState({});
  const [initialFilter, setInitialFilter] = useState({});
  const [loading, setLoading] = useState(false);
  const { sidebarLocationId, sidebarBrandId } = useMemberContext();
  const [memberType, setMemberType] = useState(
    props.memberType === "EXIST" ||
      props.memberType === "ALL" ||
      props.memberType === "PREVIOUS"
      ? "exist"
      : "new"
  );
  const phoneNumberRegex = /^[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~\d]*$/g;
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  useEffect(() => {
    setInitialDataVariables({
      filter: {
        type: { eq: "MEMBER" },
        or: initialFilter,
      },
      sort: [{ field: "createdAt", direction: "desc" }],
      limit: 500,
    });
  }, [initialFilter]);

  useEffect(() => {
    let memberLocationFilter = [];
    if (sidebarLocationId) {
      memberLocationFilter.push({ homeLocationId: { eq: sidebarLocationId } });
    } else if (sidebarBrandId) {
      const brandLocations = locationAccess.brands.find(
        (brand) => brand.brandId === sidebarBrandId
      ).locations;
      brandLocations?.map((location) => {
        memberLocationFilter.push({
          homeLocationId: { eq: location.locationId },
        });
        return null;
      });
    } else {
      memberLocationFilter.push({ homeLocationId: { eq: " " } });
    }
    setInitialDataVariables(memberLocationFilter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sidebarBrandId, sidebarLocationId]);

  const sendVoucherToExist = async (values) => {
    setLoading(true);
    try {
      const result = await client.query({
        query: SEARCH_BY_EMAIL,
        fetchPolicy: "no-cache",
        variables: {
          email: values.contact.toUpperCase(),
          filter: {
            or: initialDataVariables,
          },
          limit: 100,
        },
      });

      if (result.data.getMemberByEmail.items[0]?.memberId) {
        sendVoucherMutation({
          variables: {
            input: {
              action: "SEND_VOUCHER",
              data: {
                voucherId: props.voucherId,
                brandId: props.sidebarBrandId,
                destination: values.contact,
                content: contentSend,
                memberId: result.data.getMemberByEmail.items[0]?.memberId,
              },
            },
          },
          onCompleted: () => {
            setLoading(false);
            props.setModalSendVoucher(false);
            toast.success("Send voucher successfully");
          },
          onError: (e) => {
            setLoading(false);
            toast.error(`Error: ${e}`);
          },
        });
      } else {
        setLoading(false);
        toast.error("Member does not exist");
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error}`);
    }
  };

  const sendVoucherToNew = async (values) => {
    setLoading(true);
    try {
      if (emailRegex.test(values.contact)) {
        const result = await client.query({
          query: SEARCH_BY_EMAIL,
          fetchPolicy: "no-cache",
          variables: {
            email: values.contact.toUpperCase(),
            filter: {
              or: initialDataVariables,
            },
            limit: 100,
          },
        });

        if (!result.data.getMemberByEmail.items[0]?.memberId) {
          sendVoucherMutation({
            variables: {
              input: {
                action: "SEND_VOUCHER",
                data: {
                  voucherId: props.voucherId,
                  brandId: props.sidebarBrandId,
                  destination: values.contact,
                  content: contentSend,
                  memberId: "",
                },
              },
            },
            onCompleted: () => {
              setLoading(false);
              props.setModalSendVoucher(false);
              toast.success("Send voucher successfully");
            },
            onError: (e) => {
              setLoading(false);
              toast.error(`Error: ${e}`);
            },
          });
        } else {
          setLoading(false);
          toast.error("This email already exist");
        }
      } else {
        sendVoucherMutation({
          variables: {
            input: {
              action: "SEND_VOUCHER",
              data: {
                voucherId: props.voucherId,
                brandId: props.sidebarBrandId,
                destination: values.contact,
                content: contentSend,
                memberId: "",
              },
            },
          },
          onCompleted: () => {
            setLoading(false);
            props.setModalSendVoucher(false);
            toast.success("Send voucher successfully");
          },
          onError: (e) => {
            setLoading(false);
            toast.error(`Error: ${e}`);
          },
        });
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error}`);
    }
  };

  return (
    <Formik
      initialValues={{
        contact: "",
        note: "",
      }}
      validationSchema={sendVoucherSchema}
      validate={(values) => {
        let errors = {};

        if (phoneNumberRegex.test(values.contact) && memberType === "exist") {
          const errorText = "Invalid email address";
          errors.contact = errorText;
        }
        return errors;
      }}
      onSubmit={async (values, OnSubmitProps) => {
        if (memberType === "exist") {
          await sendVoucherToExist(values);
        } else {
          await sendVoucherToNew(values);
        }
      }}
    >
      {(formik) => {
        return (
          <div className="pd-32">
            <Form>
              <div className="status-modal ">
                <h2 className="modal-title fs-35">Send voucher</h2>
                <div
                  aria-labelledby="my-radio-group"
                  className=" payment-options-container member-type-voucher pb-18 fs-12"
                >
                  <label>
                    <Field
                      disabled={
                        props.memberType !== "EXIST" &&
                        props.memberType !== "PREVIOUS" &&
                        props.memberType !== "ALL"
                      }
                      name="cardOnFile"
                      type="radio"
                      checked={memberType === "exist"}
                      onClick={() => setMemberType("exist")}
                    />
                    Existing/Previous member
                  </label>
                  <label>
                    <Field
                      disabled={
                        props.memberType !== "NEW" && props.memberType !== "ALL"
                      }
                      type="radio"
                      name="differentCard"
                      checked={memberType === "new"}
                      onClick={() => setMemberType("new")}
                    />
                    New member
                  </label>
                </div>
                <div className="search-bar destination location-value loc-select">
                  <label className="fs-12 text-gray label" htmlFor="contact">
                    {memberType === "exist"
                      ? "Email "
                      : "Email or mobile number"}
                  </label>
                  <Input
                    className="fs-10"
                    type="text"
                    list="memberList"
                    name="contact"
                    id="contact"
                    placeholder={
                      memberType === "exist"
                        ? "Type email "
                        : "Type email or mobile number"
                    }
                  />
                </div>
                <div className="message-box">
                  <div htmlFor="note" className="fs-12 mb-8 text-gray label">
                    Notes
                  </div>
                  <textarea
                    className=" fs-12"
                    id="note"
                    name="note"
                    onChange={(e) => setContentSend(e.target.value)}
                  ></textarea>
                </div>
                <div className="btn-group">
                  <Button
                    name="Yes"
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-confirm btn-small"
                    disabled={Object.keys(formik.errors).length > 0}
                    loading={sendVoucherLoading || loading}
                  />
                  <Button
                    name="No"
                    btntype="button"
                    btnname="cancel"
                    className="btn btn-deny btn-small"
                    click={() => props.setModalSendVoucher(false)}
                  />
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};

export default SendVoucherModal;
