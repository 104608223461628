import { useState } from "react";
import { Formik, Form } from "formik";
import { useMutation } from "@apollo/client";
import { toast } from "react-toastify";
import { AiOutlineEdit } from "react-icons/ai";
import { UPDATE_ACCESS_MESSAGE } from "../../../../gqloperations/mutations";
import Button from "../../../formUiElements/Button";
import Input from "../../../formUiElements/input/Input";
import ToggleFormik from "../../../formUiElements/ToggleFormik";
import { useLocationContext } from "../../../../contexts/LocationContext";

export default function EditMessage({
  id,
  swipeMessageText,
  errorMessage,
  isMessageActive,
  messageFrequency,
}) {
  const { getLocationById } = useLocationContext();
  const [editMessage] = useMutation(UPDATE_ACCESS_MESSAGE);
  const [isActive, setIsActive] = useState(true);

  const handleClick = () => {
    setIsActive(false);
  };

  const handleCancel = () => {
    toast.warning("Update Cancelled");
    document.getElementById("swipe-message-form").reset();
    setIsActive(true);
  };
  const onSubmit = async (values, OnSubmitProps) => {
    try {
      await editMessage({
        variables: {
          input: {
            ...values,
            id,
            swipeMessageText: values.swipeMessageText,
            errorMessage: values.errorMessage,
            isMessageActive: values.isMessageActive,
            messageFrequency: values.messageFrequency,
          },
        },

        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          toast.success("Massage Saved Successfully");
          setIsActive(true);
          getLocationById();
        },
      });
    } catch (error) {
      toast.error(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };
  return (
    <Formik
      initialValues={{
        swipeMessageText: swipeMessageText,
        errorMessage: errorMessage,
        isMessageActive: isMessageActive,
        messageFrequency: messageFrequency,
      }}
      onSubmit={(values, OnSubmitProps) => {
        onSubmit(values, OnSubmitProps);
      }}
    >
      {(formik) => {
        return (
          <>
            <Form id="swipe-message-form">
              <div className="add-info-container">
                <div className="details-container loc-card">
                  <div className="title-bar">
                    <div></div>
                    {isActive ? (
                      <>
                        <span
                          className="icon-container edit"
                          onClick={handleClick}
                        >
                          <AiOutlineEdit />
                        </span>
                      </>
                    ) : (
                      <span className="icon-container">
                        <div className="sub-container save">
                          <Button
                            iconname="BiSave"
                            btntype="submit"
                            btnname="submit"
                            className="btn btn-edit save btn-lg fs-12 "
                          />
                        </div>
                        <div className="sub-container cancel">
                          <Button
                            iconname="AiOutlineCloseCircle"
                            btntype="reset"
                            btnname="reset"
                            className="btn  btn-edit cancel btn-lg fs-12"
                            click={handleCancel}
                          />
                        </div>
                      </span>
                    )}
                  </div>

                  <label
                    htmlFor="swipeMessageText"
                    className="swipe-title capitalize fs-10 pb-8"
                  >
                    Swipe Message
                  </label>
                  <textarea
                    id="swipeMessageText"
                    name="swipeMessageText"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.swipeMessageText}
                    className={isActive ? "" : "blue"}
                    disabled={isActive ? true : false}
                  ></textarea>
                  <label
                    htmlFor="errorMessage"
                    className="capitalize error-title fs-10 pb-8"
                  >
                    Error Message
                  </label>
                  <textarea
                    id="errorMessage"
                    name="errorMessage"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.errorMessage}
                    disabled={isActive ? true : false}
                    className={isActive ? "" : "blue"}
                  ></textarea>
                  <div className="swipe-frequency-container pt-8">
                    <Input
                      type="number"
                      label="Swipe Message Frequency * "
                      name="messageFrequency"
                      id="messageFrequency"
                      value={formik.values.messageFrequency}
                      autoComplete="off"
                      className={
                        isActive ? "fs-10 uppercase" : "fs-10 uppercase blue"
                      }
                      disabled={isActive ? true : false}
                    />
                    <div className="pt-12">
                      <ToggleFormik
                        label="Swipe Message Active"
                        name="isMessageActive"
                        id="isMessageActive"
                        value={formik.isMessageActive}
                        disabled={isActive ? true : false}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
}
