import "./editHealthQuestionnaire.scss";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { useMemberContext } from "../../contexts/MemberContext";
import { BeatLoader } from "react-spinners";
import { useMutation, useQuery } from "@apollo/client";
import { EDIT_HEALTHQUESTION } from "../../gqloperations/mutations";
import {
  GET_ALL_HEALTHQUESTIONNAIRE,
  GET_HEALTHQUESTIONNAIRE_BY_ID,
} from "../../gqloperations/queries";
import { HealthQuestionnaireSection } from "../../constants/commonConstant";
import {
  Select,
  RadioButton,
  Button,
  Header,
  ToggleFormik,
} from "../../components";

function EditHealthQuestionnaire() {
  const navigate = useNavigate();
  const { sidebarBrandId, sidebarBrandName } = useMemberContext();
  const { id } = useParams();
  const [questionnaire, setQuestionnaire] = useState();
  const { loading, error } = useQuery(GET_HEALTHQUESTIONNAIRE_BY_ID, {
    variables: {
      id,
    },
    onCompleted: (data) => {
      setQuestionnaire(data.getHealthQuestionnaire);
    },
  });
  const [editHealthQuestion] = useMutation(EDIT_HEALTHQUESTION);

  const onSubmit = async (values, OnSubmitProps) => {
    let variables = {
      id: "",
      isDeleted: false,
      isActive: false,
      expectedAnswer:false,
      question:'',
      mandatory:false,
      sectionLabel:'',
      sectionId:'',
    };
    const getVariable = () => {
      variables.id = id;
      variables.isDeleted = values.isDeleted;
      variables.isActive = values.isActive;
      variables.expectedAnswer =
        values.expectedAnswer === "other" || values.expectedAnswer === ""
          ? ""
          : values.expectedAnswer;
      variables.question = values.question;
      variables.mandatory = values.mandatory;
      variables.sectionLabel = values.sectionLabel;
      variables.sectionId = HealthQuestionnaireSection.filter(
        (i) => i.label.toLowerCase() === values.sectionLabel.toLowerCase()
      )[0].id;
      return variables;
    };
    try {
      await editHealthQuestion({
        variables: { input: getVariable() },
        onCompleted: () => {
          OnSubmitProps.setSubmitting(false);
          OnSubmitProps.resetForm();
          toast.success("Question Edit Successfully");
          navigate(-1);
        },
        refetchQueries: [
          {
            query: GET_ALL_HEALTHQUESTIONNAIRE,
            variables: {
              filter: {
                and: [
                  { isDeleted: { ne: true } },
                  { brandId: { eq: `${sidebarBrandId}` } },
                ],
              },
            },
          },
        ],
      });
    } catch (error) {
      toast.error(`${error}`);
    }
    OnSubmitProps.setSubmitting(false);
  };

  const handleCancel = () => {
    navigate(-1);
  };

  if (loading)
    return (
      <div className="dashboard-loading">
        <BeatLoader color="white" />
      </div>
    );

  if (error) {
    return (
      <div>
        <div>Problem in getting health question</div>
      </div>
    );
  }

  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          mandatory: questionnaire?.mandatory,
          isActive: questionnaire?.isActive,
          expectedAnswer: questionnaire?.expectedAnswer,
          sectionId: questionnaire?.sectionId,
          sectionLabel: questionnaire?.sectionLabel,
          question: questionnaire?.question,
          answerType: questionnaire?.answerType,
        }}
        // validationSchema={HealthQuestionnaireSchema}
        onSubmit={(values, OnSubmitProps) => {
          onSubmit(values, OnSubmitProps);
        }}
      >
        {(formik) => {
          return (
            <>
              <Header pageTitle="Edit Question" />
              <Form>
                <div className="edit-question-page main mt-32 pd-18 mb-12">
                  <div className="edit-card">
                    <div className="question-title-details">
                      <div className="inputs">
                        <div>
                          <h3 className="fs-14">HEALTH QUESTION</h3>
                        </div>
                        <div>
                          <h3 className="fs-12 text-gray">Brand</h3>
                          <p className="fs-12">{sidebarBrandName}</p>
                        </div>
                        <Select
                          label="Section label"
                          optionvalues={HealthQuestionnaireSection.map(
                            (i) => i.label
                          )}
                          selecttype="array"
                          name="sectionLabel"
                          id="sectionLabel"
                          autoComplete="off"
                          className="select-element-value"
                          labelclass="text-gray"
                        />
                        <div>
                          <h3 className="fs-12 text-gray">Answer Type:</h3>
                          <p className="fs-12">{questionnaire?.answerType}</p>
                        </div>
                        <div>
                          <ToggleFormik
                            label="Active"
                            name="isActive"
                            className="fs-10"
                          />{" "}
                          <ToggleFormik
                            // disabled
                            label="Mandatory"
                            name="mandatory"
                            className="fs-10"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <h3 className="fs-12 text-gray mb-18 mt-18">Question *</h3>
                    <textarea
                      className="reason-box fs-12"
                      id="question"
                      name="question"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.question}
                    ></textarea>
                  </div>
                  {formik?.values?.answerType?.toLowerCase() !== "string" && (
                    <div className="desc-row">
                      <h3 className="fs-12 mb-18 mt-18">Expected Answer *</h3>

                      <div className="radioGroup">
                        <RadioButton
                          type="radiobutton"
                          name="expectedAnswer"
                          id="radioYes"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={"yes"}
                          label="yes"
                          checked={formik?.values?.expectedAnswer === "yes"}
                          // disabled={formik?.values?.expectedAnswer !== "yes"}
                        />
                        <RadioButton
                          type="radiobutton"
                          name="expectedAnswer"
                          id="radioNo"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={"no"}
                          label="no"
                          checked={formik?.values?.expectedAnswer === "no"}
                          // disabled={formik?.values?.expectedAnswer !== "no"}
                        />
                        <RadioButton
                          type="radiobutton"
                          name="expectedAnswer"
                          id="radioNone"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={"other"}
                          label="other"
                          checked={
                            formik?.values?.expectedAnswer === "other" ||
                            formik?.values?.expectedAnswer === null ||
                            formik?.values?.expectedAnswer === ""
                          }
                          // disabled={
                          //   !(
                          //     questionnaire?.expectedAnswer === "other" ||
                          //     questionnaire?.expectedAnswer === null ||
                          //     questionnaire?.expectedAnswer === ""
                          //   )
                          // }
                        />
                      </div>
                      {/* <h3 className="fs-12">Expected Answer *</h3>
                    <textarea
                      className="reason-box fs-12"
                      id="expectedAnswer"
                      name="expectedAnswer"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.expectedAnswer}
                    ></textarea>
                    {formik?.errors?.expectedAnswer ? (
                      <div className="error fs-12">
                        {formik.errors.expectedAnswer}
                      </div>
                    ) : null} */}
                    </div>
                  )}
                </div>
                <div className="right-btn pb-24">
                  {/* {formik.isSubmitting && (
                  <Button
                    name={<BeatLoader color="white" size={11} />}
                    btnname="submit"
                    btntype="Update"
                    className="btn btn-large btn-confirm  fs-12"
                    disabled={true}
                  />
                )}
                {!formik.isSubmitting && (
                  <Button
                    name="Save"
                    btnname="submit"
                    btntype="submit"
                    className="btn btn-large btn-confirm  fs-12"
                  />
                )} */}
                  <Button
                    loading={formik.isSubmitting}
                    name={"Save"}
                    btntype="submit"
                    btnname="submit"
                    className="btn btn-large btn-confirm  fs-12"
                    disabled={formik.isSubmitting ? true : false}
                  />
                  <Button
                    name="Cancel"
                    btntype="reset"
                    btnname="submit"
                    className="btn btn-large btn-deny fs-12"
                    click={handleCancel}
                  />
                </div>
              </Form>
            </>
          );
        }}
      </Formik>
    </div>
  );
}

export default EditHealthQuestionnaire;
